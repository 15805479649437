import { createReducer, on } from '@ngrx/store';
import {
    AppUsersResponse,
    InsightsBrokerPerformance,
    OpportunityRatingRequest,
    PriorityChartData,
    ProductEndDateResponse,
    Rate,
} from '../insight.type';
import { insightActions } from './insight.actions';

export const insightNode = 'insight';

export interface InsightState {
    priority4: PriorityChartData | null;
    priority5: PriorityChartData | null;
    monthRate: Rate | null;
    variance: Rate | null;
    rentalRate: Rate | null;
    totalData: { properties: number; propertiesAvg: number; clientRevenue: number } | null;
    appUsersData: { count: number; pct: number } | null;
    opportunityData: null | OpportunityRatingRequest;
    productEndDate: null | ProductEndDateResponse;
    brokersPerformance4: InsightsBrokerPerformance[];
    brokersPerformance5: InsightsBrokerPerformance[];
    appUsers: AppUsersResponse | null;
}

export const insightState: InsightState = {
    priority4: null,
    priority5: null,
    monthRate: null,
    rentalRate: null,
    totalData: null,
    variance: null,
    appUsersData: null,
    opportunityData: null,
    productEndDate: null,
    brokersPerformance4: [],
    brokersPerformance5: [],
    appUsers: null,
};

export const insightReducer = createReducer(
    insightState,
    on(insightActions.priority4ChartSet, (state, action) => ({
        ...state,
        priority4: action.data,
    })),
    on(insightActions.priority5ChartSet, (state, action) => ({
        ...state,
        priority5: action.data,
    })),
    on(insightActions.set3MonthRate, (state, action) => ({
        ...state,
        monthRate: { ...action },
    })),
    on(insightActions.setRetentionRate, (state, action) => ({
        ...state,
        rentalRate: { ...action },
    })),
    on(insightActions.setTotalData, (state, action) => ({
        ...state,
        totalData: { ...action },
    })),
    on(insightActions.setTotalData, (state, action) => ({
        ...state,
        totalData: { ...action },
    })),
    on(insightActions.setVariance, (state, action) => ({
        ...state,
        variance: { ...action },
    })),
    on(insightActions.setAppUsersData, (state, action) => ({
        ...state,
        appUsersData: { ...action },
    })),
    on(insightActions.setOpportunityData, (state, action) => ({
        ...state,
        opportunityData: { ...action },
    })),
    on(insightActions.brokerPerformance4Set, (state, action) => ({
        ...state,
        brokersPerformance4: action.data,
    })),
    on(insightActions.brokerPerformance5Set, (state, action) => ({
        ...state,
        brokersPerformance5: action.data,
    })),
    on(insightActions.setProductEndDate, (state, action) => ({
        ...state,
        productEndDate: { ...action },
    })),
    on(insightActions.setAppUsers, (state, action) => ({
        ...state,
        appUsers: { ...action },
    })),
);

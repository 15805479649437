import { createAction, props } from '@ngrx/store';
import { Company } from '../../../dashboard/company/company.type';
import { CompanyShowingData } from '../../type';

const companyInfoRequest = createAction('[COMPANY] info has been requested', props<{ companyId: number }>());

export const companyInfoSet = createAction('[COMPANY] info has been set', props<{ info: Company }>());
export const selectedCompanySet = createAction(
    '[COMPANY] selected company has been set',
    props<{
        selectedCompany: CompanyShowingData | null;
        companiesList: CompanyShowingData[];
    }>(),
);

export const companyActions = {
    companyInfoRequest,
    companyInfoSet,
    selectedCompanySet,
};

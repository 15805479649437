import { createAction, props } from '@ngrx/store';
import { ColConfigs, PaginationMetaData } from '../../type';
import { TableState } from './table.store';

export const updateState = createAction('[TABLE] state has been updated', props<{ state: TableState }>());
export const setColSettings = createAction('[TABLE] set colSettings', props<{ configs: ColConfigs }>());
export const loadConfigs = createAction('[TABLE] load colSettings');
export const updateColSettings = createAction(
    '[TABLE] colSettings have been updated',
    props<{
        configs: ColConfigs;
    }>(),
);
export const paginationDataSet = createAction(
    '[TABLE] pagination settings have been set',
    props<{ pagination: PaginationMetaData }>(),
);

export const tableActions = {
    updateState,
    paginationDataSet,
    setColSettings,
    updateColSettings,
    loadConfigs,
};

import { Component, Input, OnInit } from '@angular/core';
import { Property } from '../../../../type';
import { DataPropertyService } from '../../../../services/data-property.service';

@Component({
    selector: 'app-property-mortgage-update',
    templateUrl: './property-mortgage-update.component.html',
})
export class PropertyMortgageUpdateComponent implements OnInit {
    @Input()
    public property: Property | null;

    @Input()
    public isSubmitForm: boolean;

    public productEndTerm: Date | null;
    public mortgageStartDate: Date | null;

    public constructor(protected readonly dataPropertyService: DataPropertyService) {}

    public ngOnInit() {
        this.productEndTerm = DataPropertyService.prepareDateToShow(this.property?.mortgages[0]?.product_end_term);
        this.mortgageStartDate = DataPropertyService.prepareDateToShow(
            this.property?.mortgages[0]?.mortgage_start_date,
        );
        this.changeMortgageToggle();
    }

    public changeMortgageToggle() {
        const disableValidation = () => {
            this.dataPropertyService.form.controls['lender_name'].disable();
            this.dataPropertyService.form.controls['monthly_mortgage_payments'].disable();
            this.dataPropertyService.form.controls['outstanding_mortgage'].disable();
            this.dataPropertyService.form.controls['product_end_term'].disable();
            this.dataPropertyService.form.controls['original_term'].disable();
            this.dataPropertyService.form.controls['interest_rate'].disable();
            this.dataPropertyService.form.controls['repayment_type'].disable();
            this.dataPropertyService.form.controls['rate_type'].disable();
            this.dataPropertyService.form.controls['mortgage_start_date'].disable();
        };

        const enableValidation = () => {
            this.dataPropertyService.form.controls['lender_name'].enable();
            this.dataPropertyService.form.controls['outstanding_mortgage'].enable();
            this.dataPropertyService.form.controls['product_end_term'].enable();
            this.dataPropertyService.form.controls['original_term'].enable();
            this.dataPropertyService.form.controls['interest_rate'].enable();
            this.dataPropertyService.form.controls['repayment_type'].enable();
            this.dataPropertyService.form.controls['rate_type'].enable();
            this.dataPropertyService.form.controls['mortgage_start_date'].enable();
            this.dataPropertyService.form.controls['monthly_mortgage_payments'].enable();
        };

        this.dataPropertyService.form.controls['has_mortgage'].value ? enableValidation() : disableValidation();
    }
}

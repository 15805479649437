<div class='overflow-hidden' *ngIf='xaxis && series'>
    <apx-chart
        [xaxis]='xaxis'
        [series]='series'

        [chart]='chart'
        [grid]='chartService.apexConfigs.grid'
        [stroke]='chartService.apexConfigs.stroke'
        [dataLabels]='chartService.apexConfigs.labels'
        [colors]='chartService.apexConfigs.colors'
        [plotOptions]='plotOptions'
        [legend]='chartService.apexConfigs.legend'
        [yaxis]='yaxis'
        [fill]='chartService.apexConfigs.fill'
        [tooltip]='chartService.apexConfigs.tooltip'
        [title]='{text: "Product Expiry Volumes Rolling 12 Months"}'

        class='rounded-lg bg-white dark:bg-black'
    >
    </apx-chart>
</div>



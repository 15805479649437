import { createSelector } from '@ngrx/store';
import { tableNode, TableState } from './table.store';

export const selectFeature = (state: { [tableNode]: TableState }) => state[tableNode];

const selectTableData = createSelector(selectFeature, state => ({
    page: state.page,
    perPage: state.perPage,
    search: state.search,
    statusFilter: state.statusFilter,
    brokerFilter: state.brokerFilter,
    priorityFilter: state.priorityFilter,
    customFilter: state.customFilter,
    sortColumn: state.sortColumn,
    sortDirection: state.sortDirection,
}));
const selectPagination = createSelector(selectFeature, state => state.pagination);
const selectColConfigs = createSelector(selectFeature, state => state.colConfigs);

export const tableSelectors = {
    selectTableData,
    selectPagination,
    selectColConfigs,
};

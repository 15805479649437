<div class='panel'>
    <h5 class='text-2xl'>Client: {{(profile$ | async).name}}</h5>
</div>

<div class='panel mt-6 flex flex-row justify-start gap-24'>
    <div>
        <div class='text-xl'>Email</div>
        <div>{{(profile$ | async).email}}</div>
    </div>

    <div>
        <div class='text-xl'>Phone</div>
        <div>{{(profile$ | async).phone}}</div>
    </div>
</div>

<ng-container *ngIf='(profile$ | async) as profile'>
    <app-client-detail [client]='profile'></app-client-detail>
</ng-container>


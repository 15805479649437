import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { userNode, UserState } from '../shared/store/user/user.store';
import { Profile } from '../shared/type';
import { userSelectors } from '../shared/store/user/user.selectors';
import { PermissionService } from '../shared/services/permission.service';

@Injectable({
    providedIn: 'root',
})
export class IsManagerGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly store: Store<{
            [userNode]: UserState;
        }>,
    ) {}

    public profile$: Observable<Profile | null> = this.store.pipe(select(userSelectors.selectProfile));

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.profile$.pipe(
            switchMap(profile =>
                PermissionService.checkCredentials(profile, PermissionService.managerRoles)
                    ? of(true)
                    : this.router.navigate(['dashboard/company']),
            ),
        );
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Broker, Investor, Meta, Property, PropertyHistory } from '../type';
import { environment as env } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { TableSortService } from './table-sort.service';
import * as qs from 'qs';
import { HttpClient } from '@angular/common/http';
import { CompanyProfile } from '../../dashboard/company/company.type';

@Injectable()
export class PropertyService {
    public constructor(
        private readonly tablesSortService: TableSortService,
        private readonly http: HttpClient,
    ) {}

    public propertyRequest(propertyId: number): Observable<{
        property: Property;
        investor: Investor;
        broker: Broker;
        investors: Investor[];
    }> {
        const filter = qs.stringify({ filter: { id: { eq: propertyId } } }, { encode: false });
        return this.http
            .get<{ data: Property[]; status: string; related: { investors: Investor[]; brokers: Broker[] } }>(
                `${env.baseServerUrl}/properties?${filter}`,
            )
            .pipe(
                map(response => ({
                    property: response.data[0],
                    investor: response.related.investors[0],
                    investors: response.related.investors,
                    broker: response.related.brokers[0],
                })),
            );
    }

    public createProperty(requestBody: any): Observable<any> {
        return this.http.post(`${env.baseServerUrl}/properties`, requestBody);
    }

    public deleteProperty(propertyId: number): Observable<any> {
        return this.http.delete(`${env.baseServerUrl}/properties/${propertyId}`);
    }

    public propertyHistory(propertyId: number): Observable<PropertyHistory[]> {
        return this.http
            .get<{ data: PropertyHistory[]; status: string }>(`${env.baseServerUrl}/properties/${propertyId}/history`)
            .pipe(map(response => response.data));
    }

    public updateProperty(propertyId: number, mortgagesId: number | null, requestBody: any): Observable<any> {
        const request = { ...requestBody };
        if (mortgagesId !== null && requestBody.mortgages)
            request['mortgages'][0] = {
                ...requestBody.mortgages[0],
                id: mortgagesId,
            };
        return this.http.patch(`${env.baseServerUrl}/properties/${propertyId}`, request);
    }

    public propertiesRequest(
        perPage: number,
        page: number,
        options?: { filters?: any[]; search?: string; sort?: { columnName: string; direction: string } },
    ): Observable<{
        properties: Property[];
        meta: Meta;
        investors: Investor[];
        brokers: Broker[];
        companies: CompanyProfile[];
    }> {
        const params: { per_page: number; page: number; search?: string } = {
            per_page: perPage,
            page,
        };
        if (options?.search) params.search = options.search;

        let _filter = '';
        if (options?.filters) _filter = PropertyService.filterMap(options.filters);

        let _sort = '';
        if (options?.sort)
            _sort = this.tablesSortService.findSchemaForSort(options.sort.columnName, options.sort.direction);

        return this.http
            .get<{
                data: Property[];
                meta: Meta;
                status: string;
                related: { investors: Investor[]; brokers: Broker[]; investor_companies: CompanyProfile[] };
            }>(
                `${env.baseServerUrl}/properties?${_filter}${
                    _filter.length > 0 && _sort.length > 0 ? '&' : ''
                }${_sort}`,
                { params },
            )
            .pipe(
                map(response => ({
                    properties: response.data,
                    meta: response.meta,
                    investors: response.related.investors,
                    brokers: response.related.brokers,
                    companies: response.related.investor_companies,
                })),
            );
    }

    public static filterMap(filters: any[]): string {
        const obj = { filter: {}, filter_any: {} };
        filters.forEach(filter => {
            obj.filter = {
                ...obj.filter,
                ...filter?.filter,
            };
            obj.filter_any = {
                ...obj.filter_any,
                ...filter?.filter_any,
            };
        });
        return qs.stringify(obj, { encode: false });
    }
}

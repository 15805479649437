import { Component, ViewChild } from '@angular/core';
import { CalendarOptions, DatesSetArg, EventClickArg, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { BrokerAppointmentService } from '../../shared/services/broker-appointment.service';
import { BehaviorSubject, combineLatestWith, first } from 'rxjs';
import * as moment from 'moment';
import { BrokerAppointment, Investor } from '../../shared/type';
import { UpdateAppointmentModalComponent } from './update-appointment-modal/update-appointment-modal.component';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
})
export class CalendarComponent {
    @ViewChild('appointmentUpdateModal') appointmentModal: UpdateAppointmentModalComponent;

    public readonly calendarEvents$: BehaviorSubject<{ events: EventInput[]; appointments: BrokerAppointment[] }> =
        new BehaviorSubject<{ events: EventInput[]; appointments: BrokerAppointment[] }>({
            events: [],
            appointments: [],
        });
    public readonly investors$: BehaviorSubject<Investor[]> = new BehaviorSubject<Investor[]>([]);
    public calendarOptions: CalendarOptions = {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        editable: true,
        dayMaxEvents: true,
        droppable: true,
        datesSet: event => {
            this.changeDate(event);
        },
        eventClick: event => {
            this.handleDateClick(event);
        },
    };

    public currentDates: DatesSetArg;

    public constructor(private readonly brokerAppointmentService: BrokerAppointmentService) {}

    public changeDate(dates: DatesSetArg | null = null): void {
        const _dates = dates ?? this.currentDates;
        this.currentDates = _dates;
        this.brokerAppointmentService
            .getBrokerAppointments(_dates.start, _dates.end)
            .pipe(first())
            .subscribe(response => {
                this.investors$.next(response.investors);
                this.calendarEvents$.next({
                    appointments: response.appointments,
                    events: response.appointments.map(event => ({
                        id: String(event.id),
                        title: this.getTitleEvent(
                            event,
                            response.investors.find(item => Number(item.id) == Number(event.investor_id)),
                        ),
                        date: moment(event.appointment_date).format('yyyy-MM-DD'),
                    })),
                });
            });
    }
    public getTitleEvent(event: BrokerAppointment, investor: Investor): string {
        const _title = event.title.length < 10 ? event.title : event.title.slice(0, 10) + '...';
        const _notes = event.notes !== null && event.notes.length > 10 ? event.notes.slice(0, 10) + '...' : event.notes;
        return `${investor.name} ${_title} ${_notes ?? ''}`;
    }

    public handleDateClick(arg: EventClickArg): void {
        this.calendarEvents$.pipe(combineLatestWith(this.investors$), first()).subscribe(([events, investors]) => {
            const _appointment = events.appointments.find(
                appointment => Number(appointment.id) == Number(arg.event.id),
            );
            const _investor = investors.find(investor => investor.id == _appointment.investor_id);
            this.appointmentModal.openUpdateModal(_appointment, _investor);
        });
    }
}

<ng-container *ngIf='(_profile$ | async) as sums; else loader'>
    <div class='mt-10'>
        <div class='grid grid-cols-6 gap-6'>
            <div>
                <div class='text-primary'>Portfolio Value</div>
                <div class='mt-2 text-2xl font-semibold'>£{{sums.portfolio_values ?? 0 | number}}</div>
            </div>
            <div>
                <div class='text-primary'>Mortgage Outstanding</div>
                <div class='mt-2 text-2xl font-semibold'>£{{sums.mortgages_outstanding ?? 0 | number}}</div>
            </div>
            <div>
                <div class='text-primary'>Monthly Mortgage</div>
                <div class='mt-2 text-2xl font-semibold'>£{{sums.monthly_mortgages_payments ?? 0 | number}}</div>
            </div>
            <div>
                <div class='text-primary'>Rental Income</div>
                <div class='mt-2 text-2xl font-semibold'>£{{sums.rentals_income ?? 0 | number}}</div>
            </div>
            <div>
                <div class='text-primary'>Growth</div>
                <div class='mt-2 text-2xl font-semibold'>{{(growths$ | async) ?? 0 | number}}%</div>
            </div>
            <div>
                <div class='text-primary'>Yield</div>
                <div class='mt-2 text-2xl font-semibold'>{{(yields$ | async) ?? 0 | number}}%</div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loader>
    <app-loader></app-loader>
</ng-template>

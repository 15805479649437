import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from 'angular-custom-modal/modal.component';
import { PropertyService } from '../../../../../services/property.service';
import { first } from 'rxjs';
import { DashboardService } from '../../../../../../dashboard/dashboard.service';
import { RouterService } from '../../../../../services/router.service';

@Component({
    selector: 'app-confirming-modal',
    templateUrl: './confirming-modal.component.html',
})
export class ConfirmingModalComponent {
    @ViewChild('modal') modal: ModalComponent;

    private propertyId: number;

    public constructor(
        private readonly propertyService: PropertyService,
        private readonly dashboardService: DashboardService,
        private readonly routerService: RouterService,
    ) {}
    public open(propertyId: number) {
        this.propertyId = propertyId;
        this.modal.open();
    }

    public delete() {
        this.propertyService
            .deleteProperty(this.propertyId)
            .pipe(first())
            .subscribe(() => {
                this.close();
                this.dashboardService.redirectToPrev(this.routerService.parentRoute());
            });
    }
    public close() {
        this.modal.close();
    }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { colDef } from '@bhplugin/ng-datatable';
import { select, Store } from '@ngrx/store';
import { tableNode, TableState } from '../../store/table/table.store';
import { tableSelectors } from '../../store/table/table.selectors';
import { combineLatestWith, first, map, Observable, switchMap } from 'rxjs';
import { TableChooserModalComponent } from './component/table-chooser-modal/table-chooser-modal.component';
import { tableActions } from '../../store/table/table.actions';
import { PermissionService } from '../../services/permission.service';

@Component({
    selector: 'app-table-chooser',
    templateUrl: './table-chooser.component.html',
})
export class TableChooserComponent implements OnInit {
    @Input() set cols(cols: colDef[]) {
        this._cols = [...cols.map(col => ({ ...col }))];
    }

    @Input() tableKey: 'propertyTable' | 'investorsPropertyTable' = 'propertyTable';

    @ViewChild('TableChooserModal') modal: TableChooserModalComponent;

    protected _cols: colDef[] = [];

    @Output() colsChange = new EventEmitter<colDef[]>();

    public colConfigs$ = this.store.pipe(select(tableSelectors.selectColConfigs)).pipe(first());

    public constructor(
        private readonly permissionService: PermissionService,
        private readonly store: Store<{
            [tableNode]: TableState;
        }>,
    ) {}

    public updateColumn(col: colDef) {
        this.colConfigs$.subscribe(store => {
            const selected = this._cols.find(_col => _col.title == col.title);
            selected.hide = !selected.hide;
            const _store = { ...store };
            _store[this.tableKey] = [...this._cols];
            this.store.dispatch(tableActions.updateColSettings({ configs: _store }));
            this.colsChange.emit(this._cols);
        });
    }

    public ngOnInit(): void {
        this.colConfigs$
            .pipe(
                combineLatestWith(this.checkPermissions(this._cols)),
                map(([config, cols]) =>
                    config && config[this.tableKey]
                        ? [
                              ...this._cols.map(col => {
                                  const val = config[this.tableKey].find(_config => _config.title == col.title);
                                  return { ...col, hide: val ? val.hide : col.hide };
                              }),
                          ]
                        : [...cols],
                ),
                switchMap(cols => this.checkPermissions(cols)),
            )
            .subscribe(result => {
                this._cols = [...result];
                this.colsChange.emit(this._cols);
            });
    }

    public openModal(): void {
        this.modal.open(this._cols);
    }
    private checkPermissions(cols: colDef[]): Observable<colDef[]> {
        const hasAllShowPermissions$ = this.permissionService.checkProfileCredentials([
            ...PermissionService.managerRoles,
            ...PermissionService.brokerRoles,
        ]);
        const _cols$ = hasAllShowPermissions$.pipe(
            map(hasPermission => (hasPermission ? cols : cols.filter(col => col.field != 'status'))),
        );
        return _cols$.pipe(first());
    }
}

<modal [closeOnOutsideClick]='true' #modal class='modal-top'>
    <ng-template #modalHeader>
        <div>Products</div>
    </ng-template>
    <ng-template #modalBody class='min-w-[30rem]'>
        <div class='space-y-2 font-semibold'>
            <div *ngFor='let product of products'
                 class='first:bg-green-100 rounded border border-[#d3d3d3] dark:border-[#1b2e4b]'>
                <button
                    type='button'
                    class='flex w-full items-center p-4 text-white-dark dark:bg-[#1b2e4b]'
                    [ngClass]="{ '!text-primary': activeAccordion === product.ProductCode }"
                    (click)='activeAccordion === product.ProductCode ? (activeAccordion = null) : (activeAccordion = product.ProductCode)'
                >
                    {{product.LenderName}}
                    <div class='ltr:ml-auto rtl:mr-auto'
                         [ngClass]="{ 'rotate-180': activeAccordion === product.ProductCode }">
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'
                             class='h-4 w-4'>
                            <path d='M19 9L12 15L5 9' stroke='currentColor' stroke-width='1.5' stroke-linecap='round'
                                  stroke-linejoin='round' />
                        </svg>
                    </div>
                </button>

                <div [@slideDownUp]='activeAccordion !== product.ProductCode' class='accordion-content'>
                    <div
                        class='space-y-2 border-t border-[#d3d3d3] p-4 text-[13px] text-white-dark dark:border-[#1b2e4b]'>

                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>Product Type :</div>
                            <div class='uppercase font-semibold'>{{product.ProductType}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>LenderName :</div>
                            <div class='uppercase font-semibold'>{{product.LenderName}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>Fees Total :</div>
                            <div class='uppercase font-semibold'>{{product.FeesTotal}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>Initial Monthly Payment :</div>
                            <div class='uppercase font-semibold'>{{product.InitialMonthlyPayment}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>Initial Pay Rate :</div>
                            <div class='uppercase font-semibold'>{{product.InitialPayRate}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>Apr Lenders :</div>
                            <div class='uppercase font-semibold'>{{product.AprLenders}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>Max LTV Available :</div>
                            <div class='uppercase font-semibold'>{{product.MaxLTVAvailable}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>Max Loan Available :</div>
                            <div class='uppercase font-semibold'>{{product.MaxLoanAvailable}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>True Cost Full Term :</div>
                            <div class='uppercase font-semibold'>{{product.TrueCostFullTerm}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>Standard Variable Rate :</div>
                            <div class='uppercase font-semibold'>{{product.StandardVariableRate}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>True Cost Over Initial Period :</div>
                            <div class='uppercase font-semibold'>{{product.TrueCostOverInitialPeriod}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>Monthly Payment AfterInitial Period :</div>
                            <div class='uppercase font-semibold'>{{product.MonthlyPaymentAfterInitialPeriod}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>Early Repayment Charge Applies Until :</div>
                            <div class='uppercase font-semibold'>{{product.EarlyRepaymentChargeAppliesUntil}}</div>
                        </div>
                        <div class='mb-2 flex w-full items-center justify-between'>
                            <div class='text-white-dark'>Early Repayment Charge PeriodSort Field :</div>
                            <div class='uppercase font-semibold'>{{product.EarlyRepaymentChargePeriodSortField}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</modal>
<style>
    .app-modal-window .modal-dialog {
        min-width: 50rem;
    }
</style>

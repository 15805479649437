<modal [closeOnOutsideClick]='true' #modal [className]='activeTab === "notes" ? "modal-max-width" : "" ' class='modal-top'>

    <ng-template #modalHeader>
        <div class='p-7'>
            <p class='text-2xl mt-5 ml-2'>{{investor.name}}</p>
        </div>
    </ng-template>
    <ng-template #modalBody>
        <div class='w-full mb-5'>

            <div class='mt-3 flex flex-wrap border-b border-white-light dark:border-[#191e3a]'>
                <a
                    class='-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black cursor-pointer'
                    [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': activeTab.toLowerCase() === 'call' }"
                    (click)="activeTab = 'call'"
                >
                    Call
                </a>
                <a
                    class='-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black cursor-pointer'
                    [ngClass]="{ '!border-white-light !border-b-white text-primary dark:!border-[#191e3a] dark:!border-b-black': activeTab.toLowerCase() === 'notes' }"
                    (click)="activeTab = 'notes'"
                >
                    Notes
                </a>
                <a
                    class='-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black cursor-pointer'
                    [ngClass]="{ '!border-white-light !border-b-white text-primary dark:!border-[#191e3a] dark:!border-b-black': activeTab.toLowerCase() === 'detail' }"
                    (click)="activeTab = 'detail'"
                >
                    Detail
                </a>
            </div>

            <div class='flex-1 pt-5 text-sm'>

                <div *ngIf="activeTab.toLowerCase() === 'call'">
                    <app-scheduled-call (closeModalEvent)='closeModal()' (updateContent)='freshContent()' [investor]='investor'></app-scheduled-call>
                </div>

                <div *ngIf="activeTab.toLowerCase() === 'notes'">
                    <app-client-note [investor]='investor'></app-client-note>
                </div>
                <div *ngIf="activeTab.toLowerCase() === 'detail'">
                    <div class='mt-3'>Phone: <span>{{investor.phone ?? '-'}}</span></div>
                    <div class='mt-3'>Email: <span>{{investor.email ?? '-'}}</span></div>
                </div>
            </div>
        </div>
    </ng-template>
</modal>

import { Component, Input, ViewChild } from '@angular/core';
import { PropertyProduct } from '../../../../type';
import { slideDownUp } from '../../../../../../shared/animations';
import { ModalComponent } from 'angular-custom-modal/modal.component';

@Component({
    selector: 'app-property-products-modal',
    templateUrl: './property-products-modal.component.html',
    animations: [slideDownUp],
})
export class PropertyProductsModalComponent {
    @Input()
    public products: PropertyProduct[];

    @ViewChild('modal') modal: ModalComponent;

    public activeAccordion = null;

    public open() {
        this.modal.open();
    }
}

<modal [closeOnOutsideClick]='true' #modal class='modal-top'>
    <ng-template #modalHeader>
        <div>Create client</div>
    </ng-template>
    <ng-template #modalBody>
        <form [formGroup]='form' autocomplete='off'  novalidate>

            <div class='mt-6'
                 [ngClass]="(form.controls['name'].touched && form.controls['name'].errors) ? 'has-error' : ''">
                <label for='name'>Name</label>
                <input id='name' type='text' class='form-input read-only:bg-gray-200'
                       formControlName='name' />
                <ng-container
                    *ngIf="form.controls['name'].touched && form.controls['name'].errors">
                    <p class='mt-1 text-danger'>Please fill</p>
                </ng-container>
            </div>

            <div class='mt-6'
                 [ngClass]="((form.controls['email'].touched && form.controls['email'].errors) || emailAlreadyExist) ? 'has-error' : ''">
                <label for='email'>Email</label>
                <input id='email' type='text' class='form-input read-only:bg-gray-200'
                       formControlName='email' />
                <ng-container
                    *ngIf="form.controls['email'].touched && form.controls['email'].errors && form.controls['email'].errors['required']">
                    <p class='mt-1 text-danger'>Please fill</p>
                </ng-container>
                <ng-container
                    *ngIf="form.controls['email'].touched && form.controls['email'].errors && form.controls['email'].errors['email']">
                    <p class='mt-1 text-danger'>Incorrect Email</p>
                </ng-container>
                <ng-container
                    *ngIf="emailAlreadyExist">
                    <p class='mt-1 text-danger'>Email already has been taken</p>
                </ng-container>
            </div>

            <div class='mt-6'>
                <label for='name'>Phone</label>
                <input id='phone' type='text' class='form-input read-only:bg-gray-200' formControlName='phone' />
            </div>

            <div class='mt-6 flex flex-row gap-1'>
                <input id='ftb' type="checkbox" class="form-checkbox" formControlName='ftb' />
                <label for='ftb'>FTB</label>
            </div>

        </form>
    </ng-template>
    <ng-template #modalFooter>
        <button type='button' (click)='save()' class='btn btn-primary ltr:ml-4 rtl:mr-4'>
            Create
        </button>

        <button type='button' (click)='close()' class='btn btn-outline-primary ltr:ml-4 rtl:mr-4'>
            Close
        </button>
    </ng-template>
</modal>

<div *ngIf='selectedPriority !== undefined'>
    <div class='panel flex flex-row justify-between'>
        <div class=' flex flex-row gap-12'>
            <h5 class='text-2xl'>{{selectedPriority?.broker.name}}</h5>

            <div class='flex flex-row'>
                <h5 class='text-2xl'>Priority </h5>
                <ng-select
                    [items]='options'
                    [(ngModel)]='selectedPriorityNumber'
                    [searchable]='false'
                    [clearable]='false'
                    (change)='priorityChanged()'
                    class='ml-4 text-2xl custom-multiselect'
                >
                </ng-select>

                <ng-select
                    [items]='optionsServicesFilter'
                    [(ngModel)]='servicesFilter'
                    [searchable]='false'
                    [clearable]='false'
                    bindLabel='name'
                    bindValue='id'
                    (change)='serviceChanged()'
                    class='ml-4 w-44 custom-multiselect'
                >
                </ng-select>
            </div>
        </div>

        <button (click)='redirectToPrevURL()' type='button' class='btn btn-outline-dark'>Back
        </button>
    </div>

    <div class='panel mt-6 pb-1.5'>

        <div *ngIf='selectedPriority' class='flex flex-row justify-between gap-6 mb-12 mt-6'>

            <div *ngIf='servicesFilter === "-"'
                 class='max-w-[19rem] card-active w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded-xl border border-[#e0e6ed]'>

                <div class='rounded-t-xl flex flex-row justify-between text-white p-5'>
                    <h5 class='text-2xl font-semibold mb-4'>Total Cases</h5>
                    <p class='text-2xl font-semibold mb-4'>{{selectedPriority.rating.total}}</p>
                </div>

                <div class='mt-3 p-5 flex flex-row justify-between'>
                    <div class='mr-2'>
                        <p>Actioned</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.actioned}}</p>
                    </div>
                    <div class='mr-2'>
                        <p>Pending</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.pending}}</p>
                    </div>
                    <div>
                        <p>Submitted</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.total}}</p>
                    </div>
                </div>

            </div>

            <div *ngIf='selectedPriority.rating.product_end_term'
                 class='max-w-[19rem] card w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded-xl border border-[#e0e6ed]'>

                <div class='rounded-t-xl flex flex-row justify-between p-5'>
                    <h5 class='text-2xl font-semibold mb-4'>Product Expiry</h5>
                    <p class='text-2xl font-semibold mb-4'>{{selectedPriority.rating.product_end_term.total}}</p>
                </div>

                <div class='mt-3 p-5 flex flex-row justify-between'>
                    <div class='mr-2'>
                        <p>Actioned</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.product_end_term.actioned}}</p>
                    </div>
                    <div class='mr-2'>
                        <p>Pending</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.product_end_term.pending}}</p>
                    </div>
                    <div>
                        <p>Submitted</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.product_end_term.total}}</p>
                    </div>
                </div>

            </div>

            <div *ngIf='selectedPriority.rating.early_review'
                 class='max-w-[19rem] card w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded-xl border border-[#e0e6ed]'>

                <div class='rounded-t-xl flex flex-row justify-between p-5'>
                    <h5 class='text-2xl font-semibold mb-4'>Early Review</h5>
                    <p class='text-2xl font-semibold mb-4'>{{selectedPriority.rating.early_review.total}}</p>
                </div>

                <div class='mt-3 p-5 flex flex-row justify-between'>
                    <div class='mr-2'>
                        <p>Actioned</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.early_review.actioned}}</p>
                    </div>
                    <div class='mr-2'>
                        <p>Pending</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.early_review.pending}}</p>
                    </div>
                    <div>
                        <p>Submitted</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.early_review.total}}</p>
                    </div>
                </div>

            </div>

            <div *ngIf='selectedPriority.rating.rate_riser'
                 class='max-w-[19rem] card w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded-xl border border-[#e0e6ed]'>

                <div class='rounded-t-xl flex flex-row justify-between p-5'>
                    <h5 class='text-2xl font-semibold mb-4'>Rate Riser</h5>
                    <p class='text-2xl font-semibold mb-4'>{{selectedPriority.rating.rate_riser.total}}</p>
                </div>

                <div class='mt-3 p-5 flex flex-row justify-between'>
                    <div class='mr-2'>
                        <p>Actioned</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.rate_riser.actioned}}</p>
                    </div>
                    <div class='mr-2'>
                        <p>Pending</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.rate_riser.actioned}}</p>
                    </div>
                    <div>
                        <p>Submitted</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.rate_riser.total}}</p>
                    </div>
                </div>

            </div>

            <div *ngIf='selectedPriority.rating.equity_available'
                 class='max-w-[19rem] card w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded-xl border border-[#e0e6ed]'>

                <div class='rounded-t-xl flex flex-row justify-between p-5'>
                    <h5 class='text-2xl font-semibold mb-4'>Equity Available</h5>
                    <p class='text-2xl font-semibold mb-4'>{{selectedPriority.rating.equity_available.total}}</p>
                </div>

                <div class='mt-3 p-5 flex flex-row justify-between'>
                    <div class='mr-2'>
                        <p>Actioned</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.equity_available.actioned}}</p>
                    </div>
                    <div class='mr-2'>
                        <p>Pending</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.equity_available.pending}}</p>
                    </div>
                    <div>
                        <p>Submitted</p>
                        <p class='text-xl flex block flex justify-center'>{{selectedPriority.rating.equity_available.total}}</p>
                    </div>
                </div>

            </div>
        </div>

        <div class='mt-6 mb-6 h-auto'>
            <app-broker-performance-chart *ngIf='servicesFilter === "-" || servicesFilter === "product_end_term"'
                                          [priority]='selectedPriority'></app-broker-performance-chart>
        </div>

        <div class='datatable'>
            <app-table
                tableName='Broker performance'
                [brokersTableSelectors]='brokersTableSelectors'
                [priorityFilter]='priorityFilters[servicesFilter]'
                [rows]='rows$ | async'
                [cols]='cols'
                [customFilter]='customFilter$ | async'
                (updateContent)='updateContent($event)'
            ></app-table>
        </div>

    </div>
</div>


<div
    *ngIf='selectedPriority === undefined'
    class='h-screen flex flex-col items-center justify-center inset-0 bg-[#fafafa] dark:bg-[#060818] z-[60] grid place-content-center animate__animated'
>
    <svg width='64' height='64' viewBox='0 0 135 135' xmlns='http://www.w3.org/2000/svg' fill='#4361ee'>
        <path
            d='M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='-360 67 67' dur='2.5s'
                              repeatCount='indefinite' />
        </path>
        <path
            d='M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='360 67 67' dur='8s'
                              repeatCount='indefinite' />
        </path>
    </svg>
</div>



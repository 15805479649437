import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from 'angular-custom-modal/modal.component';
import { InvestorService } from '../../../../services/investor.service';
import { first } from 'rxjs';
import { DataTableService } from '../../../../services/data-table.service';
import { dashboardActions } from '../../../../store/dashboard/dashboard.actions';
import { Store } from '@ngrx/store';
import { dashboardLayoutNode, DashboardLayoutState } from '../../../../store/dashboard/store';

@Component({
    selector: 'app-change-status-investor-modal',
    templateUrl: './change-status-investor-modal.component.html',
})
export class ChangeStatusInvestorModalComponent {
    public data: { id: number; invokedAt: string; client: string };

    @ViewChild('modal') modal: ModalComponent;

    public isDisableControls = false;

    public statusOptions = [
        { id: 0, name: 'Contact Required' },
        { id: 1, name: 'Contacted' },
    ];

    public status = 0;

    public constructor(
        private readonly dataTableService: DataTableService,
        private readonly investorService: InvestorService,
        private readonly store: Store<{
            [dashboardLayoutNode]: DashboardLayoutState;
        }>,
    ) {}

    public open(value: { id: number; invokedAt: string; client: string }) {
        this.data = { ...value };
        this.modal.open();
    }

    public save(): void {
        if (this.status == 0) return this.modal.close();
        const body = { client_invoked_at: null };
        this.isDisableControls = true;

        this.investorService
            .investorUpdate(this.data.id, body)
            .pipe(first())
            .subscribe(() => {
                this.isDisableControls = false;
                this.store.dispatch(dashboardActions.requestContactRequiredCount());
                this.dataTableService.updateTableContent();
                this.modal.close();
            });
    }
}

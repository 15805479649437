<ng-container [formGroup]='dataPropertyService.form'>
    <div class='py-7 px-6'>
        <div class='flex flex-row gap-2 justify-between items-center'>
            <div class='h-6'>
                <p class='text-xl font-semibold block'>Mortgage</p>
            </div>

            <div class='flex flex-row gap-2 items-center mt-2'>
                <label class='mt-1' for='has_mortgage'>Has mortgage: </label>
                <div class='w-12 h-6 relative'>
                    <input (change)='changeMortgageToggle()' formControlName='has_mortgage' type='checkbox'
                           class='custom_switch absolute w-full h-full opacity-0 z-10 cursor-pointer peer'
                           id='has_mortgage' />
                    <span for='has_mortgage'
                          class='outline_checkbox bg-icon border-2 border-[#ebedf2] dark:border-white-dark block h-full rounded-full before:absolute before:left-1 before:bg-[#ebedf2] dark:before:bg-white-dark before:bottom-1 before:w-4 before:h-4 before:rounded-full before:bg-[url(/assets/images/close.svg)] before:bg-no-repeat before:bg-center peer-checked:before:left-7 peer-checked:before:bg-[url(/assets/images/checked.svg)] peer-checked:border-primary peer-checked:before:bg-primary before:transition-all before:duration-300'></span>
                </div>
            </div>
        </div>
        <hr class='my-6 border-[#e0e6ed]' />

        <ng-container *ngIf='dataPropertyService.form.controls["has_mortgage"].value'>
            <div class='mt-6'
                 [ngClass]="(isSubmitForm && dataPropertyService.form.controls['lender_name'].errors) ? 'has-error' : ''">
                <label for='lenderName'>Lender name</label>
                <input id='lenderName' type='text' class='form-input'
                       formControlName='lender_name' />
                <ng-container
                    *ngIf="isSubmitForm && dataPropertyService.form.controls['lender_name'].errors">
                    <p class='mt-1 text-danger'>Please fill</p>
                </ng-container>
            </div>

            <div class='mt-6 flex flex-row justify-between'>

                <div
                    [ngClass]="(isSubmitForm && dataPropertyService.form.controls['outstanding_mortgage'].errors) ? 'has-error' : ''">
                    <label for='outstandingMortgage'>Outstanding Mortgage</label>
                    <input id='outstandingMortgage' type='text' class='form-input'
                           formControlName='outstanding_mortgage' />
                    <ng-container
                        *ngIf="isSubmitForm && dataPropertyService.form.controls['outstanding_mortgage'].errors">
                        <p class='mt-1 text-danger'>Please fill</p>
                    </ng-container>
                </div>

                <div
                    [ngClass]="(isSubmitForm && dataPropertyService.form.controls['monthly_mortgage_payments'].errors) ? 'has-error' : ''">
                    <label for='monthlyPayments'>Monthly payments</label>
                    <input id='monthlyPayments' type='text' class='form-input'
                           formControlName='monthly_mortgage_payments' />
                    <ng-container
                        *ngIf="isSubmitForm && dataPropertyService.form.controls['monthly_mortgage_payments'].errors">
                        <p class='mt-1 text-danger'>Please fill</p>
                    </ng-container>
                </div>

            </div>

            <div class='mt-6 flex flex-row justify-between'>

                <div
                    [ngClass]="(isSubmitForm && dataPropertyService.form.controls['product_end_term'].errors) ? 'has-error' : ''">
                    <label for='productEndTerm'>Product end term</label>
                    <div class='max-w-[12rem] flex'>
                        <div
                            class='bg-[#eee] flex justify-center items-center ltr:rounded-l-md rtl:rounded-r-md px-3 font-semibold border ltr:border-r-0 rtl:border-l-0 border-[#e0e6ed] dark:border-[#17263c] dark:bg-[#1b2e4b]'
                        >
                            <svg width='18' height='18' viewBox='0 0 24 24' fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M17 14C17.5523 14 18 13.5523 18 13C18 12.4477 17.5523 12 17 12C16.4477 12 16 12.4477 16 13C16 13.5523 16.4477 14 17 14Z'
                                    fill='currentColor' />
                                <path
                                    d='M17 18C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18Z'
                                    fill='currentColor' />
                                <path
                                    d='M13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13Z'
                                    fill='currentColor' />
                                <path
                                    d='M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z'
                                    fill='currentColor' />
                                <path
                                    d='M7 14C7.55229 14 8 13.5523 8 13C8 12.4477 7.55229 12 7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14Z'
                                    fill='currentColor' />
                                <path
                                    d='M7 18C7.55229 18 8 17.5523 8 17C8 16.4477 7.55229 16 7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18Z'
                                    fill='currentColor' />
                                <path fill-rule='evenodd' clip-rule='evenodd'
                                      d='M7 1.75C7.41421 1.75 7.75 2.08579 7.75 2.5V3.26272C8.412 3.24999 9.14133 3.24999 9.94346 3.25H14.0564C14.8586 3.24999 15.588 3.24999 16.25 3.26272V2.5C16.25 2.08579 16.5858 1.75 17 1.75C17.4142 1.75 17.75 2.08579 17.75 2.5V3.32709C18.0099 3.34691 18.2561 3.37182 18.489 3.40313C19.6614 3.56076 20.6104 3.89288 21.3588 4.64124C22.1071 5.38961 22.4392 6.33855 22.5969 7.51098C22.75 8.65018 22.75 10.1058 22.75 11.9435V14.0564C22.75 15.8941 22.75 17.3498 22.5969 18.489C22.4392 19.6614 22.1071 20.6104 21.3588 21.3588C20.6104 22.1071 19.6614 22.4392 18.489 22.5969C17.3498 22.75 15.8942 22.75 14.0565 22.75H9.94359C8.10585 22.75 6.65018 22.75 5.51098 22.5969C4.33856 22.4392 3.38961 22.1071 2.64124 21.3588C1.89288 20.6104 1.56076 19.6614 1.40314 18.489C1.24997 17.3498 1.24998 15.8942 1.25 14.0564V11.9436C1.24998 10.1058 1.24997 8.65019 1.40314 7.51098C1.56076 6.33855 1.89288 5.38961 2.64124 4.64124C3.38961 3.89288 4.33856 3.56076 5.51098 3.40313C5.7439 3.37182 5.99006 3.34691 6.25 3.32709V2.5C6.25 2.08579 6.58579 1.75 7 1.75ZM5.71085 4.88976C4.70476 5.02502 4.12511 5.27869 3.7019 5.7019C3.27869 6.12511 3.02502 6.70476 2.88976 7.71085C2.86685 7.88123 2.8477 8.06061 2.83168 8.25H21.1683C21.1523 8.06061 21.1331 7.88124 21.1102 7.71085C20.975 6.70476 20.7213 6.12511 20.2981 5.7019C19.8749 5.27869 19.2952 5.02502 18.2892 4.88976C17.2615 4.75159 15.9068 4.75 14 4.75H10C8.09318 4.75 6.73851 4.75159 5.71085 4.88976ZM2.75 12C2.75 11.146 2.75032 10.4027 2.76309 9.75H21.2369C21.2497 10.4027 21.25 11.146 21.25 12V14C21.25 15.9068 21.2484 17.2615 21.1102 18.2892C20.975 19.2952 20.7213 19.8749 20.2981 20.2981C19.8749 20.7213 19.2952 20.975 18.2892 21.1102C17.2615 21.2484 15.9068 21.25 14 21.25H10C8.09318 21.25 6.73851 21.2484 5.71085 21.1102C4.70476 20.975 4.12511 20.7213 3.7019 20.2981C3.27869 19.8749 3.02502 19.2952 2.88976 18.2892C2.75159 17.2615 2.75 15.9068 2.75 14V12Z'
                                      fill='#1C274C' />
                            </svg>

                        </div>
                        <ng2-flatpickr addClass='form-input' id='productEndTerm'
                                       [config]='dataPropertyService.basicCalendarParams'
                                       [setDate]='productEndTerm'
                                       formControlName='product_end_term'></ng2-flatpickr>
                    </div>
                    <ng-container
                        *ngIf="isSubmitForm && dataPropertyService.form.controls['product_end_term'].errors">
                        <p class='mt-1 text-danger'>Please fill</p>
                    </ng-container>
                </div>

                <div class=''
                     [ngClass]="(isSubmitForm && dataPropertyService.form.controls['original_term'].errors) ? 'has-error' : ''">
                    <label for='originalTerm'>Original term (years)</label>
                    <input id='originalTerm' type='text' class='form-input'
                           formControlName='original_term' />
                    <ng-container
                        *ngIf="isSubmitForm && dataPropertyService.form.controls['original_term'].errors?.pattern">
                        <p class='mt-1 text-danger'>Must contain only numbers</p>
                    </ng-container>
                </div>

            </div>

            <div class='mt-6 flex flex-row justify-between'>

                <div
                    [ngClass]="(isSubmitForm && dataPropertyService.form.controls['interest_rate'].errors) ? 'has-error' : ''">
                    <label for='interestRate'>Interest rate</label>
                    <input id='interestRate' type='text' class='form-input'
                           formControlName='interest_rate' />
                    <ng-container
                        *ngIf="isSubmitForm && dataPropertyService.form.controls['interest_rate'].errors">
                        <p class='mt-1 text-danger'>Please fill</p>
                    </ng-container>
                </div>

                <div class='min-w-[12rem]'
                     [ngClass]="(isSubmitForm && dataPropertyService.form.controls['repayment_type'].errors) ? 'has-error' : ''">
                    <label for='repaymentType'>Repayment type</label>
                    <ng-select
                        id='repaymentType'
                        formControlName='repayment_type'
                        [items]='dataPropertyService.repaymentTypes'
                        [searchable]='false'
                        [clearable]='false'
                        bindLabel='name'
                        bindValue='id'
                        class='custom-multiselect'
                    >
                    </ng-select>
                    <ng-container
                        *ngIf="isSubmitForm && dataPropertyService.form.controls['repayment_type'].errors">
                        <p class='mt-1 text-danger'>Please fill</p>
                    </ng-container>
                </div>

            </div>

            <div class='mt-6 flex flex-row justify-between'>

                <div class='min-w-[12rem]'
                     [ngClass]="(isSubmitForm && dataPropertyService.form.controls['rate_type'].errors) ? 'has-error' : ''">
                    <label for='rateType'>Rate Type</label>
                    <ng-select
                        id='rateType'
                        formControlName='rate_type'
                        [items]='dataPropertyService.rateTypes'
                        [searchable]='false'
                        [clearable]='false'
                        bindLabel='name'
                        bindValue='id'
                        class='custom-multiselect'
                    >
                    </ng-select>
                    <ng-container
                        *ngIf="isSubmitForm && dataPropertyService.form.controls['rate_type'].errors">
                        <p class='mt-1 text-danger'>Please fill</p>
                    </ng-container>
                </div>

                <div
                    [ngClass]="(isSubmitForm && dataPropertyService.form.controls['mortgage_start_date'].errors) ? 'has-error' : ''">
                    <label for='mortgageStartDate'>Mortgage start date</label>
                    <div class='max-w-[12rem] flex'>
                        <div
                            class='bg-[#eee] flex justify-center items-center ltr:rounded-l-md rtl:rounded-r-md px-3 font-semibold border ltr:border-r-0 rtl:border-l-0 border-[#e0e6ed] dark:border-[#17263c] dark:bg-[#1b2e4b]'
                        >
                            <svg width='18' height='18' viewBox='0 0 24 24' fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M17 14C17.5523 14 18 13.5523 18 13C18 12.4477 17.5523 12 17 12C16.4477 12 16 12.4477 16 13C16 13.5523 16.4477 14 17 14Z'
                                    fill='currentColor' />
                                <path
                                    d='M17 18C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18Z'
                                    fill='currentColor' />
                                <path
                                    d='M13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13Z'
                                    fill='currentColor' />
                                <path
                                    d='M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z'
                                    fill='currentColor' />
                                <path
                                    d='M7 14C7.55229 14 8 13.5523 8 13C8 12.4477 7.55229 12 7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14Z'
                                    fill='currentColor' />
                                <path
                                    d='M7 18C7.55229 18 8 17.5523 8 17C8 16.4477 7.55229 16 7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18Z'
                                    fill='currentColor' />
                                <path fill-rule='evenodd' clip-rule='evenodd'
                                      d='M7 1.75C7.41421 1.75 7.75 2.08579 7.75 2.5V3.26272C8.412 3.24999 9.14133 3.24999 9.94346 3.25H14.0564C14.8586 3.24999 15.588 3.24999 16.25 3.26272V2.5C16.25 2.08579 16.5858 1.75 17 1.75C17.4142 1.75 17.75 2.08579 17.75 2.5V3.32709C18.0099 3.34691 18.2561 3.37182 18.489 3.40313C19.6614 3.56076 20.6104 3.89288 21.3588 4.64124C22.1071 5.38961 22.4392 6.33855 22.5969 7.51098C22.75 8.65018 22.75 10.1058 22.75 11.9435V14.0564C22.75 15.8941 22.75 17.3498 22.5969 18.489C22.4392 19.6614 22.1071 20.6104 21.3588 21.3588C20.6104 22.1071 19.6614 22.4392 18.489 22.5969C17.3498 22.75 15.8942 22.75 14.0565 22.75H9.94359C8.10585 22.75 6.65018 22.75 5.51098 22.5969C4.33856 22.4392 3.38961 22.1071 2.64124 21.3588C1.89288 20.6104 1.56076 19.6614 1.40314 18.489C1.24997 17.3498 1.24998 15.8942 1.25 14.0564V11.9436C1.24998 10.1058 1.24997 8.65019 1.40314 7.51098C1.56076 6.33855 1.89288 5.38961 2.64124 4.64124C3.38961 3.89288 4.33856 3.56076 5.51098 3.40313C5.7439 3.37182 5.99006 3.34691 6.25 3.32709V2.5C6.25 2.08579 6.58579 1.75 7 1.75ZM5.71085 4.88976C4.70476 5.02502 4.12511 5.27869 3.7019 5.7019C3.27869 6.12511 3.02502 6.70476 2.88976 7.71085C2.86685 7.88123 2.8477 8.06061 2.83168 8.25H21.1683C21.1523 8.06061 21.1331 7.88124 21.1102 7.71085C20.975 6.70476 20.7213 6.12511 20.2981 5.7019C19.8749 5.27869 19.2952 5.02502 18.2892 4.88976C17.2615 4.75159 15.9068 4.75 14 4.75H10C8.09318 4.75 6.73851 4.75159 5.71085 4.88976ZM2.75 12C2.75 11.146 2.75032 10.4027 2.76309 9.75H21.2369C21.2497 10.4027 21.25 11.146 21.25 12V14C21.25 15.9068 21.2484 17.2615 21.1102 18.2892C20.975 19.2952 20.7213 19.8749 20.2981 20.2981C19.8749 20.7213 19.2952 20.975 18.2892 21.1102C17.2615 21.2484 15.9068 21.25 14 21.25H10C8.09318 21.25 6.73851 21.2484 5.71085 21.1102C4.70476 20.975 4.12511 20.7213 3.7019 20.2981C3.27869 19.8749 3.02502 19.2952 2.88976 18.2892C2.75159 17.2615 2.75 15.9068 2.75 14V12Z'
                                      fill='#1C274C' />
                            </svg>

                        </div>
                        <ng2-flatpickr addClass='form-input' id='mortgageStartDate'
                                       [config]='dataPropertyService.basicCalendarParams'
                                       [setDate]='mortgageStartDate'
                                       formControlName='mortgage_start_date'></ng2-flatpickr>
                    </div>
                    <ng-container
                        *ngIf="isSubmitForm && dataPropertyService.form.controls['mortgage_start_date'].errors">
                        <p class='mt-1 text-danger'>Please fill</p>
                    </ng-container>
                </div>

            </div>

        </ng-container>
    </div>
</ng-container>

import { Component } from '@angular/core';
import { map } from 'rxjs';
import { TableMetaOptions } from '../../shared/type';
import { DataTableService } from '../../shared/services/data-table.service';
import { DataPropertyService } from '../../shared/services/data-property.service';
import { propertyActions } from '../../shared/store/property/property.actions';
import { Store } from '@ngrx/store';
import { propertyNode, PropertyState } from '../../shared/store/property/property.store';
import { brokerNode, BrokerState } from '../../shared/store/broker/broker.store';

@Component({
    selector: 'app-cases',
    templateUrl: './cases.component.html',
})
export class CasesComponent {
    public title = 'All cases';
    public brokersTableSelectors: number[] = [];

    public cols = [
        { field: 'clients', title: 'Client(s)', sort: false },
        { field: 'address', title: 'Address' },
        { field: 'type', title: 'Type' },
        { field: 'broker', title: 'Broker' },
        { field: 'priorities', title: 'Priorities', sort: false },
        { field: 'status', title: 'Status' },
        {
            field: 'client-property-actions',
            title: 'Action',
            sort: false,
            headerClass: 'justify-center',
        },
    ];

    public rows$ = this.dataTableService.dataSetForPropertyTable().pipe(
        map(propertiesArr =>
            propertiesArr.map((item, key) => ({
                ...item,
                key,
                id: item.property.id,
                type: DataPropertyService.getInvestmentType(item.property.investment_type),
                productEndTerm: item.property.leadgen.product_end_term[0]?.rating,
                earlyReview: item.property.leadgen.early_review[0]?.rating,
                equityAvailable: item.property.leadgen.equity_available?.rating,
                rateRiser: item.property.leadgen.rate_riser[0]?.rating,
                broker: this.createBrokerTableSelector(item.property.id, item.property.broker_ids[0]),
                priority: item.property.leadgen.product_end_term[0]?.rating,
                investmentType: item.property.investment_type.toLowerCase(),
                statusBadge: DataTableService.statusBadge(item.property.activity_status),
                notes: item.property.notes,
            })),
        ),
    );

    public constructor(
        private readonly dataTableService: DataTableService,
        private readonly store: Store<{
            [propertyNode]: PropertyState;
            [brokerNode]: BrokerState;
        }>,
    ) {}

    private createBrokerTableSelector(propertyId: number, brokerId: number): number {
        this.brokersTableSelectors[propertyId] = brokerId;
        return propertyId;
    }

    public updateContent(params: TableMetaOptions) {
        this.store.dispatch(propertyActions.propertiesRequest({ ...params }));
    }
}

<div class='panel w-[43.5rem]'>

    <ng-container *ngIf='property.has_mortgage; else noMortgages'>
        <div class='mt-6 w-full'>
            <div class='text-xl font-semibold'>Mortgage</div>

            <div class='mb-5'>
                <div class='mt-3 flex flex-wrap border-b border-white-light dark:border-[#191e3a]'>
                    <a
                        href='javascript:'
                        class='-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'
                        [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': activeTab === 'current' }"
                        (click)='changeTab(property.mortgages[0], true)'
                    >Current</a>

                    <a *ngFor='let mortgage of property.mortgages_completed'
                       href='javascript:;'
                       class='-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'
                       [ngClass]="{ '!border-white-light !border-b-white text-primary dark:!border-[#191e3a] dark:!border-b-black': activeTab === mortgage.id.toString() }"
                       (click)='changeTab(mortgage)'
                    >{{mortgage.product_end_term | date :'dd-MM-yyyy'}}</a>
                </div>

                <div class='flex-1 pt-5 text-sm'>
                    <div class='mt-6 mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Original Mortgage :</div>
                        <div *ngIf='activeMortgage.outstandingMortgage !== null'>
                            £ {{activeMortgage.outstandingMortgage | number}}</div>
                        <div *ngIf='activeMortgage.outstandingMortgage === null'>-</div>
                    </div>
                    <div *ngIf='activeMortgage.outstandingMortgageRepayment !== null' class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Outstanding Mortgage :</div>
                        <div>£ {{activeMortgage.outstandingMortgageRepayment | number}}</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Lender name :</div>
                        <div *ngIf='activeMortgage.lenderName !== null'>
                            {{activeMortgage.lenderName}}</div>
                        <div *ngIf='activeMortgage.lenderName === null'>-</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Monthly payments :</div>
                        <div *ngIf='activeMortgage.monthlyMortgagePayments !== null'>
                            £ {{activeMortgage.monthlyMortgagePayments | number}}</div>
                        <div *ngIf='activeMortgage.monthlyMortgagePayments === null'>-</div>

                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Mortgage Status:</div>
                        <div class='capitalize' *ngIf='activeMortgage.status !== null'>
                            {{activeMortgage.status}}</div>
                        <div *ngIf='activeMortgage.status === null'>-</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Product end term :</div>
                        <div *ngIf='activeMortgage.productEndTerm !== null'>
                            {{activeMortgage.productEndTerm | date :'dd-MM-yyyy'}}</div>
                        <div *ngIf='activeMortgage.productEndTerm === null'>-</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Original term (years) :</div>
                        <div *ngIf='activeMortgage.originalTerm !== null'>
                            {{activeMortgage.originalTerm | number }}</div>
                        <div *ngIf='activeMortgage.originalTerm === null'>-</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Interest rate :</div>
                        <div *ngIf='activeMortgage.interestRate !== null'>
                            {{activeMortgage.interestRate | number }}%
                        </div>
                        <div *ngIf='activeMortgage.interestRate === null'>-</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Repayment type :</div>
                        <div *ngIf='activeMortgage.repaymentType !== null'>
                            {{activeMortgage.repaymentType}}</div>
                        <div *ngIf='activeMortgage.repaymentType === null'>-</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Rate Type (years) :</div>
                        <div *ngIf='activeMortgage.rateType !== null'>
                            {{activeMortgage.rateType}}</div>
                        <div *ngIf='activeMortgage.rateType === null'>-</div>
                    </div>
                    <div class='flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Mortgage start date :</div>
                        <div *ngIf='activeMortgage.mortgageStartDate !== null'>
                            {{activeMortgage.mortgageStartDate | date :'dd-MM-yyyy'}}</div>
                        <div *ngIf='activeMortgage.mortgageStartDate === null'>-</div>
                    </div>
                </div>

            </div>

        </div>
    </ng-container>

    <ng-template #noMortgages>
        <div class='mt-6 w-full'>
            <div class='text-xl font-semibold'>Mortgage</div>
            <div class='mb-5'>
                <div class='mt-6 mb-2 flex w-full'>
                    No mortgage
                </div>
            </div>
        </div>
    </ng-template>


</div>


import { createReducer, on } from '@ngrx/store';
import { ColConfigs, PaginationMetaData, PriorityFilter } from '../../type';
import { tableActions } from './table.actions';

export const tableNode = 'table';

export interface TableState {
    page: number;
    perPage: number;
    search: string;
    statusFilter: number;
    brokerFilter: number;
    priorityFilter: PriorityFilter;
    customFilter: any;
    sortColumn: string;
    sortDirection: string;
    pagination?: PaginationMetaData;
    colConfigs?: ColConfigs;
}

export const tableState: TableState = {
    page: 1,
    perPage: 10,
    search: '',
    statusFilter: 0,
    brokerFilter: 0,
    priorityFilter: undefined,
    customFilter: undefined,
    sortColumn: '',
    sortDirection: '',
    colConfigs: { propertyTable: [], investorsPropertyTable: [] },
    pagination: {
        current_page: 1,
        from: 1,
        last_page: 1,
        path: '',
        per_page: 10,
        to: 5,
        total: 0,
    },
};

export const TableReducer = createReducer(
    tableState,
    on(tableActions.updateState, (state, action) => ({
        ...state,
        ...action.state,
    })),
    on(tableActions.paginationDataSet, (state, action) => ({
        ...state,
        pagination: action.pagination,
    })),
    on(tableActions.setColSettings, (state, action) => ({
        ...state,
        colConfigs: action.configs,
    })),
);

<div class='flex flex-row justify-between'>

    <div class='min-w-[19rem] p-6'
         [class]="'card-priority-'+property.leadgen.product_end_term[0]?.rating">
        <div class='flex flex-row justify-between items-center'>
            <div class='flex flex-row gap-2'>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <circle cx='12' cy='12' r='10' stroke='currentColor' stroke-width='1.5' />
                    <path d='M12 8V12L14.5 14.5' stroke='currentColor' stroke-width='1.5' stroke-linecap='round'
                          stroke-linejoin='round' />
                </svg>
                <h4 class='font-semibold text-xl'>Product Expiry</h4>
            </div>
            <p class='text-sm'>Priority {{property.leadgen.product_end_term[0]?.rating}}</p>
        </div>

        <div class='mt-3'>
            <p class='text-2xl'>
                <span class='font-semibold'>{{property.leadgen.product_end_term[0]?.expiry_days}}</span> Days Remaining
            </p>
        </div>

        <div class='mt-3'>
            <p *ngIf='property.leadgen.product_end_term[0]?.lender_contact_remaining_days === null' class='text-sm'>
                There is no product expiry
            </p>
            <p *ngIf='property.leadgen.product_end_term[0]?.lender_contact_remaining_days !== null && property.leadgen.product_end_term[0]?.lender_contact_remaining_days > 0'
               class='text-sm'>
                Original lender will contact in {{property.leadgen.product_end_term[0]?.lender_contact_remaining_days}}
                days
            </p>
            <p *ngIf='property.leadgen.product_end_term[0]?.lender_contact_remaining_days !== null && property.leadgen.product_end_term[0]?.lender_contact_remaining_days <= 0'
               class='text-sm'>
                Original lender has
                contact {{convertToPositiveNumber(property.leadgen.product_end_term[0]?.lender_contact_remaining_days)}}
                days ago
            </p>
        </div>

    </div>

    <div class='min-w-[19rem] p-6'
         [class]="'card-priority-'+property.leadgen.early_review[0]?.rating">
        <div class='flex flex-row justify-between items-center'>
            <div class='flex flex-row gap-2'>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path fill-rule='evenodd' clip-rule='evenodd'
                          d='M8.46967 2.46967C8.76256 2.17678 9.23744 2.17678 9.53033 2.46967L11.5303 4.46967C11.7448 4.68417 11.809 5.00676 11.6929 5.28701C11.5768 5.56727 11.3033 5.75 11 5.75H9C5.54822 5.75 2.75 8.54822 2.75 12C2.75 15.4517 5.54846 18.25 9.00028 18.25H9.5C9.91421 18.25 10.25 18.5858 10.25 19C10.25 19.4142 9.91421 19.75 9.5 19.75H9.00028C4.72011 19.75 1.25 16.2802 1.25 12C1.25 7.71979 4.71979 4.25 9 4.25H9.18934L8.46967 3.53033C8.17678 3.23744 8.17678 2.76256 8.46967 2.46967ZM13.75 5C13.75 4.58579 14.0858 4.25 14.5 4.25H15C19.2802 4.25 22.75 7.71979 22.75 12C22.75 16.2802 19.2802 19.75 15 19.75H14.8107L15.5303 20.4697C15.8232 20.7626 15.8232 21.2374 15.5303 21.5303C15.2374 21.8232 14.7626 21.8232 14.4697 21.5303L12.4697 19.5303C12.2552 19.3158 12.191 18.9932 12.3071 18.713C12.4232 18.4327 12.6967 18.25 13 18.25H15C18.4518 18.25 21.25 15.4518 21.25 12C21.25 8.54822 18.4518 5.75 15 5.75H14.5C14.0858 5.75 13.75 5.41421 13.75 5Z'
                          fill='currentColor' />
                </svg>
                <h4 class='font-semibold text-xl'>Early Review</h4>
            </div>
            <p class='text-sm'>Priority {{property.leadgen.early_review[0]?.rating}}</p>
        </div>

        <div class='mt-3'>
            <p class='font-semibold text-2xl'>
                £ {{property.leadgen.early_review[0]?.savings | number}}
            </p>
        </div>

        <div class='mt-3'>
            <p class='text-sm'>
                {{property.leadgen.early_review[0]?.rating >= 3 ? 'There is a potential saving' : 'There is no potential saving'}}
            </p>
        </div>
    </div>

    <div class='min-w-[19rem] p-6' [class]="'card-priority-'+property.leadgen.equity_available.rating">
        <div class='flex flex-row justify-between items-center'>
            <div class='flex flex-row gap-2'>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M5.66953 9.91436L8.73167 5.77133C10.711 3.09327 11.7007 1.75425 12.6241 2.03721C13.5474 2.32018 13.5474 3.96249 13.5474 7.24712V7.55682C13.5474 8.74151 13.5474 9.33386 13.926 9.70541L13.946 9.72466C14.3327 10.0884 14.9492 10.0884 16.1822 10.0884C18.4011 10.0884 19.5106 10.0884 19.8855 10.7613C19.8917 10.7724 19.8977 10.7837 19.9036 10.795C20.2576 11.4784 19.6152 12.3475 18.3304 14.0857L15.2683 18.2287C13.2889 20.9067 12.2992 22.2458 11.3758 21.9628C10.4525 21.6798 10.4525 20.0375 10.4525 16.7528L10.4526 16.4433C10.4526 15.2585 10.4526 14.6662 10.074 14.2946L10.054 14.2754C9.6673 13.9117 9.05079 13.9117 7.81775 13.9117C5.59888 13.9117 4.48945 13.9117 4.1145 13.2387C4.10829 13.2276 4.10225 13.2164 4.09639 13.205C3.74244 12.5217 4.3848 11.6526 5.66953 9.91436Z'
                        stroke='currentColor' stroke-width='1.5' />
                </svg>
                <h4 class='font-semibold text-xl'>Equity Available</h4>
            </div>
            <p class='text-sm'>Priority {{property.leadgen.equity_available.rating}}</p>
        </div>

        <div class='mt-3'>
            <p class='font-semibold text-2xl'>
                £ {{
                (property.leadgen.equity_available.current_equity_release >= 0)
                    ? property.leadgen.equity_available.current_equity_release
                    : 0
                        | number
                }}
            </p>
        </div>

        <div class='mt-3'>
            <p class='text-sm'>
                With max loan of £ {{property.leadgen.equity_available.current_max_loan | number}}
            </p>
        </div>
    </div>

    <div class='min-w-[19rem] p-6' [class]="'card-priority-'+property.leadgen.rate_riser[0]?.rating">
        <div class='flex flex-row justify-between items-center'>
            <div class='flex flex-row gap-2'>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path fill-rule='evenodd' clip-rule='evenodd'
                          d='M15.6679 7C15.6679 6.58579 16.0036 6.25 16.4179 6.25H22C22.4142 6.25 22.75 6.58579 22.75 7V12.5458C22.75 12.96 22.4142 13.2958 22 13.2958C21.5858 13.2958 21.25 12.96 21.25 12.5458V8.80286L15.1142 14.9013C14.6452 15.3674 14.241 15.7692 13.8739 16.0477C13.4804 16.3462 13.0432 16.572 12.505 16.572C11.9668 16.5719 11.5297 16.346 11.1362 16.0474C10.7692 15.7688 10.3651 15.367 9.89629 14.9007L9.62203 14.628C9.10787 14.1167 8.77452 13.7875 8.49695 13.5769C8.23672 13.3794 8.11506 13.3573 8.03449 13.3574C7.95393 13.3574 7.83228 13.3795 7.57219 13.5772C7.29478 13.7881 6.96167 14.1175 6.44789 14.6292L2.52922 18.5314C2.23571 18.8237 1.76084 18.8227 1.46856 18.5292C1.17628 18.2357 1.17728 17.7608 1.47078 17.4686L5.42433 13.5315C5.89326 13.0645 6.29742 12.662 6.66452 12.383C7.05802 12.0839 7.49535 11.8576 8.03395 11.8574C8.57254 11.8572 9.01003 12.0832 9.40375 12.382C9.77105 12.6607 10.1755 13.063 10.6448 13.5296L10.919 13.8024C11.4327 14.3132 11.7658 14.6421 12.0431 14.8526C12.3031 15.0499 12.4247 15.072 12.5052 15.072C12.5857 15.072 12.7073 15.0499 12.9673 14.8527C13.2447 14.6423 13.5778 14.3134 14.0916 13.8027L20.1815 7.75H16.4179C16.0036 7.75 15.6679 7.41421 15.6679 7Z'
                          fill='currentColor' />
                </svg>
                <h4 class='font-semibold text-xl'>Rate Rise</h4>
            </div>
            <p class='text-sm'>Priority {{property.leadgen.rate_riser[0]?.rating}}</p>
        </div>

        <div class='mt-3'>
            <p class='font-semibold text-2xl'>
                {{property.leadgen.rate_riser[0]?.interest_rate_riser}}
            </p>
        </div>

        <div class='mt-3'>
            <p class='text-sm'>
                {{property.leadgen.rate_riser[0]?.rating_title}}
            </p>
        </div>
    </div>
</div>




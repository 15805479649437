import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { companyNode, CompanyState } from '../../../../shared/store/company/company.store';
import { companySelectors } from '../../../../shared/store/company/company.selectors';
import { userSelectors } from '../../../../shared/store/user/user.selectors';
import { userNode, UserState } from '../../../../shared/store/user/user.store';
import { companyActions } from '../../../../shared/store/company/company.actions';
import { first } from 'rxjs';

@Component({
    selector: 'app-company-overview',
    templateUrl: './overview.component.html',
})
export class OverviewComponent implements OnInit {
    public constructor(
        private readonly store: Store<{
            [companyNode]: CompanyState;
            [userNode]: UserState;
        }>,
    ) {}

    public company$ = this.store.pipe(select(companySelectors.selectCompanyInfo));
    public profile$ = this.store.pipe(select(userSelectors.selectProfile));

    public ngOnInit() {
        this.profile$
            .pipe(first())
            .subscribe(profile =>
                this.store.dispatch(companyActions.companyInfoRequest({ companyId: profile.broker_company.id })),
            );
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { insightNode, InsightState } from '../store/insight.store';
import { insightSelectors } from '../store/insight.selectors';
import { ApexAxisChartSeries, ApexXAxis } from 'ng-apexcharts';
import { BehaviorSubject, combineLatest, filter, first, tap } from 'rxjs';
import { InsightsBrokerPerformance } from '../insight.type';
import { ChartService } from '../../../shared/services/chart.service';
import { insightActions } from '../store/insight.actions';

@Component({
    selector: 'app-broker-performance',
    templateUrl: './broker-performance.component.html',
})
export class BrokerPerformanceComponent implements OnInit, OnDestroy {
    public selectedOptionData$: BehaviorSubject<InsightsBrokerPerformance[]> = new BehaviorSubject<
        InsightsBrokerPerformance[] | null
    >(null);

    public selectedOptionChart$: BehaviorSubject<{
        series: ApexAxisChartSeries;
        xaxis: ApexXAxis;
    } | null> = new BehaviorSubject<{
        series: ApexAxisChartSeries;
        xaxis: ApexXAxis;
    } | null>(null);

    private performanceSelector$ = combineLatest([
        this.store.pipe(select(insightSelectors.brokersPerformance4$)),
        this.store.pipe(select(insightSelectors.brokersPerformance5$)),
    ]);

    private subscription$ = this.performanceSelector$
        .pipe(
            filter(
                ([performance4, performance5]: [InsightsBrokerPerformance[], InsightsBrokerPerformance[]]) =>
                    performance4.length != 0 && performance5.length != 0,
            ),
            tap((data: [InsightsBrokerPerformance[], InsightsBrokerPerformance[]]) => this.dataHasBeenChanged(data)),
        )
        .subscribe();

    public options = [4, 5];
    public optionsServicesFilter = [
        { id: '-', name: '-' },
        { id: 'product_end_term', name: 'Product Expiry' },
        { id: 'rate_riser', name: 'Rate Riser' },
        { id: 'early_review', name: 'Early Review' },
        { id: 'equity_available', name: 'Equity Available' },
    ];
    public selected = 5;
    public servicesFilter = '-';

    public constructor(
        public readonly chartService: ChartService,
        private readonly store: Store<{
            [insightNode]: InsightState;
        }>,
    ) {}

    public ngOnInit() {
        this.doneChanges();
    }

    public ngOnDestroy() {
        this.subscription$.unsubscribe();
    }

    public doneChanges(): void {
        this.selectedOptionData$.next(null);
        this.selectedOptionChart$.next(null);
        const params = this.servicesFilter == '-' ? {} : { leadgen: this.servicesFilter };
        this.store.dispatch(insightActions.brokerPerformanceRequest({ params }));
    }

    public prioritySelectorChanged(): void {
        this.performanceSelector$
            .pipe(
                tap((data: [InsightsBrokerPerformance[], InsightsBrokerPerformance[]]) =>
                    this.dataHasBeenChanged(data),
                ),
                first(),
            )
            .subscribe();
    }

    public dataHasBeenChanged([performance4, performance5]: [
        InsightsBrokerPerformance[],
        InsightsBrokerPerformance[],
    ]): void {
        if (!this.selected) this.selected = 5;
        const options = [];
        options[4] = performance4;
        options[5] = performance5;
        this.selectedOptionData$.next(options[this.selected]);
        this.selectedOptionChart$.next({
            series: this.generateSeries(options[this.selected]),
            xaxis: this.generateChartXaxis(options[this.selected]),
        });
    }

    private generateSeries(data: InsightsBrokerPerformance[]): ApexAxisChartSeries {
        const submitted = data.map(item => item.rating.submitted.count);
        const notActioned = data.map(item => item.rating.notActioned.count);
        const attemptedContact = data.map(item => item.rating.attemptedContact.count);
        const contacted = data.map(item => item.rating.contacted.count);
        const invalidExpiryDate = data.map(item => item.rating.invalidExpiryDate.count);
        const lostOpportunity = data.map(item => item.rating.lostOpportunity.count);
        const noOpportunity = data.map(item => item.rating.noOpportunity.count);
        const appointmentMade = data.map(item => item.rating.appointmentMade.count);
        const completed = data.map(item => item.rating.completed.count);

        return [
            {
                name: 'Completed',
                data: [...completed],
            },
            {
                name: 'Submitted',
                data: [...submitted],
            },
            {
                name: 'Not Actioned',
                data: [...notActioned],
            },
            {
                name: 'Attempted Contact',
                data: [...attemptedContact],
            },
            {
                name: 'Contacted',
                data: [...contacted],
            },
            {
                name: 'Appointment Made',
                data: [...appointmentMade],
            },
            {
                name: 'Invalid Expiry Date',
                data: [...invalidExpiryDate],
            },
            {
                name: 'Lost Opportunity',
                data: [...lostOpportunity],
            },
            {
                name: 'No Opportunity',
                data: [...noOpportunity],
            },
        ];
    }

    private generateChartXaxis(data: InsightsBrokerPerformance[]): ApexXAxis {
        return {
            categories: data.map(item => item.name),
        };
    }
}

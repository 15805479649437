<div class='panel min-w-[19rem]'>
    <div
        class='-mx-5 mb-5 border-b border-[#e0e6ed] p-5 pt-0 dark:border-[#1b2e4b] dark:text-white-light'>
        <h5 class='text-lg font-semibold'>Activity Log</h5>
    </div>
    <ng-scrollbar class='relative !h-[360px] ltr:-mr-3 ltr:pr-3 rtl:-ml-3 rtl:pl-3'>
        <div class='space-y-7'>

            <div *ngFor='let history of (history$ | async)' class='flex'>
                <div
                    class='relative z-10 shrink-0 before:absolute before:top-10 before:left-4 before:h-[calc(100%-24px)] before:w-[2px] before:bg-white-dark/30 ltr:mr-2 rtl:ml-2'>
                    <div
                        class='flex h-8 w-8 items-center justify-center rounded-full bg-secondary text-white shadow shadow-secondary'>
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none'
                             xmlns='http://www.w3.org/2000/svg'>
                            <path fill-rule='evenodd' clip-rule='evenodd'
                                  d='M11.4697 3.46967C11.7626 3.17678 12.2374 3.17678 12.5303 3.46967L18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303C18.2374 10.8232 17.7626 10.8232 17.4697 10.5303L12.75 5.81066L12.75 20C12.75 20.4142 12.4142 20.75 12 20.75C11.5858 20.75 11.25 20.4142 11.25 20L11.25 5.81066L6.53033 10.5303C6.23744 10.8232 5.76256 10.8232 5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967L11.4697 3.46967Z'
                                  fill='currentColor' />
                        </svg>
                    </div>
                </div>
                <div>
                    <ul *ngFor='let item of history.items' class='font-semibold'>
                        <li class='capitalize'>{{item.name}}</li>
                        <li class='text-gray-400'>From <span class='text-success'>{{item.old}}</span> to <span
                            class='text-success'>{{item.new}}</span></li>
                        <span class='my-2 block'></span>
                    </ul>
                    <p class='text-xs text-white-dark'>{{history.date | date: 'dd MMM yyyy'}}</p>
                </div>
            </div>
        </div>
    </ng-scrollbar>
</div>

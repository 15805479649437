import { createReducer, on } from '@ngrx/store';
import { appActions } from './app.actions';
import { SavedAccessToken } from '../../../auth/auth.type';

export const appNode = 'app';

export interface AppState {
    token: SavedAccessToken | null;
}

export const appInitialState: AppState = {
    token: null,
};

export const AppReducer = createReducer(
    appInitialState,
    on(appActions.tokenSet, (state, action) => ({
        ...state,
        token: action.token,
    })),
);

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Broker, Investor, PaginationMetaData, SumOfPropertiesProfiles } from '../type';
import { environment as env } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Company } from '../../dashboard/company/company.type';
import { TableSortService } from './table-sort.service';
import { PropertyService } from './property.service';

@Injectable()
export class InvestorService {
    public constructor(
        private readonly http: HttpClient,
        private readonly tablesSortService: TableSortService,
    ) {}

    public createInvestor(
        brokerCompanyId: number,
        data: { email: string; name: string; ftb: boolean; phone: string },
    ): Observable<Investor> {
        if (!data.phone) delete data.phone;
        return this.http
            .post<{
                status: string;
                data: Investor;
            }>(`${env.baseServerUrl}/investors`, { ...data, is_ftb: data.ftb, broker_company_id: brokerCompanyId })
            .pipe(map(response => response.data));
    }
    public investorDetail(investorId: number): Observable<Investor> {
        return this.http
            .get<{
                status: string;
                data: Investor;
            }>(`${env.baseServerUrl}/investors/${investorId}`)
            .pipe(map(response => response.data));
    }

    public investorUpdate(investorId: number, data): Observable<Investor> {
        return this.http
            .patch<{
                status: string;
                data: Investor;
            }>(`${env.baseServerUrl}/investors/${investorId}`, data)
            .pipe(map(response => response.data));
    }

    public investorRequest(
        perPage: number,
        page: number,
        options?: { filters?: any[]; search?: string; sort?: { columnName: string; direction: string } },
    ): Observable<{
        investors: Investor[];
        brokers: Broker[];
        companies: Company[];
        paginationData: PaginationMetaData;
    }> {
        const params: { per_page: number; page: number; search?: string } = {
            per_page: perPage,
            page,
        };

        let _filter = '';
        if (options?.filters) _filter = PropertyService.filterMap(options.filters);

        if (options?.search) params.search = options.search;

        let _sort = '';
        if (options?.sort)
            _sort = this.tablesSortService.findSchemaForSort(options.sort.columnName, options.sort.direction);

        return this.http
            .get<{
                data: Investor[];
                status: string;
                meta: PaginationMetaData;
                related: { brokers: Broker[]; companies: Company[] };
            }>(
                `${env.baseServerUrl}/investors?${_filter}${_filter.length > 0 && _sort.length > 0 ? '&' : ''}${_sort}`,
                { params },
            )
            .pipe(
                map(response => ({
                    investors: response.data,
                    brokers: response.related.brokers,
                    paginationData: response.meta,
                    companies: response.related.companies,
                })),
            );
    }

    public contactRequiredCountRequest(): Observable<{ count: number }> {
        return this.http
            .get<{
                status: string;
                data: { contact_required: number };
            }>(`${env.baseServerUrl}/investors/contact-required`)
            .pipe(map(response => ({ count: response.data.contact_required })));
    }

    public sumPropertyProfilesByInvestor(investorId: number, filter?: any[]): Observable<SumOfPropertiesProfiles> {
        let _filter = '';
        if (filter) _filter = PropertyService.filterMap(filter);
        return this.http
            .get<{
                status: string;
                data: SumOfPropertiesProfiles;
            }>(`${env.baseServerUrl}/investors/${investorId}/property-profile?${_filter}`)
            .pipe(map(response => response.data));
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { DashboardRoutingModule, routesComponents } from './dashboard-routing.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MenuModule } from 'headlessui-angular';
import { ModalModule } from 'angular-custom-modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyService } from '../shared/services/company.service';
import { DataTableModule } from '@bhplugin/ng-datatable';
import { DashboardComponent } from './dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DashboardService } from './dashboard.service';
import { NgApexchartsModule } from 'ng-apexcharts';
import { OverviewService } from './insight/overview/overview.service';
import { InsightService } from './insight/insight.service';
import { ChartService } from '../shared/services/chart.service';
import { DataTableService } from '../shared/services/data-table.service';
import { TableSortService } from '../shared/services/table-sort.service';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { DataPropertyService } from '../shared/services/data-property.service';
import { BrokerService } from '../shared/services/broker.service';
import { InvestorService } from '../shared/services/investor.service';
import { BrokerPerformanceChartComponent } from '../shared/components/broker-performance-view/components/broker-performance-chart/broker-performance-chart.component';
import { PropertyDetailsComponents } from '../shared/components/property-detail/property-detail.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ToastService } from '../shared/services/toast.service';
import { SharedModule } from '../shared/shared.module';
import { ClientDetailComponent } from '../shared/components/client-detail/client-detail.component';
import { ClientViewComponent } from './client-view/client-view.component';
import { PropertyUpdateComponents } from '../shared/components/property-update/property-update.component';
import { TableInvestorsComponent } from './clients/components/table-investors/table-investors.component';
import { NgxMaskDirective } from 'ngx-mask';
import { FullCalendarModule } from '@fullcalendar/angular';
import { BrokerAppointmentService } from '../shared/services/broker-appointment.service';
import { UpdateAppointmentModalComponent } from './calendar/update-appointment-modal/update-appointment-modal.component';
import { OrganiserDetailsComponents } from './organiser/organiser.component';
import { OrganiserService } from '../shared/services/organiser.service';

@NgModule({
    declarations: [
        DashboardComponent,
        ClientViewComponent,
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        BrokerPerformanceChartComponent,
        TableInvestorsComponent,
        UpdateAppointmentModalComponent,
        ...PropertyDetailsComponents,
        ...routesComponents,
        ...PropertyUpdateComponents,
        ...OrganiserDetailsComponents,
    ],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        NgScrollbarModule.withConfig({
            visibility: 'hover',
            appearance: 'standard',
        }),
        SharedModule,
        FormsModule,
        ModalModule,
        NgApexchartsModule,
        NgxTippyModule,
        ReactiveFormsModule,
        Ng2FlatpickrModule,
        NgSelectModule,
        DataTableModule,
        MenuModule,
        NgxMaskDirective,
        FullCalendarModule,
    ],
    providers: [
        CompanyService,
        DashboardService,
        BrokerService,
        InvestorService,
        DataPropertyService,
        ChartService,
        DataTableService,
        OverviewService,
        InsightService,
        TableSortService,
        OrganiserService,
        ToastService,
        BrokerAppointmentService,
        UpdateAppointmentModalComponent,
    ],
    exports: [ClientDetailComponent],
})
export class DashboardModule {}

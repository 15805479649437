import { Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { colDef } from '@bhplugin/ng-datatable';
import { investorSelectors } from '../../shared/store/investor/investor.selectors';
import { map } from 'rxjs/operators';
import { Investor, TableMetaOptions } from '../../shared/type';
import { investorNode, InvestorState } from '../../shared/store/investor/investor.store';
import { tableNode, TableState } from '../../shared/store/table/table.store';
import { investorActions } from '../../shared/store/investor/investor.actions';
import { OrganiserDateModalComponent } from './components/organiser-date-modal/organiser-date-modal.component';
import { OrganiserService, Range } from '../../shared/services/organiser.service';

export const OrganiserDetailsComponents = [OrganiserDateModalComponent];

@Component({
    selector: 'app-organiser',
    templateUrl: './organiser.component.html',
})
export class OrganiserComponent {
    @ViewChild('datesRangeModal') datesRangeModal: OrganiserDateModalComponent;
    public tableName = 'organiser';

    public columns: colDef[] = [
        { field: 'investorName', title: 'Client', sort: true },
        { field: 'nextContact', title: 'Next Contact', sort: true },
        { field: 'lastContact', title: 'Last Contact', sort: true },
        { field: 'email', title: 'Email', sort: true },
        { field: 'phone', title: 'Phone', sort: true },
        { field: 'lastLogin', title: 'Last login', sort: true },
        { field: 'callStatus', title: 'Status', sort: true },
        {
            field: 'client-actions',
            title: 'Action',
            sort: false,
            headerClass: 'justify-center',
        },
    ];

    public investors$ = this.store.pipe(
        select(investorSelectors.selectInvestors),
        map((investors: Investor[]) =>
            investors.map(investor => ({
                investor,
                id: investor.id,
                clientId: investor.id,
                clients: [investor],
                investorName: investor.name,
                email: investor.email,
                phone: investor.phone,
                lastLogin: investor.last_login_at,
            })),
        ),
    );

    public constructor(
        public readonly organiserService: OrganiserService,
        private readonly store: Store<{
            [investorNode]: InvestorState;
            [tableNode]: TableState;
        }>,
    ) {}

    public updateContent(params: TableMetaOptions) {
        this.store.dispatch(investorActions.investorsRequest({ ...params }));
    }

    public openChangeDateRangeModal(currentRange: Range, callType: 'nextCall' | 'lastCall') {
        this.datesRangeModal.openDatesRangeModal(currentRange, callType);
    }
}

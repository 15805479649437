import { Injectable } from '@angular/core';

@Injectable()
export class EditModalService {
    public static getApiSchema(name: string): (val: number | string | boolean) => unknown | undefined {
        const schemas: { name: string; schema: (val: number | string | boolean) => unknown }[] = [
            {
                name: 'current_rental_income',
                schema: val => ({ current_rental_income: val }),
            },
            {
                name: 'purchaseDate',
                schema: val => ({ purchase_date: val }),
            },
            {
                name: 'purchasePrice',
                schema: val => ({ purchase_price: val }),
            },
            {
                name: 'market_value',
                schema: val => ({ markets_custom: { market_value: val } }),
            },
            {
                name: 'loanAmount',
                schema: val => ({ mortgages: [{ outstanding_mortgage: val }] }),
            },
            {
                name: 'monthlyPayment',
                schema: val => ({ mortgages: [{ monthly_mortgage_payments: val }] }),
            },
            {
                name: 'interestRate',
                schema: val => ({ mortgages: [{ interest_rate: val }] }),
            },
            {
                name: 'lenderName',
                schema: val => ({ mortgages: [{ lender_name: val }] }),
            },
            {
                name: 'productEndTerm',
                schema: val => ({ mortgages: [{ product_end_term: val }] }),
            },
        ];
        return schemas.find(item => item.name == name)?.schema;
    }
}

import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()
export class ToastService {
    public static showToastMessage(color: 'color-danger' | 'color-success', msg: string) {
        const toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            showCloseButton: true,
            customClass: {
                popup: color,
            },
        });
        toast.fire({ title: msg });
    }
}

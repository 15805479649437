<div class='flex flex-row justify-end mb-5'>
    <div hlMenu class='dropdown'>
        <button
            type='button'
            hlMenuButton
            class='btn dropdown-toggle rounded-none border-0 p-0 text-black shadow-none hover:text-primary dark:text-white-dark dark:hover:text-primary'
        >
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M12 7L12 14M12 14L15 11M12 14L9 11' stroke='#1C274C' stroke-width='1.5' stroke-linecap='round'
                      stroke-linejoin='round' />
                <path d='M16 17H12H8' stroke='#1C274C' stroke-width='1.5' stroke-linecap='round' />
                <path
                    d='M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z'
                    stroke='#1C274C' stroke-width='1.5' />
            </svg>

        </button>
        <ul *hlMenuItems
            class='top-auto !mt-0 w-max whitespace-nowrap ltr:left-full ltr:ml-1 rtl:right-0 rtl:mr-1 ltr:md:right-0 ltr:md:left-auto ltr:md:mr-1 rtl:md:left-full rtl:md:right-0 rtl:md:ml-1'>
            <li>
                <button (click)='saveXlsx()'>Download as .xlsx</button>
            </li>
            <li>
                <button (click)='saveCsv()'>Download as .csv</button>
            </li>
        </ul>
    </div>

</div>

<div class='datatable'>
    <ng-datatable [id]='table' *ngIf='(this.tableState$ | async) as state' #table
                  [rows]='rows'
                  [columns]='cols'
                  [isServerMode]='true'
                  [page]='state.page'
                  [sortable]='true'
                  [pageSize]='state.perPage'
                  [totalRows]='(tablePagination$ | async)?.total'
                  [sortColumn]='state.sortColumn'
                  [sortDirection]='state.sortDirection'
                  (changeServer)='changeState($event)'
                  [selectRowOnClick]='selectRowOnClick'
                  (rowClick)='rowClick.next($event)'
                  skin='whitespace-nowrap table-hover cell-content active::btn-dark'
    >
        <ng-template slot='clients' let-value='data'>
            <ng-container *ngIf='value.clients.length > 1; else unpluralName'>
                <div class='relative flex overflow-x-hidden w-32'>
                    <div class='animate-marquee whitespace-nowrap'>
                        <span *ngFor='let name of clientsNames(value.clients)'>
                            {{name}}
                        </span>
                    </div>
                </div>
            </ng-container>
            <ng-template #unpluralName>
                <div class='w-32 text-ellipsis overflow-hidden'>
                    {{clientsNames(value.clients)[0]}}
                </div>
            </ng-template>


        </ng-template>
        <ng-template slot='address' let-value='data'>
            <div class='w-32 text-ellipsis overflow-hidden'>{{value.property.address}}</div>
        </ng-template>

        <ng-template slot='lenderName' let-value='data'>
            <p class='max-w-[8rem] min-h-[1rem] text-ellipsis overflow-hidden cursor-pointer min-w-fit' (click)="updateString({
                            propertyId: value.property.id,
                            mortgageId: value.property[0]?.id ?? null,
                            valueName: 'Lender Name',
                            apiRequestStructure: EditModalService.getApiSchema('lenderName'),
                            value: value.property.mortgages[0]?.lender_name,
                        })">{{value.property.mortgages[0]?.lender_name}}</p>
        </ng-template>

        <ng-template slot='purchasePrice' let-value='data'>
            <p class='max-w-[8rem] min-h-[1rem] cursor-pointer min-w-fit' (click)="updateAmount({
                            propertyId: value.property.id,
                            valueName: 'Purchase Price',
                            apiRequestStructure: EditModalService.getApiSchema('purchasePrice'),
                            value: value.property.purchase_price,
                        })">
                £{{value.property.purchase_price | number}}</p>
        </ng-template>
        <ng-template slot='purchaseDate' let-value='data'>
            <p class='max-w-[8rem] min-h-[1rem] cursor-pointer min-w-fit' (click)="updateDate({
                                        propertyId: value.property.id,
                                        valueName: 'Purchase Date',
                                        apiRequestStructure: EditModalService.getApiSchema('purchaseDate'),
                                        value: value.property.purchase_date,
                                    })">
                {{value.property.purchase_date | date :'dd-MM-yyyy'}}</p>
        </ng-template>
        <ng-template slot='type' let-value='data'>
                <span [class]="'badge-'+value.investmentType">
                    {{ value.type }}
                </span>
        </ng-template>
        <ng-template slot='savings' let-value='data'>
            £ {{value.savings}}
        </ng-template>
        <ng-template slot='priorityEarlyReview' let-value='data'>
                <span [class]="'priority-'+value.priority">
                    {{ value.priority }}
                </span>
        </ng-template>

        <ng-template slot='priorityEquityAvailable' let-value='data'>
                <span [class]="'priority-'+value.priority">
                    {{ value.priority }}
                </span>
        </ng-template>

        <ng-template slot='priorityProduct' let-value='data'>
                <span [class]="'priority-'+value.priority">
                    {{ value.priority }}
                </span>
        </ng-template>

        <ng-template slot='priorityRateRiser' let-value='data'>
                <span [class]="'priority-'+value.priority">
                    {{ value.priority }}
                </span>
        </ng-template>

        <ng-template slot='lastLogin' let-value='data'>
            {{ value.lastLogin | date:'dd-MM-yyyy HH:mm:ss' }}
        </ng-template>
        <ng-template slot='investor-status' let-value='data'>
                <span class='cursor-pointer'
                      [className]="'badge-'+value.statusBadge"
                      [ngClass]='{"cursor-pointer": value.status === "Contact Required"}'
                      (click)='changeInvestorStatus(value)'
                >
                    {{ value.status }}
                </span>
        </ng-template>

        <ng-template slot='priorities' let-value='data'>
            <div class=' flex justify-between'>
                    <span
                        [class]="'priority-'+value.productEndTerm">PED {{value.productEndTerm}}</span>
                <span
                    [class]="'priority-'+value.earlyReview">ER {{value.earlyReview}}</span>
                <span
                    [class]="'priority-'+value.equityAvailable">EA {{value.equityAvailable}}</span>
                <span
                    [class]="'priority-'+value.rateRiser">RR {{value.rateRiser}}</span>
            </div>
        </ng-template>

        <ng-template slot='expiryDate' let-value='data'>
            {{value.expiryDate | date: 'dd-MM-yyyy'}}
        </ng-template>

        <ng-template slot='lender' let-value='data'>
            <div class='w-20 overflow-auto'>{{value.lender}}</div>
        </ng-template>

        <ng-template slot='currentEquityRelease' let-value='data'>
            <div class='w-23 overflow-auto'>
                {{value.currentEquityRelease | currency:'GBP':'symbol':'4.2-3':'en'}}</div>
        </ng-template>

        <ng-template slot='rise' let-value='data'>
            <div class='w-20 overflow-auto'>{{value.rise}}%</div>
        </ng-template>

        <ng-template slot='contactRemainingDays' let-value='data'>
            <p [class]='{"text-danger": value.contactRemainingDays < 14}'>{{value.contactRemainingDays}} days</p>
        </ng-template>

        <ng-template slot='portfolioValues' let-value='data'>
            <p>£{{value.portfolioValues | number}}</p>
        </ng-template>
        <ng-template slot='mortgagesOutstanding' let-value='data'>
            <p>£{{value.mortgagesOutstanding | number}}</p>
        </ng-template>
        <ng-template slot='monthlyMortgagesPayments' let-value='data'>
            <p>£{{value.monthlyMortgagesPayments | number}}</p>
        </ng-template>
        <ng-template slot='growth' let-value='data'>
            <p>{{value.growth | number}}%</p>
        </ng-template>
        <ng-template slot='yield' let-value='data'>
            <p>{{value.yield | number}}%</p>
        </ng-template>

        <ng-template slot='broker' let-value='data'>
            <ng-container
                *ngIf='PermissionService.checkCredentials((profile$ |async), PermissionService.managerRoles); else brokerRole'>
                <ng-select
                    [items]='brokers$ | async'
                    [(ngModel)]='brokersTableSelectors[value.broker]'
                    (change)='changeBroker($event, clientsIds(value.clients))'
                    [clearable]='false'
                    [searchable]='false'
                    [dropdownPosition]="(value.key > 5) ? 'top' : 'bottom'"
                    bindLabel='name'
                    bindValue='id'
                    class='w-44 custom-multiselect'
                >
                </ng-select>
            </ng-container>

            <ng-template #brokerRole>
                {{(profile$ | async)?.name}}
            </ng-template>

        </ng-template>

        <ng-template slot='broker-single' let-value='data'>
            {{value.propertyBrokers[0]?.name}}
        </ng-template>

        <ng-template slot='propertyValue' let-value='data'>
            <p class='cursor-pointer min-w-fit' (click)="updateAmount({
                            propertyId: value.property.id,
                            valueName: 'Property value',
                            apiRequestStructure: EditModalService.getApiSchema('market_value'),
                            value: showPropertyValue(value.property.markets_latest),
                        })">
                £{{showPropertyValue(value.property.markets_latest) | number}}</p>
        </ng-template>

        <ng-template slot='monthlyPayment' let-value='data'>
            <p class='cursor-pointer min-w-fit' (click)="updateAmount({
                propertyId: value.property.id,
                mortgageId: value.property.mortgages[0]?.id ?? null,
                valueName: 'Monthly payment',
                apiRequestStructure: EditModalService.getApiSchema('monthlyPayment'),
                value: value.property.mortgages[0]?.monthly_mortgage_payments,
            })">
                £{{value.property.mortgages[0]?.monthly_mortgage_payments | number}}</p>
        </ng-template>

        <ng-template slot='productEndTerm' let-value='data'>
            <p class='max-w-[8rem] min-h-[1rem] cursor-pointer min-w-fit' (click)="updateDate({
                propertyId: value.property.id,
                mortgageId: value.property.mortgages[0]?.id ?? null,
                valueName: 'Product End Term',
                apiRequestStructure: EditModalService.getApiSchema('productEndTerm'),
                value: value.property.mortgages[0]?.product_end_term,
            })">
                {{value.property.mortgages[0]?.product_end_term | date :'dd-MM-yyyy'}}</p>
        </ng-template>
        <ng-template slot='rentalIncome' let-value='data'>
            <p class='cursor-pointer min-w-fit' (click)="updateAmount({
                propertyId: value.property.id,
                valueName: 'Rental income',
                apiRequestStructure: EditModalService.getApiSchema('current_rental_income'),
                value: value.property.current_rental_income,
            })">
                £{{value.property.current_rental_income | number}}</p>
        </ng-template>
        <ng-template slot='hmo' let-value='data'>
            <app-hmo-select [propertyId]='value.property.id' [value]='value.property.hmo'></app-hmo-select>
        </ng-template>

        <ng-template slot='mub' let-value='data'>
            <app-mub-select [propertyId]='value.property.id' [value]='value.property.is_mub'></app-mub-select>
        </ng-template>

        <ng-template slot='investorCompany' let-value='data'>
            <div class='w-38'>
                <app-investor-company-select
                    [investorCompany]='value.investorCompany'
                    [propertyId]='value.property.id'
                    [investorIds]='value.investorId ? [value.investorId] : value.property.investor_ids'
                ></app-investor-company-select>
            </div>
        </ng-template>

        <ng-template slot='propertyOwnerType' let-value='data'>
            <app-property-owner-type-select [propertyId]='value.property.id'
                                            [value]='value.property.owner_type'></app-property-owner-type-select>
        </ng-template>

        <ng-template slot='rentalsIncome' let-value='data'>
            <p>£{{value.rentalsIncome | number}}</p>
        </ng-template>

        <ng-template slot='interestRate' let-value='data'>
            <p class='cursor-pointer min-w-fit' (click)="updateInterestRate({
                propertyId: value.property.id,
                mortgageId: value.property.mortgages[0]?.id ?? null,
                valueName: 'Interest Rate',
                apiRequestStructure: EditModalService.getApiSchema('interestRate'),
                value: value.property.mortgages[0]?.interest_rate,
            })">
                {{value.property.mortgages[0]?.interest_rate | number}}</p>
        </ng-template>
        <ng-template slot='loanAmount' let-value='data'>
            <p class='cursor-pointer min-w-fit' (click)="updateAmount({
                propertyId: value.property.id,
                mortgageId: value.property.mortgages[0]?.id ?? null,
                valueName: 'Loan amount',
                apiRequestStructure: EditModalService.getApiSchema('loanAmount'),
                value: value.property.mortgages[0]?.outstanding_mortgage,
            })">
                £{{value.property.mortgages[0]?.outstanding_mortgage | number}}</p>
        </ng-template>

        <ng-template slot='status' let-value='data'>
            <app-table-status-column [value]='value.property'></app-table-status-column>
        </ng-template>

        <ng-template slot='client-property-actions' let-value='data'>
            <div class='flex flex-row gap-3'>

                <app-table-actions-button-investor-detail-info (updateContent)='updateContent.emit($event)' [clients]='value.clients'></app-table-actions-button-investor-detail-info>

                <app-table-actions-button-client [clients]='value.clients'></app-table-actions-button-client>

                <app-table-actions-button-property [propertyId]='value.id'></app-table-actions-button-property>

            </div>
        </ng-template>

        <ng-template slot='client-actions' let-value='data'>
            <div class='flex flex-row gap-3'>
                <app-table-actions-button-investor-detail-info [clients]='value.clients'></app-table-actions-button-investor-detail-info>
                <app-table-actions-button-client [clients]='value.clients'></app-table-actions-button-client>
            </div>
        </ng-template>

        <ng-template slot='property-action' let-value='data'>
            <div class='flex flex-row justify-center'>
                <app-table-actions-button-property [propertyId]='value.id'></app-table-actions-button-property>
            </div>
        </ng-template>

        <!-- Investors Table -->
        <ng-template slot='appUser' let-value='data'>
            {{appUserShow(value.investor)}}
        </ng-template>

        <ng-template slot='created' let-value='data'>
            {{createdShow(value.investor)}}
        </ng-template>


        <!--    home_buyer-columns-->
        <ng-template slot='isFTB' let-value='data'>
            {{value.property.home_buyer.is_ftb ? 'True' : 'False'}}
        </ng-template>

        <ng-template slot='ftbMortgagePrincipleRequired' let-value='data'>
            <p class='ml-[30%]'>{{value.property.home_buyer.mortgage_principle_required ? 'Yes' : 'No'}}</p>
        </ng-template>

        <ng-template slot='ftbMortgagePrincipleExpiryDate' let-value='data'>
            {{value.property.home_buyer.mortgage_principle_expiry_date | date: 'dd-MM-yyyy'}}
        </ng-template>

        <ng-template slot='ftbOfferPrice' let-value='data'>
            <p>£{{value.property.home_buyer.offer_price | number}}</p>
        </ng-template>

        <!--    next-call-columns-->

        <ng-template slot='nextContact' let-value='data'>
            <p>{{value.investor.next_call?.call_date | date: 'dd-MM-yyyy' }}</p>
        </ng-template>

        <ng-template slot='lastContact' let-value='data'>
            <p>{{value.investor.last_call?.call_date | date: 'dd-MM-yyyy' }}</p>
        </ng-template>

        <ng-template slot='callStatus' let-value='data'>
            <ng-container *ngIf='value.investor.next_call?.rating; else callStatusEmpty'>
                <div [className]="'call-rating-'+value.investor.next_call.rating">
                    <p>{{value.investor.next_call.rating_title}}</p>
                </div>
            </ng-container>
            <ng-template #callStatusEmpty>
                {{'-'}}
            </ng-template>

        </ng-template>

    </ng-datatable>


</div>
<app-change-status-investor-modal #investorStatusModal></app-change-status-investor-modal>
<app-amount-edit-modal #amountUpdate></app-amount-edit-modal>
<app-string-edit-modal #stringUpdate></app-string-edit-modal>
<app-date-edit-modal #dateUpdate></app-date-edit-modal>
<app-interest-rate-edit-modal #interestRateModal></app-interest-rate-edit-modal>

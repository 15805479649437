import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GooglePlaceService } from '../../../services/google-place.service';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, filter, Subject, Subscription, switchMap, tap } from 'rxjs';
import { FormattedAddress, PlaceAutocompleteResponse } from '../../../type/google.type';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-place-autofill',
    templateUrl: './place-autofill.component.html',
})
export class PlaceAutofillComponent implements OnInit, OnDestroy {
    @Input() public control: FormControl;
    @Input() public isSubmitForm: boolean;

    @Output() addressSet = new EventEmitter<{
        address: FormattedAddress;
    }>();
    protected search$: Subject<string> = new Subject<string>();
    protected loadingState = false;

    private controlSubscription: Subscription;

    public autocompleteVariants$: BehaviorSubject<PlaceAutocompleteResponse[]> = new BehaviorSubject([]);

    public constructor(private readonly googlePlaceService: GooglePlaceService) {}

    public ngOnInit(): void {
        this.controlSubscription = this.search$
            .pipe(
                debounceTime(500),
                filter((str: string) => str.length >= 3 || str.length === 0),
                tap(() => (this.loadingState = true)),
                switchMap(str => this.googlePlaceService.autocompleteRequest(str)),
            )
            .subscribe(result => {
                this.autocompleteVariants$.next(result);
                this.loadingState = false;
            });
    }

    public ngOnDestroy(): void {
        this.controlSubscription.unsubscribe();
    }

    public selectAddress(addressId: string) {
        this.googlePlaceService.fullAddressSearch(addressId).subscribe(response => {
            this.control.setErrors(null);
            const _result = GooglePlaceService.formattingAddress(response);
            if (_result.addressChunks.street_number === undefined)
                this.control.setErrors({ invalidStreetNumber: true });
            if (this.control.valid) this.addressSet.emit({ address: _result });
        });
    }

    public searchFn(): boolean {
        return true;
    }
}

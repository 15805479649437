<ng-select
    id='isHMO'
    [items]='dataPropertyService.taxTypes'
    [(ngModel)]='value'
    (change)='update()'
    [searchable]='false'
    [clearable]='false'
    bindLabel='name'
    bindValue='id'
    class='custom-multiselect'
>
</ng-select>

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Investor, TableMetaOptions } from '../../../../../type';
import { ActionOnInvestorModalComponent } from './components/action-on-investor-modal/action-on-investor-modal.component';
import { ScheduledCallComponent } from './components/scheduled-call/scheduled-call.component';
import { ClientNoteComponent } from './components/note/client-note.component';

export const TableActionsButtonInvestorDetailInfoComponents = [
    ActionOnInvestorModalComponent,
    ScheduledCallComponent,
    ClientNoteComponent,
];

@Component({
    selector: 'app-table-actions-button-investor-detail-info',
    templateUrl: './table-actions-button-investor-detail-info.component.html',
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'scale(0.95)',
                }),
                animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
            ]),
            transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
        ]),
    ],
})
export class TableActionsButtonInvestorDetailInfoComponent {
    @Input()
    public clients: Investor[];

    @Output()
    public updateContent = new EventEmitter<TableMetaOptions>();

    @ViewChild('investorActions') actionOnInvestorModalComponent: ActionOnInvestorModalComponent;

    public clientActionsModal(investor: Investor): void {
        this.actionOnInvestorModalComponent.openModal(investor);
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { Property, PropertyHistory } from '../../../../type';
import { PropertyService } from '../../../../services/property.service';
import { BehaviorSubject, first } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-property-activity-log',
    templateUrl: './property-activity-log.component.html',
})
export class PropertyActivityLogComponent implements OnInit {
    @Input()
    public property: Property;

    private _history$: BehaviorSubject<PropertyHistory[]> = new BehaviorSubject<PropertyHistory[]>([]);
    public history$ = this._history$.pipe(map(data => data.map(item => this.sortProperty(item))));

    public constructor(private readonly propertyService: PropertyService) {}

    public ngOnInit(): void {
        this.propertyService
            .propertyHistory(this.property.id)
            .pipe(first())
            .subscribe(response => this._history$.next(response));
    }

    public sortProperty(history: PropertyHistory): { items: { name: string; old: string; new: string }[]; date: Date } {
        const _items = [];
        Object.keys(history.modified).forEach(val =>
            _items.push({
                name: val.replace(/[A-Z]/g, m => ' ' + m.toLowerCase()).replace('_', ' '),
                old: history.modified[val].old ? history.modified[val].old : '-',
                new: history.modified[val].new ? history.modified[val].new : '-',
            }),
        );
        return { items: _items, date: history.created_at };
    }
}

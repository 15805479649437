<modal [closeOnOutsideClick]='true' #modal class='modal-top'>
    <ng-template #modalHeader>
        <div>{{title}}</div>
    </ng-template>
    <ng-template #modalBody>
        <div class='w-full'>

            <div class='py-7 px-6'>
                <form [formGroup]='form' (ngSubmit)='save()' novalidate>
                    <div
                        [ngClass]="(isSubmitForm && form.controls['date']?.errors) ? 'has-error' : ''">
                        <ng2-flatpickr addClass='form-input'
                                       [config]='dataPropertyService.basicCalendarParams'
                                       [setDate]='startDate'
                                       formControlName='date'></ng2-flatpickr>
                        <ng-container
                            *ngIf="isSubmitForm && form.controls['date'].errors?.required">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                        <ng-container
                            *ngIf="isSubmitForm && form.controls['date'].errors?.server">
                            <p class='mt-1 text-danger'></p>
                        </ng-container>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>
    <ng-template #modalFooter>
        <button [disabled]='isDisableControls' type='button' (click)='save()' class='btn btn-primary ltr:ml-4 rtl:mr-4'>
            Save
        </button>
    </ng-template>
</modal>

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, first, Observable, switchMap, throwError } from 'rxjs';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';
import { environment as env } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(
        private readonly auth: AuthenticationService,
        private readonly router: Router,
    ) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.auth.getToken(request.url == AuthenticationService.refreshUrl).pipe(
            first(),
            map(accessToken => {
                const headers = { 'Content-Type': 'application/json' };

                if (accessToken) headers['Authorization'] = `Bearer ${accessToken.access_token}`;
                return request.clone({
                    setHeaders: headers,
                });
            }),
            switchMap(newReq => {
                return next.handle(newReq).pipe(
                    catchError((event: HttpEvent<any>) => {
                        return event instanceof HttpErrorResponse
                            ? this.handlerResponseError(event)
                            : throwError(() => event);
                    }),
                );
            }),
        );
    }

    private handlerResponseError(response: HttpErrorResponse): Observable<any> {
        if (response.status == 401 && env.production) {
            this.auth.logout();
            this.router.navigate(['auth']);
        }
        return throwError(() => response);
    }
}

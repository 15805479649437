import { createReducer, on } from '@ngrx/store';
import { companyActions } from './company.actions';
import { Company } from '../../../dashboard/company/company.type';
import { CompanyShowingData } from '../../type';

export const companyNode = 'company';

export interface CompanyState {
    selectedCompany: CompanyShowingData | null;
    companiesList: CompanyShowingData[];
    info: Company | null;
}

export const companyInitialState: CompanyState = {
    info: null,
    selectedCompany: null,
    companiesList: [],
};

export const companyReducer = createReducer(
    companyInitialState,
    on(companyActions.companyInfoSet, (state, action) => ({
        ...state,
        info: action.info,
    })),
    on(companyActions.selectedCompanySet, (state, action) => ({
        ...state,
        selectedCompany: action.selectedCompany,
        companiesList: action.companiesList,
    })),
);

import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PropertiesComponent } from './properties/properties.component';
import { PropertyDetailComponent } from '../shared/components/property-detail/property-detail.component';
import { PropertyUpdateComponent } from '../shared/components/property-update/property-update.component';
import { PropertyCreateComponent } from '../shared/components/property-create/property-create.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'properties',
    },
    {
        path: 'properties',
        component: PropertiesComponent,
    },
    {
        path: 'property/view/:id',
        component: PropertyDetailComponent,
    },
    {
        path: 'property/create/:investorId',
        component: PropertyCreateComponent,
    },
    {
        path: 'property/update/:investorId/:id',
        component: PropertyUpdateComponent,
    },
    { path: '**', redirectTo: 'investor/properties' },
];

export const routesComponents = [PropertiesComponent];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class InvestorDashboardRoutingModule {}

import { Injectable } from '@angular/core';
import { DataForPropertyTable, PriorityFilter, TableMetaOptions } from '../type';
import { combineLatest, first, Observable, Subject } from 'rxjs';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { propertyNode, PropertyState } from '../store/property/property.store';
import { brokerNode, BrokerState } from '../store/broker/broker.store';
import { tableNode, TableState } from '../store/table/table.store';
import { tableSelectors } from '../store/table/table.selectors';
import { map } from 'rxjs/operators';
import { NgDataTableComponent } from '@bhplugin/ng-datatable/lib/ng-datatable';
import { tableActions } from '../store/table/table.actions';
import { propertySelectors } from '../store/property/property.selectors';
import { propertyActions } from '../store/property/property.actions';

@Injectable()
export class DataTableService {
    public updateContentEvent$: Subject<TableMetaOptions> = new Subject<TableMetaOptions>();
    public tableState$ = this.store.pipe(
        select(tableSelectors.selectTableData),
        map(state => {
            this.router.navigate([location.pathname], {
                queryParams: this.generateQueryParams(state),
            });
            return this.generateRequestOptions(state);
        }),
    );
    private allPropertyData$ = this.store.pipe(select(propertySelectors.selectAllPropertiesData));

    public set table(table: NgDataTableComponent) {
        this._table = table;
    }

    private _table: NgDataTableComponent;

    public constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store<{
            [tableNode]: TableState;
            [propertyNode]: PropertyState;
            [brokerNode]: BrokerState;
        }>,
    ) {}

    public init(
        priorityFilter: PriorityFilter | null,
        sortColumn = '',
        sortDirection: 'asc' | 'desc' = 'asc',
        customFilter = undefined,
    ): Observable<TableState> {
        const params$ = combineLatest([
            this.store.pipe(select(tableSelectors.selectTableData)),
            this.route.queryParams,
        ]);
        return params$.pipe(
            first(),
            map(([currentData, params]: [TableState, Params]) => {
                const _state = { ...currentData };
                const _page = Number(params['page']);
                const _perPage = Number(params['perPage']);

                _state.page = _page > 1 ? _page : 1;
                _state.perPage = _perPage > 1 ? _perPage : 10;
                _state.brokerFilter = Number(params['broker']) > 0 ? Number(params['broker']) : 0;
                _state.statusFilter =
                    Number(params['status']) == 1 || Number(params['status']) == 2 ? Number(params['status']) : 0;
                _state.priorityFilter = priorityFilter
                    ? {
                          filter: priorityFilter.filter,
                          value: params['priority'] ? this.getPriorityParam(params['priority']) : priorityFilter.value,
                      }
                    : undefined;
                _state.customFilter = customFilter;
                _state.search = params['search'] ?? '';
                _state.sortColumn = params['sort'] ? params['sort'] : sortColumn;
                _state.sortDirection = params['direction'] ? params['direction'] : sortDirection;
                this.store.dispatch(tableActions.updateState({ state: _state }));
                return _state;
            }),
        );
    }

    public dataSetForPropertyTable(): Observable<DataForPropertyTable[]> {
        return this.allPropertyData$.pipe(
            map(({ properties, investors, brokers, investorCompanies }) =>
                properties
                    ? properties.map(property => ({
                          property: property,
                          clients: investors.filter(client => property.investor_ids.includes(client.id)),
                          propertyBrokers: brokers.filter(broker => property.broker_ids.includes(broker.id)),
                          investorCompany: investorCompanies.find(
                              company => company.id == property.investor_company_id,
                          ),
                      }))
                    : [],
            ),
        );
    }

    public resetDataSet(): void {
        this.store.dispatch(propertyActions.propertiesSet({ properties: [] }));
    }

    private getPriorityParam(priority: string[] | string): number[] {
        return typeof priority == 'string' ? [Number(priority)] : priority.map(val => Number(val));
    }

    public generateRequestOptions(state: TableState): TableMetaOptions {
        const options: {
            filters?: any;
            search?: string;
            sort?: {
                columnName: string;
                direction: string;
            };
        } = {
            filters: this.generateFiltersArr(state),
            search: state.search.length > 0 ? state.search : undefined,
        };

        if (state.sortColumn.length > 0)
            options.sort = {
                columnName: state.sortColumn,
                direction: state.sortDirection,
            };
        return { page: state.page, perPage: state.perPage, options };
    }

    private generateFiltersArr(state: TableState): any[] {
        const stateFilterQuery = [
            { filter: { activity_status: { in: 'NotActioned,AttemptedContact,Contacted,AppointmentMade' } } },
            { filter: { activity_status: { nin: 'NotActioned,AttemptedContact,Contacted,AppointmentMade' } } },
        ];

        const _filters = [
            state.priorityFilter && this.concatPriorityValueForSanding(state.priorityFilter.value).length > 0
                ? state.priorityFilter.filter(this.concatPriorityValueForSanding(state.priorityFilter.value))
                : '',
            state.customFilter,
        ];
        if (state.statusFilter === 1 || state.statusFilter === 2)
            _filters.push(stateFilterQuery[state.statusFilter - 1]);
        if (state.brokerFilter > 0) _filters.push({ filter: { brokers: { id: { eq: state.brokerFilter } } } });
        return _filters;
    }

    private concatPriorityValueForSanding(values: number[] | null): string {
        if (!values) return '';
        let str = '';
        values.forEach(val => {
            str = str.length == 0 ? str.concat(`${val}`) : str.concat(`,${val}`);
        });
        return str;
    }

    private generateQueryParams(state: TableState): NavigationExtras {
        const queryParams: any = {
            page: state.page,
            perPage: state.perPage,
        };

        if (state.statusFilter > 0) queryParams.status = state.statusFilter;
        if (state.brokerFilter > 0) queryParams.broker = state.brokerFilter;
        if (state.priorityFilter?.value) queryParams.priority = state.priorityFilter.value;
        if (state.search.length > 0) queryParams.search = state.search;

        if (state.sortColumn.length > 0) {
            queryParams.sort = state.sortColumn;
            queryParams.direction = state.sortDirection;
        }
        return queryParams;
    }

    public static statusBadge(activityStatus: string): string {
        const _options = {
            NotActioned: 'no-action',
            AttemptedContact: 'attempted-contact',
            Contacted: 'contacted',
            Submitted: 'submitted',
            AppointmentMade: 'appointment-made',
            InvalidExpiryDate: 'invalid-expiry',
            LostOpportunity: 'lost-no-op',
            NoOpportunity: 'lost-no-op',
        };
        const _value = Object.entries(_options).find(value => value[0] == activityStatus);
        return _value ? _value[1] : '';
    }

    public tableReset(): void {
        this._table.reset();
    }

    public updateTableContent(): void {
        this.tableState$.pipe(first()).subscribe(meta => this.updateContentEvent$.next(meta));
    }

    public updateTableWithReset(customFilter: unknown): void {
        this.store
            .pipe(select(tableSelectors.selectTableData))
            .pipe(
                map(state => {
                    const _state = { ...state };

                    _state.page = 1;
                    _state.perPage = 10;
                    _state.brokerFilter = 0;
                    _state.statusFilter = 0;
                    _state.priorityFilter = undefined;
                    _state.customFilter = customFilter;
                    _state.search = '';
                    _state.sortColumn = '';
                    _state.sortDirection = '';
                    return _state;
                }),
                first(),
            )
            .subscribe(state => {
                this.store.dispatch(tableActions.updateState({ state }));
                this.tableReset();
            });
    }
}

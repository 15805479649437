<div *ngIf='(selectedOptionData$ | async)'>
    <div
        class='panel w-auto overflow-x-auto whitespace-nowrap p-3'>
        <div class='flex flex-row w-full'>
            <h5 class='text-2xl'>Broker performance</h5>
            <ng-select
                ngxTippy='Select which Priority status you wish to look at from drop-down'
                [tippyProps]="{placement: 'left'}"
                [items]='options'
                [(ngModel)]='selected'
                [searchable]='false'
                [clearable]='false'
                (change)='prioritySelectorChanged()'
                class='ml-4 text-2xl custom-multiselect'
            >
            </ng-select>

            <ng-select
                ngxTippy='Select which category you wish to look at – All, PED, Early Review, Rate Riser, Equity Available'
                [tippyProps]="{placement: 'left'}"
                [items]='optionsServicesFilter'
                [(ngModel)]='servicesFilter'
                [searchable]='false'
                [clearable]='false'
                bindLabel='name'
                bindValue='id'
                (change)='doneChanges()'
                class='ml-4 w-44 custom-multiselect'
            >
            </ng-select>
        </div>


        <table class='mt-6 table-hover'>
            <thead>
            <tr>
                <th ngxTippy='The name of the broker'
                    [tippyProps]="{placement: 'left'}">Broker
                </th>
                <th ngxTippy='The total number of client records assigned to the broker'
                    [tippyProps]="{placement: 'left'}">Cases Assigned
                </th>
                <th ngxTippy='The total number of Priority client records in the category selected'
                    [tippyProps]="{placement: 'left'}">Priority 5
                </th>
                <th ngxTippy='The total number of client records actioned in the category selected and includes all statuses except Not Actioned'
                    [tippyProps]="{placement: 'left'}">Actioned
                </th>
                <th ngxTippy='The percentage of client records actioned in the category selected and includes all statuses except Not Actioned'
                    [tippyProps]="{placement: 'left'}">Actioned %
                </th>
                <th ngxTippy='The total number of client mortgage applications in the category selected and includes all statuses except Not Actioned'
                    [tippyProps]="{placement: 'left'}">Submitted
                </th>
                <th ngxTippy='The percentage of client mortgage applications in the category selected and includes all statuses except Not Actioned'
                    [tippyProps]="{placement: 'left'}">Submitted %
                </th>
            </tr>
            </thead>
            <tbody>

            <tr *ngFor='let broker of (selectedOptionData$ | async)'>
                <td class='cursor-pointer'><a
                    routerLink='/dashboard/broker-performance/{{broker.id}}'>{{broker.name}}</a></td>
                <td>{{broker.properties_total}}</td>
                <td>{{broker.rating.total.count}}</td>
                <td>{{broker.rating.actioned.count}}</td>
                <td>{{broker.rating.actioned.pct | number :'1.1-1'}} %</td>
                <td>{{broker.rating.submitted.count}}</td>
                <td>{{broker.rating.submitted.pct | number :'1.1-1'}} %</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf='(selectedOptionChart$ | async) as configs' class='mt-6 mb-6 h-auto'>
    <div class='panel h-min p-0'>
        <div
            class='mb-5 flex items-start justify-between border-b border-[#e0e6ed] p-5 dark:border-[#1b2e4b] dark:text-white-light'>
            <h5 class='text-lg font-semibold'>Priority {{selected}}</h5>
        </div>

        <div class='overflow-hidden'>
            <apx-chart
                [xaxis]='configs.xaxis'
                [series]='configs.series'

                [chart]='chartService.stackedChart.chart'
                [grid]='chartService.apexConfigs.grid'
                [stroke]='chartService.apexConfigs.stroke'
                [dataLabels]='chartService.stackedChart.dataLabels'
                [colors]='chartService.stackedChart.colors'
                [plotOptions]='chartService.stackedChart.plotOptions'
                [legend]='chartService.apexConfigs.legend'
                [yaxis]='chartService.apexConfigs.yaxis'
                [fill]='chartService.apexConfigs.fill'
                [tooltip]='chartService.apexConfigs.tooltip'

                class='rounded-lg bg-white dark:bg-black'
            >
            </apx-chart>
        </div>

    </div>
</div>

<div
    *ngIf='(selectedOptionData$ | async) === null'
    class='h-screen flex flex-col items-center justify-center inset-0 bg-[#fafafa] dark:bg-[#060818] z-[60] grid place-content-center animate__animated'
>
    <svg width='64' height='64' viewBox='0 0 135 135' xmlns='http://www.w3.org/2000/svg' fill='#4361ee'>
        <path
            d='M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='-360 67 67' dur='2.5s'
                              repeatCount='indefinite' />
        </path>
        <path
            d='M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='360 67 67' dur='8s'
                              repeatCount='indefinite' />
        </path>
    </svg>
</div>

<ng-container *ngIf='(investor$ | async) as investor'>

    <div class='panel flex flex-row justify-between'>
        <h5 class='text-2xl'>Client: {{investor.name}}</h5>
        <button (click)='redirectToPrevURL()' type='button' class='btn btn-outline-dark'>Back</button>
    </div>

    <div class='panel mt-6 flex flex-row justify-start gap-24'>
        <div>
            <div class='text-xl'>Email</div>
            <div>{{investor.email}}</div>
        </div>

        <div>
            <div class='text-xl'>Phone</div>
            <div>{{investor.phone}}</div>
        </div>
    </div>

    <app-client-detail [client]='investor'></app-client-detail>

    <hr class='my-6 border-[#e0e6ed]' />

    <div class='mt-6 flex gap-6 justify-start flex-row'>

        <div>

            <div class='py-7 px-6 bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed]'>
                <app-client-note [investor]='investor$ | async'></app-client-note>
            </div>
        </div>
    </div>

</ng-container>

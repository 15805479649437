import { Component, Input } from '@angular/core';
import { Property } from '../../../../type';

@Component({
    selector: 'app-property-priority',
    templateUrl: './property-priority.component.html',
    styleUrls: ['./property-priority.component.css'],
})
export class PropertyPriorityComponent {
    @Input()
    public property: Property;

    public convertToPositiveNumber(num: number): number {
        return Math.abs(num);
    }
}

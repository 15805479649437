import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { mergeMap, Observable, of } from 'rxjs';
import { AuthenticationService } from '../shared/services/authentication.service';
import { RouterService } from '../shared/services/router.service';

@Injectable({
    providedIn: 'root',
})
export class IsNotAuthenticatedGuard implements CanActivate {
    constructor(
        private auth: AuthenticationService,
        private readonly routerService: RouterService,
        private router: Router,
    ) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.auth
            .isAuthenticated()
            .pipe(mergeMap(auth => (!auth ? of(true) : this.router.navigate([this.routerService.parentRoute()]))));
    }
}

<modal [closeOnOutsideClick]='true' #modal class='modal-top'>
    <ng-template #modalHeader>
        <div>Change Status</div>
    </ng-template>
    <ng-template #modalBody>
        <div class='w-full'>

            <div class='py-7 px-6'>
                <div class='text-xl font-semibold'>{{data.address}}</div>

                <hr class='my-6 border-[#e0e6ed]' />

                <div class='mt-6'>
                    <label for='activityStatus'>Activity status</label>
                    <ng-select
                        id='activityStatus'
                        [(ngModel)]='data.status'
                        [items]='dataPropertyService.activityTypes'
                        [searchable]='false'
                        [clearable]='false'
                        bindLabel='name'
                        bindValue='id'
                        class='custom-multiselect'
                    >
                    </ng-select>

                    <div *ngIf='data.status === "NoOpportunity"' class='mt-6'>
                        <ng-select
                            [(ngModel)]='noOpportunitySelectValue'
                            [items]='noOpportunityOptions'
                            [searchable]='false'
                            [clearable]='false'
                            bindLabel='name'
                            bindValue='id'
                            class='custom-multiselect'
                        >
                        </ng-select>
                    </div>

                    <div *ngIf='data.status === "LostOpportunity"' class='mt-6'>
                        <ng-select
                            [(ngModel)]='lostOpportunitySelectValue'
                            [items]='lostOpportunityOptions'
                            [searchable]='false'
                            [clearable]='false'
                            bindLabel='name'
                            bindValue='id'
                            class='custom-multiselect'
                        >
                        </ng-select>
                    </div>

                </div>
                <div class='mt-6'>
                    <label for='activityStatus'>Notes</label>
                    <textarea id='notes' class='form-input min-h-[15rem]' [(ngModel)]='data.notes'></textarea>
                </div>

            </div>
        </div>
    </ng-template>
    <ng-template #modalFooter>
        <button [disabled]='isDisableControls' type='button' (click)='modal.close()' class='btn btn-outline-danger'>
            Cancel
        </button>
        <button [disabled]='isDisableControls' type='button' (click)='save()' class='btn btn-primary ltr:ml-4 rtl:mr-4'>
            Save
        </button>
    </ng-template>
</modal>

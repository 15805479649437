<div class='panel mt-6 pb-1.5'>
    <button
        type='button'
        class='flex w-full items-center p-4 text-white-dark dark:bg-[#1b2e4b]'
        [ngClass]="{ '!text-primary': accordian === 'Investment' }"
        (click)="clickByAccordian('Investment')"
    >
        <span class='text-2xl'>Portfolio</span>
        <div class='ltr:ml-auto rtl:mr-auto' [ngClass]="{ 'rotate-180': accordian === 'Investment' }">
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'
                 class='h-4 w-4'>
                <path d='M19 9L12 15L5 9' stroke='currentColor' stroke-width='1.5' stroke-linecap='round'
                      stroke-linejoin='round' />
            </svg>
        </div>
    </button>
    <div [@slideDownUp]="accordian !== 'Investment'" class='accordion-content'>
        <div *ngIf='accordian === "Investment"' class='mt-6'>
            <app-client-detail-companies-tabs [investorId]='client.id'
                                              [companiesList]='companiesList$ | async'
                                              (changeTab)='changingActiveCompany($event)'>
                <div [hidden]='loader$ | async'>
                    <app-property-portfolio-totals
                        [profile]='companyProfile$ | async'></app-property-portfolio-totals>
                    <div class='mt-6'>
                        <app-client-detail-property-table *ngIf='filter' (tableUpdated)='tableUpdated($event)'
                                                          [filter]='filter'>
                            <button (click)='createProperty()' type='button' class='btn btn-outline-dark'>Add Property</button>
                        </app-client-detail-property-table>
                    </div>
                </div>

                <div *ngIf='loader$ | async' class='min-h-[35vh] flex flex-row justify-center items-center'>
                    <app-loader></app-loader>
                </div>
            </app-client-detail-companies-tabs>

        </div>
    </div>


</div>

<div class='panel mt-6 pb-1.5'>
    <button
        type='button'
        class='flex w-full items-center p-4 text-white-dark dark:bg-[#1b2e4b]'
        [ngClass]="{ '!text-primary': accordian === 'Residential' }"
        (click)="clickByAccordian('Residential')"
    >
        <span class='text-2xl'>Residential</span>
        <div class='ltr:ml-auto rtl:mr-auto' [ngClass]="{ 'rotate-180': accordian === 'Residential'}">
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'
                 class='h-4 w-4'>
                <path d='M19 9L12 15L5 9' stroke='currentColor' stroke-width='1.5' stroke-linecap='round'
                      stroke-linejoin='round' />
            </svg>
        </div>
    </button>
    <div [@slideDownUp]="accordian !== 'Residential'" class='accordion-content'>
        <div *ngIf='accordian === "Residential"' class='mt-6'>
            <div class='mt-6' [hidden]='loader$ | async'>

                <app-client-detail-property-table *ngIf='filter' (tableUpdated)='tableUpdated($event)'
                                                  [filter]='filter'>
                    <button (click)='createProperty()' type='button' class='btn btn-outline-dark'>Add Property +
                    </button>
                </app-client-detail-property-table>

            </div>

            <div *ngIf='loader$ | async' class='min-h-[35vh] flex flex-row justify-center items-center'>
                <app-loader></app-loader>
            </div>

        </div>
    </div>


</div>

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { animate, style, transition, trigger } from '@angular/animations';
import { userSelectors } from '../../../../../store/user/user.selectors';
import { DashboardService } from '../../../../../../dashboard/dashboard.service';
import { userNode, UserState } from '../../../../../store/user/user.store';

@Component({
    selector: 'app-table-actions-button-property',
    templateUrl: './table-actions-button-property.component.html',
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'scale(0.95)',
                }),
                animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
            ]),
            transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
        ]),
    ],
})
export class TableActionsButtonPropertyComponent {
    @Input()
    public propertyId: number;

    public profile$ = this.store.pipe(select(userSelectors.selectProfile));

    public constructor(
        private readonly dashboardService: DashboardService,
        private readonly router: Router,
        private readonly store: Store<{
            [userNode]: UserState;
        }>,
    ) {}

    public propertyDetail(propertyId: number): void {
        this.dashboardService.savePrevUrl();
        this.profile$.pipe(first()).subscribe(profile => {
            const path =
                profile.type == 'investor'
                    ? `investor/property/view/${propertyId}`
                    : `dashboard/property/view/${propertyId}`;
            this.router.navigate([path]);
        });
    }
}

import { Component, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalUpdateModalValues } from '../../table.type';
import { DataPropertyService } from 'src/app/shared/services/data-property.service';
import { ValueChangeModalAbstract } from '../../../../assets/ValueChangeModal.abstract';
import { ModalComponent } from 'angular-custom-modal/modal.component';

@Component({
    selector: 'app-date-edit-modal',
    templateUrl: './date-edit-modal.component.html',
})
export class DateEditModalComponent extends ValueChangeModalAbstract {
    public startDate: null | Date;
    @ViewChild('modal') modal: ModalComponent;

    public open(data: ModalUpdateModalValues) {
        this.startDate = typeof data.value == 'string' ? DataPropertyService.prepareDateToShow(data.value) : null;
        const params = {
            date: [data.value, [Validators.required]],
        };
        this.form = this.fb.group(params);
        this.init('date', data, this.modal);
    }

    protected override getValue(): number | string | boolean {
        return DataPropertyService.parseDate(this.form.get('date')?.value);
    }
}

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { select, Store } from '@ngrx/store';
import { tableSelectors } from '../store/table/table.selectors';
import { investorNode, InvestorState } from '../store/investor/investor.store';
import { tableNode, TableState } from '../store/table/table.store';
import { first } from 'rxjs';
import { tableActions } from '../store/table/table.actions';
import { DataTableService } from './data-table.service';

export type Range = {
    name: string;
    filterRange: object | null;
    titleRange: string;
};

@Injectable()
export class OrganiserService {
    public tableState$ = this.store.pipe(select(tableSelectors.selectTableData));

    public ranges: Range[] = [
        {
            name: 'today',
            filterRange: { eq: moment().format('yyyy-MM-DD') },
            titleRange: moment().format('yyyy-MM-DD'),
        },
        {
            name: 'yesterday',
            filterRange: { eq: moment().subtract(1, 'day').format('yyyy-MM-DD') },
            titleRange: moment().subtract(1, 'day').format('yyyy-MM-DD'),
        },
        {
            name: 'last7days',
            filterRange: {
                gte: moment().subtract(7, 'days').format('yyyy-MM-DD'),
                lte: moment().format('yyyy-MM-DD'),
            },
            titleRange: moment().subtract(7, 'days').format('yyyy-MM-DD') + ' - ' + moment().format('yyyy-MM-DD'),
        },
        {
            name: 'last30days',
            filterRange: {
                gte: moment().subtract(30, 'days').format('yyyy-MM-DD'),
                lte: moment().format('yyyy-MM-DD'),
            },
            titleRange: moment().subtract(30, 'days').format('yyyy-MM-DD') + ' - ' + moment().format('yyyy-MM-DD'),
        },
        { name: 'all', filterRange: null, titleRange: '-' },
        {
            name: 'tomorrow',
            filterRange: { eq: moment().add(1, 'day').format('yyyy-MM-DD') },
            titleRange: moment().add(1, 'day').format('yyyy-MM-DD'),
        },
        {
            name: 'next7days',
            filterRange: {
                gte: moment().format('yyyy-MM-DD'),
                lte: moment().add(7, 'days').format('yyyy-MM-DD'),
            },
            titleRange: moment().format('yyyy-MM-DD') + ' - ' + moment().add(7, 'days').format('yyyy-MM-DD'),
        },
        {
            name: 'next30days',
            filterRange: {
                gte: moment().format('yyyy-MM-DD'),
                lte: moment().add(7, 'days').format('yyyy-MM-DD'),
            },
            titleRange: moment().format('yyyy-MM-DD') + ' - ' + moment().add(30, 'days').format('yyyy-MM-DD'),
        },
    ];

    public rangeForNextCall: Range = this.findRangeByName('all');
    public rangeForLastCall: Range = this.findRangeByName('all');

    public customFilter: object = {
        filter: {},
    };

    public constructor(
        private readonly datatablesService: DataTableService,
        private readonly store: Store<{
            [investorNode]: InvestorState;
            [tableNode]: TableState;
        }>,
    ) {}

    public statusOptions = [
        { name: '-', id: 0 },
        { name: 'Pending', id: 1 },
        { name: 'Soon', id: 2 },
        { name: 'Overdue', id: 3 },
        { name: 'Very overdue', id: 4 },
        { name: 'Extremely overdue', id: 5 },
    ];

    public listShowRecords = [
        { name: '-', id: 0 },
        { name: 'Only with next call', id: 1 },
        { name: 'Only with last call', id: 2 },
    ];

    public statusFilter = 0;
    public currentShowRecords = 0;

    public findRangeByName(name): Range {
        return this.ranges.find(range => range.name == name);
    }

    public changeRangeForNextCall(newRangeName: string): void {
        if (this.rangeForNextCall.name == newRangeName) return;
        this.rangeForNextCall = this.findRangeByName(newRangeName);
        this.selectorChanged();
    }

    public changeRangeForLastCall(newRangeName: string): void {
        if (this.rangeForLastCall.name == newRangeName) return;
        this.rangeForLastCall = this.findRangeByName(newRangeName);
        this.selectorChanged();
    }

    public selectorChanged(): void {
        let nextCallFilter: object | null = null;

        if (this.statusFilter != 0) nextCallFilter = { ...nextCallFilter, rating: { eq: this.statusFilter } };
        if (this.currentShowRecords == 1) nextCallFilter = { ...nextCallFilter, exists: true };
        if (this.rangeForNextCall.name != 'all')
            nextCallFilter = {
                ...nextCallFilter,
                call_date: this.rangeForNextCall.filterRange,
            };

        let lastCallFilter: object | null = null;
        if (this.currentShowRecords == 2) nextCallFilter = { ...lastCallFilter, exists: true };
        if (this.rangeForLastCall.name != 'all')
            lastCallFilter = { ...lastCallFilter, call_date: this.rangeForLastCall.filterRange };

        this.tableState$.pipe(first()).subscribe(state => {
            const _state = { ...state };
            _state.customFilter = { filter: { next_call: { ...nextCallFilter }, last_call: { ...lastCallFilter } } };
            _state.page = 1;
            this.store.dispatch(tableActions.updateState({ state: _state }));
            this.datatablesService.tableReset();
        });
    }
}

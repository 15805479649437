import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Broker, Investor, InvestorNote } from '../type';
import { environment as env } from '../../../environments/environment';

@Injectable()
export class InvestorNoteService {
    public constructor(private http: HttpClient) {}

    public getInvestorNotes(
        investorId: number = undefined,
    ): Observable<{ notes: InvestorNote[]; brokers: Broker[]; investors: Investor[] }> {
        return this.http
            .get<{
                data: InvestorNote[];
                related: {
                    brokers: Broker[];
                    investors: Investor[];
                };
                status: string;
            }>(`${env.baseServerUrl}/investor-notes${investorId ? '?filter[investor][id][eq]=' + investorId : ''}`)
            .pipe(
                map(response => ({
                    notes: response.data,
                    brokers: response.related.brokers,
                    investors: response.related.investors,
                })),
            );
    }

    public createInvestorNote(investorId: number, brokerId: number, text: string): Observable<InvestorNote> {
        return this.http
            .post<{
                data: InvestorNote;
                status: string;
            }>(`${env.baseServerUrl}/investor-notes`, { text, investor_id: investorId, broker_id: brokerId })
            .pipe(map(response => response.data));
    }

    public updateInvestorNote(noteId: number, text: string): Observable<InvestorNote> {
        return this.http
            .patch<{
                data: InvestorNote;
                status: string;
            }>(`${env.baseServerUrl}/investor-notes/${noteId}`, { text })
            .pipe(map(response => response.data));
    }

    public deleteInvestorNote(noteId: number): Observable<InvestorNote> {
        return this.http
            .delete<{
                data: InvestorNote;
                status: string;
            }>(`${env.baseServerUrl}/investor-notes/${noteId}`)
            .pipe(map(response => response.data));
    }
}

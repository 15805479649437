import { Component, Input } from '@angular/core';
import { DataPropertyService } from '../../../../services/data-property.service';
import { slideDownUp } from '../../../../../../shared/animations';
import { BehaviorSubject, first } from 'rxjs';

@Component({
    selector: 'app-property-erc',
    templateUrl: './property-erc.component.html',
    animations: [slideDownUp],
})
export class PropertyErcComponent {
    @Input() ercs$: BehaviorSubject<{ erc: number | null; date: Date[] }[]>;
    @Input() currentERC: number | boolean = 0;
    @Input() isOnlyShow = false;

    public accordian: string | null = null;

    public constructor(protected readonly dataPropertyService: DataPropertyService) {}

    public addERC() {
        this.ercs$.pipe(first()).subscribe(val => {
            const _ercs = [...val, { erc: undefined, date: [new Date()] }];
            this.ercs$.next(_ercs);
            this.accordian = String(_ercs.length - 1);
        });
    }

    public removeERC(index: string) {
        this.ercs$.pipe(first()).subscribe(val => {
            const _ercs = [...val];
            delete _ercs[index];
            this.ercs$.next(_ercs);
            this.accordian = null;
        });
    }
}

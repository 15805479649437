import { Component, Input, ViewChild } from '@angular/core';
import { Investor, Property } from '../../../../type';
import { Router } from '@angular/router';
import { RouterService } from '../../../../services/router.service';
import { DashboardService } from '../../../../../dashboard/dashboard.service';
import { ConfirmingModalComponent } from './confirming-modal/confirming-modal.component';

@Component({
    selector: 'app-property-info',
    templateUrl: './property-info.component.html',
})
export class PropertyInfoComponent {
    @Input()
    public property: Property;

    @Input()
    public investor: Investor;

    @ViewChild('ConfirmModal') modal: ConfirmingModalComponent;

    public constructor(
        private readonly router: Router,
        private readonly routerService: RouterService,
        private readonly dashboardService: DashboardService,
    ) {}

    public redirectToUpdateProperty(investorId: number, propertyId: number): void {
        const parentRoute = this.routerService.parentRoute();
        this.router.navigate([`${parentRoute}/property/update/${investorId}/${propertyId}`]);
        this.dashboardService.savePrevUrl();
    }

    public delete(propertyId: number): void {
        this.modal.open(propertyId);
    }
}

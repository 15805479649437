import { Injectable } from '@angular/core';
import {
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexGrid,
    ApexLegend,
    ApexPlotOptions,
    ApexStroke,
    ApexTooltip,
    ApexYAxis,
} from 'ng-apexcharts';

@Injectable()
export class ChartService {
    public apexConfigs = {
        chart: {
            height: 300,
            type: 'bar',
            fontFamily: 'Nunito, sans-serif',
            toolbar: {
                show: true,
            },
        } as ApexChart,
        grid: {
            borderColor: '#e0e6ed',
            padding: {
                left: 20,
                right: 20,
            },
        } as ApexGrid,
        labels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        } as ApexStroke,
        yaxis: {
            tickAmount: 6,
            opposite: false,
            labels: {
                offsetX: 0,
            },
        } as ApexYAxis,
        colors: [
            '#f0b215',
            '#0293FF',
            '#000080',
            '#21D6A0',
            '#EAEAEA',
            '#FFFF00',
            '#3644FF',
            '#008000',
            '#C0C0C0',
            '#808000',
            '#800000',
            '#008080',
        ],
        dropShadow: {
            enabled: true,
            blur: 3,
            color: '#515365',
            opacity: 0.4,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                borderRadius: 10,
                borderRadiusApplication: 'end',
            },
        } as ApexPlotOptions,
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.3,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 0.8,
                stops: [0, 100],
            },
        } as ApexFill,
        tooltip: {
            marker: {
                show: true,
            },
            y: {
                formatter: (val: any) => {
                    return val;
                },
            },
        } as ApexTooltip,
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '14px',
            itemMargin: {
                horizontal: 8,
                vertical: 8,
            },
        } as ApexLegend,
    };

    public stackedChart = {
        chart: {
            ...this.apexConfigs.chart,
            stacked: true,
        } as ApexChart,
        plotOptions: {
            bar: {
                ...this.apexConfigs.plotOptions.bar,
                horizontal: true,
                borderRadius: 0,
            },
        } as ApexPlotOptions,
        dataLabels: {
            style: {
                colors: [
                    '#000000',
                    '#000000',
                    '#ffffff',
                    '#ffffff',
                    '#ffffff',
                    '#000000',
                    '#4709CD',
                    '#000000',
                    '#000000',
                ],
            },
            formatter: value => '' + value,
        } as ApexDataLabels,
        colors: ['#FFA500', '#21D69F', '#4709CD', '#3643FE', '#0193FF', '#00CBFF', '#F2385A', '#EAEAEA', '#EAEAEA'],
    };
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyProfile } from '../../../../dashboard/company/company.type';
import { CompanyService } from '../../../services/company.service';
import { ToastService } from '../../../services/toast.service';
import { first } from 'rxjs';

@Component({
    selector: 'app-client-detail-companies-tabs',
    templateUrl: './client-detail-companies-tabs.component.html',
})
export class ClientDetailCompaniesTabsComponent {
    @Input() public investorId: number;
    @Input() public companiesList: CompanyProfile[] = [];

    @Output() changeTab = new EventEmitter<{
        tabName: 'All' | 'Individual' | 'Company' | 'Shared';
        company: CompanyProfile | null;
    }>();

    public editableId: number;
    public editableName: string;

    public createFlag = false;
    public createName: string;

    public activeCompany: {
        tabName: 'All' | 'Individual' | 'Company' | 'Shared';
        company: CompanyProfile | null;
    } = { tabName: 'All', company: null };

    public constructor(private readonly companyService: CompanyService) {}

    public changeActiveCompany(data: {
        tabName: 'All' | 'Individual' | 'Company' | 'Shared';
        company: CompanyProfile | null;
    }) {
        this.activeCompany = data;
        this.changeTab.emit(data);
    }

    public startCreating() {
        this.createFlag = true;
    }

    public createCompany(): unknown {
        if (this.createName === undefined || this.createName.length == 0) return (this.createFlag = false);
        this.companyService
            .investorCompanyCreate({ name: this.createName, investor_id: this.investorId })
            .pipe(first())
            .subscribe({
                next: company => this.successCreateCompany(company),
                error: () => ToastService.showToastMessage('color-danger', 'The name has already been taken'),
            });
        return;
    }

    public startEdit(companyId: number, companyName: string) {
        this.editableName = companyName;
        this.editableId = companyId;
    }

    public updateName() {
        this.companyService
            .investorCompanyUpdate(this.editableId, { name: this.editableName })
            .pipe(first())
            .subscribe({
                next: () => this.successUpdateCompany(this.editableId, this.editableName),
                error: () => ToastService.showToastMessage('color-danger', 'The name has already been taken'),
            });
    }

    private successCreateCompany(company: CompanyProfile) {
        this.companiesList.push(company);
        ToastService.showToastMessage('color-success', 'company has been created');
        this.cancel();
    }

    private successUpdateCompany(companyId: number, newName: string) {
        this.companiesList = this.companiesList.map(company => {
            if (company.id != companyId) return company;
            company.name = newName;
            return company;
        });
        ToastService.showToastMessage('color-success', 'company has been updated');
        this.cancel();
    }

    public cancel() {
        this.editableName = undefined;
        this.editableId = undefined;

        this.createFlag = false;
        this.createName = undefined;
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Investor } from 'src/app/shared/type';
import { DataPropertyService } from '../../../../../../../services/data-property.service';
import { InvestorService } from '../../../../../../../services/investor.service';
import { ToastService } from '../../../../../../../services/toast.service';

@Component({
    selector: 'app-scheduled-call',
    templateUrl: './scheduled-call.component.html',
})
export class ScheduledCallComponent implements OnInit {
    @Input() investor: Investor;

    @Output() updateContent = new EventEmitter<void>();
    @Output() closeModalEvent = new EventEmitter<void>();

    public currentStatus: 'confirm' | 'reschedule' | 'failed' = 'reschedule';

    public isDisableControls = false;
    public isSubmitForm = false;

    public states = [
        { title: 'Completed', value: 'confirm' },
        { title: 'Failed', value: 'failed' },
        { title: 'Reschedule', value: 'reschedule' },
    ];

    public initData: Date;
    public callDate: [Date];

    public constructor(
        protected readonly dataPropertyService: DataPropertyService,
        protected readonly investorService: InvestorService,
    ) {}

    public ngOnInit() {
        this.currentStatus = 'reschedule';
        this.initData = DataPropertyService.prepareDateToShow(this.investor.next_call?.call_date || new Date());
        this.callDate = [this.investor.next_call?.call_date || new Date()];
    }

    public update(): void {
        this.isSubmitForm = true;
        const successResponse = () => {
            this.isDisableControls = false;
            ToastService.showToastMessage('color-success', 'Appointment has been updated');
            this.updateContent.emit();
            this.closeModalEvent.emit();
        };

        const errorResponse = () => {
            this.isDisableControls = false;
            ToastService.showToastMessage('color-danger', 'Error');
        };

        let dataForRequest: object;

        if (this.currentStatus == 'confirm') dataForRequest = { last_call: { status: 'completed' } };
        if (this.currentStatus == 'reschedule')
            dataForRequest = { next_call: { call_date: DataPropertyService.parseDate(this.callDate) } };
        if (this.currentStatus == 'failed') dataForRequest = { last_call: { status: 'failed' } };

        this.isDisableControls = true;
        this.investorService.investorUpdate(this.investor.id, dataForRequest).subscribe({
            next: () => successResponse(),
            error: () => errorResponse(),
        });
    }
}

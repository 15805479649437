<modal [closeOnOutsideClick]='true' #modal class='modal-top'>
    <ng-template #modalHeader>
        <div>Change Status</div>
    </ng-template>
    <ng-template #modalBody>
        <div class='w-full'>

            <div class='py-7 px-6'>
                <div class='text-xl font-semibold'>{{data.client}}</div>

                <hr class='my-6 border-[#e0e6ed]' />

                <div class='mt-6'>
                    <label for='activityStatus'>Activity status</label>
                    <ng-select
                        id='activityStatus'
                        [(ngModel)]='status'
                        [items]='statusOptions'
                        [searchable]='false'
                        [clearable]='false'
                        bindLabel='name'
                        bindValue='id'
                        class='custom-multiselect'
                    >
                    </ng-select>

                </div>
                <div class='mt-6'>
                    Request sent at: <span class='font-semibold'>{{data.invokedAt | date :'dd-MM-yyyy hh:mm'}}</span>
                </div>

            </div>
        </div>
    </ng-template>
    <ng-template #modalFooter>
        <button [disabled]='isDisableControls' type='button' (click)='save()' class='btn btn-primary ltr:ml-4 rtl:mr-4'>
            OK
        </button>
    </ng-template>
</modal>

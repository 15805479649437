<ng-container *ngIf='clients.length > 1; else oneClient'>
    <div hlMenu class='dropdown !overflow-y-visible'>
        <button
            type='button'
            hlMenuButton
            class='btn dropdown-toggle rounded-none border-0 p-0 text-black shadow-none hover:text-primary'
        >
            <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fill-rule='evenodd' clip-rule='evenodd'
                      d='M10.9426 0.25H11.0574C13.3658 0.249987 15.1748 0.249977 16.5863 0.439747C18.031 0.633986 19.1711 1.03933 20.0659 1.93414C20.9607 2.82895 21.366 3.96897 21.5603 5.41371C21.75 6.82519 21.75 8.63423 21.75 10.9426V11.0574C21.75 13.3658 21.75 15.1748 21.5603 16.5863C21.366 18.031 20.9607 19.1711 20.0659 20.0659C19.1711 20.9607 18.031 21.366 16.5863 21.5603C15.1748 21.75 13.3658 21.75 11.0574 21.75H10.9426C8.63423 21.75 6.82519 21.75 5.41371 21.5603C3.96897 21.366 2.82895 20.9607 1.93414 20.0659C1.03933 19.1711 0.633986 18.031 0.439747 16.5863C0.249977 15.1748 0.249987 13.3658 0.25 11.0574V10.9426C0.249987 8.63423 0.249977 6.82519 0.439747 5.41371C0.633986 3.96897 1.03933 2.82895 1.93414 1.93414C2.82895 1.03933 3.96897 0.633986 5.41371 0.439747C6.82519 0.249977 8.63423 0.249987 10.9426 0.25ZM5.61358 1.92637C4.33517 2.09825 3.56445 2.42514 2.9948 2.9948C2.42514 3.56445 2.09825 4.33517 1.92637 5.61358C1.75159 6.91356 1.75 8.62177 1.75 11C1.75 13.3782 1.75159 15.0864 1.92637 16.3864C2.09825 17.6648 2.42514 18.4355 2.9948 19.0052C3.56445 19.5749 4.33517 19.9018 5.61358 20.0736C6.91356 20.2484 8.62177 20.25 11 20.25C13.3782 20.25 15.0864 20.2484 16.3864 20.0736C17.6648 19.9018 18.4355 19.5749 19.0052 19.0052C19.5749 18.4355 19.9018 17.6648 20.0736 16.3864C20.2484 15.0864 20.25 13.3782 20.25 11C20.25 8.62177 20.2484 6.91356 20.0736 5.61358C19.9018 4.33517 19.5749 3.56445 19.0052 2.9948C18.4355 2.42514 17.6648 2.09825 16.3864 1.92637C15.0864 1.75159 13.3782 1.75 11 1.75C8.62177 1.75 6.91356 1.75159 5.61358 1.92637ZM7.46967 8.96967C7.76256 8.67678 8.23744 8.67678 8.53033 8.96967L11 11.4393L13.4697 8.96967C13.7626 8.67678 14.2374 8.67678 14.5303 8.96967C14.8232 9.26256 14.8232 9.73744 14.5303 10.0303L11.5303 13.0303C11.2374 13.3232 10.7626 13.3232 10.4697 13.0303L7.46967 10.0303C7.17678 9.73744 7.17678 9.26256 7.46967 8.96967Z'
                      fill='currentColor' />
            </svg>

        </button>
        <ul *hlMenuItems @toggleAnimation class='whitespace-nowrap ltr:right-0 rtl:left-0'>
            <li *ngFor='let client of clients'>
                <a [routerLink]='["/dashboard/client/view/", client.id]'>{{client.name}}</a>
            </li>
        </ul>
    </div>
</ng-container>

<ng-template #oneClient>
    <button class='btn rounded-none border-0 p-0 text-black shadow-none hover:text-primary'
            (click)='clientDetail(clients[0].id)'
            type='button' ngxTippy
            data-tippy-content='Client detail'>
        <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fill-rule='evenodd' clip-rule='evenodd'
                  d='M9 6.25C7.48122 6.25 6.25 7.48122 6.25 9C6.25 10.5188 7.48122 11.75 9 11.75C10.5188 11.75 11.75 10.5188 11.75 9C11.75 7.48122 10.5188 6.25 9 6.25ZM7.75 9C7.75 8.30965 8.30965 7.75 9 7.75C9.69036 7.75 10.25 8.30965 10.25 9C10.25 9.69036 9.69036 10.25 9 10.25C8.30965 10.25 7.75 9.69036 7.75 9Z'
                  fill='currentColor' />
            <path fill-rule='evenodd' clip-rule='evenodd'
                  d='M9 12.25C7.80424 12.25 6.68461 12.4907 5.83616 12.915C5.03258 13.3168 4.25 14.0106 4.25 15L4.24987 15.0625C4.24834 15.5728 4.24576 16.4322 5.06023 17.0218C5.43818 17.2953 5.9369 17.4698 6.55469 17.581C7.1782 17.6932 7.97721 17.75 9 17.75C10.0228 17.75 10.8218 17.6932 11.4453 17.581C12.0631 17.4698 12.5618 17.2953 12.9398 17.0218C13.7542 16.4322 13.7517 15.5728 13.7501 15.0625L13.75 15C13.75 14.0106 12.9674 13.3168 12.1638 12.915C11.3154 12.4907 10.1958 12.25 9 12.25ZM5.75 15C5.75 14.8848 5.86285 14.5787 6.50698 14.2566C7.10625 13.957 7.98662 13.75 9 13.75C10.0134 13.75 10.8937 13.957 11.493 14.2566C12.1371 14.5787 12.25 14.8848 12.25 15C12.25 15.6045 12.2115 15.6972 12.0602 15.8067C11.9382 15.895 11.6869 16.0134 11.1797 16.1047C10.6782 16.1949 9.97721 16.25 9 16.25C8.02279 16.25 7.3218 16.1949 6.82031 16.1047C6.31311 16.0134 6.06182 15.895 5.93977 15.8067C5.78849 15.6972 5.75 15.6045 5.75 15Z'
                  fill='currentColor' />
            <path
                d='M19 12.75C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H15C14.5858 11.25 14.25 11.5858 14.25 12C14.25 12.4142 14.5858 12.75 15 12.75H19Z'
                fill='currentColor' />
            <path
                d='M19.75 9C19.75 9.41422 19.4142 9.75 19 9.75H14C13.5858 9.75 13.25 9.41422 13.25 9C13.25 8.58579 13.5858 8.25 14 8.25H19C19.4142 8.25 19.75 8.58579 19.75 9Z'
                fill='currentColor' />
            <path
                d='M19 15.75C19.4142 15.75 19.75 15.4142 19.75 15C19.75 14.5858 19.4142 14.25 19 14.25H16C15.5858 14.25 15.25 14.5858 15.25 15C15.25 15.4142 15.5858 15.75 16 15.75H19Z'
                fill='currentColor' />
            <path fill-rule='evenodd' clip-rule='evenodd'
                  d='M9.94358 3.25H14.0564C15.8942 3.24998 17.3498 3.24997 18.489 3.40314C19.6614 3.56076 20.6104 3.89288 21.3588 4.64124C22.1071 5.38961 22.4392 6.33856 22.5969 7.51098C22.75 8.65018 22.75 10.1058 22.75 11.9435V12.0564C22.75 13.8942 22.75 15.3498 22.5969 16.489C22.4392 17.6614 22.1071 18.6104 21.3588 19.3588C20.6104 20.1071 19.6614 20.4392 18.489 20.5969C17.3498 20.75 15.8942 20.75 14.0565 20.75H9.94359C8.10585 20.75 6.65018 20.75 5.51098 20.5969C4.33856 20.4392 3.38961 20.1071 2.64124 19.3588C1.89288 18.6104 1.56076 17.6614 1.40314 16.489C1.24997 15.3498 1.24998 13.8942 1.25 12.0564V11.9436C1.24998 10.1058 1.24997 8.65019 1.40314 7.51098C1.56076 6.33856 1.89288 5.38961 2.64124 4.64124C3.38961 3.89288 4.33856 3.56076 5.51098 3.40314C6.65019 3.24997 8.10583 3.24998 9.94358 3.25ZM5.71085 4.88976C4.70476 5.02503 4.12511 5.27869 3.7019 5.7019C3.27869 6.12511 3.02503 6.70476 2.88976 7.71085C2.75159 8.73851 2.75 10.0932 2.75 12C2.75 13.9068 2.75159 15.2615 2.88976 16.2892C3.02503 17.2952 3.27869 17.8749 3.7019 18.2981C4.12511 18.7213 4.70476 18.975 5.71085 19.1102C6.73851 19.2484 8.09318 19.25 10 19.25H14C15.9068 19.25 17.2615 19.2484 18.2892 19.1102C19.2952 18.975 19.8749 18.7213 20.2981 18.2981C20.7213 17.8749 20.975 17.2952 21.1102 16.2892C21.2484 15.2615 21.25 13.9068 21.25 12C21.25 10.0932 21.2484 8.73851 21.1102 7.71085C20.975 6.70476 20.7213 6.12511 20.2981 5.7019C19.8749 5.27869 19.2952 5.02503 18.2892 4.88976C17.2615 4.75159 15.9068 4.75 14 4.75H10C8.09318 4.75 6.73851 4.75159 5.71085 4.88976Z'
                  fill='currentColor' />
        </svg>
    </button>
</ng-template>

import { Component, Input, OnInit } from '@angular/core';
import { PropertyService } from '../../../../services/property.service';
import { CompanyService } from '../../../../services/company.service';
import { first } from 'rxjs';
import { CompanyProfile } from '../../../../../dashboard/company/company.type';

@Component({
    selector: 'app-investor-company-select',
    templateUrl: './investor-company-select.component.html',
})
export class InvestorCompanySelectComponent implements OnInit {
    @Input() public investorCompany: CompanyProfile | undefined;
    @Input() public propertyId: number;
    @Input() public investorIds: number[];

    public selectedCompanyId: number;
    public loading = false;

    public options: { id: number; name: string }[] = [
        {
            id: 0,
            name: 'Individual',
        },
    ];

    public constructor(
        private readonly propertyService: PropertyService,
        private readonly companyService: CompanyService,
    ) {}

    public ngOnInit() {
        this.setOptions(this.investorCompany ? [this.investorCompany] : []);
        this.selectedCompanyId = this.investorCompany?.id ?? 0;
    }

    public update() {
        this.propertyService
            .updateProperty(this.propertyId, null, {
                investor_company_id: this.selectedCompanyId === 0 ? null : this.selectedCompanyId,
            })
            .subscribe();
    }

    private setOptions(companies: CompanyProfile[]) {
        const arr: { id: number; name: string }[] = [{ id: 0, name: 'None' }];
        const _companies = companies.map(company => ({
            id: company.id,
            name: company.name,
        }));

        this.options = arr.concat(_companies);
    }

    public companyListRequest() {
        this.options = [];
        this.loading = true;
        this.companyService
            .investorCompaniesByInvestorIds(this.investorIds)
            .pipe(first())
            .subscribe(companies => {
                this.setOptions(companies);
                this.loading = false;
            });
    }
}

import { Component, Input } from '@angular/core';
import { Investor } from '../../../../type';

@Component({
    selector: 'app-property-shared-ownership',
    templateUrl: './property-shared-ownership.component.html',
})
export class PropertySharedOwnershipComponent {
    @Input()
    public investors: Investor[];
}

import { createAction, props } from '@ngrx/store';

const toggleSidebar = createAction('[DASHBOARD] sidebar toggled');

const setMainLoader = createAction('[DASHBOARD] main loader toggled', props<{ state: boolean }>());
const setPrevURL = createAction('[DASHBOARD] prevURL set', props<{ url: string }>());
const requestContactRequiredCount = createAction('[DASHBOARD] contact required count requested');
const setContactRequiredCount = createAction('[DASHBOARD] contact required count set', props<{ count: number }>());

export const dashboardActions = {
    toggleSidebar,
    setMainLoader,
    setPrevURL,
    setContactRequiredCount,
    requestContactRequiredCount,
};

<div class='overflow-auto h-[50vh]'>
    <ng-container *ngIf='(notes$ | async)?.length; else NoRecords'>
        <div *ngFor='let note of (notes$ | async)' class='mt-5 grid grid-cols-12 gap-6'>

            <div class='col-span-10'>
                <p class='font-bold'>Broker: <span class='font-thin'>{{note.brokerName}}</span></p>
                <p *ngIf='updatingNoteId !== note.id' class='mt-3 whitespace-pre-wrap'>{{note.text}}</p>
                <div *ngIf='updatingNoteId === note.id' class='mt-3 flex flex-row items-end gap-6'>
                    <textarea maxlength='1000' class='form-input' [(ngModel)]='updatingNoteText'></textarea>
                    <div>
                        <button
                            [disabled]='updatingNoteText === note.text || updatingNoteText?.length === 0 || isDisableControls'
                            (click)='finishUpdateNote()' type='button' class='btn btn-primary ltr:ml-4 rtl:mr-4'>Update
                        </button>
                    </div>
                </div>
            </div>

            <div class='col-span-2'>
                <div class='flex flex-row gap-6'>
                    <div class='font-bold'>{{note.created_at | date :'dd-MM-yyyy'}}</div>
                    <div hlMenu class='dropdown'>
                        <a hlMenuButton href='javascript:;'>
                            <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                class='h-5 w-5 rotate-90 opacity-70'
                            >
                                <circle cx='5' cy='12' r='2' stroke='currentColor' stroke-width='1.5'></circle>
                                <circle opacity='0.5' cx='12' cy='12' r='2' stroke='currentColor'
                                        stroke-width='1.5'></circle>
                                <circle cx='19' cy='12' r='2' stroke='currentColor' stroke-width='1.5'></circle>
                            </svg>
                        </a>
                        <ul *hlMenuItems @toggleAnimation class='w-max ltr:right-0 rtl:left-0'>
                            <li>
                                <a href='javascript:;' (click)='startUpdate(note)'>
                                    <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                        class='h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2'
                                    >
                                        <path
                                            opacity='0.5'
                                            d='M4 22H20'
                                            stroke='currentColor'
                                            stroke-width='1.5'
                                            stroke-linecap='round'
                                        />
                                        <path
                                            d='M14.6296 2.92142L13.8881 3.66293L7.07106 10.4799C6.60933 10.9416 6.37846 11.1725 6.17992 11.4271C5.94571 11.7273 5.74491 12.0522 5.58107 12.396C5.44219 12.6874 5.33894 12.9972 5.13245 13.6167L4.25745 16.2417L4.04356 16.8833C3.94194 17.1882 4.02128 17.5243 4.2485 17.7515C4.47573 17.9787 4.81182 18.0581 5.11667 17.9564L5.75834 17.7426L8.38334 16.8675L8.3834 16.8675C9.00284 16.6611 9.31256 16.5578 9.60398 16.4189C9.94775 16.2551 10.2727 16.0543 10.5729 15.8201C10.8275 15.6215 11.0583 15.3907 11.5201 14.929L11.5201 14.9289L18.3371 8.11195L19.0786 7.37044C20.3071 6.14188 20.3071 4.14999 19.0786 2.92142C17.85 1.69286 15.8581 1.69286 14.6296 2.92142Z'
                                            stroke='currentColor'
                                            stroke-width='1.5'
                                        />
                                        <path
                                            opacity='0.5'
                                            d='M13.8879 3.66406C13.8879 3.66406 13.9806 5.23976 15.3709 6.63008C16.7613 8.0204 18.337 8.11308 18.337 8.11308M5.75821 17.7437L4.25732 16.2428'
                                            stroke='currentColor'
                                            stroke-width='1.5'
                                        />
                                    </svg>

                                    Edit
                                </a>
                            </li>
                            <li>
                                <a href='javascript:;' (click)='deleteNote(note.id)'>
                                    <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                        class='h-5 w-5 shrink-0 ltr:mr-2 rtl:ml-2'
                                    >
                                        <path
                                            d='M20.5001 6H3.5'
                                            stroke='currentColor'
                                            stroke-width='1.5'
                                            stroke-linecap='round'
                                        ></path>
                                        <path
                                            d='M18.8334 8.5L18.3735 15.3991C18.1965 18.054 18.108 19.3815 17.243 20.1907C16.378 21 15.0476 21 12.3868 21H11.6134C8.9526 21 7.6222 21 6.75719 20.1907C5.89218 19.3815 5.80368 18.054 5.62669 15.3991L5.16675 8.5'
                                            stroke='currentColor'
                                            stroke-width='1.5'
                                            stroke-linecap='round'
                                        ></path>
                                        <path
                                            opacity='0.5'
                                            d='M9.5 11L10 16'
                                            stroke='currentColor'
                                            stroke-width='1.5'
                                            stroke-linecap='round'
                                        ></path>
                                        <path
                                            opacity='0.5'
                                            d='M14.5 11L14 16'
                                            stroke='currentColor'
                                            stroke-width='1.5'
                                            stroke-linecap='round'
                                        ></path>
                                        <path
                                            opacity='0.5'
                                            d='M6.5 6C6.55588 6 6.58382 6 6.60915 5.99936C7.43259 5.97849 8.15902 5.45491 8.43922 4.68032C8.44784 4.65649 8.45667 4.62999 8.47434 4.57697L8.57143 4.28571C8.65431 4.03708 8.69575 3.91276 8.75071 3.8072C8.97001 3.38607 9.37574 3.09364 9.84461 3.01877C9.96213 3 10.0932 3 10.3553 3H13.6447C13.9068 3 14.0379 3 14.1554 3.01877C14.6243 3.09364 15.03 3.38607 15.2493 3.8072C15.3043 3.91276 15.3457 4.03708 15.4286 4.28571L15.5257 4.57697C15.5433 4.62992 15.5522 4.65651 15.5608 4.68032C15.841 5.45491 16.5674 5.97849 17.3909 5.99936C17.4162 6 17.4441 6 17.5 6'
                                            stroke='currentColor'
                                            stroke-width='1.5'
                                        ></path>
                                    </svg>
                                    Delete
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </ng-container>
    <ng-template #NoRecords>
        <p class='text-3xl text-center mt-14'>No records yet</p>
    </ng-template>
</div>

<hr class='my-6 border-[#e0e6ed]' />

<div class='flex items-end flex-row gap-12'>
    <textarea maxlength='1000' class='form-input h-24' [(ngModel)]='newNote'></textarea>
    <div class='flex items-end'>
        <button [disabled]='isDisableControls || newNote.length === 0' type='button'
                (click)='create()'
                class='btn btn-primary ltr:ml-4 rtl:mr-4'>
            Post
        </button>
    </div>
</div>

<div *ngIf='(priority4Chart$ | async)'>
    <div
        class='panel flex flex-row gap-4 w-auto justify-between items-center overflow-x-auto whitespace-nowrap p-3 lg:col-span-2'>
        <h5 ngxTippy='The total number of clients who have downloaded the app, and includes existing clients and new clients'
            [tippyProps]="{placement: 'right'}"
            class='text-2xl'>Total App Users</h5>
        <div class='text-xl flex flex-row gap-28 justify-between col-end-2'>
            <p class='text-xl font-bold'>
                {{(appUsers$ | async)?.count | number}}
            </p>
            <p ngxTippy='Total number of App users as a percentage of the total number of client records in the system'
               [tippyProps]="{placement: 'left'}" class='text-xl font-bold'>
                {{(appUsers$ | async)?.pct | number}}% of total
            </p>
        </div>
    </div>
</div>

<div *ngIf='(priority4Chart$ | async)' class='mt-6 mb-6 h-auto grid grid-cols-2 gap-6'>
    <div class='panel h-min p-0'>
        <div
            class='mb-5 flex items-start justify-between border-b border-[#e0e6ed] p-5 dark:border-[#1b2e4b] dark:text-white-light'>
            <h5 class='text-lg font-semibold'>Priority 5</h5>
        </div>

        <div class='overflow-hidden'>
            <apx-chart *ngIf='(priority5Chart$ | async)'
                       [xaxis]='(priority5Chart$ | async)!.xaxis'
                       [series]='(priority5Chart$ | async)!.series'

                       [chart]='chartService.apexConfigs.chart'
                       [grid]='chartService.apexConfigs.grid'
                       [stroke]='chartService.apexConfigs.stroke'
                       [dataLabels]='chartService.apexConfigs.labels'
                       [colors]='chartService.apexConfigs.colors'
                       [plotOptions]='chartService.apexConfigs.plotOptions'
                       [legend]='chartService.apexConfigs.legend'
                       [yaxis]='chartService.apexConfigs.yaxis'
                       [fill]='chartService.apexConfigs.fill'
                       [tooltip]='chartService.apexConfigs.tooltip'

                       class='rounded-lg bg-white dark:bg-black'
            >

                <div *ngIf='(priority5Chart$ | async) === null'
                     class='grid min-h-[360px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]'>
                        <span
                            class='inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white'></span>
                </div>
            </apx-chart>
        </div>

    </div>

    <div class='panel h-full p-0'>
        <div
            class='mb-5 flex items-start justify-between border-b border-[#e0e6ed] p-5 dark:border-[#1b2e4b] dark:text-white-light'>
            <h5 class='text-lg font-semibold'>Priority 4</h5>
        </div>

        <div class='overflow-hidden'>
            <apx-chart *ngIf='(priority4Chart$ | async)'
                       [xaxis]='(priority4Chart$ | async)!.xaxis'
                       [series]='(priority4Chart$ | async)!.series'

                       [chart]='chartService.apexConfigs.chart'
                       [grid]='chartService.apexConfigs.grid'
                       [stroke]='chartService.apexConfigs.stroke'
                       [dataLabels]='chartService.apexConfigs.labels'
                       [colors]='chartService.apexConfigs.colors'
                       [plotOptions]='chartService.apexConfigs.plotOptions'
                       [legend]='chartService.apexConfigs.legend'
                       [yaxis]='chartService.apexConfigs.yaxis'
                       [fill]='chartService.apexConfigs.fill'
                       [tooltip]='chartService.apexConfigs.tooltip'

                       class='rounded-lg bg-white dark:bg-black'
            >

                <div *ngIf='(priority4Chart$ | async) === null'
                     class='grid min-h-[360px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]'>
                        <span
                            class='inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white'></span>
                </div>
            </apx-chart>

        </div>

    </div>
</div>

<div *ngIf='(priority4Chart$ | async)' class='grid grid-cols-6 gap-6'>
    <div class='panel'>
        <div class='flex flex-col flex-wrap justify-between gap-6 lg:flex-row'>
            <div
                ngxTippy='The average value of mortgages written in the last 2 years'
                [tippyProps]="{placement: 'right'}" class='font-bold'>Average Mortgage Value
            </div>
            <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />
            <div class='mb-2 flex w-full'>
                <div class='text-center text-2xl text-success'>£ {{(totalData$ | async)?.propertiesAvg | number}}</div>
            </div>
        </div>
    </div>
    <div class='panel'>
        <div class='flex flex-col flex-wrap justify-between gap-6 lg:flex-row'>
            <div
                ngxTippy='This is a static calculation and uses the formula:
                          Commission = 0.4% of Average Mortgage Value
                          Fees = £300 (our future release will have the ability for you to input your own)
'
                [tippyProps]="{placement: 'right'}" class='font-bold'>Average Client Revenue
            </div>
            <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />
            <div class='mb-2 flex w-full'>
                <div class='text-center text-2xl text-success'>
                    £ {{(totalData$ | async)?.clientRevenue | number}}</div>
            </div>
        </div>
    </div>
    <div class='col-span-4'>
        <div
            class='panel flex flex-row gap-4 w-auto justify-between items-center overflow-x-auto whitespace-nowrap p-3 lg:col-span-2'>
            <h5 ngxTippy='The total number of your client records in the system'
                [tippyProps]="{placement: 'left'}" class='text-2xl'>Total Records</h5>
            <div class='text-xl'>
                <p class='text-xl font-bold'>
                    {{(totalData$ | async)?.properties}}
                </p>
            </div>
        </div>
        <div class='grid grid-cols-2 gap-6 h-max mt-6'>
            <div class='panel'>
                <div class='flex flex-wrap justify-between gap-6 flex-row'>
                    <div class='text-xl font-semibold uppercase'>Priority 5</div>
                    <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div ngxTippy='The total number of client records within the Priority YTD'
                             [tippyProps]="{placement: 'left'}" class='text-white-dark'>Available :
                        </div>
                        <div class=''>{{(priority5Chart$ | async)?.count}}</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div
                            ngxTippy='The total number of mortgage application submitted client records in the priority as a percentage of the total number of client records within the Priority YTD'
                            [tippyProps]="{placement: 'left'}" class='text-white-dark'>Submitted :
                        </div>
                        <div class=''>{{(priority5Chart$ | async)?.pct}}%</div>
                    </div>
                </div>
            </div>
            <div class='panel'>
                <div class='flex flex-wrap justify-between gap-6 flex-row'>
                    <div class='text-xl font-semibold uppercase'>Priority 4</div>
                    <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div ngxTippy='The total number of client records within the Priority YTD'
                             [tippyProps]="{placement: 'left'}" class='text-white-dark'>Available :
                        </div>
                        <div class=''>{{(priority4Chart$ | async)?.count}}</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div
                            ngxTippy='The total number of mortgage application submitted client records in the priority as a percentage of the total number of client records within the Priority YTD'
                            [tippyProps]="{placement: 'left'}" class='text-white-dark'>Submitted :
                        </div>
                        <div class=''>{{(priority4Chart$ | async)?.pct}}%</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf='(priority4Chart$ | async)'>
    <div
        class='panel flex mt-6 flex-row gap-4 w-auto justify-between items-center overflow-x-auto whitespace-nowrap p-3 lg:col-span-2'>
        <h5 class='text-2xl'>Retention Rates</h5>
    </div>
</div>

<div *ngIf='(priority4Chart$ | async)' class='grid grid-cols-3 gap-3 mt-6'>
    <div class='panel'>
        <div class=''>
            <div
                ngxTippy='The total number of mortgage application submitted client records in the priority as a percentage of the total number of client records within the Priority YTD'
                [tippyProps]="{placement: 'left'}" class='text-xl font-semibold uppercase'>Total Retention Rates
            </div>
            <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />
            <div class=''>
                <div class='flex items-center mt-6'>
                    <div *ngIf='(rentData$ | async) as dataset' class='flex-1'>
                        <div class='mb-2 flex font-semibold text-white-dark'>
                            <h6 ngxTippy='% as calculated by the number of client records whose records are less than 120 days past the Product End Date'
                                [tippyProps]="{placement: 'left'}">Total :</h6>
                            <p class='ltr:ml-auto rtl:mr-auto'>{{dataset.total | number}}%</p>
                        </div>
                        <div class='h-2 w-full rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]'>
                            <div class='h-full w-full rounded-full bg-gradient-to-r from-[#f09819] to-[#ff5858]'
                                 [style]='{width: dataset.total+"%"}'></div>
                        </div>
                    </div>
                </div>
                <div class='flex items-center mt-6'>
                    <div *ngIf='(monthRate$ | async) as dataset' class='flex-1'>
                        <div class='mb-2 flex font-semibold text-white-dark'>
                            <h6 ngxTippy='The retention rate as per 3 months previously'
                                [tippyProps]="{placement: 'left'}">3 Months :</h6>
                            <p class='ltr:ml-auto rtl:mr-auto'>{{dataset.total | number}}%</p>
                        </div>
                        <div class='h-2 rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]'>
                            <div class='h-full w-full rounded-full bg-gradient-to-r from-[#7579ff] to-[#b224ef]'
                                 [style]='{width: dataset.total+"%"}'></div>
                        </div>
                    </div>
                </div>
                <div class='flex items-center mt-6'>
                    <div *ngIf='(variance$ | async) as dataset' class='flex-1'>
                        <div class='mb-2 flex font-semibold text-white-dark'>
                            <h6 ngxTippy='The change in retention rate between the current retention rate and the 3 months previous retention rate'
                                [tippyProps]="{placement: 'left'}">Variance :</h6>
                            <p class='ltr:ml-auto rtl:mr-auto'>{{dataset.total | number}}%</p>
                        </div>
                        <div class='h-2 w-full rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]'>
                            <div class='h-full w-full rounded-full bg-gradient-to-r from-[#3cba92] to-[#0ba360]'
                                 [style]='{width: dataset.total * 10 +"%"}'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class='panel'>
        <div class=''>
            <div class='text-xl font-semibold uppercase'>Residential</div>
            <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />
            <div class=''>
                <div class='flex items-center mt-6'>
                    <div *ngIf='(rentData$ | async) as dataset' class='flex-1'>
                        <div class='mb-2 flex font-semibold text-white-dark'>
                            <h6>Total :</h6>
                            <p class='ltr:ml-auto rtl:mr-auto'>{{dataset.residential | number}}%</p>
                        </div>
                        <div class='h-2 w-full rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]'>
                            <div class='h-full w-full rounded-full bg-gradient-to-r from-[#f09819] to-[#ff5858]'
                                 [style]='{width: dataset.residential+"%"}'></div>
                        </div>
                    </div>
                </div>
                <div class='flex items-center mt-6'>
                    <div *ngIf='(monthRate$ | async) as dataset' class='flex-1'>
                        <div class='mb-2 flex font-semibold text-white-dark'>
                            <h6>3 Months :</h6>
                            <p class='ltr:ml-auto rtl:mr-auto'>{{dataset.residential | number}}%</p>
                        </div>
                        <div class='h-2 rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]'>
                            <div class='h-full w-full rounded-full bg-gradient-to-r from-[#7579ff] to-[#b224ef]'
                                 [style]='{width: dataset.residential+"%"}'></div>
                        </div>
                    </div>
                </div>
                <div class='flex items-center mt-6'>
                    <div *ngIf='(variance$ | async) as dataset' class='flex-1'>
                        <div class='mb-2 flex font-semibold text-white-dark'>
                            <h6>Variance :</h6>
                            <p class='ltr:ml-auto rtl:mr-auto'>{{dataset.residential | number}}%</p>
                        </div>
                        <div class='h-2 w-full rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]'>
                            <div class='h-full w-full rounded-full bg-gradient-to-r from-[#3cba92] to-[#0ba360]'
                                 [style]='{width: dataset.residential * 10 +"%"}'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class='panel'>
        <div class=''>
            <div class='text-xl font-semibold uppercase'>Buy To Let</div>
            <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />

            <div class=''>
                <div class='flex items-center mt-6'>
                    <div *ngIf='(rentData$ | async) as dataset' class='flex-1'>
                        <div class='mb-2 flex font-semibold text-white-dark'>
                            <h6>Total :</h6>
                            <p class='ltr:ml-auto rtl:mr-auto'>{{dataset.investment | number}}%</p>
                        </div>
                        <div class='h-2 w-full rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]'>
                            <div class='h-full w-full rounded-full bg-gradient-to-r from-[#f09819] to-[#ff5858]'
                                 [style]='{width: dataset.investment+"%"}'></div>
                        </div>
                    </div>
                </div>
                <div class='flex items-center mt-6'>
                    <div *ngIf='(monthRate$ | async) as dataset' class='flex-1'>
                        <div class='mb-2 flex font-semibold text-white-dark'>
                            <h6>3 Months :</h6>
                            <p class='ltr:ml-auto rtl:mr-auto'>{{dataset.investment | number}}%</p>
                        </div>
                        <div class='h-2 rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]'>
                            <div class='h-full w-full rounded-full bg-gradient-to-r from-[#7579ff] to-[#b224ef]'
                                 [style]='{width: dataset.investment+"%"}'></div>
                        </div>
                    </div>
                </div>
                <div class='flex items-center mt-6'>
                    <div *ngIf='(variance$ | async) as dataset' class='flex-1'>
                        <div class='mb-2 flex font-semibold text-white-dark'>
                            <h6>Variance :</h6>
                            <p class='ltr:ml-auto rtl:mr-auto'>{{dataset.investment | number}}%</p>
                        </div>
                        <div class='h-2 w-full rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]'>
                            <div class='h-full w-full rounded-full bg-gradient-to-r from-[#3cba92] to-[#0ba360]'
                                 [style]='{width: dataset.investment * 10 +"%"}'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    *ngIf='(priority4Chart$ | async) === null'
    class='h-screen flex flex-col items-center justify-center inset-0 bg-[#fafafa] dark:bg-[#060818] z-[60] grid place-content-center animate__animated'
>
    <svg width='64' height='64' viewBox='0 0 135 135' xmlns='http://www.w3.org/2000/svg' fill='#4361ee'>
        <path
            d='M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='-360 67 67' dur='2.5s'
                              repeatCount='indefinite' />
        </path>
        <path
            d='M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='360 67 67' dur='8s'
                              repeatCount='indefinite' />
        </path>
    </svg>
</div>

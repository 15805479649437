import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Company, CompanyProfile } from '../../dashboard/company/company.type';
import { Meta } from '../type';
import { PropertyService } from './property.service';

@Injectable()
export class CompanyService {
    public constructor(private http: HttpClient) {}

    public companyInfoRequest(companyId: number): Observable<Company> {
        return this.http
            .get<{ data: Company; status: string }>(`${env.baseServerUrl}/broker-companies/${companyId}`)
            .pipe(map(response => response.data));
    }

    public investorCompanyListRequest(
        page: number,
        perPage: number,
        filter?: string,
    ): Observable<{
        companies: CompanyProfile[];
        meta: Meta;
    }> {
        let url = `${env.baseServerUrl}/investor-companies?page=${page}&per_page=${perPage}`;
        if (filter) url = url + `&${filter}`;
        return this.http
            .get<{
                data: CompanyProfile[];
                meta: Meta;
            }>(url)
            .pipe(map(response => ({ companies: response.data, meta: response.meta })));
    }

    public investorCompaniesByInvestorIds(investorIds: number[]): Observable<CompanyProfile[]> {
        const filter = PropertyService.filterMap(
            investorIds.map(id => ({ filter: { investors: { id: { eq: id } } } })),
        );
        return this.investorCompanyListRequest(1, 10, filter).pipe(map(list => list.companies));
    }

    public investorCompanyCreate(data: unknown): Observable<CompanyProfile> {
        return this.http
            .post<{ data: CompanyProfile }>(`${env.baseServerUrl}/investor-companies`, data)
            .pipe(map(response => response.data));
    }

    public investorCompanyUpdate(companyId: number, data: unknown): Observable<CompanyProfile> {
        return this.http
            .patch<{ data: CompanyProfile }>(`${env.baseServerUrl}/investor-companies/${companyId}`, data)
            .pipe(map(response => response.data));
    }
}

import { Component, OnInit } from '@angular/core';
import { combineLatest, first, map, ReplaySubject, switchMap } from 'rxjs';
import { Investor, Property, TableMetaOptions } from '../../type';
import { DataTableService } from '../../services/data-table.service';
import { ActivatedRoute } from '@angular/router';
import { DataPropertyService } from '../../services/data-property.service';
import { BrokerService } from '../../services/broker.service';
import { BrokerPerformanceAnalytic } from '../../../dashboard/insight/insight.type';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { propertySelectors } from '../../store/property/property.selectors';
import { propertyNode, PropertyState } from '../../store/property/property.store';
import { brokerNode, BrokerState } from '../../store/broker/broker.store';
import { tableSelectors } from '../../store/table/table.selectors';
import { tableNode, TableState } from '../../store/table/table.store';
import { tableActions } from '../../store/table/table.actions';
import { propertyActions } from '../../store/property/property.actions';
import { DashboardService } from '../../../dashboard/dashboard.service';

@Component({
    selector: 'app-broker-performance-view',
    templateUrl: './broker-performance-view.component.html',
    styleUrls: ['./broker-performance-view.component.scss'],
})
export class BrokerPerformanceViewComponent implements OnInit {
    public priorities: BrokerPerformanceAnalytic[] = [];
    public selectedPriority: BrokerPerformanceAnalytic | undefined;

    public selectedPriorityNumber: 4 | 5 = 5;
    public servicesFilter = '-';

    public options = [4, 5];
    public optionsServicesFilter = [
        { id: '-', name: '-' },
        { id: 'product_end_term', name: 'Product Expiry' },
        { id: 'rate_riser', name: 'Rate Riser' },
        { id: 'early_review', name: 'Early Review' },
        { id: 'equity_available', name: 'Equity Available' },
    ];

    public readonly defaultFilter = (clientId: number) => ({
        filter: {
            brokers: { id: { in: clientId } },
        },
    });

    public priorityFilters = {
        '-': {
            filter: (val: string) => ({
                filter_any: {
                    leadgen: {
                        product_end_term: { rating: { in: val } },
                        equity_available: { rating: { in: val } },
                        rate_riser: { rating: { in: val } },
                        early_review: { rating: { in: val } },
                    },
                },
            }),
            value: [this.selectedPriorityNumber],
        },
        product_end_term: {
            filter: (val: string) => ({
                filter: {
                    leadgen: {
                        product_end_term: { rating: { in: val } },
                    },
                },
            }),
            value: [this.selectedPriorityNumber],
        },
        rate_riser: {
            filter: (val: string) => ({
                filter: {
                    leadgen: {
                        rate_riser: { rating: { in: val } },
                    },
                },
            }),
            value: [this.selectedPriorityNumber],
        },
        early_review: {
            filter: (val: string) => ({
                filter: {
                    leadgen: {
                        early_review: { rating: { in: val } },
                    },
                },
            }),
            value: [this.selectedPriorityNumber],
        },
        equity_available: {
            filter: (val: string) => ({
                filter: {
                    leadgen: {
                        equity_available: { rating: { in: val } },
                    },
                },
            }),
            value: [this.selectedPriorityNumber],
        },
    };

    public customFilter$: ReplaySubject<unknown> = new ReplaySubject<unknown>(1);

    public cols = [
        { field: 'clients', title: 'Client(s)', sort: false },
        { field: 'address', title: 'Address' },
        { field: 'lenderName', title: 'Lender Name', hide: true },
        { field: 'purchasePrice', title: 'Purchase Price', hide: true },
        { field: 'purchaseDate', title: 'Purchase Date', hide: true },
        { field: 'type', title: 'Type' },
        { field: 'expiryDate', title: 'Expiry Date', type: 'date' },
        { field: 'contactRemainingDays', title: 'Lender Contact', type: 'number' },
        { field: 'broker', title: 'Broker' },
        { field: 'status', title: 'Status' },
        {
            field: 'client-property-actions',
            title: 'Action',
            sort: false,
            headerClass: 'justify-center',
        },
    ];

    public investors$ = this.store.pipe(select(propertySelectors.selectInvestors));
    public properties$ = this.store.pipe(select(propertySelectors.selectProperties));
    public tableState$ = this.store.pipe(select(tableSelectors.selectTableData));

    public brokersTableSelectors: number[] = [];

    public rows$ = combineLatest([this.properties$, this.investors$]).pipe(
        map(([properties, investors]: [Property[], Investor[]]) =>
            properties.map((property, key) => ({
                key,
                id: property.id,
                clients: investors.filter(client => property.investor_ids.includes(client.id)),
                address: property.address,
                type: DataPropertyService.getInvestmentType(property.investment_type),
                expiryDate: property.mortgages[0]?.product_end_term,
                contactRemainingDays: property.mortgages[0]?.lender_contact_remaining_days,
                broker: this.createBrokerTableSelector(property.id, property.broker_ids[0]),
                status: property.activity_status,
                investmentType: property.investment_type.toLowerCase(),
                statusBadge: DataTableService.statusBadge(property.activity_status),
                notes: property.notes,
            })),
        ),
    );

    private createBrokerTableSelector(propertyId: number, brokerId: number): number {
        this.brokersTableSelectors[propertyId] = brokerId;
        return propertyId;
    }

    public constructor(
        private readonly dashboardService: DashboardService,
        private readonly brokerService: BrokerService,
        private readonly datatableService: DataTableService,
        public readonly location: Location,
        private readonly route: ActivatedRoute,
        private readonly store: Store<{
            [propertyNode]: PropertyState;
            [brokerNode]: BrokerState;
            [tableNode]: TableState;
        }>,
    ) {}

    public ngOnInit() {
        const brokerId$ = this.route.params.pipe(
            map(params => Number(params['id'])),
            first(),
        );

        const brokerAnalytic$ = brokerId$.pipe(switchMap(id => this.brokerService.brokersDrillDownRequest(id)));

        brokerAnalytic$.pipe(first()).subscribe(brokerAnalytic => {
            this.selectedPriorityNumber = 5;
            this.customFilter$.next(this.defaultFilter(brokerAnalytic.rate4.broker.id));
            this.priorities[4] = brokerAnalytic.rate4;
            this.priorities[5] = brokerAnalytic.rate5;
            this.selectedPriority = this.priorities[this.selectedPriorityNumber];
            this.tableState$.pipe(first()).subscribe(state => {
                const _state = { ...state, priorityFilter: this.priorityFilters[this.servicesFilter] };
                this.store.dispatch(tableActions.updateState({ state: _state }));
            });
        });
    }

    public priorityChanged(): void {
        this.selectedPriority = this.priorities[this.selectedPriorityNumber];
        this.changeFilter();
    }

    public serviceChanged(): void {
        const brokerId = this.selectedPriority.broker.id;
        this.selectedPriority = undefined;
        const params = this.servicesFilter == '-' ? {} : { leadgen: this.servicesFilter };
        this.brokerService
            .brokersDrillDownRequest(brokerId, params)
            .pipe(first())
            .subscribe(response => {
                this.priorities[4] = response.rate4;
                this.priorities[5] = response.rate5;
                this.selectedPriority = this.priorities[this.selectedPriorityNumber];
                this.changeFilter();
            });
    }

    public changeFilter(): void {
        this.tableState$.pipe(first()).subscribe(state => {
            const _priority = {
                ...this.priorityFilters[this.servicesFilter],
                value: [this.selectedPriorityNumber],
            };
            const _state = { ...state, page: 1, priorityFilter: _priority };
            this.store.dispatch(tableActions.updateState({ state: _state }));
            this.datatableService.updateTableContent();
            this.datatableService.tableReset();
        });
    }

    public updateContent(params: TableMetaOptions) {
        this.store.dispatch(propertyActions.propertiesRequest({ ...params }));
    }

    public redirectToPrevURL(): void {
        this.dashboardService.redirectToPrev('/dashboard/insight/broker-performance');
    }
}

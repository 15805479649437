import { Component } from '@angular/core';
import { DataPropertyService } from '../../../../services/data-property.service';

@Component({
    selector: 'app-property-market-update',
    templateUrl: './property-market-update.component.html',
})
export class PropertyMarketUpdateComponent {
    public constructor(protected readonly dataPropertyService: DataPropertyService) {}
}

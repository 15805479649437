import { FormBuilder, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { ModalComponent } from 'angular-custom-modal/modal.component';
import { PropertyService } from '../services/property.service';
import { DataTableService } from '../services/data-table.service';
import { ModalUpdateModalValues } from '../components/table/table.type';
import { ToastService } from '../services/toast.service';
import { DataPropertyService } from '../services/data-property.service';

@Injectable({
    providedIn: 'root',
})
export abstract class ValueChangeModalAbstract {
    public title: string;
    public apiParamStructure: (val: number | string | boolean) => unknown;

    public form: FormGroup;

    private propertyId: number;
    private mortgageId: number | null = null;
    private _modal: ModalComponent;

    public isDisableControls = false;
    public isSubmitForm = false;

    public status = 0;
    private fieldName: string;

    public constructor(
        protected readonly fb: FormBuilder,
        protected readonly propertyService: PropertyService,
        protected readonly dataTableService: DataTableService,
        protected readonly dataPropertyService: DataPropertyService,
    ) {}

    protected init(fieldName: string, values: ModalUpdateModalValues, modal: ModalComponent) {
        this.fieldName = fieldName;
        this.propertyId = values.propertyId;
        this.mortgageId = values.mortgageId;
        this.title = values.valueName;
        this.apiParamStructure = values.apiRequestStructure;
        this._modal = modal;
        this._modal.open();
    }

    public abstract open({ propertyId, mortgageId, valueName, apiRequestStructure, value }: ModalUpdateModalValues);

    protected getValue(): number | string | boolean {
        return this.form.get(this.fieldName).value;
    }

    public save(): void {
        this.isSubmitForm = true;
        const successResponse = () => {
            this.isDisableControls = false;
            this.dataTableService.updateTableContent();
            this._modal.close();
        };

        const errorResponse = () => {
            this.isDisableControls = false;
            this.form.controls[this.fieldName].setErrors({ server: true });
            ToastService.showToastMessage('color-danger', 'Error');
        };

        if (this.form.valid) {
            this.isDisableControls = true;
            const params = this.apiParamStructure(this.getValue());
            this.propertyService.updateProperty(this.propertyId, this.mortgageId, params).subscribe({
                next: () => successResponse(),
                error: () => errorResponse(),
            });
        }
    }
}

import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from 'angular-custom-modal/modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrokerService } from '../../../../../../shared/services/broker.service';
import { first } from 'rxjs';
import { brokerActions } from '../../../../../../shared/store/broker/broker.actions';
import { Store } from '@ngrx/store';
import { brokerNode, BrokerState } from '../../../../../../shared/store/broker/broker.store';
import { Broker, CreateBrokerRequest } from '../../../../../../shared/type';

@Component({
    selector: 'app-update-broker-modal',
    templateUrl: './update-broker-modal.component.html',
})
export class UpdateBrokerModalComponent {
    public form: FormGroup;
    public emailAlreadyExist = false;
    public broker: Broker;

    @ViewChild('modal') modal: ModalComponent;

    public rolesType = [
        { id: 'broker', name: 'Adviser' },
        { id: 'manager', name: 'Manager' },
    ];

    public isDisableControls = false;

    public isSubmitForm = false;

    public constructor(
        public readonly fb: FormBuilder,
        private readonly brokerService: BrokerService,
        private readonly store: Store<{
            [brokerNode]: BrokerState;
        }>,
    ) {}

    public open(broker: Broker) {
        this.broker = broker;
        this.form = this.fb.group({
            name: [this.broker.name, Validators.required],
            email: [this.broker.email, [Validators.required, Validators.email]],
            phone: [this.broker.phone],
            role: [this.broker.role, [Validators.required]],
            isNeedTSendInviteEmail: [false],
        });
        this.modal.open();
    }

    public submitForm(): void {
        this.isDisableControls = true;
        this.isSubmitForm = true;
        this.emailAlreadyExist = false;
        this.form.valid ? this.send() : (this.isDisableControls = false);
    }

    private send(): void {
        const dataToRequest: CreateBrokerRequest & { phone?: string } = {
            email: this.form.get('email').value,
            name: this.form.get('name').value,
            phone: this.form.get('phone').value ? this.form.get('phone').value : undefined,
            role: this.form.get('role').value,
            send_invite: this.form.get('isNeedTSendInviteEmail').value,
        };

        this.brokerService
            .updateBroker(this.broker.id, dataToRequest)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.form.reset();
                    this.store.dispatch(brokerActions.brokersRequest({ perPage: 100, page: 1 }));
                    this.isDisableControls = false;
                    this.isSubmitForm = false;
                    this.emailAlreadyExist = false;
                    this.modal.close();
                },
                error: response => {
                    if (response.error.errors.email) this.emailAlreadyExist = true;
                    this.isDisableControls = false;
                    this.isSubmitForm = false;
                },
            });
    }

    public generateTitle(): string {
        return this.rolesType.find(item => item.id == this.form.get('role').value)?.name;
    }
}

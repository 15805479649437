import { createAction, props } from '@ngrx/store';
import { AccessToken } from '../../../auth/auth.type';

const reset = createAction('[APP] Reset');
const tokenSet = createAction(
    '[APP] Token has been set',
    props<{ token: AccessToken & { saved_at: number } } | null>(),
);
const tokenSave = createAction('[APP] Token has been saved', props<{ token: AccessToken }>());

export const resetAction = createAction('[APP] Reset action');

export const appActions = {
    tokenSet,
    tokenSave,
    reset,
    resetAction,
};

import { createSelector } from '@ngrx/store';
import { investorNode, InvestorState } from './investor.store';

export const selectFeature = (state: { [investorNode]: InvestorState }) => state[investorNode];

const selectInvestors = createSelector(selectFeature, state => state.investors);

export const investorSelectors = {
    selectInvestors,
};

import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { insightNode, InsightState } from '../store/insight.store';
import { insightSelectors } from '../store/insight.selectors';
import { insightActions } from '../store/insight.actions';
import { filter, first } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppUsersResponse } from '../insight.type';
import { ApexAxisChartSeries, ApexChart, ApexXAxis } from 'ng-apexcharts';
import { ChartService } from '../../../shared/services/chart.service';

@Component({
    selector: 'app-users',
    templateUrl: './app-users.component.html',
})
export class AppUsersComponent implements OnInit {
    public appUsers$ = this.store.pipe(
        select(insightSelectors.selectAppUsers$),
        filter(data => data != null),
        first(),
        map(data => ({
            tableDataset: this.createTableDataset(data),
            chartDataset: this.createDatasetForChart(data),
        })),
    );

    public constructor(
        public readonly chartService: ChartService,
        private readonly store: Store<{
            [insightNode]: InsightState;
        }>,
    ) {}

    public ngOnInit() {
        this.store.dispatch(insightActions.appUsersRequest());
    }

    private createDatasetForChart(data: AppUsersResponse): {
        xaxis: ApexXAxis;
        series: ApexAxisChartSeries;
        chart: ApexChart;
    } {
        const series: ApexAxisChartSeries = [
            { name: 'Total', data: data.months.map(month => month.total) },
            { name: 'Active', data: data.months.map(month => month.active) },
            { name: 'New', data: data.months.map(month => month.new) },
        ];

        const categories = data.months.map(month => month.name);
        return { xaxis: { categories }, series, chart: { ...this.chartService.apexConfigs.chart, height: 500 } };
    }

    private createTableDataset(data: AppUsersResponse): {
        tooltip: string;
        title: string;
        mtd1: string | number;
        ytd1: string | number;
        mtd2: string | number;
        ytd2: string | number;
        mtd3: string | number;
        ytd3: string | number;
    }[] {
        return [
            {
                tooltip: 'The number of clients with an existing client record who have downloaded the app',
                title: 'Number of Existing Client Apps Users (downloaded)',
                mtd1: data.existing_mtd,
                ytd1: data.existing_ytd,
                mtd2: data.residential_existing_mtd,
                ytd2: data.residential_existing_ytd,
                mtd3: data.investment_existing_mtd,
                ytd3: data.investment_existing_ytd,
            },
            {
                tooltip:
                    'The number of clients who did not have an existing client record and who have downloaded the app',
                title: 'Number of New Client App users (downloaded)',
                mtd1: data.new_mtd,
                ytd1: data.new_ytd,
                mtd2: data.residential_new_mtd,
                ytd2: data.residential_new_ytd,
                mtd3: data.investment_new_mtd,
                ytd3: data.investment_new_ytd,
            },
            {
                tooltip: 'The number of app users who have used the app in the last 30 days',
                title: 'Number of App users – use within last 30 days',
                mtd1: '',
                ytd1: data.app_use_last_30_days,
                mtd2: '',
                ytd2: data.residential_app_use_last_30_days,
                mtd3: '',
                ytd3: data.investment_app_use_last_30_days,
            },
            {
                tooltip:
                    'This is a subset of the total app users and is the number of clients who did not have an existing client record and who have downloaded the app and used the app in the last 30 days',
                title: 'Number of New App users – use within last 30 days',
                mtd1: '',
                ytd1: data.app_new_use_last_30_days,
                mtd2: '',
                ytd2: data.residential_app_new_use_last_30_days,
                mtd3: '',
                ytd3: data.investment_app_new_use_last_30_days,
            },
            {
                tooltip:
                    'The total number of those who have downloaded the app as a percentage of the total number of client records',
                title: '% of clients downloaded app',
                mtd1: '',
                ytd1: data.downloaded_pct,
                mtd2: '',
                ytd2: data.residential_downloaded_pct,
                mtd3: '',
                ytd3: data.investment_downloaded_pct,
            },
            {
                tooltip:
                    'The total number of app users who have used the app within the last 30 days as a percentage of the number of clients who have downloaded the app',
                title: '% of active users vs download',
                mtd1: '',
                ytd1: data.active_downloaded_pct,
                mtd2: '',
                ytd2: data.residential_active_downloaded_pct,
                mtd3: '',
                ytd3: data.investment_active_downloaded_pct,
            },
        ];
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IsNotAuthenticatedGuard } from './guards/is-not-authenticated.guard';
import { AuthComponent } from './auth/auth.component';
import { InvestorDashboardComponent } from './investor-dashboard/investor-dashboard.component';
import { IsInvestorGuard } from './guards/is-investor.guard';
import { IsWorkerGuard } from './guards/is-worker.guard';
import { NotActivatedComponent } from './shared/page/not-activated.compinent';

const routes: Routes = [
    {
        path: 'auth',
        canActivate: [IsNotAuthenticatedGuard],
        component: AuthComponent,
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'dashboard',
        canActivate: [AuthenticatedGuard, IsWorkerGuard],
        component: DashboardComponent,
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'investor',
        canActivate: [AuthenticatedGuard, IsInvestorGuard],
        component: InvestorDashboardComponent,
        loadChildren: () =>
            import('./investor-dashboard/investor-dashboard.module').then(m => m.InvestorDashboardModule),
    },
    {
        path: 'not-activated',
        component: NotActivatedComponent,
    },
    { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}

import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { DashboardModule } from './dashboard/dashboard.module';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { effects, reducers } from './store.index';
import { clearState } from './shared/store/app/app.meta-reducer';
import { InvestorDashboardModule } from './investor-dashboard/investor-dashboard.module';
import { NotActivatedComponent } from './shared/page/not-activated.compinent';

@NgModule({
    declarations: [AppComponent, NotActivatedComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        CommonModule,
        DashboardModule,
        InvestorDashboardModule,
        StoreModule.forRoot(reducers, { metaReducers: [clearState] }),
        EffectsModule.forRoot(effects),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

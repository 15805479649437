import { Component } from '@angular/core';
import { map } from 'rxjs';
import { TableMetaOptions } from '../../shared/type';
import { DataTableService } from '../../shared/services/data-table.service';
import { DataPropertyService } from '../../shared/services/data-property.service';
import { colDef } from '@bhplugin/ng-datatable';
import { Store } from '@ngrx/store';
import { propertyNode, PropertyState } from '../../shared/store/property/property.store';
import { brokerNode, BrokerState } from '../../shared/store/broker/broker.store';
import { propertyActions } from '../../shared/store/property/property.actions';

@Component({
    selector: 'app-home-buyer',
    templateUrl: './home-buyer.component.html',
})
export class HomeBuyerComponent {
    public title = 'Home Buyer';
    public brokersTableSelectors: number[] = [];

    protected customFilter = {
        filter: {
            home_buyer: { is_ftb: { exists: true } },
        },
    };

    public cols: colDef[] = [
        { field: 'address', title: 'Address', hide: false },
        { field: 'clients', title: 'Client(s)', hide: false, sort: false },
        { field: 'isFTB', title: 'FTB', hide: false, sort: false },
        { field: 'ftbMortgagePrincipleRequired', title: 'Mortgage Principle Required', hide: false, sort: false },
        { field: 'ftbMortgagePrincipleExpiryDate', title: 'Mortgage Principle Expiry Date', hide: false },
        { field: 'ftbOfferPrice', title: 'Offer Price', hide: false },
        {
            field: 'client-property-actions',
            title: 'Action',
            sort: false,
            headerClass: 'justify-center',
            hide: false,
        },
    ];

    public rows$ = this.dataTableService.dataSetForPropertyTable().pipe(
        map(propertiesArr =>
            propertiesArr.map((item, key) => ({
                ...item,
                key,
                id: item.property.id,
                type: DataPropertyService.getInvestmentType(item.property.investment_type),
                broker: this.createBrokerTableSelector(item.property.id, item.property.broker_ids[0]),
                investmentType: item.property.investment_type.toLowerCase(),
                statusBadge: DataTableService.statusBadge(item.property.activity_status),
                notes: item.property.notes,

                portfolioValues: item.property.sums_of_profiles.portfolio_values,
                mortgagesOutstanding: item.property.sums_of_profiles.mortgages_outstanding,
                monthlyMortgagesPayments: item.property.sums_of_profiles.monthly_mortgages_payments,
                rentalsIncome: item.property.sums_of_profiles.rentals_income,
                growth: item.property.sums_of_profiles.growths,
                yield: item.property.sums_of_profiles.yields,
            })),
        ),
    );

    public constructor(
        private readonly dataTableService: DataTableService,
        private readonly store: Store<{
            [propertyNode]: PropertyState;
            [brokerNode]: BrokerState;
        }>,
    ) {}

    public updateContent(params: TableMetaOptions) {
        this.store.dispatch(propertyActions.propertiesRequest({ ...params }));
    }

    private createBrokerTableSelector(propertyId: number, brokerId: number): number {
        this.brokersTableSelectors[propertyId] = brokerId;
        return propertyId;
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BrokerAppointment, CreateBrokerAppointmentRequest, Investor, UpdateBrokerAppointmentRequest } from '../type';
import { environment as env } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import * as qs from 'qs';

@Injectable()
export class BrokerAppointmentService {
    public constructor(private readonly http: HttpClient) {}

    public getBrokerAppointments(
        startDate: Date,
        endDate: Date,
    ): Observable<{ appointments: BrokerAppointment[]; investors: Investor[] }> {
        const filter = qs.stringify(
            {
                filter: {
                    appointment_date: {
                        gte: moment(startDate).format('yyyy-MM-DD'),
                        lte: moment(endDate).format('yyyy-MM-DD'),
                    },
                },
            },
            { encode: false },
        );
        return this.http
            .get<{ data: BrokerAppointment[]; status: string; related: { investors: Investor[] } }>(
                `${env.baseServerUrl}/broker-appointments?${filter}`,
            )
            .pipe(
                map(response => ({ appointments: response.data ?? [], investors: response.related.investors ?? [] })),
            );
    }

    public createBrokerAppointment(data: CreateBrokerAppointmentRequest): Observable<BrokerAppointment> {
        const _data = { ...data, appointment_date: moment(data.date).format('yyyy-MM-DD') };
        delete _data.date;
        return this.http
            .post<{ data: BrokerAppointment; status: string }>(`${env.baseServerUrl}/broker-appointments`, _data)
            .pipe(map(response => response.data));
    }

    public updateBrokerAppointment(
        appointmentId: number,
        data: UpdateBrokerAppointmentRequest,
    ): Observable<BrokerAppointment> {
        const _data = { ...data, appointment_date: moment(data.date).format('yyyy-MM-DD') };
        delete _data.date;
        return this.http
            .patch<{ data: BrokerAppointment; status: string }>(
                `${env.baseServerUrl}/broker-appointments/${appointmentId}`,
                _data,
            )
            .pipe(map(response => response.data));
    }

    public deleteBrokerAppointment(appointmentId: number): Observable<string> {
        return this.http
            .delete<{ status: string }>(`${env.baseServerUrl}/broker-appointments/${appointmentId}`)
            .pipe(map(response => response.status));
    }
}

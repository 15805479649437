import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { colDef } from '@bhplugin/ng-datatable';
import { ModalComponent } from 'angular-custom-modal/modal.component';

@Component({
    selector: 'app-table-chooser-modal',
    templateUrl: './table-chooser-modal.component.html',
})
export class TableChooserModalComponent {
    @ViewChild('modal') modal: ModalComponent;

    @Output() colChanged = new EventEmitter<colDef>();

    public cols: colDef[] = [];

    public open(cols: colDef[]) {
        this.cols = cols;
        this.modal.open();
    }
    public close() {
        this.modal.close();
    }
}

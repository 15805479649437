import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { map, tap } from 'rxjs';
import { Action } from '@ngrx/store';
import { tableActions } from './table.actions';

@Injectable()
export class TableEffects implements OnInitEffects {
    public propertyRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tableActions.updateColSettings),
            tap(action => localStorage.setItem('colSettings', JSON.stringify(action.configs))),
            map(action => tableActions.setColSettings({ configs: action.configs })),
        ),
    );

    public loadConfigs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tableActions.loadConfigs),
            map(() => {
                const configs = JSON.parse(localStorage.getItem('colSettings'));
                return tableActions.setColSettings({ configs });
            }),
        ),
    );

    constructor(private actions$: Actions) {}

    public ngrxOnInitEffects(): Action {
        const configs = JSON.parse(localStorage.getItem('colSettings'));
        return tableActions.setColSettings({ configs });
    }
}

<div class='panel h-full w-full min-w-[19rem]'>

    <div class='mt-6 w-full'>
        <p class='text-xl font-semibold'>Home Buyer</p>

        <div class='mt-6 mb-2 flex w-full items-center justify-between'>
            <div class='text-white-dark'>FTB:</div>
            <div *ngIf='homeBuyer.is_ftb === null'>-</div>
            <div *ngIf='homeBuyer.is_ftb !== null'>{{homeBuyer.is_ftb ? 'Yes' : 'No'}}</div>
        </div>

        <div class='mb-2 flex w-full items-center justify-between'>
            <div class='text-white-dark'>Accepted Date:</div>
            <div *ngIf='homeBuyer.accepted_date'>
                {{homeBuyer.accepted_date | date :'dd-MM-yyyy'}}</div>
            <div *ngIf='homeBuyer.accepted_date === null'>-</div>
        </div>

        <div class='mb-2 flex w-full items-center justify-between'>
            <div class='text-white-dark'>Offer Date:</div>
            <div *ngIf='homeBuyer.offer_date'>
                {{homeBuyer.offer_date | date :'dd-MM-yyyy'}}</div>
            <div *ngIf='homeBuyer.offer_date === null'>-</div>
        </div>

        <div class='mb-2 flex w-full items-center justify-between'>
            <div class='text-white-dark'>Offer Accepted:</div>
            <div *ngIf='homeBuyer.offer_accepted === null'>-</div>
            <div *ngIf='homeBuyer.offer_accepted !== null'>{{homeBuyer.offer_accepted ? 'Yes' : 'No'}}</div>
        </div>

        <div class='mb-2 flex w-full items-center justify-between'>
            <div class='text-white-dark'>Offer Price:</div>
            <div *ngIf='homeBuyer.offer_price'>
                £ {{homeBuyer.offer_price | number}}</div>
            <div *ngIf='homeBuyer.offer_price === null'>-</div>
        </div>

        <div class='mb-2 flex w-full items-center justify-between'>
            <div class='text-white-dark'>Mortgage Principle Expiry Date:</div>
            <div *ngIf='homeBuyer.mortgage_principle_expiry_date'>
                {{homeBuyer.mortgage_principle_expiry_date | date :'dd-MM-yyyy'}}</div>
            <div *ngIf='homeBuyer.mortgage_principle_expiry_date === null'>-</div>
        </div>

        <div class='mb-2 flex w-full items-center justify-between'>
            <div class='text-white-dark'>Mortgage Principle Required:</div>
            <div *ngIf='homeBuyer.mortgage_principle_required === null'>-</div>
            <div *ngIf='homeBuyer.mortgage_principle_required !== null'>{{homeBuyer.mortgage_principle_required ? 'Yes' : 'No'}}</div>
        </div>

    </div>
</div>

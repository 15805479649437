<ng-container *ngIf='(opportunityData$ | async) as opportunity; else loader'>
    <div class='panel mt-6 pb-1.5 overflow-hidden'>
        <div class='table-fixed'>
            <div class='flex flex-row justify-between p-5 bg-[#f6f8fa]'>
                <div ngxTippy='Select which Priority level you want to look at using the drop-down box'
                     [tippyProps]="{placement: 'left'}" class='flex flex-row'>
                    <p class='text-2xl'>Priority</p>
                    <ng-select
                        [items]='options'
                        [(ngModel)]='selected'
                        [searchable]='false'
                        [clearable]='false'
                        (change)='changeSelect($event)'
                        class='ml-4 text-2xl custom-multiselect'
                    >
                    </ng-select>
                </div>
                <div class='text-2xl'>Total</div>
                <div class='text-2xl'>Product Expiry Date</div>
                <div class='text-2xl'>Early Review</div>
                <div class='text-2xl'>Equity Available</div>
                <div class='text-2xl'>Rate Riser</div>
            </div>
            <table class='table-striped'>
                <thead>
                <tr>
                    <th></th>
                    <th ngxTippy='The total number of client records in that priority in the current month'
                        [tippyProps]="{placement: 'left'}">Month
                    </th>
                    <th ngxTippy='The total number of client records in that priority in the current year (from 1st Jan)'
                        [tippyProps]="{placement: 'left'}">YTD
                    </th>
                    <th ngxTippy='The total number of client records in that priority in the current month'
                        [tippyProps]="{placement: 'left'}">Month
                    </th>
                    <th ngxTippy='The total number of client records in that priority in the current year (from 1st Jan)'
                        [tippyProps]="{placement: 'left'}">YTD
                    </th>
                    <th ngxTippy='The total number of client records in that priority in the current month'
                        [tippyProps]="{placement: 'left'}">Month
                    </th>
                    <th ngxTippy='The total number of client records in that priority in the current year (from 1st Jan)'
                        [tippyProps]="{placement: 'left'}">YTD
                    </th>
                    <th ngxTippy='The total number of client records in that priority in the current month'
                        [tippyProps]="{placement: 'left'}">Month
                    </th>
                    <th ngxTippy='The total number of client records in that priority in the current year (from 1st Jan)'
                        [tippyProps]="{placement: 'left'}">YTD
                    </th>
                    <th ngxTippy='The total number of client records in that priority in the current month'
                        [tippyProps]="{placement: 'left'}">Month
                    </th>
                    <th ngxTippy='The total number of client records in that priority in the current year (from 1st Jan)'
                        [tippyProps]="{placement: 'left'}">YTD
                    </th>
                </tr>
                </thead>
                <tbody>

                <tr>
                    <td class='font-bold'>Total</td>
                    <td class='p-3' *ngFor='let text of opportunity.total'>{{text}}</td>
                </tr>

                <tr>
                    <td ngxTippy='The total number of client records where contact has been made and includes Attempted Contact, Contact, Invalid Expiry Date found, and Appointment Made'
                        [tippyProps]="{placement: 'left'}" class='font-bold'>Actioned
                    </td>
                    <td class='p-3' *ngFor='let text of opportunity.actioned'>{{text}}</td>
                </tr>

                <tr>
                    <td ngxTippy='The total number of mortgage applications submitted in the Priority'
                        [tippyProps]="{placement: 'left'}" class='font-bold'>Submitted
                    </td>
                    <td class='p-3' *ngFor='let text of opportunity.submitted'>{{text}}</td>
                </tr>

                <tr>
                    <td
                        ngxTippy='The total number of client records in the category where no action has been undertaken'
                        [tippyProps]="{placement: 'left'}" class='font-bold'>Not Actioned
                    </td>
                    <td class='p-3' *ngFor='let text of opportunity.notActioned'>{{text}}</td>
                </tr>

                <tr>
                    <td ngxTippy='The total number of Lost Opportunities which includes clients who have transferred to another broker, or the client has transacted with a lender'
                        [tippyProps]="{placement: 'left'}" class='font-bold'>Lost Opp
                    </td>
                    <td class='p-3' *ngFor='let text of opportunity.lostOpportunity'>{{text}}</td>
                </tr>

                <tr>
                    <td ngxTippy='The total number of No Opportunities which includes Property Sold, No Mortgage Option, and Product Transfer to Existing Lender already undertaken'
                        [tippyProps]="{placement: 'left'}" class='font-bold'>No Opp
                    </td>
                    <td class='p-3' *ngFor='let text of opportunity.noOpportunity'>{{text}}</td>
                </tr>

                </tbody>
            </table>
        </div>
    </div>

    <div class='mt-6 mb-6 h-auto'>
        <div class='panel h-min p-0'>
            <div
                class='mb-5 flex items-start justify-between border-b border-[#e0e6ed] p-5 dark:border-[#1b2e4b] dark:text-white-light'>
                <h5 class='text-lg font-semibold'>Priority {{selected}}
                </h5>
            </div>

            <div class='overflow-hidden'>
                <apx-chart
                    [xaxis]='xaxis'
                    [series]='opportunity.series'

                    [chart]='chartService.stackedChart.chart'
                    [grid]='chartService.apexConfigs.grid'
                    [stroke]='chartService.apexConfigs.stroke'
                    [dataLabels]='chartService.stackedChart.dataLabels'
                    [colors]='chartColors'
                    [plotOptions]='chartService.stackedChart.plotOptions'
                    [legend]='chartService.apexConfigs.legend'
                    [yaxis]='chartService.apexConfigs.yaxis'
                    [fill]='chartService.apexConfigs.fill'
                    [tooltip]='chartService.apexConfigs.tooltip'

                    class='rounded-lg bg-white dark:bg-black'
                >
                </apx-chart>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loader>
    <app-loader></app-loader>
</ng-template>



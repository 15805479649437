import { Injectable } from '@angular/core';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { dashboardLayoutNode, DashboardLayoutState } from '../shared/store/dashboard/store';
import { dashboardActions } from '../shared/store/dashboard/dashboard.actions';
import { dashboardSelectors } from '../shared/store/dashboard/dashboard.selectors';

@Injectable()
export class DashboardService {
    public constructor(
        private readonly router: Router,
        private readonly store: Store<{
            [dashboardLayoutNode]: DashboardLayoutState;
        }>,
    ) {}

    public savePrevUrl(): void {
        this.store.dispatch(dashboardActions.setPrevURL({ url: this.router.url }));
    }

    public redirectToPrev(defaultURL: string) {
        const prevURLL$ = this.store.pipe(select(dashboardSelectors.selectPrevURL), first());
        prevURLL$.subscribe(url => {
            this.store.dispatch(dashboardActions.setPrevURL({ url: null }));
            return url !== null ? this.router.navigateByUrl(url) : this.router.navigate([defaultURL]);
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
    selector: 'app-not-activated',
    templateUrl: './not-activated.component.html',
})
export class NotActivatedComponent implements OnInit {
    public constructor(private readonly authService: AuthenticationService) {}

    public ngOnInit() {
        this.authService.logout();
    }
}

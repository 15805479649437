import { Injectable } from '@angular/core';
import { ApexXAxis } from 'ng-apexcharts';
import { DataMonth } from '../insight.type';

@Injectable()
export class OverviewService {
    public apexXaxis(months: DataMonth[]): ApexXAxis {
        return {
            categories: months.map(mount => mount.name),
            axisBorder: {
                show: true,
                color: '#e0e6ed',
            },
        };
    }

    public apexSeries(months: DataMonth[]): { name: string; data: number[] }[] {
        return [
            {
                name: 'Total',
                data: months.map(month => month.count),
            },
            {
                name: 'Submitted',
                data: months.map(month => month.submitted),
            },
        ];
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestorDashboardRoutingModule, routesComponents } from './investor-dashboard-routing.module';
import { InvestorDashboardComponent } from './investor-dashboard.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SharedModule } from '../shared/shared.module';
import { MenuModule } from 'headlessui-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
    declarations: [InvestorDashboardComponent, HeaderComponent, SidebarComponent, FooterComponent, ...routesComponents],
    imports: [
        CommonModule,
        InvestorDashboardRoutingModule,
        NgScrollbarModule.withConfig({
            visibility: 'hover',
            appearance: 'standard',
        }),
        SharedModule,
        MenuModule,
        NgSelectModule,
        FormsModule,
        DashboardModule,
    ],
    providers: [],
})
export class InvestorDashboardModule {}

import { createReducer, on } from '@ngrx/store';
import { dashboardActions } from './dashboard.actions';

export const dashboardLayoutNode = 'dashboard';

export interface DashboardLayoutState {
    toggleSidebar: boolean;
    showMainLoader: boolean;
    prevUrl: string | null;
    contactRequestCount: number;
}

export const dashboardLayoutInitialState: DashboardLayoutState = {
    toggleSidebar: true,
    showMainLoader: true,
    prevUrl: null,
    contactRequestCount: 0,
};

export const dashboardLayoutReducer = createReducer(
    dashboardLayoutInitialState,
    on(dashboardActions.toggleSidebar, state => ({
        ...state,
        toggleSidebar: !state.toggleSidebar,
    })),
    on(dashboardActions.setMainLoader, (state, action) => ({
        ...state,
        showMainLoader: action.state,
    })),
    on(dashboardActions.setPrevURL, (state, action) => ({
        ...state,
        prevUrl: action.url,
    })),
    on(dashboardActions.setContactRequiredCount, (state, action) => ({
        ...state,
        contactRequestCount: action.count,
    })),
);

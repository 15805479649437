import { Injectable } from '@angular/core';
import * as qs from 'qs';

@Injectable()
export class TableSortService {
    private schemas: { name: string; schema: (direction: string) => any }[] = [
        { name: 'address', schema: (direction: string) => ({ address: direction }) },
        { name: 'type', schema: (direction: string) => ({ investment_type: direction }) },
        { name: 'lender', schema: (direction: string) => ({ mortgages: { lender_name: direction } }) },
        { name: 'expiryDate', schema: (direction: string) => ({ mortgages: { product_end_term: direction } }) },
        { name: 'countdown', schema: (direction: string) => ({ mortgages: { expiry_days: direction } }) },
        { name: 'broker', schema: (direction: string) => ({ brokers: { name: direction } }) },
        { name: 'broker-single', schema: (direction: string) => ({ brokers: { name: direction } }) },
        {
            name: 'priorityProduct',
            schema: (direction: string) => ({ leadgen: { product_end_term: { rating: direction } } }),
        },
        { name: 'status', schema: (direction: string) => ({ activity_status: direction }) },
        { name: 'propertyOwnerType', schema: (direction: string) => ({ owner_type: direction }) },
        { name: 'rentalIncome', schema: (direction: string) => ({ current_rental_income: direction }) },
        { name: 'loanAmount', schema: (direction: string) => ({ mortgages: { outstanding_mortgage: direction } }) },
        { name: 'interestRate', schema: (direction: string) => ({ mortgages: { interest_rate: direction } }) },
        {
            name: 'monthlyPayment',
            schema: (direction: string) => ({ mortgages: { monthly_mortgage_payments: direction } }),
        },

        { name: 'savings', schema: (direction: string) => ({ mortgages: { savings: direction } }) },
        { name: 'productEndTerm', schema: (direction: string) => ({ mortgages: { product_end_term: direction } }) },
        {
            name: 'contactRemainingDays',
            schema: (direction: string) => ({ mortgages: { lender_contact_remaining_days: direction } }),
        },
        {
            name: 'currentEquityRelease',
            schema: (direction: string) => ({ leadgen: { equity_available: { current_equity_release: direction } } }),
        },
        {
            name: 'priorityEarlyReview',
            schema: (direction: string) => ({ leadgen: { early_review: { rating: direction } } }),
        },
        {
            name: 'priorityEquityAvailable',
            schema: (direction: string) => ({ leadgen: { equity_available: { rating: direction } } }),
        },

        {
            name: 'priorityRateRiser',
            schema: (direction: string) => ({ leadgen: { rate_riser: { rating: direction } } }),
        },
        { name: 'currentRate', schema: (direction: string) => ({ mortgages: { interest_rate: direction } }) },

        { name: 'investorName', schema: (direction: string) => ({ name: direction }) },
        { name: 'email', schema: (direction: string) => ({ email: direction }) },
        { name: 'phone', schema: (direction: string) => ({ phone: direction }) },
        { name: 'registered', schema: (direction: string) => ({ registered_at: direction }) },
        { name: 'lastLogin', schema: (direction: string) => ({ last_login_at: direction }) },
        { name: 'investor-status', schema: (direction: string) => ({ client_invoked_at: direction }) },
        { name: 'organic', schema: (direction: string) => ({ created_by: direction }) },

        //FTB-columns

        {
            name: 'ftbAcceptedDate',
            schema: (direction: string) => ({
                home_buyer: { accepted_date: direction },
            }),
        },
        {
            name: 'ftbMortgagePrincipleExpiryDate',
            schema: (direction: string) => ({
                home_buyer: { mortgage_principle_expiry_date: direction },
            }),
        },
        {
            name: 'ftbOfferDate',
            schema: (direction: string) => ({
                home_buyer: { offer_date: direction },
            }),
        },
        {
            name: 'ftbOfferPrice',
            schema: (direction: string) => ({
                home_buyer: { offer_price: direction },
            }),
        },

        //next_call-columns
        {
            name: 'nextContact',
            schema: (direction: string) => ({
                next_call: { call_date: direction },
            }),
        },
        {
            name: 'lastContact',
            schema: (direction: string) => ({
                last_call: { call_date: direction },
            }),
        },
        {
            name: 'callStatus',
            schema: (direction: string) => ({
                next_call: { rating: direction },
            }),
        },
    ];

    public findSchemaForSort(columnName: string, direction: string): string {
        const selectedSchema = this.schemas.find(schema => schema.name == columnName);
        return qs.stringify({ sort: selectedSchema.schema(direction) }, { encode: false });
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { Property, PropertyMortgage } from '../../../../type';

@Component({
    selector: 'app-property-mortgage-info',
    templateUrl: './property-mortgage-info.component.html',
})
export class PropertyMortgageInfoComponent implements OnInit {
    @Input()
    public property: Property;

    public activeTab = 'current';
    public activeMortgage = {
        outstandingMortgage: null,
        outstandingMortgageRepayment: null,
        lenderName: null,
        monthlyMortgagePayments: null,
        status: null,
        productEndTerm: null,
        originalTerm: null,
        interestRate: null,
        repaymentType: null,
        rateType: null,
        mortgageStartDate: null,
    };

    public mortgages: PropertyMortgage[] = [];

    public ngOnInit(): void {
        this.setActiveMortgage(this.property.mortgages[0]);
    }

    public changeTab(mortgage: PropertyMortgage, isCurrent = false): void {
        this.activeTab = isCurrent ? 'current' : mortgage.id.toString();
        this.setActiveMortgage(mortgage);
    }

    public setActiveMortgage(mortgage: PropertyMortgage): void {
        this.activeMortgage.outstandingMortgage = mortgage?.outstanding_mortgage;
        this.activeMortgage.outstandingMortgageRepayment =
            mortgage?.repayment_type == 'Repayment' ? mortgage?.outstanding_mortgage_reduced : null;
        this.activeMortgage.lenderName = mortgage?.lender_name;
        this.activeMortgage.monthlyMortgagePayments = mortgage?.monthly_mortgage_payments;
        this.activeMortgage.status = mortgage?.status;
        this.activeMortgage.productEndTerm = mortgage?.product_end_term;
        this.activeMortgage.originalTerm = mortgage?.original_term;
        this.activeMortgage.interestRate = mortgage?.interest_rate;
        this.activeMortgage.repaymentType = mortgage?.repayment_type;
        this.activeMortgage.rateType = mortgage?.rate_type;
        this.activeMortgage.mortgageStartDate = mortgage?.mortgage_start_date;
    }
}

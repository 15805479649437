import { createReducer, on } from '@ngrx/store';
import { userActions } from './user.actions';
import { Profile } from '../../type';

export const userNode = 'user';

export interface UserState {
    profile: Profile | null;
}

export const userInitialState: UserState = {
    profile: null,
};

export const userReducer = createReducer(
    userInitialState,
    on(userActions.profileSet, (state, action) => ({
        ...state,
        profile: action.profile,
    })),
);

import { Component, Input } from '@angular/core';
import { PropertyService } from '../../../../services/property.service';
import { DataPropertyService } from '../../../../services/data-property.service';

@Component({
    selector: 'app-property-owner-type-select',
    templateUrl: './property-owner-type-select.component.html',
})
export class PropertyOwnerTypeSelectComponent {
    @Input() value: string;
    @Input() propertyId: number;

    public constructor(
        protected readonly dataPropertyService: DataPropertyService,
        private readonly propertyService: PropertyService,
    ) {}

    public update() {
        this.propertyService.updateProperty(this.propertyId, null, { owner_type: this.value }).subscribe();
    }
}

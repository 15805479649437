import { Injectable } from '@angular/core';
import { utils, WorkBook, WorkSheet, writeFile } from 'xlsx';
import { saveAs } from 'file-saver';

@Injectable()
export class TableDownloadService {
    public downloadAsXLSX(table: HTMLElement, tableName: string): void {
        const name = tableName + '.xls';
        const ws: WorkSheet = this.formattingStrings(utils.table_to_sheet(table));
        const wb: WorkBook = utils.book_new();
        utils.book_append_sheet(wb, ws, name);
        writeFile(wb, name);
    }

    public downloadAsCsv(table: HTMLElement, tableName: string): void {
        const name = tableName + '.cvs';
        const ws: WorkSheet = this.formattingStrings(utils.table_to_sheet(table));
        const cvs = utils.sheet_to_csv(ws);
        const blob = new Blob([cvs], { type: 'text/csv' });
        saveAs(blob, name);
    }

    private formattingStrings(ws: WorkSheet): WorkSheet {
        Object.entries(ws).forEach(result => {
            const checking = this.removeImpurities(result[1]?.v);
            if (checking) ws[result[0]] = { ...result[1], v: checking };
        });
        return this.removeActionColumn(ws);
    }

    private removeActionColumn(ws: WorkSheet): WorkSheet {
        let columnLatter = undefined;
        Object.entries(ws).forEach(result => {
            if (result[1]?.v == 'Action' || result[1]?.v == 'Actions') columnLatter = result[0][0];
            if (columnLatter != undefined && result[0][0] == columnLatter) delete ws[result[0]];
        });
        return ws;
    }

    private removeImpurities(str: string | number | undefined): string | number | undefined {
        if (typeof str != 'string') return str;
        const phase = /(.* )?\{\\n if \(this." }-->/i;

        const findAndRemove = (str: string): string => {
            str = str.substring(1);
            return str.replace(phase, '');
        };

        return RegExp(phase).test(str) ? findAndRemove(str) : str;
    }
}

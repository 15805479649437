<modal [closeOnOutsideClick]='true' #modal class='modal-top'>

    <ng-template #modalHeader>
        <div class='p-7'>
            <p class='text-2xl mt-5 ml-2'>Change Date</p>
        </div>

    </ng-template>
    <ng-template #modalBody>
        <div class='w-full'>
            <div class='flex flex-row gap-6 ml-24'>
                <div>
                    <button (click)='btnClick("today")' [class]='currentRange.name === "today" ? "btn-primary" : "btn-outline-primary"'
                            class='btn w-full mt-5'>Today
                    </button>
                    <button (click)='btnClick("yesterday")' [class]='currentRange.name === "yesterday" ? "btn-primary" : "btn-outline-primary"'
                            class='btn w-full mt-5'>Yesterday
                    </button>
                    <button (click)='btnClick("last7days")' [class]='currentRange.name === "last7days" ? "btn-primary" : "btn-outline-primary"'
                            class='btn w-full mt-5'>Last 7 days
                    </button>
                    <button (click)='btnClick("last30days")' [class]='currentRange.name === "last30days" ? "btn-primary" : "btn-outline-primary"'
                            class='btn w-full mt-5'>Last 30 days
                    </button>
                    <button (click)='btnClick("all")' [class]='currentRange.name === "all" ? "btn-primary" : "btn-outline-primary"'
                            class='btn w-full mt-5'>All Time
                    </button>
                </div>
                <div>
                    <button class='mt-[3.1rem]'></button>
                    <button (click)='btnClick("tomorrow")' [class]='currentRange.name === "tomorrow" ? "btn-primary" : "btn-outline-primary"'
                            class='btn w-full mt-5'>Tomorrow
                    </button>
                    <button (click)='btnClick("next7days")' [class]='currentRange.name === "next7days" ? "btn-primary" : "btn-outline-primary"'
                            class='btn w-full mt-5'>Next 7 days
                    </button>
                    <button (click)='btnClick("next30days")' [class]='currentRange.name === "next30days" ? "btn-primary" : "btn-outline-primary"'
                            class='btn w-full mt-5'>Next 30 days
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</modal>

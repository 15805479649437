import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableChooserComponent } from './components/table-chooser/table-chooser.component';
import { TableComponent, TableComponents } from './components/table/table.component';
import { TableFilersComponent } from './components/table-filters/table-filers.component';
import { ChangeStatusModalComponent } from './components/change-status-modal/change-status-modal.component';
import { CreateBrokerModalComponent } from '../dashboard/company/components/users/components/create-broker-modal/create-broker-modal.component';
import { UpdateBrokerModalComponent } from '../dashboard/company/components/users/components/update-broker-modal/update-broker-modal.component';
import { DataTableModule } from '@bhplugin/ng-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'angular-custom-modal';
import { PropertyPortfolioTotalsComponent } from './components/property-portfolio-totals/property-portfolio-totals.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MenuModule } from 'headlessui-angular';
import { RouterModule } from '@angular/router';
import { clientDetailComponents } from './components/client-detail/client-detail.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TableDownloadService } from './services/table-download.service';
import { PropertyService } from './services/property.service';
import { PlaceAutofillComponent } from './components/property-create/place-autofill/place-autofill.component';
import { GooglePlaceService } from './services/google-place.service';
import { RouterService } from './services/router.service';
import { PermissionService } from './services/permission.service';
import { TableChooserModalComponent } from './components/table-chooser/component/table-chooser-modal/table-chooser-modal.component';
import { ClientCreateModalComponent } from '../dashboard/clients/components/client-create-modal/client-create-modal.component';
import { InvestorNoteService } from './services/investor-note.service';

@NgModule({
    declarations: [...SharedModule.components],
    imports: [
        CommonModule,
        DataTableModule,
        NgSelectModule,
        Ng2FlatpickrModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        ModalModule,
        MenuModule,
        RouterModule,
    ],
    exports: [...SharedModule.components],
    providers: [
        provideNgxMask(),
        TableDownloadService,
        PropertyService,
        GooglePlaceService,
        RouterService,
        PermissionService,
        InvestorNoteService,
    ],
})
export class SharedModule {
    public static components = [
        TableChooserComponent,
        TableChooserModalComponent,
        ClientCreateModalComponent,
        TableComponent,
        TableFilersComponent,
        ChangeStatusModalComponent,
        CreateBrokerModalComponent,
        UpdateBrokerModalComponent,
        PropertyPortfolioTotalsComponent,
        LoaderComponent,
        PlaceAutofillComponent,
        ...TableComponents,
        ...clientDetailComponents,
    ];
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class RouterService {
    public constructor(private readonly router: Router) {}

    public parentRoute(): string {
        return this.router.url.split('/')[1];
    }
}

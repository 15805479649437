<div
    class='w-[19rem] h-[25rem] bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed]'>

    <div class='py-7 px-6'>
        <div class='text-xl font-semibold'>Market Valuation</div>
        <hr class='my-6 border-[#e0e6ed]' />

        <h4 class='h-2 mt-6 mb-10 font-bold'>Property value</h4>

        <div class='mt-2 flex w-full items-center justify-between'>
            <div class='text-white-dark'>Use market or custom:</div>
            <div>{{property.use_custom_market ? 'Custom' : 'MDV'}}</div>
        </div>

        <div class='mt-4 flex w-full items-center justify-between'>
            <div class='text-white-dark'>Market Value :</div>
            <ng-container
                *ngIf='(property?.markets_latest !== undefined) && (property.markets_latest[0]?.market_value !== 0); else emptyValue'>
                <div>£ {{property.markets_latest[0]?.market_value | number}}</div>
            </ng-container>
        </div>

        <h4 class='h-2 mt-6 mb-10 font-bold'>Rental value</h4>

        <div class='mt-4 flex w-full items-center justify-between'>
            <div class='text-white-dark'>Use rental or custom:</div>
            <div>{{property.use_custom_rental ? 'Custom' : 'MDV'}}</div>
        </div>

        <div class='mt-4 flex w-full items-center justify-between'>
            <div class='text-white-dark'>Rental Value :</div>
            <ng-container
                *ngIf='(property?.markets_latest !== undefined) && (property.markets_latest[0]?.rental_value !== 0); else emptyValue'>
                <div>£ {{property.markets_latest[0]?.rental_value | number}}</div>
            </ng-container>
        </div>

    </div>
</div>


<ng-template #emptyValue>
    <div>-</div>
</ng-template>

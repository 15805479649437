import { createAction, props } from '@ngrx/store';
import { Investor } from '../../type';

const investorsRequest = createAction(
    '[INVESTOR] investors have been requested',
    props<{
        perPage: number;
        page: number;
        options?: { filters?: any; search?: string; sort?: { columnName: string; direction: string } };
    }>(),
);

export const investorsSet = createAction('[INVESTOR] investors have been set', props<{ investors: Investor[] }>());

export const investorActions = {
    investorsRequest,
    investorsSet,
};

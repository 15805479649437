<div
    class='panel flex flex-row justify-between'>
    <h5 class='text-2xl'>Property update</h5>
    <button (click)='location.back()' type='button' class='btn btn-outline-dark'>Back</button>
</div>

<form *ngIf='(dataset$ | async) as dataset' [formGroup]='dataPropertyService.form' (ngSubmit)='submitForm()' novalidate>
    <div class='mt-6 flex gap-6 flex-row justify-between'>

        <div class='w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed]'>

            <div class='py-7 px-6'>
                <div class='text-xl font-semibold'><p>Property</p></div>
                <hr class='my-6 border-[#e0e6ed]' />

                <div class='mt-6'
                     [ngClass]="(dataPropertyService.form.controls['address'].touched && dataPropertyService.form.controls['address'].errors) ? 'has-error' : ''">
                    <label for='address'>Address</label>
                    <input readonly id='address' type='text' class='form-input read-only:bg-gray-200'
                           formControlName='address' />
                    <ng-container
                        *ngIf="dataPropertyService.form.controls['address'].touched && dataPropertyService.form.controls['address'].errors">
                        <p class='mt-1 text-danger'>Please fill</p>
                    </ng-container>
                </div>

                <div class='mt-6 flex flex-row justify-between'>

                    <div
                        [ngClass]="(dataPropertyService.form.controls['postcode'].touched && dataPropertyService.form.controls['postcode'].errors) ? 'has-error' : ''">
                        <label for='postcode'>Postcode</label>
                        <input readonly id='postcode' type='text' class='form-input read-only:bg-gray-200'
                               formControlName='postcode' />
                        <ng-container
                            *ngIf="dataPropertyService.form.controls['postcode'].touched && dataPropertyService.form.controls['postcode'].errors">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                    </div>

                    <div
                        [ngClass]="(dataPropertyService.form.controls['purchase_price'].touched && dataPropertyService.form.controls['purchase_price'].errors) ? 'has-error' : ''">
                        <label for='purchasedPrice'>Purchased Price</label>
                        <input id='purchasedPrice' type='text' class='form-input'
                               formControlName='purchase_price' />
                        <ng-container
                            *ngIf="dataPropertyService.form.controls['purchase_price'].touched && dataPropertyService.form.controls['purchase_price'].errors">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                    </div>

                </div>

                <div class='mt-6 flex flex-row justify-between'>

                    <div
                        [ngClass]="(dataPropertyService.form.controls['purchase_date'].touched && dataPropertyService.form.controls['purchase_date'].errors) ? 'has-error' : ''">
                        <label for='purchasedDate'>Purchased Date</label>
                        <div class='max-w-[12rem] flex'>
                            <div
                                class='bg-[#eee] flex justify-center items-center ltr:rounded-l-md rtl:rounded-r-md px-3 font-semibold border ltr:border-r-0 rtl:border-l-0 border-[#e0e6ed] dark:border-[#17263c] dark:bg-[#1b2e4b]'
                            >
                                <svg width='18' height='18' viewBox='0 0 24 24' fill='none'
                                     xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M17 14C17.5523 14 18 13.5523 18 13C18 12.4477 17.5523 12 17 12C16.4477 12 16 12.4477 16 13C16 13.5523 16.4477 14 17 14Z'
                                        fill='currentColor' />
                                    <path
                                        d='M17 18C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18Z'
                                        fill='currentColor' />
                                    <path
                                        d='M13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13Z'
                                        fill='currentColor' />
                                    <path
                                        d='M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z'
                                        fill='currentColor' />
                                    <path
                                        d='M7 14C7.55229 14 8 13.5523 8 13C8 12.4477 7.55229 12 7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14Z'
                                        fill='currentColor' />
                                    <path
                                        d='M7 18C7.55229 18 8 17.5523 8 17C8 16.4477 7.55229 16 7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18Z'
                                        fill='currentColor' />
                                    <path fill-rule='evenodd' clip-rule='evenodd'
                                          d='M7 1.75C7.41421 1.75 7.75 2.08579 7.75 2.5V3.26272C8.412 3.24999 9.14133 3.24999 9.94346 3.25H14.0564C14.8586 3.24999 15.588 3.24999 16.25 3.26272V2.5C16.25 2.08579 16.5858 1.75 17 1.75C17.4142 1.75 17.75 2.08579 17.75 2.5V3.32709C18.0099 3.34691 18.2561 3.37182 18.489 3.40313C19.6614 3.56076 20.6104 3.89288 21.3588 4.64124C22.1071 5.38961 22.4392 6.33855 22.5969 7.51098C22.75 8.65018 22.75 10.1058 22.75 11.9435V14.0564C22.75 15.8941 22.75 17.3498 22.5969 18.489C22.4392 19.6614 22.1071 20.6104 21.3588 21.3588C20.6104 22.1071 19.6614 22.4392 18.489 22.5969C17.3498 22.75 15.8942 22.75 14.0565 22.75H9.94359C8.10585 22.75 6.65018 22.75 5.51098 22.5969C4.33856 22.4392 3.38961 22.1071 2.64124 21.3588C1.89288 20.6104 1.56076 19.6614 1.40314 18.489C1.24997 17.3498 1.24998 15.8942 1.25 14.0564V11.9436C1.24998 10.1058 1.24997 8.65019 1.40314 7.51098C1.56076 6.33855 1.89288 5.38961 2.64124 4.64124C3.38961 3.89288 4.33856 3.56076 5.51098 3.40313C5.7439 3.37182 5.99006 3.34691 6.25 3.32709V2.5C6.25 2.08579 6.58579 1.75 7 1.75ZM5.71085 4.88976C4.70476 5.02502 4.12511 5.27869 3.7019 5.7019C3.27869 6.12511 3.02502 6.70476 2.88976 7.71085C2.86685 7.88123 2.8477 8.06061 2.83168 8.25H21.1683C21.1523 8.06061 21.1331 7.88124 21.1102 7.71085C20.975 6.70476 20.7213 6.12511 20.2981 5.7019C19.8749 5.27869 19.2952 5.02502 18.2892 4.88976C17.2615 4.75159 15.9068 4.75 14 4.75H10C8.09318 4.75 6.73851 4.75159 5.71085 4.88976ZM2.75 12C2.75 11.146 2.75032 10.4027 2.76309 9.75H21.2369C21.2497 10.4027 21.25 11.146 21.25 12V14C21.25 15.9068 21.2484 17.2615 21.1102 18.2892C20.975 19.2952 20.7213 19.8749 20.2981 20.2981C19.8749 20.7213 19.2952 20.975 18.2892 21.1102C17.2615 21.2484 15.9068 21.25 14 21.25H10C8.09318 21.25 6.73851 21.2484 5.71085 21.1102C4.70476 20.975 4.12511 20.7213 3.7019 20.2981C3.27869 19.8749 3.02502 19.2952 2.88976 18.2892C2.75159 17.2615 2.75 15.9068 2.75 14V12Z'
                                          fill='#1C274C' />
                                </svg>

                            </div>
                            <ng2-flatpickr addClass='form-input' id='purchasedDate'
                                           [config]='dataPropertyService.basicCalendarParams'
                                           [setDate]='purchaseDate'
                                           formControlName='purchase_date'></ng2-flatpickr>
                        </div>
                        <ng-container
                            *ngIf="dataPropertyService.form.controls['purchase_date'].touched && dataPropertyService.form.controls['purchase_date'].errors">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                    </div>

                    <div class='min-w-[12rem]'
                         [ngClass]="(dataPropertyService.form.controls['type'].touched && dataPropertyService.form.controls['type'].errors) ? 'has-error' : ''">
                        <label for='propertyType'>Property Type</label>
                        <ng-select
                            id='propertyType'
                            formControlName='type'
                            [items]='dataPropertyService.propertyTypes'
                            [searchable]='false'
                            [clearable]='false'
                            bindLabel='name'
                            bindValue='id'
                            class='custom-multiselect'
                        >
                        </ng-select>
                        <ng-container
                            *ngIf="dataPropertyService.form.controls['type'].touched && dataPropertyService.form.controls['type'].errors">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                    </div>

                </div>

                <div class='mt-6 flex flex-row justify-between'>

                    <div class='min-w-[12rem]'
                         [ngClass]="(dataPropertyService.form.controls['owner_type'].touched && dataPropertyService.form.controls['owner_type'].errors) ? 'has-error' : ''">
                        <label for='taxType'>Tax Type</label>
                        <ng-select
                            id='taxType'
                            formControlName='owner_type'
                            [items]='dataPropertyService.taxTypes'
                            [searchable]='false'
                            [clearable]='false'
                            bindLabel='name'
                            bindValue='id'
                            class='custom-multiselect'
                        >
                        </ng-select>
                        <ng-container
                            *ngIf="dataPropertyService.form.controls['owner_type'].touched && dataPropertyService.form.controls['owner_type'].errors">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                    </div>

                    <div
                        [ngClass]="(dataPropertyService.form.controls['bedroom_count'].touched && dataPropertyService.form.controls['bedroom_count'].errors) ? 'has-error' : ''">
                        <label for='bedrooms'>Bedrooms</label>
                        <input id='bedrooms' type='text' class='form-input'
                               formControlName='bedroom_count' />
                        <ng-container
                            *ngIf="dataPropertyService.form.controls['bedroom_count'].touched && dataPropertyService.form.controls['bedroom_count'].errors?.required">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                        <ng-container
                            *ngIf="dataPropertyService.form.controls['bedroom_count'].touched && dataPropertyService.form.controls['bedroom_count'].errors?.pattern">
                            <p class='mt-1 text-danger'>Must contain only numbers</p>
                        </ng-container>
                    </div>

                </div>

                <div class='mt-6 flex flex-row justify-between'>

                    <div
                        [ngClass]="(dataPropertyService.form.controls['current_rental_income'].touched && dataPropertyService.form.controls['current_rental_income'].errors) ? 'has-error' : ''">
                        <label for='currentRentalIncome'>Current rental income</label>
                        <input id='currentRentalIncome' type='text' class='form-input'
                               formControlName='current_rental_income' />
                        <ng-container
                            *ngIf="dataPropertyService.form.controls['current_rental_income'].touched && dataPropertyService.form.controls['current_rental_income'].errors">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                    </div>

                    <div class='min-w-[12rem]'
                         [ngClass]="(dataPropertyService.form.controls['hmo'].touched && dataPropertyService.form.controls['hmo'].errors) ? 'has-error' : ''">
                        <label for='isHMO'>Is HMO</label>
                        <ng-select
                            id='isHMO'
                            formControlName='hmo'
                            [items]='dataPropertyService.boolTypes'
                            [searchable]='false'
                            [clearable]='false'
                            bindLabel='name'
                            bindValue='id'
                            class='custom-multiselect'
                        >
                        </ng-select>
                        <ng-container
                            *ngIf="dataPropertyService.form.controls['hmo'].touched && dataPropertyService.form.controls['hmo'].errors">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                    </div>

                    <div class='min-w-[12rem]'
                         [ngClass]="(dataPropertyService.form.controls['mub'].touched && dataPropertyService.form.controls['mub'].errors) ? 'has-error' : ''">
                        <label for='isMUB'>Is MUB</label>
                        <ng-select
                            id='isMUB'
                            formControlName='mub'
                            [items]='dataPropertyService.boolTypes'
                            [searchable]='false'
                            [clearable]='false'
                            bindLabel='name'
                            bindValue='id'
                            class='custom-multiselect'
                        >
                        </ng-select>
                        <ng-container
                            *ngIf="dataPropertyService.form.controls['mub'].touched && dataPropertyService.form.controls['mub'].errors">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                    </div>

                </div>

            </div>
        </div>

        <div
            class='w-full h-fit bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed]'>
            <ng-container *ngIf='dataset.property.home_buyer === null; else HomeBuyer'>
                <app-property-mortgage-update [isSubmitForm]='isSubmitForm' [property]='property$ | async'></app-property-mortgage-update>
            </ng-container>

            <ng-template #HomeBuyer>
                <app-property-home-buyer-update *ngIf='dataset.property.home_buyer !== null' [homeBuyer]='dataset.property.home_buyer'></app-property-home-buyer-update>
            </ng-template>


        </div>

    </div>

    <hr class='my-6 border-[#e0e6ed]' />

    <div *ngIf='dataset.property.home_buyer === null' class='mt-6 flex gap-6 flex-row justify-between'>

        <app-property-market-update></app-property-market-update>


        <app-property-erc [currentERC]="dataPropertyService.form.controls['has_mortgage'].value" [ercs$]='ercs$'></app-property-erc>

        <div
            class='max-w-[19rem] w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed]'>

            <div class='py-7 px-6'>
                <div class='text-xl font-semibold uppercase'>Custom product</div>
                <hr class='my-6 border-[#e0e6ed]' />
                <div class='min-w-[12rem]'
                     [ngClass]="(dataPropertyService.form.controls['IsEnabledCustomProduct'].touched && dataPropertyService.form.controls['IsEnabledCustomProduct'].errors) ? 'has-error' : ''">
                    <label for='IsEnabledCustomProduct'>Enable</label>
                    <ng-select
                        id='IsEnabledCustomProduct'
                        formControlName='IsEnabledCustomProduct'
                        [items]='dataPropertyService.boolTypes'
                        [searchable]='false'
                        [clearable]='false'
                        bindLabel='name'
                        bindValue='id'
                        class='custom-multiselect'
                    >
                    </ng-select>
                    <ng-container
                        *ngIf="dataPropertyService.form.controls['IsEnabledCustomProduct'].touched && dataPropertyService.form.controls['IsEnabledCustomProduct'].errors">
                        <p class='mt-1 text-danger'>Please fill</p>
                    </ng-container>
                </div>
                <div class='mt-6'
                     [ngClass]="(dataPropertyService.form.controls['total_fees'].touched && dataPropertyService.form.controls['total_fees'].errors) ? 'has-error' : ''">
                    <label for='totalFees'>Total fees</label>
                    <input id='totalFees' type='text' class='form-input'
                           formControlName='total_fees' />
                    <ng-container
                        *ngIf="dataPropertyService.form.controls['total_fees'].touched && dataPropertyService.form.controls['total_fees'].errors">
                        <p class='mt-1 text-danger'>Please fill</p>
                    </ng-container>
                </div>
                <div class='mt-6'
                     [ngClass]="(dataPropertyService.form.controls['initial_monthly_payment'].touched && dataPropertyService.form.controls['initial_monthly_payment'].errors) ? 'has-error' : ''">
                    <label for='initialMonthlyPayments'>Initial monthly payments</label>
                    <input id='initialMonthlyPayments' type='text' class='form-input'
                           formControlName='initial_monthly_payment' />
                    <ng-container
                        *ngIf="dataPropertyService.form.controls['initial_monthly_payment'].touched && dataPropertyService.form.controls['initial_monthly_payment'].errors">
                        <p class='mt-1 text-danger'>Please fill</p>
                    </ng-container>
                </div>
            </div>
        </div>

    </div>

    <hr class='my-6 border-[#e0e6ed]' />

    <div class='mt-6 flex gap-6 flex-row justify-between'>

        <div class='w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed]'>

            <div class='py-7 px-6'>
                <div class='text-xl font-semibold'><p>Notes</p></div>
                <hr class='my-6 border-[#e0e6ed]' />

                <div class='mt-6'>
                    <textarea id='notes' class='form-input' formControlName='notes'></textarea>
                </div>

            </div>
        </div>


        <div class='w-full'></div>

    </div>

    <div class='mt-6 w-auto overflow-x-auto whitespace-nowrap p-3'>
        <div class='flex flex-row justify-center gap-24'>
            <div>
                <button [disabled]='isSaveBTNDisabled' type='submit' class='btn btn-outline-success'>Save</button>
            </div>
            <div>
                <button (click)='location.back()' type='button' class='btn btn-outline-danger'>Cancel</button>
            </div>
        </div>
    </div>
</form>

<div
    *ngIf='(dataset$ | async) === null'
    class='h-screen flex flex-col items-center justify-center inset-0 bg-[#fafafa] dark:bg-[#060818] z-[60] grid place-content-center animate__animated'
>
    <svg width='64' height='64' viewBox='0 0 135 135' xmlns='http://www.w3.org/2000/svg' fill='#4361ee'>
        <path
            d='M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='-360 67 67' dur='2.5s'
                              repeatCount='indefinite' />
        </path>
        <path
            d='M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='360 67 67' dur='8s'
                              repeatCount='indefinite' />
        </path>
    </svg>
</div>


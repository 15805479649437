import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { HomeBuyer } from '../type';

@Injectable()
export class DataPropertyService {
    public form: FormGroup;

    public basicCalendarParams: FlatpickrOptions = {
        dateFormat: 'd-m-Y',
        position: 'form-input',
    };

    public propertyTypes = [
        { id: 'DetachedHouse', name: 'Detached House' },
        { id: 'SemiDetachedHouse', name: 'Semi Detached House' },
        { id: 'TerracedHouse', name: 'Terraced House' },
        { id: 'PurposeBuilt', name: 'Purpose Built' },
        { id: 'AboveShopCommercial', name: 'Above Shop Commercial' },
        { id: 'Studio', name: 'Studio' },
        { id: 'Conversion', name: 'Conversion' },
        { id: 'UnitBlock', name: 'Unit Block' },
    ];

    public activityTypes = [
        { id: 'NotActioned', name: 'Not actioned' },
        { id: 'AttemptedContact', name: 'Attempted contact' },
        { id: 'Contacted', name: 'Contacted' },
        { id: 'AppointmentMade', name: 'Appointment Made' },
        { id: 'Submitted', name: 'Submitted' },
        { id: 'InvalidExpiryDate', name: 'Invalid expiry date' },
        { id: 'LostOpportunity', name: 'Lost opportunity' },
        { id: 'NoOpportunity', name: 'No opportunity' },
    ];

    public investmentTypes = [
        { id: 'Investment', name: 'Buy to let' },
        { id: 'Residential', name: 'Residential' },
    ];

    public rateTypes = [
        { id: 'Fixed', name: 'Fixed' },
        { id: 'Tracker', name: 'Tracker' },
        { id: 'Discount', name: 'Discount' },
        { id: 'Capped', name: 'Capped' },
        { id: 'Variable', name: 'Variable' },
    ];

    public marketTypes = [
        { id: true, name: 'Custom' },
        { id: false, name: 'MDV' },
    ];

    public taxTypes = [
        { id: 'IndividualBaseRateTax', name: 'Individual base rate' },
        { id: 'HighRateTax', name: 'High rate' },
        { id: 'LtdCo', name: 'Ltd co' },
    ];

    public boolTypes = [
        { id: 'true', name: 'Yes' },
        { id: 'false', name: 'No' },
    ];

    public repaymentTypes = [
        { id: 'Repayment', name: 'Repayment' },
        { id: 'InterestOnly', name: 'Interest only' },
    ];

    public initData = {
        address: ['', [Validators.required]],
        postcode: [],
        purchase_price: [],
        purchase_date: [],
        type: ['DetachedHouse', Validators.required],
        owner_type: ['IndividualBaseRateTax', Validators.required],
        bedroom_count: [0],
        current_rental_income: [0],
        hmo: ['false'],
        mub: ['false'],
        has_mortgage: [true],

        lender_name: [''],
        outstanding_mortgage: [null, Validators.required],
        monthly_mortgage_payments: [null, Validators.required],
        product_end_term: [[]],
        original_term: [],
        interest_rate: [null, Validators.required],
        repayment_type: ['Repayment'],
        rate_type: ['Fixed'],
        mortgage_start_date: [[]],

        market_value: [0],
        use_custom_market: [false],
        rental_market: [0],
        use_custom_rental: [false],

        erc: [0],
        date: [null],

        investment_type: ['Investment', Validators.required],
        activity_status: ['NotActioned'],

        total_fees: [],
        initial_monthly_payment: [],
    };

    public constructor(public readonly fb: FormBuilder) {}

    public initForm(data): void {
        this.form = this.fb.group(data);
    }

    public prepareDataToRequest(
        form: FormGroup,
        ercs: { erc: number | null; date: Date[] }[],
        homeBuyer: HomeBuyer | null = null,
    ): any {
        const property = this.removeUndefinedValues({
            address: form.get('address')?.value,
            postcode: form.get('postcode')?.value,
            purchase_price: form.get('purchase_price')?.value ? Number(form.get('purchase_price')?.value) : undefined,
            purchase_date: DataPropertyService.parseDate(form.get('purchase_date')?.value),
            type: form.get('type')?.value,
            has_mortgage: form.get('has_mortgage')?.value,
            owner_type: form.get('owner_type')?.value,
            bedroom_count: form.get('bedroom_count')?.value,
            current_rental_income: form.get('current_rental_income')?.value,
            hmo: form.get('hmo')?.value ? form.get('hmo').value == 'true' : undefined,
            is_mub: form.get('mub')?.value ? form.get('mub').value == 'true' : undefined,
            investment_type: form.get('investment_type')?.value,
            activity_status: form.get('activity_status')?.value,
            use_custom_market: form.get('use_custom_market')?.value,
            use_custom_rental: form.get('use_custom_rental')?.value,
        });

        const t7t_data_custom = this.removeUndefinedValues({
            initial_monthly_payment: form.get('initial_monthly_payment')?.value,
            total_fees: form.get('total_fees')?.value,
        });

        const mortgageDetails = this.removeUndefinedValues({
            lender_name: form.get('lender_name')?.value,
            outstanding_mortgage: form.get('outstanding_mortgage')?.value,
            monthly_mortgage_payments: Number(form.get('monthly_mortgage_payments')?.value)
                ? Number(form.get('monthly_mortgage_payments')?.value)
                : undefined,
            product_end_term: DataPropertyService.parseDate(form.get('product_end_term')?.value),
            original_term: form.get('original_term')?.value,
            interest_rate: form.get('interest_rate')?.value,
            repayment_type: form.get('repayment_type')?.value,
            rate_type: form.get('rate_type')?.value,
            mortgage_start_date: DataPropertyService.parseDate(form.get('mortgage_start_date')?.value),
            ercs:
                ercs.length > 0
                    ? ercs.map(val => ({
                          ...val,
                          date: DataPropertyService.parseDate(val.date),
                      }))
                    : undefined,
            use_t7t_data_custom: form.get('IsEnabledCustomProduct')?.value
                ? form.get('IsEnabledCustomProduct').value == 'true'
                : undefined,
            t7t_data_custom: Object.keys(t7t_data_custom).length > 0 ? t7t_data_custom : undefined,
        });
        const marketsCustom = this.removeUndefinedValues({
            market_value: form.get('market_value') ? Number(form.get('market_value').value) : undefined,
            rental_value: form.get('rental_market') ? Number(form.get('rental_market').value) : undefined,
        });

        let sendingData: { [str: string]: unknown } = {};
        if (Object.keys(property).length > 0) sendingData = { ...property };
        if (Object.keys(mortgageDetails).length > 0 || form.get('has_mortgage')?.value)
            sendingData = { ...sendingData, mortgages: [mortgageDetails] };
        if (Object.keys(marketsCustom).length > 0) sendingData = { ...sendingData, markets_custom: marketsCustom };
        if (homeBuyer) sendingData = { ...sendingData, home_buyer: this.parseHomeBuyer(form) };
        return sendingData;
    }

    private parseHomeBuyer(form: FormGroup): object {
        return this.removeUndefinedValues({
            accepted_date: DataPropertyService.parseDate(form.get('accepted_date')?.value),
            mortgage_principle_expiry_date: DataPropertyService.parseDate(
                form.get('mortgage_principle_expiry_date')?.value,
            ),
            offer_date: DataPropertyService.parseDate(form.get('offer_date')?.value),
            is_ftb: form.get('is_ftb')?.value,
            offer_price: form.get('offer_price')?.value ? Number(form.get('offer_price')?.value) : undefined,
            offer_accepted: form.get('offer_accepted')?.value,
            mortgage_principle_required: form.get('mortgage_principle_required')?.value,
        });
    }

    private removeUndefinedValues(obj: object): object {
        const _obj = { ...obj };
        const values = Object.entries(obj);
        values.forEach(val => {
            if (val[1] === undefined) delete _obj[val[0]];
        });
        return _obj;
    }

    public static getInvestmentType(type: string): string {
        return type == 'Investment' ? 'Buy To Let' : type;
    }

    public static prepareDateToShow(field: string | Date | null): null | Date {
        if (!field) return null;
        return moment(field).toDate();
    }

    public static parseDate(field: Date[] | null[]): string | undefined {
        if (!field || field.length == 0 || field[0] === null) return undefined;
        return moment(field[0].toISOString()).format('YYYY-MM-DD');
    }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { filter, Observable, of, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { userNode, UserState } from '../shared/store/user/user.store';
import { Profile } from '../shared/type';
import { userSelectors } from '../shared/store/user/user.selectors';
import { AuthenticationService } from '../shared/services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class IsInvestorGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly authService: AuthenticationService,
        private readonly store: Store<{
            [userNode]: UserState;
        }>,
    ) {}

    public profile$: Observable<Profile | null> = this.store.pipe(select(userSelectors.selectProfile));

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const logout$ = () => {
            this.authService.logout();
            return this.router.navigate(['auth/login']);
        };
        return this.profile$.pipe(
            filter(profile => !!profile),
            switchMap(profile => (profile.type == 'investor' ? of(true) : logout$())),
        );
    }
}

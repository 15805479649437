import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { companyNode, CompanyState } from './company.store';
import { map, of, switchMap, withLatestFrom } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { CompanyService } from '../../services/company.service';
import { companyActions } from './company.actions';
import { companySelectors } from './company.selectors';

@Injectable()
export class CompanyEffects {
    public requestCompanyData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(companyActions.companyInfoRequest),
            withLatestFrom(this.store.pipe(select(companySelectors.selectCompanyInfo))),
            switchMap(([actionParams, company]) =>
                company ? of(company) : this.companyService.companyInfoRequest(actionParams.companyId),
            ),
            map(company => companyActions.companyInfoSet({ info: company })),
        ),
    );

    constructor(
        private store: Store<{ [companyNode]: CompanyState }>,
        private actions$: Actions,
        private companyService: CompanyService,
    ) {}
}

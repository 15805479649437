import { Component } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { select, Store } from '@ngrx/store';
import { insightNode, InsightState } from '../store/insight.store';
import { insightSelectors } from '../store/insight.selectors';
import { filter, first, map } from 'rxjs';
import { insightActions } from '../store/insight.actions';
import { OverviewService } from './overview.service';
import { ChartService } from '../../../shared/services/chart.service';

@Component({
    selector: 'app-insight-overview',
    templateUrl: './overview.component.html',
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'scale(0.95)',
                }),
                animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
            ]),
            transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
        ]),
    ],
})
export class OverviewComponent {
    public priority4Chart$ = this.store.pipe(
        select(insightSelectors.selectPriority4ChartData$),
        filter(data => data !== null),
        first(),
        map(data => ({
            xaxis: this.overviewService.apexXaxis(data.months),
            series: this.overviewService.apexSeries(data.months),
            count: data.count,
            pct: data.pct,
        })),
    );
    public priority5Chart$ = this.store.pipe(
        select(insightSelectors.selectPriority5ChartData$),
        filter(data => data !== null),
        first(),
        map(data => ({
            xaxis: this.overviewService.apexXaxis(data.months),
            series: this.overviewService.apexSeries(data.months),
            count: data.count,
            pct: data.pct,
        })),
    );

    public totalData$ = this.store.pipe(select(insightSelectors.selectTotalData$));
    public monthRate$ = this.store.pipe(select(insightSelectors.selectMonthRate$));
    public rentData$ = this.store.pipe(select(insightSelectors.selectRentalRate$));
    public variance$ = this.store.pipe(select(insightSelectors.selectVariance$));
    public appUsers$ = this.store.pipe(select(insightSelectors.selectAppUsersData$));

    constructor(
        public readonly overviewService: OverviewService,
        public readonly chartService: ChartService,
        private readonly store: Store<{
            [insightNode]: InsightState;
        }>,
    ) {
        this.store.dispatch(insightActions.insightOverviewDataRequest());
    }
}

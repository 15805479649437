import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { insightNode, InsightState } from '../store/insight.store';
import { insightSelectors } from '../store/insight.selectors';
import { insightActions } from '../store/insight.actions';
import { ApexAxisChartSeries, ApexChart, ApexPlotOptions, ApexXAxis } from 'ng-apexcharts';
import { filter, first } from 'rxjs';
import { ProductEndDateResponse } from '../insight.type';
import { map } from 'rxjs/operators';
import { ChartService } from '../../../shared/services/chart.service';
import { now } from 'moment';

@Component({
    selector: 'app-broker-performance',
    templateUrl: './product-end-date.component.html',
})
export class ProductEndDateComponent implements OnInit {
    public productEndDate$ = this.store.pipe(
        select(insightSelectors.selectProductEndDate$),
        filter(data => data != null),
        first(),
        map(data => ({
            currentYear: data.current_year,
            tableDataSet: this.createDatasetForTable(data),
            monthsChart: this.createDatasetForMonthsChart(data),
            pedChart: this.createDatasetForPedChart(data),
        })),
    );

    public plotOption: ApexPlotOptions = {
        bar: {
            ...this.chartService.apexConfigs.plotOptions.bar,
            distributed: true,
        },
    };

    public constructor(
        public readonly chartService: ChartService,
        private readonly store: Store<{
            [insightNode]: InsightState;
        }>,
    ) {}

    public ngOnInit() {
        this.store.dispatch(insightActions.productEndDateRequest());
    }

    private createDatasetForMonthsChart(data: ProductEndDateResponse): {
        xaxis: ApexXAxis;
        series: ApexAxisChartSeries;
        chart: ApexChart;
    } {
        const categories: string[] = [];
        const series: number[] = [];

        data.current_year.forEach(item => {
            categories.push(item.month);
            series.push(item.total);
        });
        return {
            xaxis: { categories },
            series: [{ name: 'Total', data: series }],
            chart: { ...this.chartService.apexConfigs.chart, height: 600 },
        };
    }

    private createDatasetForPedChart(data: ProductEndDateResponse): {
        xaxis: ApexXAxis;
        series: ApexAxisChartSeries;
        chart: ApexChart;
    } {
        const series = [
            {
                data: [
                    data.late_121.count,
                    data.late_120_61.count,
                    data.late_60_31.count,
                    data.late_30_pre_ped_90.count,
                    data.pre_ped_90_120.count,
                    data.pre_ped_121_180.count,
                    data.pre_ped_181.count,
                ],
            },
        ];

        const xaxis = {
            categories: [
                '-121 days',
                '-61 days -120 days',
                '-31 days - 60 days',
                '-30 days < 90 days pre-PED',
                '>90 days - < 120 days pre-PED',
                '>121 days - <180 days pre-PED',
                '>181 days pre-PED',
            ],
        };

        return { series, xaxis, chart: { ...this.chartService.apexConfigs.chart, height: 400 } };
    }

    private createDatasetForTable(data: ProductEndDateResponse): {
        title: string;
        count: number;
        pct: number;
        priority: string;
    }[] {
        return [
            {
                title: '-121 days ( less or equal -121)',
                count: data.late_121.count,
                pct: data.late_121.pct,
                priority: 'Priority 1',
            },
            {
                title: '-61 days - 120 days (between -61 days and -121 days)',
                count: data.late_120_61.count,
                pct: data.late_120_61.pct,
                priority: 'Priority 1',
            },
            {
                title: '-31 days - 60 days (between -31 and -60 days)',
                count: data.late_60_31.count,
                pct: data.late_60_31.pct,
                priority: 'Priority 4',
            },
            {
                title: '-30 days - <90 days pre-PED (between -30 and 90 days)',
                count: data.late_30_pre_ped_90.count,
                pct: data.late_30_pre_ped_90.pct,
                priority: 'Priority 5',
            },
            {
                title: '> 90 days - <120 days Pre PED (between 90 and 120 days)',
                count: data.pre_ped_90_120.count,
                pct: data.pre_ped_90_120.pct,
                priority: 'Priority 4',
            },
            {
                title: '>121 days to <180 daysD (between 121 and 180 days )',
                count: data.pre_ped_121_180.count,
                pct: data.pre_ped_121_180.pct,
                priority: 'Priority 3',
            },
            {
                title: '>181 days Pre-PED (more than 181 days until PED )',
                count: data.pre_ped_181.count,
                pct: data.pre_ped_181.pct,
                priority: 'Priority 1',
            },
        ];
    }

    protected readonly now = now;
}

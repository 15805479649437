import { createSelector } from '@ngrx/store';
import { companyNode, CompanyState } from './company.store';

export const selectFeature = (state: { [companyNode]: CompanyState }) => state[companyNode];

const selectCompanyInfo = createSelector(selectFeature, state => state.info);
const selectSelectedCompany = createSelector(selectFeature, state => state.selectedCompany);
const selectCompaniesList = createSelector(selectFeature, state => state.companiesList);

export const companySelectors = {
    selectCompanyInfo,
    selectSelectedCompany,
    selectCompaniesList,
};

import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { catchError, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Profile } from '../shared/type';
import { select, Store } from '@ngrx/store';
import { userSelectors } from '../shared/store/user/user.selectors';
import { userNode, UserState } from '../shared/store/user/user.store';
import { userActions } from '../shared/store/user/user.actions';
import { UserService } from '../shared/services/user.service';
import { companyActions } from '../shared/store/company/company.actions';

@Injectable({
    providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private readonly userService: UserService,
        private router: Router,
        private auth: AuthenticationService,
        private readonly store: Store<{
            [userNode]: UserState;
        }>,
    ) {}

    public profile$: Observable<Profile | null> = this.store.pipe(select(userSelectors.selectProfile));

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const auth$ = this.profile$.pipe(
            switchMap(profile => (profile !== null ? of(true) : getProfile$.pipe(map(() => true)))),
        );
        const getProfile$ = this.userService.profileRequest().pipe(
            tap(profile => {
                this.store.dispatch(userActions.profileSet({ profile }));
                this.store.dispatch(
                    companyActions.selectedCompanySet({
                        selectedCompany: profile.broker_company,
                        companiesList: profile.broker_companies,
                    }),
                );
            }),
            catchError(() => this.router.navigate(['not-activated'])),
        );
        return this.auth
            .isAuthenticated()
            .pipe(mergeMap(auth => (auth ? auth$ : this.router.navigate(['auth/login']))));
    }
}

import { Component, Input } from '@angular/core';
import { DataTableService } from '../../../../services/data-table.service';

@Component({
    selector: 'app-table-status-column',
    templateUrl: './table-status-column.component.html',
})
export class TableStatusColumnComponent {
    @Input()
    public value: { id: number; activity_status: string; notes: string; address: string };
    protected readonly DataTableService = DataTableService;
}

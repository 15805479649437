import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { map, tap } from 'rxjs';
import { Action } from '@ngrx/store';
import { appActions } from './app.actions';

@Injectable()
export class AppEffects implements OnInitEffects {
    public static accessTokenKey = 'accessToken';

    public saveTokenRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appActions.tokenSave),
            map(action => (action.token ? { ...action.token, saved_at: Date.now() } : null)),
            tap(token =>
                token
                    ? localStorage.setItem(AppEffects.accessTokenKey, JSON.stringify(token))
                    : localStorage.removeItem(AppEffects.accessTokenKey),
            ),
            map(token => appActions.tokenSet({ token })),
        ),
    );

    public deleteTokenRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appActions.reset),
            tap(() => localStorage.removeItem(AppEffects.accessTokenKey)),
            map(() => appActions.tokenSet(null)),
        ),
    );

    constructor(private actions$: Actions) {}

    public ngrxOnInitEffects(): Action {
        const token = localStorage.getItem(AppEffects.accessTokenKey);
        try {
            const parsedToken = JSON.parse(token);
            return appActions.tokenSet({ token: parsedToken });
        } catch (e) {
            localStorage.removeItem(AppEffects.accessTokenKey);
            return appActions.tokenSet({ token: null });
        }
    }
}

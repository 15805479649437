import { Component, Input } from '@angular/core';
import { DataPropertyService } from '../../../../services/data-property.service';
import { Property } from '../../../../type';

@Component({
    selector: 'app-property-market',
    templateUrl: './property-market.component.html',
})
export class PropertyMarketComponent {
    @Input()
    public property: Property | null;
    public constructor(protected readonly dataPropertyService: DataPropertyService) {}
}

<div class='mt-6'
     [ngClass]="(isSubmitForm && control.errors) ? 'has-error' : ''">
    <label for='address'>Address</label>
    <ng-select
        [items]='autocompleteVariants$ | async'
        [formControl]='control'
        [clearable]='false'
        [loading]='loadingState'
        (change)='selectAddress($event.address_id)'
        notFoundText='Start type an address...'
        bindLabel='address'
        bindValue='address_id'
        (search)='search$.next($event.term)'
        [searchFn]='searchFn'
        placeholder='Enter a address'
        class='custom-multiselect'
        id='address'
    >
    </ng-select>
    <ng-container *ngIf='isSubmitForm && control.errors?.required'>
        <p class='mt-1 text-danger'>Please fill</p>
    </ng-container>
    <ng-container *ngIf='isSubmitForm && control.errors?.invalidStreetNumber'>
        <p class='mt-1 text-danger'>Invalid street number</p>
    </ng-container>
</div>

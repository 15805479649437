import { Component } from '@angular/core';
import { map } from 'rxjs';
import { PriorityFilter, TableMetaOptions } from '../../shared/type';
import { DataTableService } from '../../shared/services/data-table.service';
import { DataPropertyService } from '../../shared/services/data-property.service';
import { colDef } from '@bhplugin/ng-datatable';
import { Store } from '@ngrx/store';
import { propertyNode, PropertyState } from '../../shared/store/property/property.store';
import { brokerNode, BrokerState } from '../../shared/store/broker/broker.store';
import { propertyActions } from '../../shared/store/property/property.actions';
import { TableColService } from '../../shared/services/table-col.service';

@Component({
    selector: 'app-rate-riser',
    templateUrl: './rate-riser.component.html',
})
export class RateRiserComponent {
    public readonly title = 'Mortgages with rate riser';
    public readonly priorityFilter: PriorityFilter = {
        filter: (val: string) => ({ filter: { leadgen: { rate_riser: { rating: { in: val } } } } }),
        value: [3, 4, 5],
    };

    public brokersTableSelectors: number[] = [];

    public cols: colDef[] = [
        { field: 'currentRate', title: 'Current Rate', type: 'number' },
        { field: 'rise', title: 'Rise', type: 'number', sort: false },
        { field: 'priorityRateRiser', title: 'Priority' },

        ...TableColService.ServiceTablesCols,

        {
            field: 'client-property-actions',
            title: 'Action',
            sort: false,
            headerClass: 'justify-center',
        },
    ];

    public rows$ = this.dataTableService.dataSetForPropertyTable().pipe(
        map(propertiesArr =>
            propertiesArr.map((item, key) => ({
                ...item,
                key,
                id: item.property.id,
                type: DataPropertyService.getInvestmentType(item.property.investment_type),
                currentRate: item.property.mortgages[0]?.interest_rate,
                rise: item.property.leadgen.rate_riser[0]?.interest_rate_riser,
                broker: this.createBrokerTableSelector(item.property.id, item.property.broker_ids[0]),
                priority: item.property.leadgen.rate_riser[0]?.rating,
                investmentType: item.property.investment_type.toLowerCase(),
                statusBadge: DataTableService.statusBadge(item.property.activity_status),
                notes: item.property.notes,

                portfolioValues: item.property.sums_of_profiles.portfolio_values,
                mortgagesOutstanding: item.property.sums_of_profiles.mortgages_outstanding,
                monthlyMortgagesPayments: item.property.sums_of_profiles.monthly_mortgages_payments,
                rentalsIncome: item.property.sums_of_profiles.rentals_income,
                growth: item.property.sums_of_profiles.growths,
                yield: item.property.sums_of_profiles.yields,
            })),
        ),
    );

    public constructor(
        private readonly dataTableService: DataTableService,
        private readonly store: Store<{
            [propertyNode]: PropertyState;
            [brokerNode]: BrokerState;
        }>,
    ) {}

    public updateContent(params: TableMetaOptions) {
        this.store.dispatch(propertyActions.propertiesRequest({ ...params }));
    }

    private createBrokerTableSelector(propertyId: number, brokerId: number): number {
        this.brokersTableSelectors[propertyId] = brokerId;
        return propertyId;
    }
}

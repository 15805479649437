import { createSelector } from '@ngrx/store';
import { brokerNode, BrokerState } from './broker.store';

export const selectFeature = (state: { [brokerNode]: BrokerState }) => state[brokerNode];

const selectBrokers = createSelector(selectFeature, state => state.brokers);

export const brokerSelectors = {
    selectBrokers,
};

import { createAction, props } from '@ngrx/store';
import { Broker } from '../../type';

const brokersRequest = createAction(
    '[BROKERS] brokers have been requested',
    props<{ perPage: number; page: number }>(),
);

export const brokersSet = createAction('[BROKERS] brokers have been set', props<{ brokers: Broker[] }>());

export const brokerActions = {
    brokersRequest,
    brokersSet,
};

import { Component, OnInit } from '@angular/core';
import { filter, map } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { insightNode, InsightState } from '../store/insight.store';
import { insightSelectors } from '../store/insight.selectors';
import { insightActions } from '../store/insight.actions';
import { OpportunityRatingOption, OpportunityRatingRequest } from '../insight.type';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { animate, style, transition, trigger } from '@angular/animations';
import { ChartService } from '../../../shared/services/chart.service';

@Component({
    selector: 'app-opportunity-summary-overview',
    templateUrl: './opportunity-summary.component.html',
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'scale(0.95)',
                }),
                animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
            ]),
            transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
        ]),
    ],
})
export class OpportunitySummaryComponent implements OnInit {
    protected opportunityData$ = this.store.pipe(
        select(insightSelectors.selectOpportunityData$),
        filter(data => data != null),
        map(data => ({
            total: this.generateTotalField(data),
            actioned: this.generateActionedField(data),
            submitted: this.generateColumnData(data, 'submitted'),
            notActioned: this.generateColumnData(data, 'notActioned'),
            lostOpportunity: this.generateColumnData(data, 'lostOpportunity'),
            noOpportunity: this.generateColumnData(data, 'noOpportunity'),
            series: this.generateSeries(data),
        })),
    );

    public chartColors = ['#21D69F', '#4709CD', '#3643FE', '#0193FF', '#00CBFF', '#F2385A', '#EAEAEA', '#EAEAEA'];

    protected categoriesList: { name: string; key: string }[] = [
        { name: 'Total', key: 'total' },
        { name: 'Product Expiry', key: 'productEndTerm' },
        { name: 'Early Review', key: 'earlyReview' },
        { name: 'Equity Available', key: 'equityAvailable' },
        { name: 'Rate Riser', key: 'rateRiser' },
    ];

    protected statusesList: { name: string; key: string }[] = [
        { name: 'Submitted', key: 'submitted' },
        { name: 'Not Actioned', key: 'notActioned' },
        { name: 'Attempted Contact', key: 'attemptedContact' },
        { name: 'Contacted', key: 'contacted' },
        { name: 'Appointment Made', key: 'appointmentMade' },
        { name: 'Invalid Expiry Date', key: 'invalidExpiryDate' },
        { name: 'Lost Opportunity', key: 'lostOpportunity' },
        { name: 'No Opportunity', key: 'noOpportunity' },
    ];

    public options = [4, 5];
    public selected = 5;
    public xaxis = {
        categories: this.categoriesList.map(item => item.name),
    };

    public constructor(
        public readonly chartService: ChartService,
        private readonly store: Store<{
            [insightNode]: InsightState;
        }>,
    ) {}

    public changeSelect(rating: number): void {
        this.store.dispatch(insightActions.opportunityDataRequest({ rating }));
    }

    public ngOnInit() {
        this.changeSelect(this.selected);
    }

    private generateActionedField(data: OpportunityRatingRequest): (number | string)[] {
        const list = ['attemptedContact', 'contacted', 'invalidExpiryDate', 'appointmentMade'];
        return Array.prototype.concat(
            this.generatePeriodData(this.calcSum(data, 'total', list)),
            this.generatePeriodData(this.calcSum(data, 'productEndTerm', list)),
            this.generatePeriodData(this.calcSum(data, 'earlyReview', list)),
            this.generatePeriodData(this.calcSum(data, 'equityAvailable', list)),
            this.generatePeriodData(this.calcSum(data, 'rateRiser', list)),
        );
    }

    private generateColumnData(data: OpportunityRatingRequest, key): (number | string)[] {
        return Array.prototype.concat(
            this.generatePeriodData(data.total[key]),
            this.generatePeriodData(data.productEndTerm[key]),
            this.generatePeriodData(data.earlyReview[key]),
            this.generatePeriodData(data.equityAvailable[key]),
            this.generatePeriodData(data.rateRiser[key]),
        );
    }

    private generateTotalField(data: OpportunityRatingRequest): (number | string)[] {
        const list = this.statusesList.map(status => status.key);
        return Array.prototype.concat(
            this.generatePeriodData(this.calcSum(data, 'total', list)),
            this.generatePeriodData(this.calcSum(data, 'productEndTerm', list)),
            this.generatePeriodData(this.calcSum(data, 'earlyReview', list)),
            this.generatePeriodData(this.calcSum(data, 'equityAvailable', list)),
            this.generatePeriodData(this.calcSum(data, 'rateRiser', list)),
        );
    }

    private calcSum(data: OpportunityRatingRequest, category: string, list: string[]): OpportunityRatingOption {
        let counts = {
            countByMonth: 0,
            countByYear: 0,
            monthPercentage: 0,
            yearPercentage: 0,
        } as OpportunityRatingOption;

        list.forEach(
            key =>
                (counts = {
                    countByMonth: counts.countByMonth + data[category][key].countByMonth,
                    monthPercentage: counts.monthPercentage + data[category][key].monthPercentage,
                    countByYear: counts.countByYear + data[category][key].countByYear,
                    yearPercentage: counts.yearPercentage + data[category][key].yearPercentage,
                }),
        );
        return counts;
    }

    private generatePeriodData(option: OpportunityRatingOption): (number | string)[] {
        return option
            ? [
                  `${option.countByMonth} (${option.monthPercentage.toFixed(1) + '%'})`,
                  `${option.countByYear} (${option.yearPercentage.toFixed(1) + '%'})`,
              ]
            : [0, '0%', 0, '0%'];
    }

    private generateSeries(data: OpportunityRatingRequest): ApexAxisChartSeries {
        return this.statusesList.map(status => ({
            name: status.name,
            data: this.categoriesList.map(category => data[category.key][status.key]?.countByMonth ?? 0),
        }));
    }
}

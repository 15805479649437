import { Component, Input } from '@angular/core';
import { PropertyService } from '../../../../services/property.service';

@Component({
    selector: 'app-mub-select',
    templateUrl: './mub-select.component.html',
})
export class MubSelectComponent {
    @Input() value: boolean;
    @Input() propertyId: number;

    public options = [
        { id: true, name: 'Yes' },
        { id: false, name: 'No' },
    ];

    public constructor(private readonly propertyService: PropertyService) {}

    public update() {
        this.propertyService.updateProperty(this.propertyId, null, { is_mub: Boolean(this.value) }).subscribe();
    }

    protected readonly Boolean = Boolean;
}

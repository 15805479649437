import { Component, EventEmitter, Input, Output } from '@angular/core';
import { colDef } from '@bhplugin/ng-datatable';
import { combineLatest, map, tap } from 'rxjs';
import { DataForPropertyTable, TableMetaOptions } from '../../../type';
import { DataTableService } from '../../../services/data-table.service';
import { select, Store } from '@ngrx/store';
import { brokerSelectors } from '../../../store/broker/broker.selectors';
import { Location } from '@angular/common';
import { propertyNode, PropertyState } from '../../../store/property/property.store';
import { brokerNode, BrokerState } from '../../../store/broker/broker.store';
import { propertyActions } from '../../../store/property/property.actions';
import { TableColService } from '../../../services/table-col.service';

@Component({
    selector: 'app-client-detail-property-table',
    templateUrl: './client-detail-property-table.component.html',
})
export class ClientDetailPropertyTableComponent {
    @Input() filter: unknown;
    @Input() investorId: number | undefined = undefined;

    @Output() tableUpdated = new EventEmitter<boolean>();

    public brokers$ = this.store.pipe(select(brokerSelectors.selectBrokers));

    public constructor(
        private readonly dataTableService: DataTableService,
        public readonly location: Location,
        private readonly store: Store<{
            [propertyNode]: PropertyState;
            [brokerNode]: BrokerState;
        }>,
    ) {}

    public cols: colDef[] = [...TableColService.dashboardClientViewCols];

    public rows$ = combineLatest([this.dataTableService.dataSetForPropertyTable(), this.brokers$]).pipe(
        tap(() => this.tableUpdated.emit(true)),
        map(
            ([propertiesArr, brokers]: [
                DataForPropertyTable[],
                {
                    id: number;
                    name: string;
                }[],
            ]) =>
                propertiesArr.map((item, key) => ({
                    ...item,
                    key,
                    id: item.property.id,
                    type: item.property.investment_type,
                    broker: brokers.find(broker => broker.id === item.property.broker_ids[0])?.name,
                    investmentType: item.property.investment_type.toLowerCase(),
                    statusBadge: DataTableService.statusBadge(item.property.activity_status),
                    investorId: this.investorId,

                    portfolioValues: item.property.sums_of_profiles.portfolio_values,
                    mortgagesOutstanding: item.property.sums_of_profiles.mortgages_outstanding,
                    monthlyMortgagesPayments: item.property.sums_of_profiles.monthly_mortgages_payments,
                    rentalsIncome: item.property.sums_of_profiles.rentals_income,
                    growth: item.property.sums_of_profiles.growths,
                    yield: item.property.sums_of_profiles.yields,
                })),
        ),
    );

    public updateTable(params: TableMetaOptions) {
        this.store.dispatch(propertyActions.propertiesRequest({ ...params }));
    }
}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PropertyService } from '../../services/property.service';
import { BehaviorSubject, combineLatestWith, first, mergeMap, ReplaySubject, switchMap } from 'rxjs';
import { DataPropertyService } from '../../services/data-property.service';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { Broker, Investor, Property } from '../../type';
import { ToastService } from '../../services/toast.service';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { RouterService } from '../../services/router.service';
import { PropertyMortgageUpdateComponent } from './components/property-mortgage-update/property-mortgage-update.component';
import { PropertyMarketUpdateComponent } from './components/property-market-update/property-market-update.component';
import { PropertyHomeBuyerUpdateComponent } from './components/property-home-buyer-update/property-home-buyer-update.component';

export const PropertyUpdateComponents = [
    PropertyMortgageUpdateComponent,
    PropertyMarketUpdateComponent,
    PropertyHomeBuyerUpdateComponent,
];

@Component({
    selector: 'app-property-update',
    templateUrl: './property-update.component.html',
})
export class PropertyUpdateComponent implements OnInit {
    public dataset$: BehaviorSubject<{ property: Property; investor: Investor; broker: Broker } | null> =
        new BehaviorSubject<{ property: Property; investor: Investor; broker: Broker } | null>(null);
    public property$: ReplaySubject<Property> = new ReplaySubject(1);
    public isSaveBTNDisabled: boolean;

    constructor(
        public readonly location: Location,
        private readonly propertyService: PropertyService,
        private readonly route: ActivatedRoute,
        public readonly dataPropertyService: DataPropertyService,
        private readonly dashboardService: DashboardService,
        private readonly routerService: RouterService,
    ) {}

    public purchaseDate: Date | null;
    public ercs$: BehaviorSubject<{ erc: number | null; date: Date[] }[]>;
    public isSubmitForm = false;

    public ngOnInit() {
        this.isSaveBTNDisabled = false;
        this.route.params
            .pipe(
                first(),
                switchMap(params => this.propertyService.propertyRequest(Number(params['id']))),
            )
            .subscribe(property => {
                if (property.property) this.property$.next(property.property);
                this.purchaseDate = DataPropertyService.prepareDateToShow(property.property.purchase_date);
                const _ercs =
                    property.property.mortgages[0]?.ercs.map(value => ({
                        erc: Number(value.erc),
                        date: [new Date(value.date)],
                    })) || [];
                this.ercs$ = new BehaviorSubject<{ erc: number | null; date: Date[] }[]>(_ercs);

                let propertyParams: object = {
                    address: [property.property.address],
                    postcode: [property.property.postcode],
                    purchase_price: [
                        property.property.purchase_price ? Number(property.property.purchase_price) : null,
                    ],
                    purchase_date: [null, [Validators.required]],
                    type: [property.property.type, Validators.required],
                    owner_type: [property.property.owner_type, Validators.required],
                    bedroom_count: [
                        Number(property.property.bedroom_count),
                        [Validators.required, Validators.pattern(new RegExp('^\\d+$'))],
                    ],
                    current_rental_income: [Number(property.property.current_rental_income), Validators.required],
                    hmo: [property.property.hmo ? String(property.property.hmo) : 'false'],
                    mub: [property.property.is_mub ? String(property.property.is_mub) : 'false'],
                    has_mortgage: [property.property.has_mortgage],

                    lender_name: [property.property.mortgages[0]?.lender_name],
                    outstanding_mortgage: [property.property.mortgages[0]?.outstanding_mortgage, Validators.required],
                    monthly_mortgage_payments: [
                        property.property.mortgages[0]?.monthly_mortgage_payments,
                        Validators.required,
                    ],
                    product_end_term: [
                        [DataPropertyService.prepareDateToShow(property.property.mortgages[0]?.product_end_term)],
                    ],
                    original_term: [
                        property.property.mortgages[0]?.original_term,
                        Validators.pattern(new RegExp('^\\d+$')),
                    ],
                    interest_rate: [property.property.mortgages[0]?.interest_rate, Validators.required],
                    repayment_type: [property.property.mortgages[0]?.repayment_type],
                    rate_type: [property.property.mortgages[0]?.rate_type],
                    mortgage_start_date: [null],

                    market_value: [property.property.markets_latest[0]?.market_value],
                    use_custom_market: [property.property.use_custom_market],
                    use_custom_rental: [property.property.use_custom_rental],
                    rental_market: [property.property.markets_latest[0]?.rental_value],

                    IsEnabledCustomProduct: [
                        property.property.mortgages[0]?.use_t7t_data_custom
                            ? String(property.property.mortgages[0]?.use_t7t_data_custom)
                            : 'false',
                    ],

                    erc: [property.property.mortgages[0]?.ercs[0]?.erc],
                    date: [null, [Validators.required]],

                    investment_type: [property.property.investment_type, Validators.required],
                    activity_status: [property.property.activity_status],

                    total_fees: [property.property.mortgages[0]?.t7t_data_custom?.total_fees],
                    initial_monthly_payment: [property.property.mortgages[0]?.t7t_data_custom?.total_fees],

                    notes: [property.property.notes],
                };

                if (property.property.home_buyer !== null)
                    propertyParams = this.homeBuyerJoin(propertyParams, property.property);

                this.dataPropertyService.initForm(propertyParams);
                this.dataset$.next(property);
            });
    }

    private homeBuyerJoin(propertyParams: object, property: Property) {
        const homeBuyerParams = {
            accepted_date: [property.home_buyer.accepted_date],
            is_ftb: [property.home_buyer.is_ftb],
            offer_accepted: [property.home_buyer.offer_accepted],
            mortgage_principle_expiry_date: [property.home_buyer.mortgage_principle_expiry_date],
            mortgage_principle_required: [property.home_buyer.mortgage_principle_required],
            offer_date: [property.home_buyer.offer_date],
            offer_price: [property.home_buyer.offer_price ? Number(property.home_buyer.offer_price) : null],
        };

        return { ...propertyParams, ...homeBuyerParams };
    }

    public submitForm(): void {
        this.isSubmitForm = true;
        const _form = Object.entries(this.dataPropertyService.form.controls);
        const dataForSending = _form.filter(value => value[1].touched);

        const isValid = (): boolean => {
            let _valid = true;
            dataForSending.forEach(value => {
                if (value[1].invalid) _valid = false;
            });
            return _valid;
        };

        const sendDate = () => {
            this.isSaveBTNDisabled = true;
            const _data: { [x: string]: AbstractControl<any, any> } = {};
            dataForSending.forEach(val => (_data[val[0]] = val[1]));
            const form = new FormGroup(_data);

            const requestData = (property: Property, ercs: { erc: number | null; date: Date[] }[]) =>
                this.propertyService.updateProperty(
                    property.id,
                    property.mortgages[0]?.id ?? null,
                    this.dataPropertyService.prepareDataToRequest(form, ercs, property.home_buyer),
                );

            this.dataset$
                .pipe(
                    combineLatestWith(this.ercs$),
                    mergeMap(([dataset, ercs]) => requestData(dataset.property, ercs)),
                    first(),
                )
                .subscribe({
                    next: () => {
                        this.isSaveBTNDisabled = false;
                        this.dashboardService.redirectToPrev(this.routerService.parentRoute());
                    },
                    error: () => {
                        this.isSaveBTNDisabled = false;
                        ToastService.showToastMessage('color-danger', 'Incorrect data');
                    },
                });
        };
        isValid()
            ? sendDate()
            : ToastService.showToastMessage(
                  'color-danger',
                  dataForSending.length == 0 ? "Changes haven't detected" : 'Please enter valid values',
              );
    }
}

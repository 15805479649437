import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { investorActions } from './investor.actions';
import { investorNode, InvestorState } from './investor.store';
import { InvestorService } from '../../services/investor.service';
import { tableActions } from '../table/table.actions';

@Injectable()
export class InvestorEffects {
    public requestInvestorsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(investorActions.investorsRequest),
            switchMap(actionParams =>
                this.investorService.investorRequest(actionParams.perPage, actionParams.page, actionParams.options),
            ),
            switchMap(response =>
                of(
                    investorActions.investorsSet({ investors: response.investors }),
                    tableActions.paginationDataSet({
                        pagination: response.paginationData,
                    }),
                ),
            ),
        ),
    );

    constructor(
        private store: Store<{ [investorNode]: InvestorState }>,
        private actions$: Actions,
        private investorService: InvestorService,
    ) {}
}

<div *ngIf='appUsers$ | async'>
    <div
        class='panel w-auto overflow-x-auto whitespace-nowrap p-3'>
        <div class='flex flex-row w-full'>
            <h5 class='text-2xl'>App users</h5>
        </div>
    </div>
</div>

<div *ngIf='(appUsers$ | async) as dataset' class='mt-6 panel grid grid-cols-2 gap-6'>

    <div class=''>
        <table class='table'>
            <thead>
            <tr>
                <th rowspan='2' style='width:25%'>Total clients invited</th>
                <th colspan='2'>Total</th>
                <th ngxTippy='Residential app users'
                    [tippyProps]="{placement: 'left'}" colspan='2'>Residential
                </th>
                <th ngxTippy='BTL app users (with BTL loaded property)'
                    [tippyProps]="{placement: 'left'}" colspan='2'>BTL
                </th>
            </tr>
            <tr>
                <th ngxTippy='The current month'
                    [tippyProps]="{placement: 'left'}">MTD
                </th>
                <th ngxTippy='The current year commencing 1.1'
                    [tippyProps]="{placement: 'left'}">YTD
                </th>
                <th ngxTippy='The current month'
                    [tippyProps]="{placement: 'left'}">MTD
                </th>
                <th ngxTippy='The current year commencing 1.1'
                    [tippyProps]="{placement: 'left'}">YTD
                </th>
                <th ngxTippy='The current month'
                    [tippyProps]="{placement: 'left'}">MTD
                </th>
                <th ngxTippy='The current year commencing 1.1'
                    [tippyProps]="{placement: 'left'}">YTD
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor='let data of dataset.tableDataset'>
                <td [ngxTippy]='data.tooltip'
                    [tippyProps]="{placement: 'left'}">{{data.title}}</td>
                <td>{{data.mtd1}}</td>
                <td>{{data.ytd1}}</td>
                <td>{{data.mtd2}}</td>
                <td>{{data.ytd2}}</td>
                <td>{{data.mtd3}}</td>
                <td>{{data.ytd3}}</td>
            </tr>
            </tbody>
        </table>
    </div>

    <div class='w-auto h-full'>
        <div class='overflow-hidden'>
            <apx-chart
                [xaxis]='dataset.chartDataset.xaxis'
                [series]='dataset.chartDataset.series'

                [chart]='dataset.chartDataset.chart'
                [grid]='chartService.apexConfigs.grid'
                [stroke]='chartService.apexConfigs.stroke'
                [dataLabels]='chartService.apexConfigs.labels'
                [colors]='chartService.apexConfigs.colors'
                [plotOptions]='chartService.apexConfigs.plotOptions'
                [legend]='chartService.apexConfigs.legend'
                [yaxis]='chartService.apexConfigs.yaxis'
                [fill]='chartService.apexConfigs.fill'
                [tooltip]='chartService.apexConfigs.tooltip'

                class='rounded-lg bg-white dark:bg-black'
            >
            </apx-chart>
        </div>
    </div>
</div>

<div
    *ngIf='(appUsers$ | async) === null'
    class='h-screen flex flex-col items-center justify-center inset-0 bg-[#fafafa] dark:bg-[#060818] z-[60] grid place-content-center animate__animated'
>
    <svg width='64' height='64' viewBox='0 0 135 135' xmlns='http://www.w3.org/2000/svg' fill='#4361ee'>
        <path
            d='M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='-360 67 67' dur='2.5s'
                              repeatCount='indefinite' />
        </path>
        <path
            d='M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='360 67 67' dur='8s'
                              repeatCount='indefinite' />
        </path>
    </svg>
</div>

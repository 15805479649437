import { createSelector } from '@ngrx/store';
import { insightNode, InsightState } from './insight.store';

export const selectFeature = (state: { [insightNode]: InsightState }) => state[insightNode];

const selectPriority5ChartData$ = createSelector(selectFeature, state => state.priority5);
const selectPriority4ChartData$ = createSelector(selectFeature, state => state.priority4);
const selectMonthRate$ = createSelector(selectFeature, state => state.monthRate);
const selectTotalData$ = createSelector(selectFeature, state => state.totalData);
const selectRentalRate$ = createSelector(selectFeature, state => state.rentalRate);
const selectVariance$ = createSelector(selectFeature, state => state.variance);
const selectAppUsersData$ = createSelector(selectFeature, state => state.appUsersData);
const selectProductEndDate$ = createSelector(selectFeature, state => state.productEndDate);
const selectAppUsers$ = createSelector(selectFeature, state => state.appUsers);

const brokersPerformance4$ = createSelector(selectFeature, state => state.brokersPerformance4);
const brokersPerformance5$ = createSelector(selectFeature, state => state.brokersPerformance5);

const selectOpportunityData$ = createSelector(selectFeature, state => state.opportunityData);

export const insightSelectors = {
    selectPriority5ChartData$,
    selectPriority4ChartData$,
    selectMonthRate$,
    selectTotalData$,
    selectRentalRate$,
    selectVariance$,
    selectAppUsersData$,
    selectAppUsers$,
    selectOpportunityData$,
    brokersPerformance4$,
    brokersPerformance5$,
    selectProductEndDate$,
};

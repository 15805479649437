<div
    class='panel w-auto overflow-x-auto whitespace-nowrap p-3'>
    <div class='flex flex-row w-full justify-between items-center'>
        <h5 class='text-2xl'>{{title}}</h5>
        <div *ngIf='(company$ | async) as company' class='flex flex-row gap-3'>
            <div>
                <div class='mb-2 w-full'>
                    <div class='text-white-dark'>IOS :</div>
                    <div>{{company.appstore_url ?? '-'}}</div>
                </div>
                <div class='mb-2 w-full'>
                    <div class='text-white-dark'>Android :</div>
                    <div>{{company.googlestore_url ?? '-'}}</div>
                </div>
            </div>
            <img class='w-20 h-20 rounded-2xl overflow-hidden object-cover'
                 [src]='(company$ | async)?.app_icon' alt='' />
        </div>
    </div>
</div>

<app-table-investors [tableName]='title'></app-table-investors>

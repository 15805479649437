import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { propertyNode, PropertyState } from '../../store/property/property.store';
import { BehaviorSubject, filter, first, Observable, switchMap, tap } from 'rxjs';
import { SelectedProperty } from '../../type';
import { propertySelectors } from '../../store/property/property.selectors';
import { propertyActions } from '../../store/property/property.actions';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PropertyInvestorInfoComponent } from './components/property-investor-info/property-investor-info.component';
import { PropertyPriorityComponent } from './components/property-priority/property-priority.component';
import { PropertyProductsModalComponent } from './components/property-products-modal/property-products-modal.component';
import { PropertyInfoComponent } from './components/property-info/property-info.component';
import { PropertyActivityLogComponent } from './components/property-activity-log/property-activity-log.component';
import { PropertyMortgageInfoComponent } from './components/property-mortgage-info/property-mortgage-info.component';
import { PropertyService } from '../../services/property.service';
import { PropertySharedOwnershipComponent } from './components/property-shared-ownership/property-shared-ownership.component';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { RouterService } from '../../services/router.service';
import { PropertyErcComponent } from './components/property-erc/property-erc.component';
import { PropertyMarketComponent } from './components/property-market/property-market.component';
import { ConfirmingModalComponent } from './components/property-info/confirming-modal/confirming-modal.component';
import { ToastService } from '../../services/toast.service';
import { PropertyHomeBuyerComponent } from './components/property-home-buyer/property-home-buyer.component';

export const PropertyDetailsComponents = [
    PropertyInvestorInfoComponent,
    PropertyPriorityComponent,
    PropertyProductsModalComponent,
    PropertyInfoComponent,
    ConfirmingModalComponent,
    PropertyActivityLogComponent,
    PropertyMortgageInfoComponent,
    PropertySharedOwnershipComponent,
    PropertyErcComponent,
    PropertyMarketComponent,
    PropertyHomeBuyerComponent,
];

@Component({
    selector: 'app-property-detail',
    templateUrl: './property-detail.component.html',
})
export class PropertyDetailComponent implements OnInit, OnDestroy {
    public property$: Observable<SelectedProperty> = this.store.pipe(
        select(propertySelectors.selectProperty),
        filter(selector => selector?.property != null),
    );

    public notes = '';
    public ercs$: BehaviorSubject<{ erc: number | null; date: Date[] }[]>;

    public constructor(
        public readonly location: Location,
        private readonly propertyService: PropertyService,
        private readonly route: ActivatedRoute,
        private readonly dashboardService: DashboardService,
        private readonly routerService: RouterService,
        private readonly store: Store<{
            [propertyNode]: PropertyState;
        }>,
    ) {}

    public ngOnInit(): void {
        this.property$.pipe(first()).subscribe(data => {
            this.notes = data.property.notes;
            const _ercs =
                data.property.mortgages[0]?.ercs.map(value => ({
                    erc: Number(value.erc),
                    date: [new Date(value.date)],
                })) || [];
            this.ercs$ = new BehaviorSubject<{ erc: number | null; date: Date[] }[]>(_ercs);
        });
        this.route.params
            .pipe(first())
            .subscribe(params =>
                this.store.dispatch(propertyActions.initProperty({ propertyId: Number(params['id']) })),
            );
    }

    public ngOnDestroy(): void {
        this.store.dispatch(propertyActions.setSelectedProperty(null));
    }

    public updateNote(): void {
        const update$ = date =>
            this.propertyService
                .updateProperty(date.property.id, null, { notes: this.notes })
                .pipe(tap(() => propertyActions.initProperty({ propertyId: date.property.id })));
        this.property$
            .pipe(
                first(),
                switchMap(date => update$(date)),
            )
            .subscribe({
                complete: () => ToastService.showToastMessage('color-success', 'Note has been updated'),
                error: () => ToastService.showToastMessage('color-danger', 'Something went wrong'),
            });
    }

    public redirectToPrevURL(): void {
        this.dashboardService.redirectToPrev(this.routerService.parentRoute());
    }
}

<div class='mt-3 flex flex-wrap gap-6 border-b border-white-light dark:border-[#191e3a]'>
    <div
        class='cursor-pointer -mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'
        [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': activeCompany.tabName === 'All' }"
        (click)="changeActiveCompany({tabName: 'All', company: null})"
    >
        All
    </div>
    <div
        class='cursor-pointer -mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'
        [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': activeCompany.tabName === 'Individual' }"
        (click)="changeActiveCompany({tabName: 'Individual', company: null})"
    >
        Individual
    </div>
    <div
        class='cursor-pointer -mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'
        [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': activeCompany.tabName === 'Shared' }"
        (click)="changeActiveCompany({tabName: 'Shared', company: null})"
    >
        Shared
    </div>
    <div *ngFor='let companyFromList of companiesList'>
        <ng-container *ngIf='editableId !== companyFromList.id; else editingName'>
            <div class='inline-flex'>
                <div
                    class='cursor-pointer -mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'
                    [ngClass]="{ '!border-white-light !border-b-white text-primary dark:!border-[#191e3a] dark:!border-b-black': activeCompany.company?.id === companyFromList.id }"
                    (click)="changeActiveCompany({tabName: 'Company', company: companyFromList})">{{companyFromList.name}}</div>
                <div (click)='startEdit(companyFromList.id, companyFromList.name)'
                     class='m-auto cursor-pointer block border border-transparent !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'>
                    <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M22 10.5V12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2H13.5'
                            stroke='currentColor' stroke-width='1.5' stroke-linecap='round' />
                        <path
                            d='M16.652 3.45506L17.3009 2.80624C18.3759 1.73125 20.1188 1.73125 21.1938 2.80624C22.2687 3.88124 22.2687 5.62415 21.1938 6.69914L20.5449 7.34795M16.652 3.45506C16.652 3.45506 16.7331 4.83379 17.9497 6.05032C19.1662 7.26685 20.5449 7.34795 20.5449 7.34795M16.652 3.45506L10.6872 9.41993C10.2832 9.82394 10.0812 10.0259 9.90743 10.2487C9.70249 10.5114 9.52679 10.7957 9.38344 11.0965C9.26191 11.3515 9.17157 11.6225 8.99089 12.1646L8.41242 13.9M20.5449 7.34795L14.5801 13.3128C14.1761 13.7168 13.9741 13.9188 13.7513 14.0926C13.4886 14.2975 13.2043 14.4732 12.9035 14.6166C12.6485 14.7381 12.3775 14.8284 11.8354 15.0091L10.1 15.5876M10.1 15.5876L8.97709 15.9619C8.71035 16.0508 8.41626 15.9814 8.21744 15.7826C8.01862 15.5837 7.9492 15.2897 8.03811 15.0229L8.41242 13.9M10.1 15.5876L8.41242 13.9'
                            stroke='currentColor' stroke-width='1.5' />
                    </svg>
                </div>
            </div>
        </ng-container>
        <ng-template #editingName>
            <div class='inline-flex'>
                <div>
                    <input autofocus [(ngModel)]='editableName' class='form-input border-none'>
                </div>
                <div
                    (click)='updateName()'
                    class='m-auto cursor-pointer block border border-transparent !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'>
                    <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M16.0303 10.0303C16.3232 9.73744 16.3232 9.26256 16.0303 8.96967C15.7374 8.67678 15.2626 8.67678 14.9697 8.96967L10.5 13.4393L9.03033 11.9697C8.73744 11.6768 8.26256 11.6768 7.96967 11.9697C7.67678 12.2626 7.67678 12.7374 7.96967 13.0303L9.96967 15.0303C10.2626 15.3232 10.7374 15.3232 11.0303 15.0303L16.0303 10.0303Z'
                            fill='#22c55e' />
                        <path fill-rule='evenodd' clip-rule='evenodd'
                              d='M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z'
                              fill='#22c55e' />
                    </svg>
                </div>
                <div
                    (click)='cancel()'
                    class='m-auto ml-2 cursor-pointer block border border-transparent !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'>
                    <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M10.0303 8.96965C9.73741 8.67676 9.26253 8.67676 8.96964 8.96965C8.67675 9.26255 8.67675 9.73742 8.96964 10.0303L10.9393 12L8.96966 13.9697C8.67677 14.2625 8.67677 14.7374 8.96966 15.0303C9.26255 15.3232 9.73743 15.3232 10.0303 15.0303L12 13.0607L13.9696 15.0303C14.2625 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2625 15.0303 13.9696L13.0606 12L15.0303 10.0303C15.3232 9.73744 15.3232 9.26257 15.0303 8.96968C14.7374 8.67678 14.2625 8.67678 13.9696 8.96968L12 10.9393L10.0303 8.96965Z'
                            fill='#f43f5e' />
                        <path fill-rule='evenodd' clip-rule='evenodd'
                              d='M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z'
                              fill='#f43f5e' />
                    </svg>
                </div>
            </div>
        </ng-template>

    </div>

    <ng-container *ngIf='!createFlag; else CreateCompany'>
        <div class='inline-flex' (click)='startCreating()'>
            <div
                class='cursor-pointer -mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'
            >
                <div class='m-auto flex flex-row'>
                    <p>Create new company</p>
                </div>
            </div>
            <div
                class='m-auto cursor-pointer block border border-transparent !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'>
                <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <circle cx='12' cy='12' r='10' stroke='currentColor' stroke-width='1.5' />
                    <path d='M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15' stroke='currentColor' stroke-width='1.5'
                          stroke-linecap='round' />
                </svg>
            </div>
        </div>
    </ng-container>
    <ng-template #CreateCompany>
        <div class='inline-flex'>
            <div>
                <input autofocus [(ngModel)]='createName' class='form-input border-none'>
            </div>
            <div
                (click)='createCompany()'
                class='m-auto cursor-pointer block border border-transparent !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'>
                <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M16.0303 10.0303C16.3232 9.73744 16.3232 9.26256 16.0303 8.96967C15.7374 8.67678 15.2626 8.67678 14.9697 8.96967L10.5 13.4393L9.03033 11.9697C8.73744 11.6768 8.26256 11.6768 7.96967 11.9697C7.67678 12.2626 7.67678 12.7374 7.96967 13.0303L9.96967 15.0303C10.2626 15.3232 10.7374 15.3232 11.0303 15.0303L16.0303 10.0303Z'
                        fill='#22c55e' />
                    <path fill-rule='evenodd' clip-rule='evenodd'
                          d='M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z'
                          fill='#22c55e' />
                </svg>
            </div>
            <div
                (click)='cancel()'
                class='m-auto ml-2 cursor-pointer block border border-transparent !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black'>
                <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M10.0303 8.96965C9.73741 8.67676 9.26253 8.67676 8.96964 8.96965C8.67675 9.26255 8.67675 9.73742 8.96964 10.0303L10.9393 12L8.96966 13.9697C8.67677 14.2625 8.67677 14.7374 8.96966 15.0303C9.26255 15.3232 9.73743 15.3232 10.0303 15.0303L12 13.0607L13.9696 15.0303C14.2625 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2625 15.0303 13.9696L13.0606 12L15.0303 10.0303C15.3232 9.73744 15.3232 9.26257 15.0303 8.96968C14.7374 8.67678 14.2625 8.67678 13.9696 8.96968L12 10.9393L10.0303 8.96965Z'
                        fill='#f43f5e' />
                    <path fill-rule='evenodd' clip-rule='evenodd'
                          d='M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z'
                          fill='#f43f5e' />
                </svg>
            </div>
        </div>
    </ng-template>
</div>
<ng-content></ng-content>
<div *ngIf='activeCompany.company as company' class='flex flex-row gap-6 justify-between p-2'>
    <div>
        <div class='font-light text-gray-400'>
            Company name:
        </div>
        <div class='text-xl'>
            {{company.name}}
        </div>
    </div>

    <div>
        <div class='font-light text-gray-400'>
            Company phone:
        </div>
        <div class='text-xl'>
            {{company.phone}}
        </div>
    </div>

    <div>
        <div class='font-light text-gray-400'>
            Company email:
        </div>
        <div class='text-xl'>
            {{company.email}}
        </div>
    </div>

    <div class=''>
        <div class='font-light text-gray-400'>
            Company website:
        </div>
        <div class='text-xl'>
            {{company.website}}
        </div>
    </div>
</div>

import { createSelector } from '@ngrx/store';
import { dashboardLayoutNode, DashboardLayoutState } from './store';

const selectFeature = (state: { [dashboardLayoutNode]: DashboardLayoutState }) => state[dashboardLayoutNode];

const selectMainLoaderCondition = createSelector(selectFeature, state => state.showMainLoader);
const selectPrevURL = createSelector(selectFeature, state => state.prevUrl);

const selectSidebarCondition = createSelector(selectFeature, state => state.toggleSidebar);
const selectContactRequiredCount = createSelector(selectFeature, state => state.contactRequestCount);

export const dashboardSelectors = {
    selectMainLoaderCondition,
    selectPrevURL,
    selectSidebarCondition,
    selectContactRequiredCount,
};

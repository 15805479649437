import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { propertyActions } from './property.actions';
import { propertyNode, PropertyState } from './property.store';
import { map } from 'rxjs/operators';
import { brokerNode, BrokerState } from '../broker/broker.store';
import { PropertyService } from '../../services/property.service';
import { tableActions } from '../table/table.actions';

@Injectable()
export class PropertyEffects {
    public requestPropertiesData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(propertyActions.propertiesRequest),
            switchMap(actionParams =>
                this.propertyService.propertiesRequest(actionParams.perPage, actionParams.page, actionParams.options),
            ),
            switchMap(response =>
                of(
                    propertyActions.propertiesSet({ properties: response.properties }),
                    tableActions.paginationDataSet({
                        pagination: response.meta,
                    }),
                    propertyActions.investorsSet({ investors: response.investors }),
                    propertyActions.brokersSet({ brokers: response.brokers }),
                    propertyActions.investorCompaniesSet({ companies: response.companies }),
                ),
            ),
        ),
    );

    public propertyRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(propertyActions.initProperty),
            tap(() => this.store.dispatch(propertyActions.setSelectedProperty(null))),
            switchMap(action => this.propertyService.propertyRequest(action.propertyId)),
            map(property => propertyActions.setSelectedProperty(property)),
        ),
    );

    constructor(
        private store: Store<{ [propertyNode]: PropertyState; [brokerNode]: BrokerState }>,
        private actions$: Actions,
        private propertyService: PropertyService,
    ) {}
}

<div class=''>
    <nav
        class='sidebar fixed top-0 bottom-0 z-50 h-full min-h-screen w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300'>
        <div class='h-full bg-white dark:bg-[#0e1726]'>
            <div class='flex items-center justify-between px-4 py-3'>
                <div
                    class='flex gap-6 items-center space-x-1.5 ltr:ml-auto rtl:mr-auto rtl:space-x-reverse dark:text-[#d0d2d6] sm:flex-1 ltr:sm:ml-0 sm:rtl:mr-0 lg:space-x-2'>
                    <div class='font-bold flex flex-row items-center'>
                        <div class='dropdown shrink-0 mr-5'>
                            <div hlMenu class='!block'>
                                <button type='button' hlMenuButton class='group relative block'>
                                    <img class='h-9 w-9 rounded-full object-cover saturate-50 group-hover:saturate-100'
                                         [src]='(company$ | async)?.app_icon' alt='' />
                                </button>
                                <ul
                                    *hlMenuItems
                                    @toggleAnimation
                                    class='top-11 w-[230px] !py-0 font-semibold text-dark ltr:ml-1 rtl:mr-0 dark:text-white-dark dark:text-white-light/90'
                                >
                                    <li>
                                        <div class='flex items-center px-2 py-3'>
                                            <div class='flex-none'>
                                                <img class='h-9 w-9 rounded-full object-cover saturate-50 group-hover:saturate-100'
                                                     [src]='(company$ | async)?.app_icon' alt='' />
                                            </div>
                                            <div class='truncate ltr:pl-4 rtl:pr-4'>
                                                <h4 class='text-base'>
                                                    {{(profile$ | async)?.name}}
                                                    <span
                                                        class='rounded bg-success-light capitalize px-1 text-xs text-success ltr:ml-2 rtl:ml-2'>
                                            {{(profile$ | async)?.permission}}
                                        </span>
                                                </h4>
                                                <a class='text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white'>
                                                    {{(profile$ | async)?.email}}
                                                </a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <a (click)='logOut()' routerLink='/auth/login' *hlMenuItem='let menuItem'
                                           class='!py-3 text-danger'>
                                            <svg
                                                class='h-4.5 w-4.5 shrink-0 rotate-90 ltr:mr-2 rtl:ml-2'
                                                width='18'
                                                height='18'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    opacity='0.5'
                                                    d='M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195'
                                                    stroke='currentColor'
                                                    stroke-width='1.5'
                                                    stroke-linecap='round'
                                                />
                                                <path
                                                    d='M12 15L12 2M12 2L15 5.5M12 2L9 5.5'
                                                    stroke='currentColor'
                                                    stroke-width='1.5'
                                                    stroke-linecap='round'
                                                    stroke-linejoin='round'
                                                />
                                            </svg>

                                            Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <ng-container *ngIf='(companyList$ | async).length > 1; else companyName'>
                            <ng-select
                                [items]='companyList$ | async'
                                [(ngModel)]='selectedCompany'
                                bindLabel='business_name'
                                bindValue='id'
                                [searchable]='false'
                                [clearable]='false'
                                (change)='switchCompany()'
                                class='min-w-[12rem] custom-multiselect'
                            >
                            </ng-select>
                        </ng-container>
                        <ng-template #companyName>
                            <div class='font-bold'>{{(company$ | async).business_name}}</div>
                        </ng-template>
                    </div>
                </div>
                <a
                    class='collapse-icon flex h-8 w-8 items-center rounded-full transition duration-300 hover:bg-gray-500/10 hover:text-primary rtl:rotate-180 dark:text-white-light dark:hover:bg-dark-light/10'
                    (click)='toggleSidebar()'
                >
                    <svg class='m-auto h-5 w-5' width='20' height='20' viewBox='0 0 24 24' fill='none'
                         xmlns='http://www.w3.org/2000/svg'>
                        <path d='M13 19L7 12L13 5' stroke='currentColor' stroke-width='1.5' stroke-linecap='round'
                              stroke-linejoin='round' />
                        <path
                            opacity='0.5'
                            d='M16.9998 19L10.9998 12L16.9998 5'
                            stroke='currentColor'
                            stroke-width='1.5'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                        />
                    </svg>
                </a>
            </div>
            <ng-scrollbar class='relative !h-[calc(100vh-80px)]' appearance='compact'>
                <ul class='relative space-y-0.5 p-4 py-0 font-semibold'>

                    <h2 class='-mx-4 mb-1 flex items-center bg-white-light/30 py-3 px-7 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]'>
                        <svg
                            class='hidden h-5 w-4 flex-none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            stroke-width='1.5'
                            fill='none'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                        >
                            <line x1='5' y1='12' x2='19' y2='12'></line>
                        </svg>
                        <span>SERVICES</span>
                    </h2>

                    <li class='menu nav-item'>
                        <a routerLink='/investor/properties'
                           [queryParams]='{page: 1, perPage: 10}'
                           class='nav-link group' routerLinkActive='active'
                           (click)='toggleMobileMenu()'>
                            <div class='flex items-center'>
                                <svg class='shrink-0 text-amber-100 group-hover:!text-primary' width='20' height='20'
                                     viewBox='0 0 24 24' fill='none'
                                     xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M22 22H2' stroke='#1C274C' stroke-width='1.5' stroke-linecap='round'
                                          fill='currentColor' />
                                    <path opacity='0.5'
                                          d='M21 22V14.5C21 13.6716 20.3284 13 19.5 13H16.5C15.6716 13 15 13.6716 15 14.5V22'
                                          stroke='#1C274C' stroke-width='1.5' fill='currentColor' />
                                    <path
                                        d='M15 22V5C15 3.58579 15 2.87868 14.5607 2.43934C14.1213 2 13.4142 2 12 2C10.5858 2 9.87868 2 9.43934 2.43934C9 2.87868 9 3.58579 9 5V22'
                                        stroke='none' stroke-width='1.5' fill='currentColor' />
                                    <path opacity='0.5'
                                          d='M9 22V9.5C9 8.67157 8.32843 8 7.5 8H4.5C3.67157 8 3 8.67157 3 9.5V22'
                                          stroke='none' stroke-width='1.5' fill='currentColor' />
                                </svg>


                                <span
                                    class='text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark'>
                                    Properties
                                </span>
                            </div>
                        </a>
                    </li>

                </ul>
            </ng-scrollbar>
        </div>
    </nav>
</div>

import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from 'angular-custom-modal/modal.component';
import { DataPropertyService } from '../../services/data-property.service';
import { PropertyService } from '../../services/property.service';
import { first } from 'rxjs';
import { DataTableService } from '../../services/data-table.service';

@Component({
    selector: 'app-change-status-modal',
    templateUrl: './change-status-modal.component.html',
})
export class ChangeStatusModalComponent {
    public data: { id: number; status: string; notes: string; address: string };

    @ViewChild('modal') modal: ModalComponent;

    public isDisableControls = false;

    public lostOpportunityOptions = [
        { id: 'None', name: 'None' },
        { id: 'ClientTransactedOtherBroker', name: 'Client Transacted Other Broker' },
        { id: 'ClientTransactedWithLender', name: 'Client Transacted With Lender' },
    ];

    public noOpportunityOptions = [
        { id: 'None', name: 'None' },
        { id: 'PropertySold', name: 'Property sold' },
        { id: 'NoMortgageOption', name: 'No mortgage option' },
    ];

    public noOpportunitySelectValue = 'None';
    public lostOpportunitySelectValue = 'None';

    public constructor(
        public readonly dataPropertyService: DataPropertyService,
        private readonly propertyService: PropertyService,
        private readonly dataTableService: DataTableService,
    ) {}

    public open(value: { id: number; activity_status: string; notes: string; address: string }) {
        this.data = { ...value, status: value.activity_status };
        this.modal.open();
    }

    public save(): void {
        this.isDisableControls = true;
        const body: any = {
            activity_status: this.data.status,
            notes: this.data.notes,
        };

        if (this.data.status == 'LostOpportunity')
            body.activity_status_lost_opportunity = this.lostOpportunitySelectValue;

        if (this.data.status == 'NoOpportunity') body.activity_status_no_opportunity = this.noOpportunitySelectValue;

        if (this.data.status == 'Contacted') body.client_invoked_at = false;

        this.propertyService
            .updateProperty(this.data.id, null, body)
            .pipe(first())
            .subscribe(() => {
                this.isDisableControls = false;
                this.dataTableService.updateTableContent();
                this.modal.close();
            });
    }
}

import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { userNode, UserState } from '../../shared/store/user/user.store';
import { userSelectors } from '../../shared/store/user/user.selectors';
import { shareReplay } from 'rxjs';

@Component({
    selector: 'app-properties',
    templateUrl: './properties.component.html',
})
export class PropertiesComponent {
    public profile$ = this.store.pipe(select(userSelectors.selectProfile), shareReplay(1));

    public constructor(
        private readonly store: Store<{
            [userNode]: UserState;
        }>,
    ) {}
}

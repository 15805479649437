import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent } from 'angular-custom-modal/modal.component';
import { Investor, TableMetaOptions } from '../../../../../../../type';
import { DataPropertyService } from '../../../../../../../services/data-property.service';
import { DataTableService } from '../../../../../../../services/data-table.service';

@Component({
    selector: 'app-action-on-investor-modal',
    templateUrl: './action-on-investor-modal.component.html',
})
export class ActionOnInvestorModalComponent {
    @ViewChild('modal') modal: ModalComponent;

    @Input() investor: Investor;

    @Output()
    public updateContent = new EventEmitter<TableMetaOptions>();

    public activeTab: string;

    public constructor(
        protected readonly dataPropertyService: DataPropertyService,
        protected readonly dataTableService: DataTableService,
    ) {}

    public openModal(investor: Investor): void {
        this.investor = investor;
        this.activeTab = 'call';
        this.modal.open();
    }

    public closeModal(): void {
        this.modal.close();
    }

    public freshContent() {
        this.dataTableService.updateTableContent();
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, first, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { animate, style, transition, trigger } from '@angular/animations';
import { Investor, InvestorNote } from '../../../../../../../type';
import { userSelectors } from '../../../../../../../store/user/user.selectors';
import { ToastService } from '../../../../../../../services/toast.service';
import { userNode, UserState } from '../../../../../../../store/user/user.store';
import { InvestorNoteService } from '../../../../../../../services/investor-note.service';

type InvestorNoteWithBrokers = InvestorNote & { brokerName: string };

@Component({
    selector: 'app-client-note',
    templateUrl: './client-note.component.html',
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scale(0.95)' }),
                animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
            ]),
            transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
        ]),
    ],
})
export class ClientNoteComponent implements OnInit {
    protected notes$: BehaviorSubject<InvestorNoteWithBrokers[] | null> = new BehaviorSubject<
        InvestorNoteWithBrokers[] | null
    >(null);
    public profile$ = this.store.pipe(select(userSelectors.selectProfile));

    @Input() investor: Investor;

    protected isDisableControls = false;
    protected newNote: string;
    public updatingNoteId: number;
    public updatingNoteText: string;

    public constructor(
        private readonly investorNoteService: InvestorNoteService,
        private readonly store: Store<{
            [userNode]: UserState;
        }>,
    ) {}

    public ngOnInit(): void {
        this.freshNotes();
    }

    private freshNotes() {
        this.newNote = '';
        this.updatingNoteId = undefined;
        this.updatingNoteText = undefined;
        this.isDisableControls = false;
        this.investorNoteService
            .getInvestorNotes(this.investor.id)
            .pipe(first())
            .subscribe(response =>
                this.notes$.next(
                    response.notes.map(note => ({
                        ...note,
                        brokerName: response.brokers.find(broker => broker.id == note.broker_id).name,
                    })),
                ),
            );
    }

    public create() {
        this.isDisableControls = true;
        const successResponse = () => {
            ToastService.showToastMessage('color-success', 'Note has been added');
            this.freshNotes();
        };

        const errorResponse = () => {
            ToastService.showToastMessage('color-danger', 'Error');
        };

        this.profile$
            .pipe(
                switchMap(profile =>
                    this.investorNoteService.createInvestorNote(this.investor.id, profile.id, this.newNote),
                ),
                first(),
            )
            .subscribe({
                next: () => successResponse(),
                error: () => errorResponse(),
            });
    }

    public startUpdate(note: InvestorNoteWithBrokers): void {
        this.updatingNoteId = note.id;
        this.updatingNoteText = note.text;
    }

    public finishUpdateNote(): void {
        this.isDisableControls = true;
        const successResponse = () => {
            ToastService.showToastMessage('color-success', 'Note has been updated');
            this.freshNotes();
        };

        const errorResponse = () => {
            this.isDisableControls = false;
            ToastService.showToastMessage('color-danger', 'Error');
        };

        this.investorNoteService.updateInvestorNote(this.updatingNoteId, this.updatingNoteText).subscribe({
            next: () => successResponse(),
            error: () => errorResponse(),
        });
    }

    public deleteNote(noteId: number): void {
        this.isDisableControls = true;
        const successResponse = () => {
            ToastService.showToastMessage('color-success', 'Note has been deleted');
            this.freshNotes();
        };

        const errorResponse = () => {
            this.isDisableControls = false;
            ToastService.showToastMessage('color-danger', 'Error');
        };

        this.investorNoteService.deleteInvestorNote(noteId).subscribe({
            next: () => successResponse(),
            error: () => errorResponse(),
        });
    }
}

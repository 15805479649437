<div class='mb-5 flex flex-col gap-5 md:flex-row md:items-center'>
    <div class='flex flex-row gap-10 ltr:ml-auto rtl:mr-auto'>
        <ng-select *ngIf='priorityFilter'
                   [items]='priorityOption'
                   [(ngModel)]='priorityFilter.value'
                   [multiple]='true'
                   [clearable]='false'
                   bindLabel='name'
                   bindValue='id'
                   placeholder='Select an option'
                   class='custom-multiselect'
                   (change)='selectorChanged()'
        >
        </ng-select>
        <ng-select
            [items]='statusOptions'
            [(ngModel)]='statusFilter'
            [clearable]='false'
            [searchable]='false'
            placeholder='Status'
            bindLabel='name'
            bindValue='id'
            class='w-28 custom-multiselect'
            (change)='selectorChanged()'
        >

        </ng-select>

        <ng-container *ngIf='PermissionService.checkCredentials((profile$ |async), PermissionService.managerRoles)'>
            <ng-select
                [items]='brokersOption$ | async'
                [(ngModel)]='brokerFilter'
                [clearable]='false'
                [searchable]='false'
                bindLabel='name'
                bindValue='id'
                placeholder='Advisor'
                class='w-44 custom-multiselect'
                (change)='selectorChanged()'
            >
            </ng-select>
        </ng-container>

        <input [formControl]='searchInput' type='text' class='form-input w-auto' placeholder='Search...' />
    </div>
</div>

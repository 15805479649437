import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { brokerActions } from './broker.actions';
import { brokerNode, BrokerState } from './broker.store';
import { BrokerService } from '../../services/broker.service';

@Injectable()
export class BrokerEffects {
    public requestBrokersData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(brokerActions.brokersRequest),
            switchMap(actionParams => this.brokerService.brokersRequest(actionParams.page, actionParams.perPage)),
            map(response => brokerActions.brokersSet({ brokers: response.brokers })),
        ),
    );

    constructor(
        private store: Store<{ [brokerNode]: BrokerState }>,
        private actions$: Actions,
        private brokerService: BrokerService,
    ) {}
}

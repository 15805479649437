import { createAction, props } from '@ngrx/store';
import { Broker, Investor, PaginationMetaData, Property, SelectedProperty } from '../../type';
import { CompanyProfile } from '../../../dashboard/company/company.type';

const propertiesRequest = createAction(
    '[PROPERTY] properties has been requested',
    props<{
        perPage: number;
        page: number;
        options?: { filters?: any; search?: string; sort?: { columnName: string; direction: string } };
    }>(),
);

const propertiesSet = createAction('[PROPERTY] properties have been set', props<{ properties: Property[] }>());
const setSelectedProperty = createAction('[PROPERTY] property has been set', props<SelectedProperty | null>());
const initProperty = createAction('[PROPERTY] property has been requested', props<{ propertyId: number }>());

export const investorsSet = createAction('[PROPERTY] investors have been set', props<{ investors: Investor[] }>());
export const investorCompaniesSet = createAction(
    '[PROPERTY] investor-companies have been set',
    props<{
        companies: CompanyProfile[];
    }>(),
);
export const brokersSet = createAction(
    "[PROPERTY] property's brokers have been set",
    props<{
        brokers: Broker[];
    }>(),
);

export const paginationDataSet = createAction(
    '[PROPERTY] pagination settings have been set',
    props<{ pagination: PaginationMetaData }>(),
);

export const propertyActions = {
    propertiesRequest,
    propertiesSet,
    investorsSet,
    paginationDataSet,
    setSelectedProperty,
    initProperty,
    brokersSet,
    investorCompaniesSet,
};

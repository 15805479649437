import { Component, Input } from '@angular/core';
import { PaginationMetaData, SumOfPropertiesProfiles } from '../../type';
import { select, Store } from '@ngrx/store';
import { tableSelectors } from '../../store/table/table.selectors';
import { tableNode, TableState } from '../../store/table/table.store';
import { BehaviorSubject, combineLatestWith, map, Observable } from 'rxjs';

@Component({
    selector: 'app-property-portfolio-totals',
    templateUrl: './property-portfolio-totals.component.html',
})
export class PropertyPortfolioTotalsComponent {
    @Input() set profile(data: SumOfPropertiesProfiles | null) {
        this._profile$.next(data);
    }

    private tablePagination$ = this.store.pipe(select(tableSelectors.selectPagination));

    public _profile$: BehaviorSubject<SumOfPropertiesProfiles | null> =
        new BehaviorSubject<SumOfPropertiesProfiles | null>(null);

    public yields$: Observable<number> = this._profile$.pipe(
        combineLatestWith(this.tablePagination$),
        map(([profile, pagination]: [SumOfPropertiesProfiles | null, PaginationMetaData]) =>
            profile?.yields ? profile.yields / pagination.total : 0,
        ),
    );

    public growths$: Observable<number> = this._profile$.pipe(
        combineLatestWith(this.tablePagination$),
        map(([profile, pagination]: [SumOfPropertiesProfiles | null, PaginationMetaData]) =>
            profile?.growths ? profile.growths / pagination.total : 0,
        ),
    );

    public constructor(
        private readonly store: Store<{
            [tableNode]: TableState;
        }>,
    ) {}
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { insightNode, InsightState } from './insight.store';
import { insightActions } from './insight.actions';
import { InsightService } from '../insight.service';

@Injectable()
export class InsightEffects {
    public insightOverviewData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(insightActions.insightOverviewDataRequest),
            switchMap(() => this.insightService.overviewDataRequest()),
            switchMap(response =>
                of(
                    insightActions.priority4ChartSet({ data: response.priority_rating.rating_4 }),
                    insightActions.setAppUsersData({ ...response.app_users }),
                    insightActions.priority5ChartSet({ data: response.priority_rating.rating_5 }),
                    insightActions.setTotalData({
                        properties: response.properties_total,
                        propertiesAvg: response.properties_total_avg_value,
                        clientRevenue: response.total_avg_client_revenue,
                    }),
                    insightActions.setRetentionRate({ ...response.retention_rate }),
                    insightActions.setVariance({ ...response.variance }),
                    insightActions.set3MonthRate({ ...response.properties_3months_back_rate }),
                ),
            ),
        ),
    );

    public opportunityData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(insightActions.opportunityDataRequest),
            switchMap(({ rating }) => this.insightService.opportunityDataRequest(rating)),
            map(data => insightActions.setOpportunityData(data)),
        ),
    );

    public productEndDateRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(insightActions.productEndDateRequest),
            switchMap(() => this.insightService.productEndDateRequest()),
            map(data => insightActions.setProductEndDate(data)),
        ),
    );

    public appUsersRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(insightActions.appUsersRequest),
            switchMap(() => this.insightService.appUsersRequest()),
            map(data => insightActions.setAppUsers(data)),
        ),
    );

    public brokerPerformanceRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(insightActions.brokerPerformanceRequest),
            switchMap(actionParams => this.insightService.brokerPerformanceRequest(actionParams.params)),
            switchMap(sortedData =>
                of(
                    insightActions.brokerPerformance4Set({ data: sortedData.rate4 }),
                    insightActions.brokerPerformance5Set({ data: sortedData.rate5 }),
                ),
            ),
        ),
    );

    constructor(
        private store: Store<{ [insightNode]: InsightState }>,
        private actions$: Actions,
        private insightService: InsightService,
    ) {}
}

import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from 'angular-custom-modal/modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, Observable, switchMap } from 'rxjs';
import { Profile } from '../../../../shared/type';
import { select, Store } from '@ngrx/store';
import { userSelectors } from '../../../../shared/store/user/user.selectors';
import { userNode, UserState } from '../../../../shared/store/user/user.store';
import { InvestorService } from '../../../../shared/services/investor.service';
import { DataTableService } from '../../../../shared/services/data-table.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
    selector: 'app-client-create-modal',
    templateUrl: './client-create-modal.component.html',
})
export class ClientCreateModalComponent {
    @ViewChild('modal') modal: ModalComponent;

    public form: FormGroup;

    public profile$: Observable<Profile | null> = this.store.pipe(select(userSelectors.selectProfile));

    public emailAlreadyExist = false;

    public constructor(
        private readonly store: Store<{
            [userNode]: UserState;
        }>,
        public readonly fb: FormBuilder,
        private readonly investorService: InvestorService,
        public readonly datatableService: DataTableService,
    ) {}

    public open() {
        const initData = {
            email: ['', [Validators.required, Validators.email]],
            name: ['', [Validators.required]],
            phone: [undefined],
            ftb: [false],
        };
        this.form = this.fb.group(initData);
        this.modal.open();
    }

    public save() {
        this.form.markAllAsTouched();

        const successResponse = () => {
            ToastService.showToastMessage('color-success', 'Appointment has been created');
            this.emailAlreadyExist = false;
            this.datatableService.updateTableContent();
            this.modal.close();
        };

        const errorResponse = response => {
            let errorMessage = 'Some error';
            if (response.error.errors.email && response.error.errors.email[0] == 'The email has already been taken.') {
                this.emailAlreadyExist = true;
                errorMessage = 'The email has already been taken.';
            }
            ToastService.showToastMessage('color-danger', errorMessage);
        };

        if (this.form.valid)
            this.profile$
                .pipe(
                    switchMap(profile =>
                        this.investorService.createInvestor(profile.broker_company.id, this.form.value),
                    ),
                    first(),
                )
                .subscribe({
                    next: () => successResponse(),
                    error: error => errorResponse(error),
                });
    }

    public close() {
        this.modal.close();
    }
}

<div
    class='panel w-auto overflow-x-auto whitespace-nowrap p-3'>
    <div class='flex flex-row w-full'>
        <h5 class='text-2xl'>Calendar</h5>
    </div>
</div>

<div class='panel mt-6 p-3 h-[75vh]'>

    <full-calendar class='h-[72vh]' [options]="calendarOptions" [events]='(calendarEvents$ | async).events'></full-calendar>

    <app-update-appointment-modal (updateContent)='changeDate()' #appointmentUpdateModal></app-update-appointment-modal>

</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataTableService } from '../../services/data-table.service';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { filter, first, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { userNode, UserState } from '../../store/user/user.store';
import { userSelectors } from '../../store/user/user.selectors';
import { brokerSelectors } from '../../store/broker/broker.selectors';
import { brokerNode, BrokerState } from '../../store/broker/broker.store';
import { tableSelectors } from '../../store/table/table.selectors';
import { tableActions } from '../../store/table/table.actions';
import { tableNode, TableState } from '../../store/table/table.store';
import { PriorityFilter } from '../../type';
import { PermissionService } from '../../services/permission.service';

@Component({
    selector: 'app-table-filters',
    templateUrl: './table-filers.component.html',
})
export class TableFilersComponent implements OnInit, OnDestroy {
    protected brokers$ = this.store.pipe(select(brokerSelectors.selectBrokers));
    protected readonly PermissionService = PermissionService;

    protected statusFilter = 0;
    protected brokerFilter = 0;
    protected priorityFilter: PriorityFilter;

    public statusOptions = [
        { name: '-', id: 0 },
        { name: 'Pending', id: 1 },
        { name: 'Actioned', id: 2 },
    ];

    public priorityOption = [
        { name: 'Priority 1', id: 1 },
        { name: 'Priority 2', id: 2 },
        { name: 'Priority 3', id: 3 },
        { name: 'Priority 4', id: 4 },
        { name: 'Priority 5', id: 5 },
    ];

    public profile$ = this.store.pipe(select(userSelectors.selectProfile));

    public tableState$ = this.store.pipe(select(tableSelectors.selectTableData));

    public searchInput = new FormControl('', [Validators.minLength(3)]);

    public brokersOption$ = this.brokers$.pipe(map(brokers => [{ id: 0, name: 'All' }, ...brokers]));

    public searchSubscribe = this.searchInput.valueChanges
        .pipe(
            debounceTime(500),
            filter((str: string) => str.length >= 3 || str.length === 0),
            switchMap(str =>
                this.tableState$.pipe(
                    map(state => ({ ...state, search: str })),
                    first(),
                ),
            ),
        )
        .subscribe(state => {
            this.store.dispatch(tableActions.updateState({ state }));
            this.datatablesService.updateTableContent();
        });
    public filterSubscribe: Subscription;

    public constructor(
        public readonly datatablesService: DataTableService,
        private readonly store: Store<{
            [userNode]: UserState;
            [brokerNode]: BrokerState;
            [tableNode]: TableState;
        }>,
    ) {}

    public ngOnInit() {
        this.filterSubscribe = this.tableState$.subscribe(state => {
            this.statusFilter = state.statusFilter;
            this.brokerFilter = state.brokerFilter;
            if (state.search.length >= 3 && state.search !== this.searchInput.value)
                this.searchInput.patchValue(state.search);
            const _priorityFilter = { ...state.priorityFilter };
            this.priorityFilter = Object.keys(_priorityFilter).length > 0 ? _priorityFilter : null;
        });
    }

    public ngOnDestroy() {
        this.filterSubscribe.unsubscribe();
        this.searchSubscribe.unsubscribe();
    }

    public selectorChanged(): void {
        this.tableState$.pipe(first()).subscribe(state => {
            const _state = { ...state };
            _state.statusFilter = this.statusFilter;
            _state.brokerFilter = this.brokerFilter;
            _state.priorityFilter = this.priorityFilter;
            _state.page = 1;
            this.store.dispatch(tableActions.updateState({ state: _state }));
            this.datatablesService.tableReset();
        });
    }
}

import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgDataTableComponent } from '@bhplugin/ng-datatable/lib/ng-datatable';
import { DataTableService } from '../../services/data-table.service';
import { BrokerService } from '../../services/broker.service';
import { filter, first, mergeMap, Subscription } from 'rxjs';
import { colDef } from '@bhplugin/ng-datatable/lib/modals';
import { Investor, MarketLatest, PriorityFilter, TableChangeEventData, TableMetaOptions } from '../../type';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { brokerNode, BrokerState } from '../../store/broker/broker.store';
import { userSelectors } from '../../store/user/user.selectors';
import { userNode, UserState } from '../../store/user/user.store';
import { brokerActions } from '../../store/broker/broker.actions';
import { tableNode, TableState } from '../../store/table/table.store';
import { brokerSelectors } from '../../store/broker/broker.selectors';
import { tableSelectors } from '../../store/table/table.selectors';
import { map } from 'rxjs/operators';
import { tableActions } from '../../store/table/table.actions';
import { propertyActions } from '../../store/property/property.actions';
import { ChangeStatusInvestorModalComponent } from './components/change-status-investor-modal/change-status-investor-modal.component';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { TableActionsButtonClientComponent } from './components/table-actions-buttons/client/table-actions-button-client.component';
import { TableActionsButtonPropertyComponent } from './components/table-actions-buttons/property/table-actions-button-property.component';
import { PermissionService } from '../../services/permission.service';
import { TableStatusColumnComponent } from './components/table-status-column/table-status-column.component';
import { AmountEditModalComponent } from './components/amount-edit-modal/amount-edit-modal.component';
import { ModalUpdateModalValues } from './table.type';
import { InterestRateEditModalComponent } from './components/interest-rate-edit-modal/interest-rate-edit-modal.component';
import { HmoSelectComponent } from './components/hmo-select/hmo-select.component';
import { PropertyOwnerTypeSelectComponent } from './components/property-owner-type-select/property-owner-type-select.component';
import { TableDownloadService } from '../../services/table-download.service';
import { StringEditModalComponent } from './components/string-edit-modal/string-edit-modal.component';
import { EditModalService } from './services/edit-modal.service';
import { DateEditModalComponent } from './components/date-edit-modal/date-edit-modal.component';
import { MubSelectComponent } from './components/mub-select/mub-select.component';
import { InvestorCompanySelectComponent } from './components/investor-company-select/investor-company-select.component';
import {
    TableActionsButtonInvestorDetailInfoComponent,
    TableActionsButtonInvestorDetailInfoComponents,
} from './components/table-actions-buttons/investor-detail-info/table-actions-button-investor-detail-info.component';

export const TableComponents = [
    TableActionsButtonClientComponent,
    TableActionsButtonPropertyComponent,
    TableStatusColumnComponent,
    ChangeStatusInvestorModalComponent,
    AmountEditModalComponent,
    InterestRateEditModalComponent,
    HmoSelectComponent,
    PropertyOwnerTypeSelectComponent,
    StringEditModalComponent,
    DateEditModalComponent,
    MubSelectComponent,
    InvestorCompanySelectComponent,
    TableActionsButtonInvestorDetailInfoComponent,
    ...TableActionsButtonInvestorDetailInfoComponents,
];

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('table') table: NgDataTableComponent;
    @ViewChild('investorStatusModal') modal: ChangeStatusInvestorModalComponent;
    @ViewChild('amountUpdate') amountUpdate: AmountEditModalComponent;
    @ViewChild('stringUpdate') stringUpdate: StringEditModalComponent;
    @ViewChild('interestRateModal') interestRateModal: InterestRateEditModalComponent;
    @ViewChild('dateUpdate') dateUpdateModal: DateEditModalComponent;

    @Input() public rows: {
        [str: string]: any;
    }[];
    @Input() public cols: colDef[];
    @Input() public brokersTableSelectors: number[] = [];
    @Input() public priorityFilter: PriorityFilter | null = null;
    @Input() public sortDirection: 'asc' | 'desc' = 'asc';
    @Input() public sort = '';
    @Input() public customFilter = undefined;
    @Input() public tableName = 'Table';
    @Input() public selectRowOnClick = false;

    @Output() updateContent = new EventEmitter<TableMetaOptions>();
    @Output() rowClick = new EventEmitter<any>();

    public profile$ = this.store.pipe(select(userSelectors.selectProfile));
    protected readonly PermissionService = PermissionService;
    protected readonly EditModalService = EditModalService;

    protected brokers$ = this.store.pipe(select(brokerSelectors.selectBrokers));

    public tableState$ = this.store.pipe(select(tableSelectors.selectTableData));
    public tablePagination$ = this.store.pipe(select(tableSelectors.selectPagination));

    private contentUpdateSubscription: Subscription;

    protected readonly DataTableService = DataTableService;

    public constructor(
        private readonly dashboardService: DashboardService,
        public readonly datatableService: DataTableService,
        private readonly brokerService: BrokerService,
        private readonly tableDownloadService: TableDownloadService,
        private readonly router: Router,
        private readonly store: Store<{
            [brokerNode]: BrokerState;
            [userNode]: UserState;
            [tableNode]: TableState;
        }>,
    ) {
        this.store.dispatch(propertyActions.propertiesSet({ properties: [] }));
    }

    public ngOnInit() {
        this.profile$
            .pipe(
                filter(profile => profile !== null),
                first(),
            )
            .subscribe(profile => {
                if (PermissionService.checkCredentials(profile, PermissionService.managerRoles))
                    this.store.dispatch(
                        brokerActions.brokersRequest({
                            perPage: 100,
                            page: 1,
                        }),
                    );
            });

        this.contentUpdateSubscription = this.datatableService.updateContentEvent$.subscribe(params =>
            this.updateContent.emit(params),
        );

        this.datatableService
            .init(this.priorityFilter, this.sort, this.sortDirection, this.customFilter)
            .pipe(first())
            .subscribe(() => this.datatableService.updateTableContent());
    }

    public ngAfterViewInit() {
        this.datatableService.table = this.table;
    }

    public ngOnDestroy() {
        this.contentUpdateSubscription.unsubscribe();
    }

    public propertyDetail(propertyId: number): void {
        this.dashboardService.savePrevUrl();
        this.profile$.pipe(first()).subscribe(profile => {
            const path =
                profile.type == 'investor'
                    ? `investor/property/view/${propertyId}`
                    : `dashboard/property/view/${propertyId}`;
            this.router.navigate([path]);
        });
    }

    public clientDetail(clientId: number): void {
        this.dashboardService.savePrevUrl();
        this.router.navigate([`dashboard/client/view/${clientId}`]);
    }

    public changeBroker(
        event: {
            id: number;
            name: string;
        },
        clientIds: number[],
    ): void {
        this.brokerService
            .assignInvestorRequest(event.id, clientIds)
            .pipe(
                mergeMap(() => this.datatableService.tableState$),
                first(),
            )
            .subscribe(options => this.updateContent.emit(options));
    }

    public changeState(item: TableChangeEventData): void {
        this.tableState$
            .pipe(
                map(state => {
                    const _state = { ...state };
                    _state.page = item.current_page;
                    _state.perPage = item.pagesize;
                    _state.sortColumn = item.sort_column;
                    _state.sortDirection = item.sort_direction;
                    return _state;
                }),
                first(),
            )
            .subscribe(newState => {
                this.store.dispatch(tableActions.updateState({ state: newState }));
                this.datatableService.updateTableContent();
            });
    }

    public changeInvestorStatus(data): void {
        if (data.invokedAt == null) return;
        this.modal.open(data);
    }

    public updateAmount(data: ModalUpdateModalValues): void {
        this.amountUpdate.open(data);
    }

    public updateString(data: ModalUpdateModalValues): void {
        this.stringUpdate.open(data);
    }

    public updateDate(data: ModalUpdateModalValues): void {
        this.dateUpdateModal.open(data);
    }

    public updateInterestRate(data: ModalUpdateModalValues): void {
        this.interestRateModal.open(data);
    }

    public clientsNames(clients: Investor[]): string[] {
        return clients.map((client, key) => (key == 0 ? client.name : `, ${client.name}`));
    }

    public clientsIds(clients: Investor[]): number[] {
        return clients.map(client => client.id);
    }

    public showPropertyValue(market: MarketLatest[]): number {
        if (market.length == 0) return 0;
        const _market = market.find(mar => mar.type == 'Custom') ?? market[0];
        return _market.market_value;
    }

    public saveXlsx() {
        const table = document.getElementsByTagName('table').item(0);
        this.tableDownloadService.downloadAsXLSX(table, this.tableName);
    }

    public saveCsv() {
        const table = document.getElementsByTagName('table').item(0);
        this.tableDownloadService.downloadAsCsv(table, this.tableName);
    }

    public appUserShow(investor: Investor): string {
        if (investor.is_app_user && investor.is_organic) return 'Yes::Organic';
        if (investor.is_app_user && !investor.is_organic) return 'Yes::NotOrganic';
        return 'No';
    }
    public createdShow(investor: Investor): string {
        if (investor.created_by === 'broker') return 'Broker';
        if (investor.created_by === 'importer') return 'Importer';
        if (investor.created_by === 'app_register') return 'App';
        if (investor.created_by === 'landing') return 'Landing';
        return '-';
    }
}

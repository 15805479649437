import { Component, Input } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexPlotOptions, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { ChartService } from '../../../../services/chart.service';
import { BrokerPerformanceAnalytic } from '../../../../../dashboard/insight/insight.type';

@Component({
    selector: 'app-broker-performance-chart',
    templateUrl: './broker-performance-chart.component.html',
})
export class BrokerPerformanceChartComponent {
    @Input()
    set priority(priority: BrokerPerformanceAnalytic | undefined) {
        if (!priority) return;
        this.setChart(priority);
    }

    public xaxis: ApexXAxis | undefined;
    public series: ApexAxisChartSeries | undefined;
    public chart: ApexChart = {
        ...this.chartService.apexConfigs.chart,
        stacked: true,
    };

    public yaxis: ApexYAxis = {
        tickAmount: 6,
        opposite: false,
        labels: {
            offsetX: 0,
        },
        title: {
            text: 'No. Records',
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 600,
                cssClass: 'apexcharts-yaxis-title',
            },
        },
    };

    public plotOptions: ApexPlotOptions = {
        bar: {
            ...this.chartService.apexConfigs.plotOptions.bar,
            distributed: true,
        },
    };

    public constructor(public readonly chartService: ChartService) {}

    public setChart(brokerAnalytic: BrokerPerformanceAnalytic): void {
        const categories: string[] = [];
        const series: number[] = [];

        brokerAnalytic.rating.forecast.forEach(item => {
            categories.push(item.month);
            series.push(item.count);
        });

        this.xaxis = { categories };
        this.series = [{ name: 'Total', data: series }];
    }
}

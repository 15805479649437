import { Injectable } from '@angular/core';
import { Profile } from '../type';
import { filter, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { userNode, UserState } from '../store/user/user.store';
import { userSelectors } from '../store/user/user.selectors';
import { map } from 'rxjs/operators';

@Injectable()
export class PermissionService {
    public static readonly managerRoles = ['manager', 'admin'];
    public static readonly brokerRoles = ['broker'];
    public static readonly clientRoles = ['investor'];

    private profile$: Observable<Profile | null> = this.store.pipe(select(userSelectors.selectProfile));

    public constructor(
        private readonly store: Store<{
            [userNode]: UserState;
        }>,
    ) {}

    public static checkCredentials(userProfile: Profile | null, listAllowedRoles: string[]): boolean {
        if (userProfile === null) return false;
        return listAllowedRoles.includes(userProfile.role);
    }

    public checkProfileCredentials(listAllowedRoles: string[]): Observable<boolean> {
        return this.profile$.pipe(
            filter(profile => !!profile),
            map(profile => PermissionService.checkCredentials(profile, listAllowedRoles)),
        );
    }
}

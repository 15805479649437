import { Component, Input } from '@angular/core';
import { HomeBuyer } from '../../../../type';

@Component({
    selector: 'app-property-home-buyer',
    templateUrl: './property-home-buyer.component.html',
})
export class PropertyHomeBuyerComponent {
    @Input()
    public homeBuyer: HomeBuyer;
}

import { appActions } from './app.actions';

export function clearState(reducer) {
    return function (state, action) {
        if (action.type === appActions.reset.type) {
            state = undefined;
        }

        return reducer(state, action);
    };
}

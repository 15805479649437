import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { AddressChunk, FormattedAddress, PlaceAutocompleteResponse } from '../type/google.type';
import { map, Observable, of } from 'rxjs';

@Injectable()
export class GooglePlaceService {
    public constructor(private readonly http: HttpClient) {}

    public autocompleteRequest(input: string): Observable<PlaceAutocompleteResponse[]> {
        const url = `${env.baseServerUrl}/properties/address/suggestions`;
        if (input.length === 0) return of([]);
        return this.http
            .post<{ data: PlaceAutocompleteResponse[] }>(url, { input })
            .pipe(map(response => response.data));
    }

    public fullAddressSearch(addressId: string): Observable<AddressChunk> {
        const url = `${env.baseServerUrl}/properties/address/details`;
        return this.http
            .post<{
                data: AddressChunk;
                status: string;
            }>(url, { address_id: addressId })
            .pipe(map(response => response.data));
    }

    public static formattingAddress(response: AddressChunk): FormattedAddress {
        return {
            addressId: response.address_id,
            addressChunks: { ...response },
            formattedAddress: `${response.street_number} ${response.route} ${response.postcode}`,
        } as FormattedAddress;
    }
}

<div
    class='panel w-auto overflow-x-auto whitespace-nowrap p-3'>
    <div class='flex flex-row w-full'>
        <h5 class='text-2xl'>Advisers</h5>
    </div>
</div>

<div *ngIf='(brokers$ | async) as brokers' class='panel mt-6 pb-1.5'>

    <div class='flex flex-row justify-between'>
        <button (click)='createUserModal.open()' type='button' class='btn  btn-outline-dark'>Add new adviser</button>
        <input [(ngModel)]='search' type='text' class='form-input w-auto' placeholder='Search...' />
    </div>

    <div class='mt-6 datatable'>
        <ng-datatable
            [rows]='(brokers$ | async)!'
            [columns]='columns'
            sortColumn='id'
            [search]='search'
            skin='whitespace-nowrap table-hover active::btn-dark'
        >
            <ng-template slot='activity'
                         let-value='data'> {{ value.activity | date:'dd-MM-yyyy HH:mm:ss' }}</ng-template>
            <ng-template slot='name' let-value='data'><p class='capitalize'>{{ value.name }}</p></ng-template>
            <ng-template slot='role' let-value='data'><p class='capitalize'>{{ value.role }}</p></ng-template>
            <ng-template slot='status' let-value='data'><p class='capitalize'>{{ value.status }}</p></ng-template>
            <ng-template slot='action' let-value='data'>
                <div class='flex flex-row justify-center'>
                    <div>
                        <button (click)='updateBrokerModal.open(value.obj)' type='button' class='ltr:mr-2 rtl:ml-2'
                                ngxTippy='Edit'>
                            <svg width='24' height='24' viewBox='0 0 24 24' fill='none'
                                 xmlns='http://www.w3.org/2000/svg' class='h-4.5 w-4.5'>
                                <path
                                    d='M15.2869 3.15178L14.3601 4.07866L5.83882 12.5999L5.83881 12.5999C5.26166 13.1771 4.97308 13.4656 4.7249 13.7838C4.43213 14.1592 4.18114 14.5653 3.97634 14.995C3.80273 15.3593 3.67368 15.7465 3.41556 16.5208L2.32181 19.8021L2.05445 20.6042C1.92743 20.9852 2.0266 21.4053 2.31063 21.6894C2.59466 21.9734 3.01478 22.0726 3.39584 21.9456L4.19792 21.6782L7.47918 20.5844L7.47919 20.5844C8.25353 20.3263 8.6407 20.1973 9.00498 20.0237C9.43469 19.8189 9.84082 19.5679 10.2162 19.2751C10.5344 19.0269 10.8229 18.7383 11.4001 18.1612L11.4001 18.1612L19.9213 9.63993L20.8482 8.71306C22.3839 7.17735 22.3839 4.68748 20.8482 3.15178C19.3125 1.61607 16.8226 1.61607 15.2869 3.15178Z'
                                    stroke='currentColor'
                                    stroke-width='1.5'
                                />
                                <path
                                    opacity='0.5'
                                    d='M14.36 4.07812C14.36 4.07812 14.4759 6.04774 16.2138 7.78564C17.9517 9.52354 19.9213 9.6394 19.9213 9.6394M4.19789 21.6777L2.32178 19.8015'
                                    stroke='currentColor'
                                    stroke-width='1.5'
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </ng-template>
        </ng-datatable>

    </div>
</div>

<div
    *ngIf='(brokers$ | async) === null'
    class='h-screen flex flex-col items-center justify-center inset-0 bg-[#fafafa] dark:bg-[#060818] z-[60] grid place-content-center animate__animated'
>
    <svg width='64' height='64' viewBox='0 0 135 135' xmlns='http://www.w3.org/2000/svg' fill='#4361ee'>
        <path
            d='M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='-360 67 67' dur='2.5s'
                              repeatCount='indefinite' />
        </path>
        <path
            d='M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z'
        >
            <animateTransform attributeName='transform' type='rotate' from='0 67 67' to='360 67 67' dur='8s'
                              repeatCount='indefinite' />
        </path>
    </svg>
</div>
<app-create-broker-modal #createUserModal></app-create-broker-modal>
<app-update-broker-modal #updateBrokerModal></app-update-broker-modal>

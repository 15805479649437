import { createReducer, on } from '@ngrx/store';
import { Broker, Investor, PaginationMetaData, Property, SelectedProperty } from '../../type';
import { propertyActions } from './property.actions';
import { CompanyProfile } from '../../../dashboard/company/company.type';

export const propertyNode = 'property';

export interface PropertyState {
    properties: Property[];
    investors: Investor[];
    investorCompanies: CompanyProfile[];
    brokers: Broker[];
    pagination: PaginationMetaData;
    selectedProperty: null | SelectedProperty;
}

export const propertyState: PropertyState = {
    properties: [],
    investors: [],
    brokers: [],
    investorCompanies: [],
    selectedProperty: null,
    pagination: {
        current_page: 1,
        from: 1,
        last_page: 1,
        path: '',
        per_page: 10,
        to: 5,
        total: 0,
    },
};

export const propertyReducer = createReducer(
    propertyState,
    on(propertyActions.propertiesSet, (state, action) => ({
        ...state,
        properties: action.properties,
    })),
    on(propertyActions.paginationDataSet, (state, action) => ({
        ...state,
        pagination: action.pagination,
    })),
    on(propertyActions.investorsSet, (state, action) => ({
        ...state,
        investors: action.investors,
    })),
    on(propertyActions.brokersSet, (state, action) => ({
        ...state,
        brokers: action.brokers,
    })),
    on(propertyActions.investorCompaniesSet, (state, action) => ({
        ...state,
        investorCompanies: action.companies,
    })),
    on(propertyActions.setSelectedProperty, (state, action) => ({
        ...state,
        selectedProperty: action,
    })),
);

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    AppUsersResponse,
    InsightsBrokerPerformance,
    InsightsBrokerPerformanceResponse,
    InsightsOverviewResponse,
    OpportunityRatingRequest,
    ProductEndDateResponse,
} from './insight.type';
import { environment as env } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class InsightService {
    public constructor(private readonly http: HttpClient) {}

    public overviewDataRequest(): Observable<InsightsOverviewResponse> {
        return this.http
            .get<{ data: InsightsOverviewResponse; status: string }>(`${env.baseServerUrl}/insights/overview`)
            .pipe(map(response => response.data));
    }

    public productEndDateRequest(): Observable<ProductEndDateResponse> {
        return this.http
            .get<{ data: ProductEndDateResponse; status: string }>(`${env.baseServerUrl}/insights/product-end-date`)
            .pipe(map(response => response.data));
    }

    public opportunityDataRequest(priority: number): Observable<OpportunityRatingRequest> {
        return this.http
            .get<{
                data: OpportunityRatingRequest;
                status: string;
            }>(`${env.baseServerUrl}/insights/opportunity/${priority}`)
            .pipe(map(response => response.data));
    }

    public appUsersRequest(): Observable<AppUsersResponse> {
        return this.http
            .get<{ data: AppUsersResponse; status: string }>(`${env.baseServerUrl}/insights/app-users`)
            .pipe(map(response => response.data));
    }

    public brokerPerformanceRequest(params = {}): Observable<{
        rate4: InsightsBrokerPerformance[];
        rate5: InsightsBrokerPerformance[];
    }> {
        return this.http
            .get<{ data: InsightsBrokerPerformanceResponse[]; status: string }>(
                `${env.baseServerUrl}/insights/broker-performance`,
                { params },
            )
            .pipe(map(response => this.brokerPerformanceResponseSort(response.data)));
    }

    private brokerPerformanceResponseSort(data: InsightsBrokerPerformanceResponse[]): {
        rate4: InsightsBrokerPerformance[];
        rate5: InsightsBrokerPerformance[];
    } {
        const rate4: InsightsBrokerPerformance[] = [];
        const rate5: InsightsBrokerPerformance[] = [];
        data.forEach(item => {
            rate4.push({
                ...item.broker,
                rating: item.priority_rating.rating_4,
                properties_total: item.properties_total,
            } as InsightsBrokerPerformance);
            rate5.push({
                ...item.broker,
                rating: item.priority_rating.rating_5,
                properties_total: item.properties_total,
            } as InsightsBrokerPerformance);
        });
        return { rate4, rate5 };
    }
}

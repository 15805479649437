import { Component } from '@angular/core';
import { first, map, Observable, shareReplay, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { InvestorService } from '../../shared/services/investor.service';
import { Broker, Investor } from '../../shared/type';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { select, Store } from '@ngrx/store';
import { userSelectors } from '../../shared/store/user/user.selectors';
import { userNode, UserState } from '../../shared/store/user/user.store';
import { DashboardService } from '../dashboard.service';

@Component({
    selector: 'app-client-view',
    templateUrl: './client-view.component.html',
})
export class ClientViewComponent {
    public investor$: Observable<Investor> = this.route.params.pipe(
        map(params => Number(params['id'])),
        switchMap((id: number) => this.investorService.investorDetail(id)),
        first(),
        shareReplay(1),
    );
    public broker$: Observable<Broker> = this.store.pipe(
        select(userSelectors.selectProfile),
        map(profile => ({ id: profile.id }) as Broker),
    );

    public notes = '';

    calendarOptions: CalendarOptions = {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        events: [{ title: 'Meeting', start: new Date() }],
    };

    public constructor(
        private readonly investorService: InvestorService,
        private readonly dashboardService: DashboardService,
        private readonly route: ActivatedRoute,
        private readonly store: Store<{
            [userNode]: UserState;
        }>,
    ) {}

    public redirectToPrevURL(): void {
        this.dashboardService.redirectToPrev('dashboard');
    }
}

import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { ChangeStatusInvestorModalComponent } from '../../../../shared/components/table/components/change-status-investor-modal/change-status-investor-modal.component';
import { investorSelectors } from '../../../../shared/store/investor/investor.selectors';
import { Investor, TableMetaOptions } from '../../../../shared/type';
import { investorNode, InvestorState } from '../../../../shared/store/investor/investor.store';
import { investorActions } from '../../../../shared/store/investor/investor.actions';
import { filter, first } from 'rxjs';
import { tableActions } from '../../../../shared/store/table/table.actions';
import { FormControl, Validators } from '@angular/forms';
import { tableSelectors } from '../../../../shared/store/table/table.selectors';
import { DataTableService } from '../../../../shared/services/data-table.service';
import { tableNode, TableState } from '../../../../shared/store/table/table.store';
import { colDef } from '@bhplugin/ng-datatable';
import { ClientCreateModalComponent } from '../client-create-modal/client-create-modal.component';

@Component({
    selector: 'app-table-investors',
    templateUrl: './table-investors.component.html',
})
export class TableInvestorsComponent implements OnDestroy {
    @ViewChild('modal') modal: ChangeStatusInvestorModalComponent;
    @ViewChild('clientModalCreate') createClientModal: ClientCreateModalComponent;

    @Input() tableName = 'Table';

    public tableState$ = this.store.pipe(select(tableSelectors.selectTableData));

    protected customFilter = {};

    public searchInput = new FormControl('', [Validators.minLength(3)]);

    public columns: colDef[] = [
        { field: 'investorName', title: 'Client', sort: true },
        { field: 'email', title: 'Email', sort: true },
        { field: 'phone', title: 'Phone', sort: true },
        { field: 'registered', title: 'Registered', sort: true },
        { field: 'lastLogin', title: 'Last login', sort: true },
        { field: 'appUser', title: 'App User', sort: false },
        { field: 'created', title: 'Created By', sort: false },
        { field: 'investor-status', title: 'Status', sort: true },
        {
            field: 'client-actions',
            title: 'Action',
            sort: false,
            headerClass: 'justify-center',
        },
    ];

    public investors$ = this.store.pipe(
        select(investorSelectors.selectInvestors),
        map((investors: Investor[]) =>
            investors.map(investor => ({
                investor,
                id: investor.id,
                clientId: investor.id,
                clients: [investor],
                investorName: investor.name,
                email: investor.email,
                phone: investor.phone,
                registered: investor.registered_at,
                lastLogin: investor.last_login_at,
                status: investor.client_invoked_at ? 'Contact Required' : 'None',
                statusBadge: investor.client_invoked_at ? 'invalid-expiry' : 'lost-no-op',
                invokedAt: investor.client_invoked_at,
                organic: investor.created_by == 'app_register' ? 'Yes' : 'No',
            })),
        ),
    );

    public constructor(
        public readonly datatablesService: DataTableService,
        private readonly store: Store<{
            [investorNode]: InvestorState;
            [tableNode]: TableState;
        }>,
    ) {}

    public ngOnDestroy() {
        this.searchSubscribe.unsubscribe();
    }

    public updateContent(params: TableMetaOptions) {
        this.store.dispatch(investorActions.investorsRequest({ ...params }));
    }

    public searchSubscribe = this.searchInput.valueChanges
        .pipe(
            debounceTime(500),
            filter((str: string) => str.length >= 3 || str.length === 0),
            switchMap(str =>
                this.tableState$.pipe(
                    map(state => ({ ...state, search: str })),
                    first(),
                ),
            ),
        )
        .subscribe(state => {
            this.store.dispatch(tableActions.updateState({ state }));
            this.datatablesService.updateTableContent();
        });

    public createClient() {
        this.createClientModal.open();
    }
}

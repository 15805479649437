<div>
    <div class='panel'>
        <div class='px-4'>
            <div class='text-2xl font-semibold uppercase'>COMPANY OVERVIEW</div>
        </div>

        <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />

        <div class='flex flex-col flex-wrap justify-between gap-6 lg:flex-row'>
            <div class='flex-1'>
                <div class='space-y-1 text-white-dark'>
                    <div>Business Owner :</div>
                    <div class='font-semibold text-black dark:text-white'>{{(company$ | async)?.owner?.name}}</div>
                </div>
            </div>

            <div class='flex flex-col justify-between gap-6 sm:flex-row lg:w-2/3'>
                <div class='xl:1/3 sm:w-1/2 lg:w-2/5'>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Total Brokers :</div>
                        <div>{{(company$ | async)?.stats?.brokers_count}}</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Total Clients :</div>
                        <div>{{(company$ | async)?.stats?.investors_count}}</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Total Residential Records :</div>
                        <div>{{(company$ | async)?.stats?.residential_properties_count}}</div>
                    </div>
                    <div class='flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Total BTL Records :</div>
                        <div>{{(company$ | async)?.stats?.investment_properties_count}}</div>
                    </div>
                </div>
                <div class='xl:1/3 sm:w-1/2 lg:w-2/5'>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Business Name :</div>
                        <div>{{(company$ | async)?.business_name}}</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Business Email :</div>
                        <div>{{(company$ | async)?.business_email}}</div>
                    </div>
                    <div class='mb-2 flex w-full items-center justify-between'>
                        <div class='text-white-dark'>Business Address :</div>
                        <div>{{(company$ | async)?.business_address}}</div>
                    </div>
                    <div class='flex w-full items-center justify-between'>
                        <div class='text-white-dark'>ID :</div>
                        <div>{{(company$ | async)?.id}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf='(company$ | async)?.leadgen_settings as settings'
     class='mt-12 flex gap-6 flex-row justify-between grid-cols-4'>

    <div
        class='max-w-[19rem] w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed] dark:border-[#1b2e4b] dark:bg-[#191e3a] dark:shadow-none'>

        <div class='py-7 px-6'>
            <div class='text-xl font-semibold uppercase'>PRODUCT EXPIRY</div>
            <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Enabled :</div>
                <div>{{settings.ped_on === 1 ? 'True' : 'False'}}</div>
            </div>
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Limit :</div>
                <div>{{settings.ped_limit}}</div>
            </div>
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Min max :</div>
                <div>{{settings.ped_priority_min}} - {{settings.ped_priority_max}}</div>
            </div>
        </div>

    </div>

    <div
        class='max-w-[19rem] w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed] dark:border-[#1b2e4b] dark:bg-[#191e3a] dark:shadow-none'>

        <div class='py-7 px-6'>
            <div class='text-xl font-semibold uppercase'>EARLY REVIEW</div>
            <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Enabled :</div>
                <div>{{settings.er_on === 1 ? 'True' : 'False'}}</div>
            </div>
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Limit :</div>
                <div>{{settings.er_limit}}</div>
            </div>
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Min max :</div>
                <div>{{settings.er_priority_min}} - {{settings.er_priority_max}}</div>
            </div>
        </div>

    </div>

    <div
        class='max-w-[19rem] w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed] dark:border-[#1b2e4b] dark:bg-[#191e3a] dark:shadow-none'>

        <div class='py-7 px-6'>
            <div class='text-xl font-semibold uppercase'>EQUITY AVAILABLE</div>
            <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Enabled :</div>
                <div>{{settings.ea_on === 1 ? 'True' : 'False'}}</div>
            </div>
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Limit :</div>
                <div>{{settings.ea_limit}}</div>
            </div>
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Min max :</div>
                <div>{{settings.ea_priority_min}} - {{settings.ea_priority_max}}</div>
            </div>
        </div>

    </div>

    <div
        class='max-w-[19rem] w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed] dark:border-[#1b2e4b] dark:bg-[#191e3a] dark:shadow-none'>

        <div class='py-7 px-6'>
            <div class='text-xl font-semibold uppercase'>FEES</div>
            <hr class='my-6 border-[#e0e6ed] dark:border-[#1b2e4b]' />
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Commission Rate Tracker :</div>
                <div>{{settings.commission_rate_transfer}}</div>
            </div>
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Commission Rate Remortgage :</div>
                <div>{{settings.commission_rate_remortgage}}</div>
            </div>
            <div class='mb-2 flex w-full items-center justify-between'>
                <div class='text-white-dark'>Company Fee :</div>
                <div>{{settings.company_fee}}</div>
            </div>
        </div>

    </div>
</div>

<ng-container [formGroup]='dataPropertyService.form'>
    <div
        class='w-[19rem] bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed]'>

        <div class='py-7 px-6'>
            <div class='text-xl font-semibold'>Market Valuation</div>
            <hr class='my-6 border-[#e0e6ed]' />

            <h4 class='h-2 mt-6 mb-10 font-bold'>Property value</h4>

            <div class='mb-2 w-full'>
                <div class='mt-6'>
                    <label for='useMarketOrCustom'>Use market or custom</label>
                    <ng-select
                        id='useMarketOrCustom'
                        formControlName='use_custom_market'
                        [items]='dataPropertyService.marketTypes'
                        [searchable]='false'
                        [clearable]='false'
                        bindLabel='name'
                        bindValue='id'
                        class='custom-multiselect'
                    >
                    </ng-select>
                </div>
            </div>

            <div *ngIf='dataPropertyService.form.controls["use_custom_market"].value' class='mt-6'
                 [ngClass]="(dataPropertyService.form.controls['market_value'].touched && dataPropertyService.form.controls['market_value'].errors) ? 'has-error' : ''">
                <label for='customMarketValue'>Custom market value</label>
                <input id='customMarketValue' type='text' class='form-input'
                       formControlName='market_value' />
                <ng-container
                    *ngIf="dataPropertyService.form.controls['market_value'].touched && dataPropertyService.form.controls['market_value'].errors">
                    <p class='mt-1 text-danger'>Please fill</p>
                </ng-container>
            </div>

            <h4 class='h-2 mt-6 mb-10 font-bold'>Rental value</h4>

            <div class='mb-2 w-full'>
                <div class='mt-6'>
                    <label for='useCustomRental'>Use rental or custom</label>
                    <ng-select
                        id='useCustomRental'
                        formControlName='use_custom_rental'
                        [items]='dataPropertyService.marketTypes'
                        [searchable]='false'
                        [clearable]='false'
                        bindLabel='name'
                        bindValue='id'
                        class='custom-multiselect'
                    >
                    </ng-select>
                </div>
            </div>

            <div *ngIf='dataPropertyService.form.controls["use_custom_rental"].value' class='mt-6'
                 [ngClass]="(dataPropertyService.form.controls['rental_market'].touched && dataPropertyService.form.controls['rental_market'].errors) ? 'has-error' : ''">
                <label for='rentalMarket'>Custom market value</label>
                <input id='rentalMarket' type='text' class='form-input'
                       formControlName='rental_market' />
                <ng-container
                    *ngIf="dataPropertyService.form.controls['rental_market'].touched && dataPropertyService.form.controls['rental_market'].errors">
                    <p class='mt-1 text-danger'>Please fill</p>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #emptyValue>
    <div>-</div>
</ng-template>

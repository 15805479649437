import { createSelector } from '@ngrx/store';
import { appNode, AppState } from './app.store';

export const selectFeature = (state: { [appNode]: AppState }) => state[appNode];

const selectToken = createSelector(selectFeature, state => state.token);

export const appSelectors = {
    selectToken,
};

import { createSelector } from '@ngrx/store';
import { propertyNode, PropertyState } from './property.store';

export const selectFeature = (state: { [propertyNode]: PropertyState }) => state[propertyNode];

const selectProperties = createSelector(selectFeature, state => state.properties);
const selectAllPropertiesData = createSelector(selectFeature, state => ({
    properties: state.properties,
    investors: state.investors,
    brokers: state.brokers,
    investorCompanies: state.investorCompanies,
}));
const selectInvestors = createSelector(selectFeature, state => state.investors);
const selectInvestorCompanies = createSelector(selectFeature, state => state.investorCompanies);
const selectPropertiesBrokers = createSelector(selectFeature, state => state.brokers);
const selectPagination = createSelector(selectFeature, state => state.pagination);

const selectProperty = createSelector(selectFeature, state => state.selectedProperty);

export const propertySelectors = {
    selectProperties,
    selectPagination,
    selectInvestors,
    selectProperty,
    selectPropertiesBrokers,
    selectInvestorCompanies,
    selectAllPropertiesData,
};

<modal [closeOnOutsideClick]='true' #modal class='modal-top'>
    <ng-template #modalHeader>
        <div>Column filter</div>
    </ng-template>
    <ng-template #modalBody>
        <div class='grid grid-cols-2 gap-4 m-8'>
            <div *ngFor='let col of cols'>
                <label class="flex items-center cursor-pointer">
                    <input type="checkbox" class="form-checkbox" [(ngModel)]='!col.hide' (change)='colChanged.emit(col)' />
                    <span class=" text-white-dark">{{col.title}}</span>
                </label>
            </div>
        </div>

    </ng-template>
    <ng-template #modalFooter>
        <button type='button' (click)='close()' class='btn btn-primary ltr:ml-4 rtl:mr-4'>
            Close
        </button>
    </ng-template>
</modal>

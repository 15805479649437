import { Component, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { companySelectors } from '../../shared/store/company/company.selectors';
import { companyNode, CompanyState } from '../../shared/store/company/company.store';
import { ChangeStatusInvestorModalComponent } from '../../shared/components/table/components/change-status-investor-modal/change-status-investor-modal.component';

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
})
export class ClientsComponent {
    @ViewChild('modal') modal: ChangeStatusInvestorModalComponent;

    public title = 'Clients';

    public company$ = this.store.pipe(select(companySelectors.selectSelectedCompany));

    public constructor(
        private readonly store: Store<{
            [companyNode]: CompanyState;
        }>,
    ) {}
}

<ng-container *ngIf='cols.length > 0'>
    <div class='flex flex-row justify-between'>
        <div class='pb-3'>
            <ng-content></ng-content>
        </div>
        <div class='flex flex-row gap-6 justify-end'>
            <app-table-chooser tableKey='investorsPropertyTable' [(cols)]='cols'></app-table-chooser>
            <app-table-filters></app-table-filters>
        </div>
    </div>
    <div class='datatable'>
        <app-table
            [rows]='rows$ | async'
            [cols]='cols'
            [customFilter]='filter'
            (updateContent)='updateTable($event)'
        ></app-table>
    </div>
</ng-container>


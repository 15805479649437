<modal [closeOnOutsideClick]='true' #modal class='modal-top'>
    <ng-template #modalHeader>
        <div>{{generateTitle()}} #{{broker.id}}</div>
    </ng-template>
    <ng-template #modalBody>
        <div class='w-full'>
            <form [formGroup]='form' novalidate>
                <div class='py-7 px-6'>
                    <div class='flex flex-row gap-6 justify-between'>
                        <div>
                            <div class='font-semibold'>Name</div>
                            <div>{{broker.name}}</div>

                            <div class='mt-6 font-semibold'>Email</div>
                            <div>{{broker.email}}</div>
                        </div>
                        <div>
                            <div class='font-semibold'>Role</div>
                            <div>{{broker.role}}</div>

                            <div class='mt-6 font-semibold'>Status</div>
                            <div [ngClass]='{
                                "badge-no-action": broker.state !== "active",
                                "badge-success": broker.state === "active"
                            }' class='badge-no-action'>{{broker.state}}</div>
                        </div>
                    </div>

                    <div class='mt-6'
                         [ngClass]="(isSubmitForm && form.controls['name'].errors) ? 'has-error' : ''">
                        <label for='name'>Full name</label>
                        <input id='name' type='text' class='form-input'
                               formControlName='name' />
                        <ng-container *ngIf="isSubmitForm && form.controls['name'].errors">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                    </div>

                    <div class='mt-6'
                         [ngClass]="(isSubmitForm && form.controls['email'].errors) ? 'has-error' : ''">
                        <label for='email'>Email Address</label>
                        <input id='email' type='text' class='form-input'
                               formControlName='email' />
                        <ng-container *ngIf="isSubmitForm && form.controls['email'].hasError('required')">
                            <p class='mt-1 text-danger'>Please fill</p>
                        </ng-container>
                        <ng-container *ngIf="isSubmitForm && form.controls['email'].hasError('email')">
                            <p class='mt-1 text-danger'>Invalid Value</p>
                        </ng-container>
                    </div>

                    <div class='mt-6'
                         [ngClass]="(isSubmitForm && form.controls['phone'].errors) ? 'has-error' : ''">
                        <label for='phone'>Phone</label>
                        <input id='phone' type='text' class='form-input'
                               formControlName='phone' />
                    </div>

                    <div class='mt-6'>
                        <label for='roles'>Role</label>
                        <p class='text-xs'>Advisers will only see their allocations of clients.</p>
                        <p class='text-xs'>Managers will see all clients within the company.</p>
                        <ng-select
                            id='roles'
                            formControlName='role'
                            [items]='rolesType'
                            [searchable]='false'
                            [clearable]='false'
                            bindLabel='name'
                            bindValue='id'
                            class='mt-6 custom-multiselect'
                        >
                        </ng-select>
                    </div>
                    <label *ngIf='broker.state !== "active"' class='mt-6 inline-flex'>
                        <input formControlName='isNeedTSendInviteEmail' type='checkbox' class='form-checkbox' checked />
                        <span>Send invite email</span>
                    </label>
                </div>
                <div>
                    <p *ngIf='emailAlreadyExist' class='text-danger py-7 px-6'>
                        This email already exist
                    </p>
                </div>
            </form>
        </div>
    </ng-template>
    <ng-template #modalFooter>
        <button [disabled]='isDisableControls' type='button' (click)='modal.close()' class='btn btn-outline-danger'>
            Cancel
        </button>
        <button [disabled]='isDisableControls' (click)='submitForm()' type='button'
                class='btn btn-outline-primary ltr:ml-4 rtl:mr-4 disabled:bg-gray-400 disabled:border-gray-400 disabled:text-white'>
            Update
        </button>
    </ng-template>
</modal>


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewComponent as CompanyOverviewComponent } from './company/components/overview/overview.component';
import { UsersComponent } from './company/components/users/users.component';
import { ProductExpiryComponent } from './product-expiry/product-expiry.component';
import { EarlyReviewComponent } from './early-review/early-review.component';
import { OverviewComponent as InsightOverviewComponent } from './insight/overview/overview.component';
import { OpportunitySummaryComponent } from './insight/opportunity-summary/opportunity-summary.component';
import { EquityAvailableComponent } from './equity-available/equity-available.component';
import { RateRiserComponent } from './rate-riser/rate-riser.component';
import { CasesComponent } from './cases/cases.component';
import { BrokerPerformanceComponent } from './insight/broker-performance/broker-performance.component';
import { ProductEndDateComponent } from './insight/product-end-date/product-end-date.component';
import { AppUsersComponent } from './insight/app-users/app-users.component';
import { PropertyDetailComponent } from '../shared/components/property-detail/property-detail.component';
import { ClientDetailComponent } from '../shared/components/client-detail/client-detail.component';
import { PropertyCreateComponent } from '../shared/components/property-create/property-create.component';
import { PropertyUpdateComponent } from '../shared/components/property-update/property-update.component';
import { BrokerPerformanceViewComponent } from '../shared/components/broker-performance-view/broker-performance-view.component';
import { IsManagerGuard } from '../guards/is-manager.guard';
import { ClientViewComponent } from './client-view/client-view.component';
import { HomeBuyerComponent } from './home-buyer/home-buyer.component';
import { ClientsComponent } from './clients/clients.component';
import { CalendarComponent } from './calendar/calendar.component';
import { OrganiserComponent } from './organiser/organiser.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'company/overview',
    },
    {
        path: 'company',
        children: [
            { path: 'overview', component: CompanyOverviewComponent },
            { path: 'users', component: UsersComponent, canActivate: [IsManagerGuard] },
        ],
    },
    {
        path: 'property/view/:id',
        component: PropertyDetailComponent,
    },
    {
        path: 'property/create/:investorId',
        component: PropertyCreateComponent,
    },
    {
        path: 'property/update/:investorId/:id',
        component: PropertyUpdateComponent,
    },
    {
        path: 'client/view/:id',
        component: ClientViewComponent,
    },
    {
        path: 'product-expiry',
        component: ProductExpiryComponent,
    },
    {
        path: 'early-review',
        component: EarlyReviewComponent,
    },
    {
        path: 'equity-available',
        component: EquityAvailableComponent,
    },
    {
        path: 'broker-performance/:id',
        component: BrokerPerformanceViewComponent,
    },
    {
        path: 'cases',
        component: CasesComponent,
    },
    {
        path: 'home-buyer',
        component: HomeBuyerComponent,
    },
    {
        path: 'organiser',
        component: OrganiserComponent,
    },
    {
        path: 'clients',
        component: ClientsComponent,
    },
    { path: '**', redirectTo: 'dashboard/company' },
];

export const routesComponents = [
    CompanyOverviewComponent,
    UsersComponent,
    ProductExpiryComponent,
    EarlyReviewComponent,
    HomeBuyerComponent,
    InsightOverviewComponent,
    OpportunitySummaryComponent,
    EquityAvailableComponent,
    RateRiserComponent,
    BrokerPerformanceComponent,
    ProductEndDateComponent,
    CasesComponent,
    AppUsersComponent,
    PropertyDetailComponent,
    ClientDetailComponent,
    PropertyCreateComponent,
    PropertyUpdateComponent,
    ClientsComponent,
    BrokerPerformanceViewComponent,
    CalendarComponent,
    OrganiserComponent,
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule {}

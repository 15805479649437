import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { Investor } from '../../../../../type';
import { DashboardService } from '../../../../../../dashboard/dashboard.service';

@Component({
    selector: 'app-table-actions-button-client',
    templateUrl: './table-actions-button-client.component.html',
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'scale(0.95)',
                }),
                animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
            ]),
            transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
        ]),
    ],
})
export class TableActionsButtonClientComponent {
    @Input()
    public clients: Investor[];

    public constructor(
        private readonly dashboardService: DashboardService,
        private readonly router: Router,
    ) {}

    public clientDetail(clientId: number): void {
        this.dashboardService.savePrevUrl();
        this.router.navigate([`dashboard/client/view/${clientId}`]);
    }
}

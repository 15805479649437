import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalComponent } from 'angular-custom-modal/modal.component';
import { OrganiserService, Range } from '../../../../shared/services/organiser.service';

@Component({
    selector: 'app-organiser-date-modal',
    templateUrl: './organiser-date-modal.component.html',
})
export class OrganiserDateModalComponent {
    @ViewChild('modal') modal: ModalComponent;

    @Output() updateContent = new EventEmitter<void>();

    public currentRange: Range;
    private callType: 'nextCall' | 'lastCall';

    public constructor(public readonly organiserService: OrganiserService) {}

    public btnClick(newRangeName: string): void {
        this.callType == 'nextCall'
            ? this.organiserService.changeRangeForNextCall(newRangeName)
            : this.organiserService.changeRangeForLastCall(newRangeName);
        this.modal.close();
    }

    public openDatesRangeModal(currentRange: Range, callType: 'nextCall' | 'lastCall'): void {
        this.currentRange = currentRange;
        this.callType = callType;
        this.modal.open();
    }
}

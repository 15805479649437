import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { companyNode, CompanyState } from '../../../../shared/store/company/company.store';
import { ActivatedRoute } from '@angular/router';
import { Broker } from '../../../../shared/type';
import { brokerSelectors } from '../../../../shared/store/broker/broker.selectors';
import { brokerNode, BrokerState } from '../../../../shared/store/broker/broker.store';
import { map } from 'rxjs/operators';
import { of, switchMap, tap } from 'rxjs';
import { brokerActions } from '../../../../shared/store/broker/broker.actions';

@Component({
    selector: 'app-company-users',
    templateUrl: './users.component.html',
})
export class UsersComponent {
    public search = '';

    public columns = [
        { field: 'id', title: 'ID' },
        { field: 'name', title: 'Name' },
        { field: 'email', title: 'Email' },
        { field: 'role', title: 'Role' },
        { field: 'clients', title: 'Clients' },
        { field: 'btl', title: 'Resi | BTL' },
        { field: 'status', title: 'Status' },
        { field: 'activity', title: 'Last Active' },
        {
            field: 'action',
            title: 'Action',
            sort: false,
            headerClass: 'justify-center',
        },
    ];

    private brokersRequest$ = of([]).pipe(
        tap(() => this.store.dispatch(brokerActions.brokersRequest({ perPage: 100, page: 1 }))),
    );

    public brokers$ = this.store.pipe(
        select(brokerSelectors.selectBrokers),
        switchMap(brokers => (brokers.length > 0 ? of(brokers) : this.brokersRequest$)),
        map((brokers: Broker[]) =>
            brokers.map(broker => ({
                id: broker.id,
                name: broker.name,
                email: broker.email,
                role: broker.role,
                clients: broker.stats.investors_count,
                btl: broker.stats.investment_properties_count + broker.stats.residential_properties_count,
                status: broker.state,
                activity: broker.last_login_at,
                obj: broker,
            })),
        ),
    );

    public constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store<{
            [companyNode]: CompanyState;
            [brokerNode]: BrokerState;
        }>,
    ) {}
}

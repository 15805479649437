import { Component, Input, OnInit } from '@angular/core';
import { HomeBuyer } from '../../../../type';
import { DataPropertyService } from '../../../../services/data-property.service';

@Component({
    selector: 'app-property-home-buyer-update',
    templateUrl: './property-home-buyer-update.component.html',
})
export class PropertyHomeBuyerUpdateComponent implements OnInit {
    @Input()
    public homeBuyer: HomeBuyer;

    public acceptedDate: Date | null;
    public offerDate: Date | null;
    public mortgagePrincipleExpiryDate: Date | null;

    public constructor(public readonly dataPropertyService: DataPropertyService) {}

    public ngOnInit() {
        this.acceptedDate = DataPropertyService.prepareDateToShow(this.homeBuyer.accepted_date);
        this.offerDate = DataPropertyService.prepareDateToShow(this.homeBuyer.offer_date);
        this.mortgagePrincipleExpiryDate = DataPropertyService.prepareDateToShow(
            this.homeBuyer.mortgage_principle_expiry_date,
        );
    }
}

import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalComponent } from 'angular-custom-modal/modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../shared/services/toast.service';
import { BrokerAppointmentService } from '../../../shared/services/broker-appointment.service';
import { DataPropertyService } from '../../../shared/services/data-property.service';
import { BrokerAppointment, Investor } from '../../../shared/type';

@Component({
    selector: 'app-update-appointment-modal',
    templateUrl: './update-appointment-modal.component.html',
})
export class UpdateAppointmentModalComponent {
    @ViewChild('modal') modal: ModalComponent;

    @Output() updateContent = new EventEmitter<void>();

    public form: FormGroup;

    public appointment: BrokerAppointment;
    public investor: Investor;

    public isDisableControls = false;
    public isSubmitForm = false;

    public states = [
        { title: 'Pending', value: 'pending' },
        { title: 'Done', value: 'done' },
        { title: 'Void', value: 'void' },
    ];

    public types = [
        { title: 'Appointment', value: 'appointment' },
        { title: 'Reminder', value: 'reminder' },
    ];

    public initData: Date;

    public constructor(
        protected readonly fb: FormBuilder,
        protected readonly brokerAppointmentService: BrokerAppointmentService,
        protected readonly dataPropertyService: DataPropertyService,
    ) {}

    public save(): void {
        this.isSubmitForm = true;
        const successResponse = () => {
            this.isDisableControls = false;
            this.updateContent.emit();
            ToastService.showToastMessage('color-success', 'Appointment has been updated');
            this.modal.close();
        };

        const errorResponse = () => {
            this.isDisableControls = false;
            ToastService.showToastMessage('color-danger', 'Error');
        };

        if (this.form.valid) {
            this.isDisableControls = true;
            this.brokerAppointmentService
                .updateBrokerAppointment(this.appointment.id, {
                    type: this.form.controls['type'].value,
                    title: this.form.controls['title'].value,
                    notes: this.form.controls['notes'].value,
                    state: this.form.controls['state'].value,
                    date: new Date(this.form.get('date').value),
                })
                .subscribe({
                    next: () => successResponse(),
                    error: () => errorResponse(),
                });
        }
    }

    public openUpdateModal(appointment: BrokerAppointment, investor: Investor): void {
        this.investor = investor;
        this.appointment = appointment;
        this.form = this.fb.group({
            title: [appointment.title, Validators.required],
            notes: [appointment.notes],
            type: [appointment.type],
            state: [appointment.state],
            date: [null],
        });
        this.initData = DataPropertyService.prepareDateToShow(appointment.appointment_date);
        this.modal.open();
    }

    public delete() {
        this.isSubmitForm = true;
        const successResponse = () => {
            this.isDisableControls = false;
            this.updateContent.emit();
            ToastService.showToastMessage('color-success', 'Appointment has been deleted');
            this.modal.close();
        };

        const errorResponse = () => {
            this.isDisableControls = false;
            ToastService.showToastMessage('color-danger', 'Error');
        };

        this.isDisableControls = true;
        this.brokerAppointmentService.deleteBrokerAppointment(this.appointment.id).subscribe({
            next: () => successResponse(),
            error: () => errorResponse(),
        });
    }
}

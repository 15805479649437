import { createReducer, on } from '@ngrx/store';
import { Investor } from '../../type';
import { investorActions } from './investor.actions';

export const investorNode = 'investor';

export interface InvestorState {
    investors: Investor[];
}

export const investorInitialState: InvestorState = {
    investors: [],
};

export const investorReducer = createReducer(
    investorInitialState,
    on(investorActions.investorsSet, (state, action) => ({
        ...state,
        investors: action.investors,
    })),
);

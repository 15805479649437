import { Component, Input } from '@angular/core';
import { Broker, Investor, Property } from '../../../../type';
import { Router } from '@angular/router';
import { DataTableService } from '../../../../services/data-table.service';

@Component({
    selector: 'app-property-investor-info',
    templateUrl: './property-investor-info.component.html',
})
export class PropertyInvestorInfoComponent {
    @Input()
    public property: Property;

    @Input()
    public investor: Investor;

    @Input()
    public broker: Broker;

    public constructor(private readonly router: Router) {}

    public redirectToInvestorView(investorId: number): void {
        this.router.navigate([`dashboard/client/view/${investorId}`]);
    }

    protected readonly DataTableService = DataTableService;
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';
import { InvestorService } from '../../services/investor.service';
import { dashboardActions } from './dashboard.actions';

@Injectable()
export class DashboardEffects {
    public requestContactRequiredCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardActions.requestContactRequiredCount),
            switchMap(() => this.investorService.contactRequiredCountRequest()),
            map(response => dashboardActions.setContactRequiredCount(response)),
        ),
    );

    constructor(
        private actions$: Actions,
        private investorService: InvestorService,
    ) {}
}

import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { animate, style, transition, trigger } from '@angular/animations';
import { dashboardLayoutNode, DashboardLayoutState } from '../../../shared/store/dashboard/store';
import { filter, first, Observable } from 'rxjs';
import { userSelectors } from '../../../shared/store/user/user.selectors';
import { Profile } from '../../../shared/type';
import { userNode, UserState } from '../../../shared/store/user/user.store';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Router } from '@angular/router';
import { companySelectors } from '../../../shared/store/company/company.selectors';
import { companyNode, CompanyState } from '../../../shared/store/company/company.store';
import { Title } from '@angular/platform-browser';
import { dashboardActions } from '../../../shared/store/dashboard/dashboard.actions';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scale(0.95)' }),
                animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
            ]),
            transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
        ]),
    ],
})
export class HeaderComponent implements OnInit {
    public profile$: Observable<Profile | null> = this.store.pipe(select(userSelectors.selectProfile));
    public company$ = this.store.pipe(select(companySelectors.selectSelectedCompany));

    public constructor(
        private readonly titleService: Title,
        private readonly authService: AuthenticationService,
        private readonly router: Router,
        private readonly store: Store<{
            [dashboardLayoutNode]: DashboardLayoutState;
            [userNode]: UserState;
            [companyNode]: CompanyState;
        }>,
    ) {}

    public ngOnInit() {
        this.setActiveDropdown();
        this.company$
            .pipe(
                filter(company => !!company),
                first(),
            )
            .subscribe(company => this.titleService.setTitle(`${company.business_name} | LeadGen`));
    }

    public setActiveDropdown(): void {
        const selector = document.querySelector('ul.horizontal-menu a[routerLink="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add('active');
            const all: any = document.querySelectorAll('ul.horizontal-menu .nav-link.active');
            for (let i = 0; i < all.length; i++) {
                all[0]?.classList.remove('active');
            }
            const ul: any = selector.closest('ul.sub-menu');
            if (ul) {
                let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele?.classList.add('active');
                    });
                }
            }
        }
    }

    public toggleSidebar(): void {
        this.store.dispatch(dashboardActions.toggleSidebar());
    }

    public logOut() {
        this.authService.logout();
        this.router.navigate(['auth']);
    }
}

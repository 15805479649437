export const environment = {
    production: false,
    baseUrl: 'https://dev.leadgen.propertycircle.uk',
    baseServerUrl: 'https://dev.portal.propertycircle.uk/api/v1',

    googleApiKey: 'AIzaSyCPsUC86i-v8ZBARFXH3SJW1wI-FUv1Al4',

    apiRoutes: {
        endpoints: {
            auth: {
                login: '/auth/login',
                me: '/auth/me',
                refresh: '/auth/refresh',
            },
        },
    },
};

<div *ngIf='(property$ | async) as dataset'>

    <app-property-products-modal #productsModal
                                 [products]='dataset.property.mortgages[0]?.products'></app-property-products-modal>

    <div class='panel flex flex-row justify-between'>
        <h5 class='text-2xl'>Property detail</h5>
        <button (click)='redirectToPrevURL()' type='button' class='btn btn-outline-dark'>Back</button>
    </div>

    <div class='mt-6'>
        <app-property-investor-info [property]='dataset.property' [investor]='dataset.investor'
                                    [broker]='dataset.broker'></app-property-investor-info>
    </div>

    <div *ngIf='dataset.property.home_buyer === null' class='mt-6'>
        <app-property-priority [property]='dataset.property'></app-property-priority>
    </div>

    <hr *ngIf='dataset.property.home_buyer === null' class='my-6 border-[#e0e6ed]' />

    <div class='flex flex-row gap-6 justify-between'>
        <app-property-info [property]='dataset.property' [investor]='dataset.investor'></app-property-info>

        <ng-container *ngIf='dataset.property.home_buyer === null else HomeBuyer'>
            <app-property-mortgage-info [property]='dataset.property'></app-property-mortgage-info>
        </ng-container>

        <ng-template #HomeBuyer>
            <app-property-home-buyer [homeBuyer]='dataset.property.home_buyer'></app-property-home-buyer>
        </ng-template>

        <app-property-activity-log [property]='dataset.property'></app-property-activity-log>
    </div>


    <hr class='my-6 border-[#e0e6ed]' />

    <div *ngIf='dataset.property.home_buyer === null' class='mt-6 flex gap-6 flex-row justify-between'>
        <app-property-market [property]='dataset.property'></app-property-market>


        <app-property-erc [currentERC]='dataset.property.mortgages[0]?.current_erc || 0' [isOnlyShow]='true' [ercs$]='ercs$'></app-property-erc>

        <div
            class='max-w-[19rem] w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed]'>

            <div class='py-7 px-6'>
                <div class='flex flex-row gap-3 items-center'>
                    <div class='text-xl font-semibold'>Comparison product</div>
                    <div class='cursor-pointer' (click)='productsModal.open()'>
                        <svg width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M13 11L22 2M22 2H16.6562M22 2V7.34375' stroke='currentColor' stroke-width='1.5'
                                  stroke-linecap='round' stroke-linejoin='round' />
                            <path
                                d='M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2'
                                stroke='currentColor' stroke-width='1.5' stroke-linecap='round' />
                        </svg>

                    </div>
                </div>
                <hr class='my-6 border-[#e0e6ed]' />
                <div class='mb-2 flex w-full items-center justify-between'>
                    <div class='text-white-dark'>Lender :</div>
                    <div *ngIf='dataset.property.mortgages[0]?.products[0] !== undefined'>
                        {{dataset.property.mortgages[0]?.products[0]?.LenderName}}</div>
                    <div *ngIf='dataset.property.mortgages[0]?.products[0] === undefined'>-</div>
                </div>
                <div class='mb-2 flex w-full items-center justify-between'>
                    <div class='text-white-dark'>Total fees :</div>
                    <div *ngIf='dataset.property.mortgages[0]?.products[0] !== undefined'>
                        £ {{dataset.property.mortgages[0]?.products[0]?.FeesTotal | number}}</div>
                    <div *ngIf='dataset.property.mortgages[0]?.products[0] === undefined'>-</div>
                </div>
                <div class='mb-2 flex w-full items-center justify-between'>
                    <div class='text-white-dark'>Initial monthly payments :</div>
                    <div *ngIf='dataset.property.mortgages[0]?.products[0] !== undefined'>
                        £ {{dataset.property.mortgages[0]?.products[0]?.InitialMonthlyPayment | number}}</div>
                    <div *ngIf='dataset.property.mortgages[0]?.products[0] === undefined'>-</div>
                </div>
            </div>
        </div>

        <div
            class='max-w-[19rem] w-full bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed]'>

            <div class='py-7 px-6'>
                <div class='text-xl font-semibold uppercase'>Custom product</div>
                <hr class='my-6 border-[#e0e6ed]' />
                <div class='mb-2 flex w-full items-center justify-between'>
                    <div class='text-white-dark'>Enabled :</div>
                    <div class='uppercase font-semibold' *ngIf='dataset.property.mortgages[0] !== undefined'>
                        {{dataset.property.mortgages[0]?.use_t7t_data_custom}}</div>
                    <div *ngIf='dataset.property.mortgages[0] === undefined'>-</div>
                </div>
                <div class='mb-2 flex w-full items-center justify-between'>
                    <div class='text-white-dark'>Total fees :</div>
                    <div *ngIf='dataset.property.mortgages[0]?.t7t_data_custom?.total_fees !== null'>
                        £ {{dataset.property.mortgages[0]?.t7t_data_custom?.total_fees | number}}</div>
                    <div *ngIf='dataset.property.mortgages[0]?.t7t_data_custom?.total_fees === null'>-</div>
                </div>
                <div class='mb-2 flex w-full items-center justify-between'>
                    <div class='text-white-dark'>Initial monthly payments :</div>
                    <div *ngIf='dataset.property.mortgages[0]?.t7t_data_custom?.initial_monthly_payment !== null'>
                        {{dataset.property.mortgages[0]?.t7t_data_custom?.initial_monthly_payment | number}}</div>
                    <div *ngIf='dataset.property.mortgages[0]?.t7t_data_custom?.initial_monthly_payment === null'>-
                    </div>
                </div>
            </div>
        </div>

    </div>

    <hr *ngIf='dataset.property.home_buyer === null' class='my-6 border-[#e0e6ed]' />

    <div class='mt-6 flex gap-6 justify-between flex-row'>

        <div
            class='w-[43.5rem] bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed]'>

            <div class='py-7 px-6'>
                <div class='text-xl font-semibold'>Notes</div>

                <hr class='my-6 border-[#e0e6ed]' />

                <div class='mb-2'>
                    <textarea id='notes' class='form-input' [(ngModel)]='notes'></textarea>
                    <div class='mt-3 flex flex-row justify-end'>
                        <ng-container *ngIf='dataset.property.notes === null; else updateBTN'>
                            <button (click)='updateNote()' [disabled]='dataset.property.notes === notes' type='button'
                                    class='btn btn-outline-primary'>Add
                            </button>
                        </ng-container>
                        <ng-template #updateBTN>
                            <button (click)='updateNote()' [disabled]='dataset.property.notes === notes' type='button'
                                    class='btn btn-outline-primary'>Update
                            </button>
                        </ng-template>
                    </div>
                </div>

            </div>
        </div>

        <div
            class='w-[43.5rem] bg-white shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-[#e0e6ed] overflow-y-scroll max-h-64'>
            <app-property-shared-ownership [investors]='dataset.investors'
            ></app-property-shared-ownership>
        </div>
    </div>
</div>

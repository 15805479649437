import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { dashboardLayoutNode, DashboardLayoutState } from '../../../shared/store/dashboard/store';
import { slideDownUp } from '../../../../shared/animations';
import { BehaviorSubject, filter, first } from 'rxjs';
import { userNode, UserState } from '../../../shared/store/user/user.store';
import { userSelectors } from '../../../shared/store/user/user.selectors';
import { dashboardActions } from '../../../shared/store/dashboard/dashboard.actions';
import { companySelectors } from '../../../shared/store/company/company.selectors';
import { companyNode, CompanyState } from '../../../shared/store/company/company.store';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { appActions } from '../../../shared/store/app/app.actions';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    animations: [
        slideDownUp,
        trigger('toggleAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scale(0.95)' }),
                animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
            ]),
            transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
        ]),
    ],
})
export class SidebarComponent implements OnDestroy {
    public activeDropdown$: BehaviorSubject<string[]> = new BehaviorSubject(['']);
    public profile$ = this.store.pipe(select(userSelectors.selectProfile));
    public company$ = this.store.pipe(select(companySelectors.selectSelectedCompany));
    public companyList$ = this.store.pipe(select(companySelectors.selectCompaniesList));

    public selectedCompany: number;

    private routerSubscribe = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(event => this.autoToggleSidebar((event as NavigationEnd).url));

    constructor(
        private readonly authService: AuthenticationService,
        private readonly store: Store<{
            [dashboardLayoutNode]: DashboardLayoutState;
            [userNode]: UserState;
            [companyNode]: CompanyState;
        }>,
        public router: Router,
    ) {}

    public ngOnDestroy() {
        this.routerSubscribe.unsubscribe();
    }

    public toggleSidebar(): void {
        this.store.dispatch(dashboardActions.toggleSidebar());
    }

    public autoToggleSidebar(route: string): void {
        const _route = route.split('/');
        const penultimatePart = _route[_route.length - 2];
        const lastPart = _route[_route.length - 1];
        this.activeDropdown$.next([penultimatePart, lastPart]);
    }

    public toggleAccordion(name: string) {
        this.activeDropdown$
            .pipe(first())
            .subscribe(val =>
                val.includes(name) ? this.activeDropdown$.next(val.filter(d => d !== name)) : val.push(name),
            );
    }

    public toggleMobileMenu() {
        if (window.innerWidth < 1024) this.toggleSidebar();
    }

    public logOut() {
        this.authService.logout();
        this.router.navigate(['auth']);
    }

    public switchCompany() {
        this.authService
            .authToAnotherCompany(this.selectedCompany)
            .pipe(first())
            .subscribe(token => {
                this.store.dispatch(appActions.tokenSave({ token }));
                window.location.reload();
            });
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Profile } from '../type';
import { environment as env } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
    public constructor(private http: HttpClient) {}

    public profileRequest(): Observable<Profile> {
        return this.http
            .get<{ data: Profile; status: string }>(`${env.baseServerUrl}/auth/me`)
            .pipe(map(response => response.data));
    }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { dashboardLayoutNode, DashboardLayoutState } from '../shared/store/dashboard/store';
import { userNode, UserState } from '../shared/store/user/user.store';
import { userSelectors } from '../shared/store/user/user.selectors';
import { filter, first } from 'rxjs';
import { dashboardSelectors } from '../shared/store/dashboard/dashboard.selectors';
import { dashboardActions } from '../shared/store/dashboard/dashboard.actions';

@Component({
    selector: 'app-investor-dashboard',
    templateUrl: './investor-dashboard.component.html',
})
export class InvestorDashboardComponent implements OnInit {
    public sidebarCondition$ = this.store.pipe(select(dashboardSelectors.selectSidebarCondition));
    public mainLoaderCondition$ = this.store.pipe(select(dashboardSelectors.selectMainLoaderCondition));
    public user$ = this.store.pipe(select(userSelectors.selectProfile));

    public showTopButton = false;

    public constructor(
        private readonly store: Store<{
            [dashboardLayoutNode]: DashboardLayoutState;
            [userNode]: UserState;
        }>,
    ) {}

    public ngOnInit() {
        this.store.dispatch(dashboardActions.setMainLoader({ state: true }));
        this.user$
            .pipe(
                filter(profile => profile !== null),
                first(),
            )
            .subscribe(() => this.store.dispatch(dashboardActions.setMainLoader({ state: false })));
    }

    @HostListener('scroll')
    private onScroll() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            this.showTopButton = true;
        } else {
            this.showTopButton = false;
        }
    }

    public toggleSidebar(): void {
        this.store.dispatch(dashboardActions.toggleSidebar());
    }

    public goToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
}

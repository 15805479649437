<modal [closeOnOutsideClick]='true' #modal class='modal-top'>
    <ng-template #modalHeader>
        <div>Delete?</div>
    </ng-template>
    <ng-template #modalBody>
        <p class='h-3'>Are you sure you want to permanently delete this property? This cannot be undone</p>
    </ng-template>
    <ng-template #modalFooter>
        <div class='flex flex-row justify-center'>

            <button type='button' (click)='delete()' class='btn btn-danger ltr:ml-4 rtl:mr-4'>
                Delete
            </button>

            <button type='button' (click)='close()' class='btn btn-primary ltr:ml-4 rtl:mr-4'>
                Close
            </button>
        </div>

    </ng-template>
</modal>

import { Injectable } from '@angular/core';
import { catchError, filter, first, map, Observable, of, tap } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccessToken, RegistrationTokenInfoResponse, SavedAccessToken } from '../../auth/auth.type';
import { select, Store } from '@ngrx/store';
import { userNode, UserState } from '../store/user/user.store';
import { userActions } from '../store/user/user.actions';
import { appActions } from '../store/app/app.actions';
import { appNode, AppState } from '../store/app/app.store';
import { appSelectors } from '../store/app/app.selectors';
import { companyActions } from '../store/company/company.actions';
import { UserService } from './user.service';
import { Profile } from '../type';
import { tableActions } from '../store/table/table.actions';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    public constructor(
        private readonly http: HttpClient,
        private readonly userService: UserService,
        private readonly store: Store<{
            [appNode]: AppState;
            [userNode]: UserState;
        }>,
    ) {}

    public static refreshUrl = `${env.baseServerUrl}/auth/refresh`;

    public isAuthenticated(): Observable<boolean> {
        return this.store.pipe(
            select(appSelectors.selectToken),
            map(token => !!token),
        );
    }

    public getToken(forceMode = false): Observable<SavedAccessToken> {
        return this.store.pipe(
            select(appSelectors.selectToken),
            tap(token => this.freshToken(token, forceMode)),
            filter(token => this.isValidToken(token, forceMode)),
        );
    }

    private isValidToken(token: SavedAccessToken | null, forceMode = false): boolean {
        if (!token || forceMode) return true;
        const checkTime = token.expires_in + token.saved_at / 1000 - (Date.now() / 1000 - 500);
        return checkTime >= 0;
    }

    private freshToken(token: SavedAccessToken, forceMode = false): void {
        if (this.isValidToken(token, forceMode)) return;
        this.http
            .post<AccessToken>(AuthenticationService.refreshUrl, {})
            .pipe(
                catchError(() => of(null)),
                first(),
            )
            .subscribe(token => this.store.dispatch(appActions.tokenSave({ token })));
    }

    public authenticate(token: AccessToken): Observable<Profile> {
        this.store.dispatch(appActions.tokenSave({ token }));
        this.store.dispatch(tableActions.loadConfigs());
        return this.userService.profileRequest().pipe(
            tap(profile => {
                this.store.dispatch(userActions.profileSet({ profile }));
                this.store.dispatch(
                    companyActions.selectedCompanySet({
                        selectedCompany: profile.broker_company,
                        companiesList: profile.broker_companies,
                    }),
                );
            }),
        );
    }

    public authToAnotherCompany(companyId: number): Observable<AccessToken> {
        return this.http
            .post<AccessToken>(`${env.baseServerUrl}/auth/broker-company`, {
                broker_company_id: companyId,
            })
            .pipe(map(response => response));
    }

    public logout(): void {
        this.store.dispatch(appActions.reset());
    }

    public attemptLogin(email: string, password: string, type: string): Observable<AccessToken> {
        return this.http.post<AccessToken>(`${env.baseServerUrl}/auth/login`, {
            email,
            password,
            type,
            url: '-',
        });
    }

    public registrationTokenInfoRequest(token: string): Observable<RegistrationTokenInfoResponse> {
        return this.http
            .get<{ data: RegistrationTokenInfoResponse; status: string }>(`${env.baseServerUrl}/auth/register/${token}`)
            .pipe(map(response => response.data));
    }

    public registration(data: {
        registration_token: string;
        phone?: string;
        email: string;
        name?: string;
        password: string;
        password_confirmation: string;
    }): Observable<AccessToken> {
        return this.http.post<AccessToken>(`${env.baseServerUrl}/auth/register`, data);
    }

    public sendingEmailForResetPassword(data: { email: string; type: 'broker' | 'investor' }): Observable<string> {
        return this.http
            .post<{ message: string }>(`${env.baseServerUrl}/auth/password/send`, {
                ...data,
                callback_url: `${env.baseUrl}/auth/recover`,
            })
            .pipe(map(response => response.message));
    }

    public resetPassword(
        email: string,
        token: string,
        password: string,
        password_confirmation: string,
    ): Observable<AccessToken> {
        return this.http.put<AccessToken>(`${env.baseServerUrl}/auth/password/reset`, {
            email,
            token,
            password,
            password_confirmation,
        });
    }
}

import { createAction, props } from '@ngrx/store';
import {
    AppUsersResponse,
    InsightsBrokerPerformance,
    OpportunityRatingRequest,
    PriorityChartData,
    ProductEndDateResponse,
    Rate,
} from '../insight.type';

const insightOverviewDataRequest = createAction('[INSIGHT] overview data has been requested');
const opportunityDataRequest = createAction(
    '[INSIGHT] opportunity data has been requested',
    props<{
        rating: number;
    }>(),
);
const brokerPerformanceRequest = createAction(
    '[INSIGHT] broker performance has been requested',
    props<{ params?: any }>(),
);
const productEndDateRequest = createAction('[INSIGHT] product and date has been requested');
const appUsersRequest = createAction('[INSIGHT] app users has been requested');

const setAppUsers = createAction('[INSIGHT] app users has been set', props<AppUsersResponse>());
const setOpportunityData = createAction('[INSIGHT] opportunity data has been set', props<OpportunityRatingRequest>());
export const setAppUsersData = createAction(
    '[INSIGHT] app users data have been set',
    props<{ count: number; pct: number }>(),
);
export const setProductEndDate = createAction(
    '[INSIGHT] product End Date has been set',
    props<ProductEndDateResponse>(),
);

export const set3MonthRate = createAction('[INSIGHT] 3 month rate have been set', props<Rate>());
export const setVariance = createAction('[INSIGHT] variance has been set', props<Rate>());
export const setRetentionRate = createAction('[INSIGHT] retention rate has been set', props<Rate>());
export const setTotalData = createAction(
    '[INSIGHT] totalData have been set',
    props<{ properties: number; propertiesAvg: number; clientRevenue: number }>(),
);

export const priority4ChartSet = createAction(
    '[INSIGHT] data for priority4Chart has been set',
    props<{ data: PriorityChartData }>(),
);

export const brokerPerformance4Set = createAction(
    '[INSIGHT] broker performance 4 has been set',
    props<{ data: InsightsBrokerPerformance[] }>(),
);
export const brokerPerformance5Set = createAction(
    '[INSIGHT] broker performance 5 has been set',
    props<{ data: InsightsBrokerPerformance[] }>(),
);

export const priority5ChartSet = createAction(
    '[INSIGHT] data for priority5Chart has been set',
    props<{ data: PriorityChartData }>(),
);

export const insightActions = {
    insightOverviewDataRequest,
    priority4ChartSet,
    priority5ChartSet,
    set3MonthRate,
    setRetentionRate,
    setVariance,
    setTotalData,
    setAppUsersData,
    opportunityDataRequest,
    setOpportunityData,
    brokerPerformanceRequest,
    brokerPerformance4Set,
    brokerPerformance5Set,
    productEndDateRequest,
    setProductEndDate,
    appUsersRequest,
    setAppUsers,
};

import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Investor, Profile, SumOfPropertiesProfiles } from '../../type';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { InvestorService } from '../../services/investor.service';
import { ClientDetailPropertyTableComponent } from './client-detail-property-table/client-detail-property-table.component';
import { ClientDetailCompaniesTabsComponent } from './client-detail-companies-tabs/client-detail-companies-tabs.component';
import { CompanyProfile } from '../../../dashboard/company/company.type';
import { CompanyService } from '../../services/company.service';
import { DataTableService } from '../../services/data-table.service';
import { slideDownUp } from '../../../../shared/animations';
import { RouterService } from '../../services/router.service';

export const clientDetailComponents = [ClientDetailPropertyTableComponent, ClientDetailCompaniesTabsComponent];

@Component({
    selector: 'app-client-detail',
    templateUrl: './client-detail.component.html',
    animations: [slideDownUp],
})
export class ClientDetailComponent implements OnInit {
    @Input() public client: Investor | Profile;
    public companiesList$: BehaviorSubject<CompanyProfile[]> = new BehaviorSubject([]);
    public companyProfile$: BehaviorSubject<SumOfPropertiesProfiles | null> = new BehaviorSubject(null);

    public filter: unknown;
    public loader$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public accordian: 'Investment' | 'Residential' | null = 'Investment';

    public constructor(
        private readonly investorService: InvestorService,
        private readonly routerService: RouterService,
        private readonly tableService: DataTableService,
        public readonly location: Location,
        private readonly router: Router,
        private readonly companyService: CompanyService,
        private readonly dataTableService: DataTableService,
    ) {}

    public ngOnInit() {
        const companies$ = this.companyService.investorCompaniesByInvestorIds([this.client.id]);
        this.setCompanyProfile(this.client.id);
        this.filter = {
            filter: { investors: { id: { eq: this.client.id } }, investment_type: { eq: this.accordian } },
        };
        companies$.subscribe(companies => this.companiesList$.next(companies));
    }

    public createProperty(): void {
        const parentRoute = this.routerService.parentRoute();
        this.router.navigate([`${parentRoute}/property/create/${this.client.id}`]);
    }

    public changingActiveCompany(data: {
        tabName: 'All' | 'Individual' | 'Company' | 'Shared';
        company: CompanyProfile | null;
    }): void {
        this.loader$.next(true);

        this.dataTableService.resetDataSet();
        this.setCompanyProfile(this.client.id, ClientDetailComponent.getInvestorFilter(data));
        this.filter = {
            filter: {
                investors: { id: { eq: this.client.id } },
                ...ClientDetailComponent.getPropertyFilter(data),
                investment_type: { eq: this.accordian },
            },
        };
        this.tableService.updateTableWithReset(this.filter);
    }

    public static getPropertyFilter(data: {
        tabName: 'All' | 'Individual' | 'Company' | 'Shared';
        company: CompanyProfile | null;
    }): object | null {
        let filter = {};
        if (data.tabName == 'Individual' || data.tabName == 'Shared') filter = { ownership: { eq: data.tabName } };
        if (data.tabName == 'Company') filter = { investor_company: { id: { eq: data.company.id } } };
        return filter;
    }

    public static getInvestorFilter(data: {
        tabName: 'All' | 'Individual' | 'Company' | 'Shared';
        company: CompanyProfile | null;
    }): object | null {
        let filter = {};
        if (data.tabName == 'Individual' || data.tabName == 'Shared') filter = { ownership: { eq: data.tabName } };
        if (data.tabName == 'Company') filter = { investor_companies: { id: { eq: data.company.id } } };
        return filter;
    }

    public tableUpdated(condition: boolean) {
        this.loader$.next(!condition);
    }

    private setCompanyProfile(clientId: number, filter?: object | null) {
        const _filter = { filter: { ...filter, investment_type: { eq: 'Investment' } } };
        this.companyProfile$.next(null);
        this.investorService
            .sumPropertyProfilesByInvestor(clientId, [_filter])
            .subscribe(sums => this.companyProfile$.next(sums));
    }

    public clickByAccordian(accordian: 'Investment' | 'Residential'): unknown {
        if (this.accordian == accordian) return (this.accordian = null);
        this.accordian = accordian;
        return this.changingActiveCompany({ tabName: 'All', company: null });
    }
}

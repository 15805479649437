<div class='py-7 px-6'>
    <div class='text-xl font-semibold'>Shared Ownership</div>

    <hr class='my-6 border-[#e0e6ed]' />

    <table class='table-auto'>
        <thead>
        <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor='let investor of investors'>
            <td class='truncate'><a [routerLink]='["/dashboard/client/view/", investor.id]'>{{investor.name}}</a></td>
            <td class='truncate'>{{investor.phone}}</td>
            <td class='truncate'>{{investor.email}}</td>
        </tr>
        </tbody>
    </table>
</div>

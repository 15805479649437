import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PropertyService } from '../../services/property.service';
import { BehaviorSubject, combineLatestWith, first, Observable, switchMap } from 'rxjs';
import { DataPropertyService } from '../../services/data-property.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { FormattedAddress } from '../../type/google.type';
import { Profile } from '../../type';
import { select, Store } from '@ngrx/store';
import { userSelectors } from '../../store/user/user.selectors';
import { userNode, UserState } from '../../store/user/user.store';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-property-create',
    templateUrl: './property-create.component.html',
})
export class PropertyCreateComponent implements OnInit {
    public profile$: Observable<Profile | null> = this.store.pipe(select(userSelectors.selectProfile));
    public ercs$: BehaviorSubject<{ erc: number | null; date: Date[] }[]>;
    public isSaveBTNDisabled: boolean;

    constructor(
        public readonly location: Location,
        private readonly propertyService: PropertyService,
        private readonly route: ActivatedRoute,
        public readonly dataPropertyService: DataPropertyService,
        private readonly store: Store<{
            [userNode]: UserState;
        }>,
    ) {}

    public isSubmitForm = false;
    private address: FormattedAddress;

    public ngOnInit() {
        this.isSaveBTNDisabled = false;
        this.dataPropertyService.initForm(this.dataPropertyService.initData);
        this.ercs$ = new BehaviorSubject<{ erc: number | null; date: Date[] }[]>([]);
    }

    public submitForm(): void {
        this.isSubmitForm = true;

        if (!this.dataPropertyService.form.valid) return;

        this.isSaveBTNDisabled = true;
        this.route.params
            .pipe(
                first(),
                combineLatestWith(this.profile$, this.ercs$),
                switchMap(([params, profile, ercs]) =>
                    this.propertyService.createProperty({
                        ...this.dataPropertyService.prepareDataToRequest(this.dataPropertyService.form, ercs),
                        address: this.address.formattedAddress,
                        address_id: this.address.addressId,
                        route: this.address.addressChunks.route,
                        street_number: this.address.addressChunks.street_number,
                        county: this.address.addressChunks.county,
                        postal_town: this.address.addressChunks.postal_town,
                        address_vendor: this.address.addressChunks.address_vendor,
                        paf: this.address.addressChunks.paf,
                        uprn: this.address.addressChunks.uprn,
                        coordinates: { ...this.address.addressChunks.coordinates },
                        investor_ids:
                            Number(profile.id) != Number(params['investorId'])
                                ? [Number(params['investorId'])]
                                : undefined,
                    }),
                ),
            )
            .subscribe({
                next: () => {
                    ToastService.showToastMessage('color-success', 'Property has been created');
                    this.isSaveBTNDisabled = false;
                    this.location.back();
                },
                error: () => {
                    this.isSaveBTNDisabled = false;
                    ToastService.showToastMessage('color-danger', 'Incorrect data');
                },
            });
    }

    public addressControl(): FormControl {
        return this.dataPropertyService.form.controls['address'] as FormControl;
    }

    public addressSet(event: { address: FormattedAddress }): void {
        this.address = event.address;
        this.dataPropertyService.form.controls['postcode'].patchValue(event.address.addressChunks.postcode);
    }
}

import { colDef } from '@bhplugin/ng-datatable';

export class TableColService {
    public static ServiceTablesCols: colDef[] = [
        { field: 'clients', title: 'Client(s)', sort: false },
        { field: 'address', title: 'Address' },
        { field: 'lenderName', title: 'Lender Name', hide: true },
        { field: 'purchasePrice', title: 'Purchase Price', hide: true },
        { field: 'purchaseDate', title: 'Purchase Date', hide: true },
        { field: 'investorCompany', title: 'Ltd Co Name', hide: true, sort: false },
        { field: 'productEndTerm', title: 'Product end term', hide: true, sort: true },
        { field: 'type', title: 'Type' },
        { field: 'broker', title: 'Broker' },

        { field: 'hmo', title: 'HMO', sort: false, hide: true },
        { field: 'mub', title: 'MUB', sort: false, hide: true },
        { field: 'propertyOwnerType', title: 'Property owner type', hide: true },
        { field: 'propertyValue', sort: false, title: 'Property value', hide: true },
        { field: 'loanAmount', title: 'Loan Amount', hide: true },
        { field: 'monthlyPayment', title: 'Monthly payment', hide: true },
        { field: 'interestRate', title: 'Interest rate', hide: true },
        { field: 'rentalIncome', title: 'Rental Income', hide: true },

        { field: 'portfolioValues', title: 'Total Portfolios Values', hide: true, sort: false },
        { field: 'mortgagesOutstanding', title: 'Total Mortgages Outstanding', hide: true, sort: false },
        { field: 'monthlyMortgagesPayments', title: 'Total Monthly Mortgages', hide: true, sort: false },
        { field: 'rentalsIncome', title: 'Total Rentals Income', hide: true, sort: false },
        { field: 'growth', title: 'Growth', hide: true, sort: false },
        { field: 'yield', title: 'Yield', hide: true, sort: false },

        { field: 'status', title: 'Status' },
    ];

    public static dashboardClientViewCols: colDef[] = [
        { field: 'address', title: 'Property', hide: false },
        { field: 'lenderName', title: 'Lender Name', hide: true },
        { field: 'purchasePrice', title: 'Purchase Price', hide: true },
        { field: 'purchaseDate', title: 'Purchase Date', hide: true },
        { field: 'investorCompany', title: 'Ltd Co Name', hide: true, sort: false },
        { field: 'productEndTerm', title: 'Product end term', hide: true, sort: true },
        { field: 'type', title: 'Type', hide: false },
        { field: 'broker-single', title: 'Broker', hide: false },
        { field: 'status', title: 'Status', hide: false },

        { field: 'hmo', title: 'HMO', sort: false, hide: true },
        { field: 'mub', title: 'MUB', sort: false, hide: true },
        { field: 'propertyOwnerType', title: 'Property owner type', hide: true },
        { field: 'propertyValue', sort: false, title: 'Property value', hide: true },
        { field: 'loanAmount', title: 'Loan Amount', hide: true },
        { field: 'monthlyPayment', title: 'Monthly payment', hide: true },
        { field: 'interestRate', title: 'Interest rate', hide: true },
        { field: 'rentalIncome', title: 'Rental Income', hide: true },

        { field: 'portfolioValues', title: 'Total Portfolios Values', hide: true, sort: false },
        { field: 'mortgagesOutstanding', title: 'Total Mortgages Outstanding', hide: true, sort: false },
        { field: 'monthlyMortgagesPayments', title: 'Total Monthly Mortgages', hide: true, sort: false },
        { field: 'rentalsIncome', title: 'Total Rentals Income', hide: true, sort: false },
        { field: 'growth', title: 'Growth', hide: true, sort: false },
        { field: 'yield', title: 'Yield', hide: true, sort: false },

        {
            field: 'property-action',
            title: 'Action',
            sort: false,
            headerClass: 'justify-center',
        },
    ];
}

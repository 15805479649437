<div
    class='panel w-auto overflow-x-auto whitespace-nowrap p-3'>
    <div class='flex flex-row w-full'>
        <h5 class='text-2xl'>{{title}}</h5>
    </div>
</div>

<div class='panel mt-6 pb-1.5'>

    <div class='flex flex-row gap-6 justify-end'>
        <app-table-chooser [(cols)]='cols'></app-table-chooser>
        <app-table-filters></app-table-filters>
    </div>

    <app-table
        [tableName]='title'
        [rows]='rows$ | async'
        [cols]='cols'
        sort='priorityEquityAvailable'
        sortDirection='desc'
        [brokersTableSelectors]='brokersTableSelectors'
        [priorityFilter]='priorityFilter'
        (updateContent)='updateContent($event)'
    ></app-table>
</div>


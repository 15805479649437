import { UserEffects } from './shared/store/user/user.effects';
import { CompanyEffects } from './shared/store/company/company.effects';
import { PropertyEffects } from './shared/store/property/property.effects';
import { BrokerEffects } from './shared/store/broker/broker.effects';
import { InsightEffects } from './dashboard/insight/store/insight.effects';
import { insightNode, insightReducer } from './dashboard/insight/store/insight.store';
import { propertyNode, propertyReducer } from './shared/store/property/property.store';
import { companyNode, companyReducer } from './shared/store/company/company.store';
import { brokerNode, brokerReducer } from './shared/store/broker/broker.store';
import { userNode, userReducer } from './shared/store/user/user.store';
import { dashboardLayoutNode, dashboardLayoutReducer } from './shared/store/dashboard/store';
import { investorNode, investorReducer } from './shared/store/investor/investor.store';
import { InvestorEffects } from './shared/store/investor/investor.effects';
import { tableNode, TableReducer } from './shared/store/table/table.store';
import { TableEffects } from './shared/store/table/table.effects';
import { DashboardEffects } from './shared/store/dashboard/dashboard.effects';
import { appNode, AppReducer } from './shared/store/app/app.store';
import { AppEffects } from './shared/store/app/app.effects';

export const reducers = {
    [appNode]: AppReducer,
    [dashboardLayoutNode]: dashboardLayoutReducer,
    [userNode]: userReducer,
    [brokerNode]: brokerReducer,
    [investorNode]: investorReducer,
    [companyNode]: companyReducer,
    [propertyNode]: propertyReducer,
    [insightNode]: insightReducer,
    [tableNode]: TableReducer,
};

export const effects = [
    AppEffects,
    UserEffects,
    CompanyEffects,
    InvestorEffects,
    PropertyEffects,
    BrokerEffects,
    InsightEffects,
    TableEffects,
    DashboardEffects,
];

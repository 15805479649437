import { createReducer, on } from '@ngrx/store';
import { Broker } from '../../type';
import { brokerActions } from './broker.actions';

export const brokerNode = 'broker';

export interface BrokerState {
    brokers: Broker[];
}

export const brokerInitialState: BrokerState = {
    brokers: [],
};

export const brokerReducer = createReducer(
    brokerInitialState,
    on(brokerActions.brokersSet, (state, action) => ({
        ...state,
        brokers: action.brokers,
    })),
);
